<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <v-card-title class="text-h5">
        ¿Agregar puntos de saldo a <br />{{ cliente.nombre }}?
      </v-card-title>
      <v-card-text>
        Los puntos de saldo son puntos que el cliente puede canjear como
        descuentos o por productos en sus compras
        <br />
        <br />
        <v-select
          :items="pagos"
          label="¿Cómo pagó el cliente por éstos puntos?"
          item-value="value"
          item-text="label"
          v-model="tipoPago"
        ></v-select>
        <v-text-field
          label="Saldo por agregar"
          type="number"
          v-model="puntosSaldo"
        />
        <v-select
          :items="empleados"
          label="Cajero"
          item-value="id"
          item-text="nombre"
          v-model="idEmpleado"
        ></v-select>
        <v-text-field label="Motivo" type="text" v-model="motivo" />
        <v-text-field
          label="Usuario"
          type="text"
          v-model="usuario"
          v-if="tipoPago == 'ninguno'"
        />
        <v-text-field
          label="Contraseña"
          type="password"
          v-model="password"
          v-if="tipoPago == 'ninguno'"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" text @click="dialog = false"> Cancelar </v-btn>
        <v-btn color="info" text @click="pedirPermiso()" :disabled="isLoading">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  name: "ModalSaldoCliente",
  props: ["cliente", "callback"],
  data: () => ({
    usuario: "",
    password: "",
    idEmpleado: 0,
    isLoading: false,
    dialog: false,
    puntosSaldo: 0,
    tipoPago: "efectivo",
    motivo: "Ninguno",
    empleados: [],
    pagos: [
      {
        label: "Efectivo",
        value: "efectivo"
      },
      { label: "Ninguno", value: "ninguno" },
      {
        label: "Crédito",
        value: "credito"
      },
      {
        label: "Débito",
        value: "debito"
      },
      { label: "Transferencia", value: "transferencia" },
      { label: "Ize. crédito", value: "izCredito" },
      { label: "Ize. débito", value: "izDebito" },
      { label: "Depósito en efectivo", value: "depEfectivo" }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    dialog(val) {
      if (!val) return;
      this.usuario = "usuario";
      this.password = "contraseña";
    }
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    async pedirPermiso() {
      if (this.tipoPago != "ninguno") {
        this.send();
        return;
      }
      if (!this.usuario || !this.password) {
        this.$toasted.error(
          "Introduce un usuario y/o contraseña con permisos para agregar saldo sin método de pago",
          {
            position: "bottom-right",
            duration: 2000
          }
        );
      }
      let myUrl = process.env.VUE_APP_API_URL + "abonos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "testSaldoSinMotivoPermisson",
        token: localStorage.token,
        geo: localStorage.geo,
        usuario: this.usuario.trim(),
        password: this.password
      });
      if (respuesta.data.permisson == "Ok") {
        this.send();
        this.passDialog = false;
      } else {
        this.$toasted.error("Datos incorrectos", {
          position: "bottom-right",
          duration: 2000
        });
      }
    },
    async send() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "abonos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        token: localStorage.token,
        geo: localStorage.geo,
        monto: this.puntosSaldo,
        tipo: "puntosSaldo",
        tipoPago: this.tipoPago,
        idCliente: this.cliente.id,
        idEmpleado: this.idEmpleado,
        idVenta: 0,
        idCaja: localStorage.idCaja,
        motivo: this.motivo
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Saldo agregado", {
          position: "bottom-right",
          duration: 5000
        });
        this.callback();
        this.updateSaldoCliente();
        this.usuario = "";
        this.password = "";
        this.puntosSaldo = 0;
        this.isLoading = false;
        this.dialog = false;
      }
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllAbsolute",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.empleados = respuesta.data.rows.reverse();
      this.isLoading = false;
    },
    initialize() {
      this.fetchEmpleados();
    },

    updateSaldoCliente() {
      this.$store.commit("clientes/updateSaldoCliente", {
        clienteId: this.cliente.id,
        saldo: this.puntosSaldo
      });
    }
  }
};
</script>
