<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <v-row align="end">
                <v-col cols="12" md="6" sm="12">
                  <h1>Reporte de movimiento de productos</h1>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="text-right">
                  <v-btn
                    elevation="2"
                    small
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <label>Desde: </label>
                  <input
                    type="date"
                    v-model="desde"
                    style="width: 170px; max-width:170px;"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <label>Hasta: </label>
                  <input
                    type="date"
                    v-model="hasta"
                    style="width: 170px; max-width:170px;"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="movimientos"
          :items-per-page="7"
          sort-by="movimientoId"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          key="movimientoId"
          class="elevation-1"
          calculate-widths
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
export default {
  name: "ReportesMovimientosProductos",
  data: () => ({
    loading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    busquedaCliente: "",
    busquedaCategoria: "",
    busquedaSubcategoria: "",
    busquedaMarca: "",
    categorias: [],
    subcategorias: [],
    marcas: [],
    headers: [
      { text: "Id", value: "id", width: "80px" },
      { text: "Fecha y hora", value: "fechaHora", width: "140px" },
      { text: "Producto", value: "nombreProducto", width: "240px" },
      { text: "Tipo", value: "tipo", width: "110px" },
      { text: "Status", value: "status", width: "140px" },
      { text: "Cajero", value: "nombreCajero", width: "140px" },
      { text: "Vendedor", value: "nombreVendedor", width: "140px" },
      { text: "Cliente", value: "nombreCliente", width: "140px" },
      { text: "Cantidad", value: "cantidad", width: "140px" },
      { text: "Monto", value: "monto", width: "140px" },
      { text: "Descuento aplicado", value: "descuentoAplicado", width: "140px" }
    ],
    ventas: [],
    empleados: [],
    placeholderVentas: [
      {
        id: "48459",
        fecha: "24/10/2021",
        hora: "20:15:00",
        status: "Por cobrar",
        vendedor: "Adiel Jadai Euán Baeza",
        mascota: "Chato(c)",
        cliente: "Sergio Alejandro May Zarraga",
        marca: "Incipets",
        categoria: "Clinica",
        subcategoria: "Baños",
        productoServicio: "Incineracion tradicional 2 a 5kg",
        precioCompraAI: "297.41",
        precioCompraDI: "345.00",
        cantidad: "1",
        subtotal: "706.90",
        impuestoPorcentaje: "16%",
        impuesto: "113.10",
        total: "820.00",
        descuentoPorcentaje: "0%",
        descuento: "0.00",
        neto: "820.00",
        cheque: "0.00",
        efectivo: "820.00",
        tarjetaCredito: "0.00",
        tarjetaDebito: "0.00",
        transferencia: "0.00",
        izeCredito: "0.00",
        izeDebito: "0.00",
        puntosUsados: "0.00"
      }
    ],
    movimientos: []
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchMovimientos(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchMovimientos(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  computed: {},
  methods: {
    async fetchMovimientos(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "inventario.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllReporteMovimientos",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta.data.status == "Ok") {
        this.movimientos = respuesta.data.rows;
        this.isLoading = false;
      }
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var clientes = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, clientes, "Movimientos");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.movimientos.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            // Verificar si el valor es numérico
            let valor = registro[header.value];
            if (!isNaN(valor) && valor !== "") {
              // Convertir el valor a número
              valor = Number(valor);
            }
            XLSX.utils.sheet_add_aoa(sheet, [[valor]], {
              // Agregar el valor al archivo
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-MovimientosProductos.xlsx`);
    },
    initialize() {
      this.fetchMovimientos(
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }
};
</script>
