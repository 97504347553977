import axios from "axios";

export const getAllClientes = async () => {
  let myUrl = process.env.VUE_APP_API_URL + "clientes.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getAll",
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") return respuesta.data.rows;
  return [];
};

export const getClientesIniciales = async () => {
  let myUrl = process.env.VUE_APP_API_URL + "clientes.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getInitials",
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") return respuesta.data.rows;
  return [];
};

export const getClientesBySearch = async search => {
  let myUrl = process.env.VUE_APP_API_URL + "clientes.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getBySearch",
    search,
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") return respuesta.data.rows;
  return [];
};
export const getClientesById = async id => {
  let myUrl = process.env.VUE_APP_API_URL + "clientes.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getById",
    id,
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") return respuesta.data.rows;
  return [];
};
