<template>
  <v-col cols="12" sm="12">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card :loading="isLoading">
          <v-card-title>
            <h1>
              {{ toCaps(empleados[0].nombre, { nombre: true }) }}
            </h1>
          </v-card-title>

          <v-card-text>
            <h2>
              <p>{{ empleados[0].cargo }}</p>
            </h2>
            <p><b>Teléfono 1: </b> {{ empleados[0].tel1 }}</p>
            <p><b>Teléfono 2: </b>{{ empleados[0].tel2 }}</p>
            <p><b>Correo: </b>{{ empleados[0].correo }}</p>
            <p><b>RFC: </b> {{ empleados[0].rfc }}</p>
            <p><b>Código postal: </b>{{ empleados[0].codigoPostal }}</p>
            <p><b>Dirección: </b>{{ empleados[0].direccion }}</p>
            <p><b>Departamento: </b> {{ empleados[0].departamento }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
import { proString } from "../../tools/proStrings";
export default {
  name: "DetallesCliente",
  data: () => ({
    headers: [
      {
        text: "Nombre",
        align: "start",
        value: "nombre"
      },
      { text: "Raza", value: "raza" },
      { text: "Edad", value: "edad" },
      { text: "Sexo", value: "sexo" },
      { text: "Esteril", value: "esteril" },
      { text: "Acciones", value: "acciones" }
    ],
    empleados: [
      {
        nombre: "Cargando...",
        tel1: "Cargando...",
        tel2: "Cargando...",
        direccion: "Cargando...",
        observaciones: "Cargando..."
      }
    ],
    mascotas: []
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.fetchEmpleados();
      this.fetchMascotas();
    },
    toCaps(myString, myOptions) {
      return proString(myString, myOptions);
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getOne",
        id: this.$route.params.id,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.empleados = respuesta.data.rows;
      this.isLoading = false;
    },
    async fetchMascotas() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "mascotas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      this.mascotas = respuesta.data.rows;
      this.mascotas = this.mascotas.filter(
        item => item.idPropietario == this.empleados[0].id
      );
      this.isLoading = false;
    }
  }
};
</script>
