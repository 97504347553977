import XLSX from "xlsx";

export default function xlsxFileToJson(file, callback) {
  var f = file;
  var reader = new FileReader();
  var celdas = [];
  reader.onload = function(e) {
    var data = e.target.result;
    data = new Uint8Array(data);
    //process_wb(XLSX.read(data, {type: 'array'}));
    /* read the file */
    var workbook = XLSX.read(data, { type: "array" }); // parse the file
    var sheet = workbook.Sheets[workbook.SheetNames[0]]; // get the first worksheet

    /* loop through every cell manually */
    var range = XLSX.utils.decode_range(sheet["!ref"]); // get the range
    for (var R = range.s.r; R <= range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var cellref = XLSX.utils.encode_cell({ c: C, r: R }); // construct A1 reference for cell
        if (!sheet[cellref]) continue; // if cell doesn't exist, move on
        var cell = sheet[cellref];
        celdas.push({ row: R, col: C, val: cell.v });
      }
    }
    callback(celdas);
  };
  reader.readAsArrayBuffer(f);
}
export function generarReporteExcel(datos, headers, nombreArchivo) {
  let columnasProcesadas = [];
  var hoja = XLSX.utils.json_to_sheet(columnasProcesadas);
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, hoja, "Datos");
  var sheet = wb.Sheets[wb.SheetNames[0]];
  var offset = 1;

  datos.map((registro, i) => {
    let col = 0;
    headers.forEach(header => {
      if (i == 0) {
        // Agregar los encabezados con un formato general
        XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
          origin: XLSX.utils.encode_cell({ r: i, c: col })
        });
      }
      if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
        let valor = registro[header.value];
        let cell = { v: valor };
        if (!isNaN(valor) && valor !== "") {
          // Verificación y conversión a número
          cell.v = Number(valor);
        }
        XLSX.utils.sheet_add_json(sheet, [cell], {
          origin: XLSX.utils.encode_cell({ r: i + offset, c: col }),
          skipHeader: true
        });
      }
      col++;
    });
  });

  XLSX.writeFile(wb, `${nombreArchivo}.xlsx`);
}
