<style>
.coloroption {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  padding-top: 2px;
}
</style>
<template>
  <v-col cols="12">
    <v-select
      :items="arrayCodigos"
      item-text="codigo"
      item-value="codigo"
      label="Codigos Internos"
      v-model="valuesArray"
      multiple
    >
      <template slot="item" slot-scope="{ item }">
        <div>
          <div :class="`${item.class} white--text coloroption`">
            {{ item.codigo }}
          </div>
        </div>
      </template>
    </v-select>
  </v-col>
  <!--v-model="cliente.codigosInternos"--->
</template>
<script>
import { internalCodeColor } from "../tools/randomColor";
export default {
  name: "SelectCodInternos",
  data: () => ({
    codInternos: {
      mascotas: [
        {
          nombre: "Rojo",
          id: "0",
          hex: "#c62828",
          class: "red darken-3",
          codigo: "A"
        },
        {
          nombre: "Amarillo",
          id: "1",
          hex: "#f9a825",
          class: "yellow darken-3",
          codigo: "B"
        },
        {
          nombre: "Negro",
          id: "2",
          hex: "#212121",
          class: "grey darken-4",
          codigo: "C"
        },
        {
          nombre: "Morado",
          id: "3",
          hex: "#6A1B9A",
          class: "purple darken-3",
          codigo: "E"
        },
        {
          nombre: "Verde",
          id: "4",
          hex: "#CDDC39",
          class: "lime",
          codigo: "I"
        },
        {
          nombre: "Naranja",
          id: "5",
          hex: "#e64a19",
          class: "deep-orange darken-2",
          codigo: "D"
        },
        {
          nombre: "Café",
          id: "6",
          hex: "#6d4c41",
          class: "brown darken-1",
          codigo: "K"
        }
      ],
      clientes: [
        {
          nombre: "Naranja",
          id: "0",
          hex: "#c62828",
          class: "orange darken-1",
          codigo: 1
        },
        {
          nombre: "Amarillo",
          id: "1",
          hex: "#f9a825",
          class: "yellow lighten-1",
          codigo: 2
        },
        {
          nombre: "Verde",
          id: "2",
          hex: "#212121",
          class: "green darken-3",
          codigo: 3
        },
        {
          nombre: "Azul",
          id: "3",
          hex: "#e64a19",
          class: "light-blue darken-4",
          codigo: 4
        },
        {
          nombre: "Rojo",
          id: "4",
          hex: "#6d4c41",
          class: "red darken-4",
          codigo: 5
        },
        {
          nombre: "Gris",
          id: "5",
          hex: "#757575",
          class: "grey darken-1",
          codigo: 6
        },
        {
          nombre: "Negro",
          id: "6",
          hex: "#424242",
          class: "grey darken-3",
          codigo: 7
        }
      ]
    },
    tipo: "",
    arrayCodigos: [],
    valuesArray: []
  }),
  props: {
    valor: Object // Define the data type
  },
  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.opciones();
    },
    getArray() {
      return this.valuesArray.map(codInternoObj => {
        return codInternoObj;
      });
    },
    setArray(newArray) {
      this.valuesArray = this.codInternos[this.valor.tipo].filter(
        codInternoObj => {
          return (
            newArray.filter(arrNum => codInternoObj.codigo == arrNum).length > 0
          );
        }
      );
    },
    internColorSelector(object) {
      return internalCodeColor(object);
    },
    opciones() {
      this.arrayCodigos = this.codInternos[this.valor.tipo];
    }
  }
};
</script>
