/* eslint-disable */
<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Grupos</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="blue"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="grupos"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :header-props="headerProps"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 250, 500, -1]
          }"
          dense
        >
          <template v-slot:[`item.detalles`]="{ item }">
            <v-btn icon color="info" link :to="`/DetallesCliente/${item.id}`">
              <v-icon small class="mr-0">mdi-text-box</v-icon>
            </v-btn>
            <v-btn icon @click="editItem(item.id)">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon @click="deleteItem(item)">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="blue my-6" rounded @click="initialize">
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
export default {
  name: "Grupos",
  data: () => ({
    search: "",
    isLoading: false,
    headerProps: {
      sortByText: "Ordenar por"
    },
    headers: [{ text: "Nombre", value: "nombre" }],
    grupos: [],
    cli: {}
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", { name: "grupos", search: val });
    }
  },
  methods: {
    initialize() {
      this.fetchGrupos();
      this.search = this.$store.state.searchParams["grupos"];
    },
    async fetchGrupos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "grupos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.grupos = respuesta.data.rows;
      this.isLoading = false;
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    }
  }
};
</script>
