<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      @keydown.esc="cerrar"
      @click:outside="cerrar"
      max-width="600px"
    >
      <v-card>
        <v-form
          ref="entryForm"
          color="teal"
          v-model="valid"
          @submit.prevent="submitHandler"
        >
          <v-card-title>
            <span class="headline">
              <span v-if="llamada.id != undefined">Actualizar</span>
              <span v-else>Agregar</span> Llamada
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    @dblclick="resetearCampos"
                    label="Cliente"
                    v-model="llamada.idCliente"
                    :rules="nameRules"
                    :items="clientesFiltrados"
                    :loading="isLoading"
                    item-text="nombre"
                    item-value="id"
                    required
                    ref="cliente"
                    @keydown="handleClienteSearchInput"
                    @keydown.enter="
                      focusNext({ actualRef: 'cliente', nextRef: 'mascota' })
                    "
                  >
                    <template v-slot:item="{ item }">
                      <template v-if="testIfObject(item)">
                        <v-list-item>
                          <v-list-item-content
                            v-text="item"
                          ></v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-list-item>
                        <perfil
                          :item="{
                            nombre: item.nombre,
                            imagen: '',
                            tipo: 'clientes',
                            codigosInternos: codigosInternos(
                              item.codigosInternos
                            ),
                            tamano: 'sm'
                          }"
                        ></perfil>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="item.nombre"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="item.tipo"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    @dblclick="resetearCampos"
                    label="Mascota"
                    v-model="llamada.idMascota"
                    :rules="nameRules"
                    :items="mascotasFiltradas"
                    :loading="isLoading"
                    item-text="nombre"
                    item-value="id"
                    required
                    ref="mascota"
                    @keydown.enter="
                      focusNext({ actualRef: 'tamano', nextRef: 'direccion' })
                    "
                    @focus="
                      () => {
                        fetchMascotas(llamada.idCliente);
                      }
                    "
                  >
                    <template v-slot:item="{ item }">
                      <template v-if="testIfObject(item)">
                        <v-list-item>
                          <v-list-item-content
                            v-text="item"
                          ></v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-list-item>
                        <perfil
                          :item="{
                            nombre: item.nombre,
                            imagen: '',
                            tipo: 'mascotas',
                            codigosInternos: codigosInternos(
                              item.codigosInternos
                            ),
                            tamano: 'sm'
                          }"
                        ></perfil>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="item.nombre"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="item.propietario"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-select
                    label="Tipo de llamada"
                    v-model="llamada.tipo"
                    :items="tiposLlamadas"
                    item-text="nombre"
                    item-value="valor"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Fecha de llamada"
                    type="date"
                    v-model="llamada.fechaLlamar"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Motivo de llamada"
                    type="text"
                    v-model="llamada.motivo"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar">Cerrar</v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-if="llamada.id == undefined"
              :disabled="isSending"
            >
              Agregar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-else
              :disabled="isSending"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Perfil from "../../components/Perfil";
export default {
  name: "ModalLlamadas",
  components: { Perfil },
  data: () => ({
    isLoading: false,
    isSending: false,
    valid: false,
    tipoRequest: "",
    empleados: [],
    nameRules: [v => !!v || "El nombre es requerido"],
    tiposLlamadas: [
      { nombre: "Recordatorio", valor: "recordatorio" },
      { nombre: "Filtro médico", valor: "filtroMedico" },
      { nombre: "Redes", valor: "redes" },
      { nombre: "Teléfono", valor: "telefono" },
      { nombre: "WhatsApp", valor: "whatsapp" },
      { nombre: "Carnet", valor: "carnet" }
    ]
  }),

  computed: {
    ...mapState("clientes", ["clientes"]),
    ...mapState("mascotas", ["mascotas"]),
    open() {
      return this.$store.state.modals.llamadas.open;
    },

    llamada() {
      return this.$store.state.modals.llamadas.item;
    },
    mascotasFiltradas() {
      if (!this.llamada.idCliente) {
        return this.mascotas;
      } else {
        if (this.llamada) {
          return this.mascotas.filter(
            mascota => mascota.idPropietario == this.llamada.idCliente
          );
        }
        return this.mascotas;
      }
    },
    clientesFiltrados() {
      if (!this.llamada.idMascota) {
        return this.clientes;
      } else {
        let miMascota = this.mascotas.filter(
          mascota => this.llamada.idMascota == mascota.id
        )[0];
        if (miMascota) {
          let misClientesFiltrados = this.clientes.filter(
            cliente => cliente.id == miMascota.idPropietario
          );
          return misClientesFiltrados;
        }
        return this.clientes;
      }
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.fetchEmpleados();
    },
    testIfObject(item) {
      return typeof item !== "object";
    },
    resetearCampos() {
      this.llamada.idCliente = 0;
      this.llamada.idMascota = 0;
    },
    codigosInternos(cods) {
      if (cods?.length && cods?.length > 1) {
        try {
          const parsedCods = JSON.parse(cods);

          if (Array.isArray(parsedCods)) {
            return [...parsedCods];
          }
          return [];
        } catch (error) {
          return [];
        }
      }
      return [];
    },
    focusNext(parameters) {
      let { actualRef, nextRef } = {
        actualRef: null,
        nextRef: null,
        ...parameters
      };
      if (actualRef) this.$refs[actualRef].blur();
      if (nextRef) this.$refs[nextRef].focus();
    },
    handleClienteSearchInput(e) {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchClientes(e);
      }, 500);
    },
    async fetchMascotas(idCliente = null) {
      this.isLoading = true;
      if (idCliente)
        await this.$store.dispatch("mascotas/getByOwnerId", idCliente);
      else await this.$store.dispatch("mascotas/getIniciales");
      this.isLoading = false;
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.empleados = respuesta.data.rows;
      this.isLoading = false;
    },
    async updateLlamada() {
      this.isSending = true;

      let myUrl = process.env.VUE_APP_API_URL + "llamadas.php";
      let tipo = "update";
      if (!this.llamada.id) tipo = "insert";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.llamada]
      });

      if (respuesta.data.status == "Ok") {
        this.isSending = false;

        this.$store.state.modals["llamadas"].callback();

        if (this.llamada.id == undefined) {
          this.$toasted.success("Llamada agregada", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Llamada actualizada", {
            position: "bottom-right",
            duration: 5000
          });
        }

        this.cerrar();
      }
    },
    async submitHandler() {
      let valido = await this.$refs.entryForm.validate();
      if (valido) {
        this.updateLlamada();
      }
    },
    async fetchSearchClientes(e) {
      this.isLoadingCliente = true;
      await this.$store.dispatch("clientes/getBySearch", e.target.value);
      this.isLoadingCliente = false;
    },
    cerrar() {
      this.$store.commit("openModal", {
        modal: "llamadas",
        open: false
      });
    }
  }
};
</script>
