<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <h1>Consultas</h1>
              <br />
              <v-row>
                <v-col cols="12" sm="12" md="5" lg="5">
                  <label>Desde: </label>
                  <input type="date" v-model="desde" />
                </v-col>
                <v-col cols="12" sm="12" md="5" lg="5">
                  <label>Hasta: </label>
                  <input type="date" v-model="hasta" />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-btn
                    elevation="2"
                    small
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col>
                <v-col cols="12" sm="12" md="5" lg="5">
                  <v-chip>Número de consultas: {{ numeroConsultas }}</v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="consultas"
          :items-per-page="7"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          calculate-widths
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
export default {
  name: "ReportesEstetica",
  data: () => ({
    isLoading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    headers: [
      { text: "No. Cons.", value: "id" },
      { text: "Status", value: "status", width: "140px" },
      { text: "Propietario", value: "nombreCliente" },
      { text: "MVZ", value: "nombreEmpleado" },
      { text: "Teléfono", value: "clienteTel" },
      { text: "Direccion", value: "direccion", width: "200px" },
      { text: "Mascota", value: "nombreMascota" },
      { text: "Raza", value: "raza" },
      { text: "Servicio", value: "servicio" },
      { text: "SAD", value: "sad" },
      { text: "Motivos", value: "motivo" },
      { text: "Fecha registro", value: "fechaRegistro", width: "130px" },
      { text: "Hora registro", value: "horaRegistro", width: "130px" },
      { text: "Registrado por", value: "nombreResponsableRegistro" },
      { text: "Citado por", value: "nombreResponsableCita" },
      { text: "Fuente", value: "fuente" },
      { text: "Con cita", value: "conCita", width: "80px" },
      { text: "Golden", value: "isFirstTime", width: "80px" },
      { text: "Fecha cita", value: "fechaCita", width: "130px" },
      { text: "Hora cita", value: "horaCita", width: "130px" }
    ],
    consultas: [],
    placeholderConsultas: []
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchConsultas(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchConsultas(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  computed: {
    numeroConsultas() {
      return this.consultas.length;
    }
  },
  methods: {
    async fetchConsultas(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "serviciosClinica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllReporte",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta) {
        this.consultas = respuesta.data.rows;
        this.isLoading = false;
      }
      //this.consultas = this.placeholderConsultas;
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var consultas = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, consultas, "Consultas");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.consultas.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            // Verificar si el valor es numérico
            let valor = registro[header.value];
            if (!isNaN(valor) && valor !== "") {
              // Convertir el valor a número
              valor = Number(valor);
            }
            XLSX.utils.sheet_add_aoa(sheet, [[valor]], {
              // Agregar el valor al archivo
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-consultas.xlsx`);
    },
    initialize() {
      this.fetchConsultas(
        moment()
          .subtract(1, "weeks")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }
};
</script>
