<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Mascotas</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="indigo"
                dense
                @keydown="handleSearchInput"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                v-if="canAddMascotas"
                elevation="2"
                small
                color="indigo white--text "
                @click="addItem()"
                >Agregar mascota</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="mascotasFiltradas"
          :items-per-page="7"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.edad`]="{ item }">
            <span>{{ getEdad(item.fechaNacimiento) }}</span>
          </template>
          <template v-slot:[`item.nombre`]="{ item }">
            <NombreMascota
              :mascota="{
                nombre: item.nombre,
                codigosInternos: item.codigosInternos
              }"
            />
          </template>
          <template v-slot:[`item.propietario`]="{ item }">
            <NombreCliente
              :cliente="{
                nombre: item.propietario,
                codigosInternos: item.codigosInternosPropietario
              }"
            />
          </template>
          <template v-slot:[`item.fechaNacimiento`]="{ item }">
            <span>{{ getFormattedDate(item.fechaNacimiento) }}</span>
          </template>
          <template v-slot:[`item.detalles`]="{ item }">
            <v-btn icon color="info" link :to="`/DetallesMascota/${item.id}`">
              <v-icon small>mdi-text-box</v-icon>
            </v-btn>
            <v-btn icon color="info" @click="editItem(item)">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon color="danger" @click="deleteItem(item)">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="indigo white--text my-6" rounded @click="initialize">
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../../tools/proStrings";
import NombreCliente from "../Clientes/NombreCliente.vue";
import NombreMascota from "../Mascotas/NombreMascota.vue";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "Mascotas",
  components: { NombreCliente, NombreMascota },
  data: () => ({
    isLoading: false,
    loading: false,
    search: "",
    tipoRequest: "",
    valid: false,
    debouncing: null,
    canAddMascotas: null,
    formData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigoInterno: "",
      rfc: "",
      codigoPostal: "",
      direccion: "",
      departamento: "",
      cargo: ""
    },
    emptyData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigosInternos: [],
      rfc: "",
      codigoPostal: "",
      direccion: "",
      departamento: "",
      cargo: ""
    },
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Grupo/Especie", value: "grupo" },
      { text: "Específico/Raza", value: "especifico" },
      { text: "Fecha de Nacimiento", value: "fechaNacimiento" },
      { text: "Edad", value: "edad" },
      { text: "Sexo", value: "sexo" },
      { text: "Tamaño", value: "tamano" },
      { text: "Estéril", value: "esteril" },
      { text: "Propietario", value: "propietario", align: "center" },
      { text: "Acciones", value: "detalles", align: "right" }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 450);

      this.$store.commit("setSearchParams", {
        name: "mascotas",
        search: val
      });
    }
  },
  computed: {
    ...mapState("mascotas", ["mascotas"]),
    mascotasFiltradas() {
      if (this.loading) return [];
      let registrosSorteados = [...(this.mascotas || [])];
      registrosSorteados = registrosSorteados?.filter(registro => {
        return (
          matchStrings({ s: this.search, str: registro.nombre }) ||
          matchStrings({ s: this.search, str: registro.propietario }) ||
          this.search == ""
        );
      });
      return registrosSorteados;
    }
  },
  methods: {
    initialize() {
      this.fetchMascotas();
      this.checkPermissons();
      this.search = this.$store.state.searchParams["mascotas"] || "";
    },
    checkPermissons() {
      this.canAddMascotas = JSON.parse(localStorage.permisos)?.some(
        permiso => permiso.id == 63
      );
    },
    handleSearchInput() {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchMascotas();
      }, 500);
    },
    getEdad(date) {
      const edad = moment().diff(date, "months");
      const years = parseInt(edad / 12);
      const months = edad % 12;
      let stringEdad = "";
      years != 1
        ? (stringEdad = years + " años")
        : (stringEdad = years + "año");
      months != 1
        ? (stringEdad = stringEdad + " y " + months + " meses")
        : (stringEdad = stringEdad + " y " + months + " mes");
      return stringEdad;
    },
    getFormattedDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    async fetchMascotas() {
      this.isLoading = true;
      await this.$store.dispatch("mascotas/getIniciales");
      this.isLoading = false;
    },
    async fetchSearchMascotas() {
      this.isLoading = true;
      await this.$store.dispatch("mascotas/getBySearch", this.search);
      this.isLoading = false;
    },

    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "mascotas",
        item: {},
        callback: () => {
          this.$store.commit("mascotas/refreshCache");
          this.fetchMascotas();
        }
      });

      this.$store.commit("openModal", { modal: "mascotas", open: true });
    },
    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "mascotas",
        item: { ...myItem },
        callback: () => {
          this.$store.commit("mascotas/refreshCache");
          this.fetchMascotas();
        }
      });
      this.$store.commit("openModal", { modal: "mascotas", open: true });
    },
    async deleteItem(item) {
      if (!confirm(`¿Realmente deseas eliminar el registro ${item.nombre}?`)) {
        return;
      }
      this.$store.commit("mascotas/refreshCache");
      let myUrl = process.env.VUE_APP_API_URL + "mascotas.php";
      let response = await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: item.id
      });
      if (response.data.status == "Ok") {
        this.$toasted.success("Se eliminó exitosamente la mascota", {
          position: "bottom-right",
          duration: 5000
        });
        this.fetchMascotas();
      }
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    }
  }
};
</script>
