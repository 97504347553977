<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Biometrías</h1>
              <br />
              <v-text-field
                @keydown.enter="simulateTab"
                :loading="isLoading"
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="indigo"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
              <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="2"
                    small
                    color="indigo white--text "
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      () => {
                        dialog = true;
                        resetBiometriaHematica();
                      }
                    "
                  >
                    Agregar biometriaHematica
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Biometría hemática</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                        v-if="biometriaHematica.id == 0"
                        dark
                        text
                        @click="send"
                      >
                        Agregar
                      </v-btn>
                      <v-btn v-else dark text @click="send"> Actualizar </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-row
                    align="start"
                    justify="center"
                    class="container text-center"
                  >
                    <v-col cols="12" sm="3">
                      <h4 class="title">Datos generales</h4>
                      <v-autocomplete
                        :loading="isLoading"
                        label="Cliente"
                        dense
                        :items="clientes"
                        @keydown="handleClienteSearchInput"
                        @focus="handleClienteSearchInput"
                        v-model="biometriaHematica.idCliente"
                        item-value="id"
                        item-text="nombre"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.nombre"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="data.item.tel1"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                      <v-select
                        :loading="isLoading"
                        label="Mascota"
                        dense
                        :items="mascotasFiltradas"
                        v-model="biometriaHematica.idMascota"
                        item-value="id"
                        item-text="nombre"
                        @focus="
                          () => {
                            fetchMascotas(biometriaHematica.idCliente);
                          }
                        "
                      ></v-select>
                      <v-select
                        :loading="isLoading"
                        label="MVZ"
                        dense
                        :items="empleados"
                        v-model="biometriaHematica.idMvz"
                        item-value="id"
                        item-text="nombre"
                      ></v-select>
                      <v-text-field
                        @keydown.enter="simulateTab"
                        :loading="isLoading"
                        type="date"
                        label="Fecha"
                        v-model="biometriaHematica.fecha"
                      ></v-text-field>
                      <v-text-field
                        @keydown.enter="simulateTab"
                        :loading="isLoading"
                        type="time"
                        label="Hora"
                        v-model="biometriaHematica.hora"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <h4 class="title">Examen</h4>
                      <div
                        v-for="(examen, ind) in examenFisico"
                        :key="`examen${ind}`"
                      >
                        <v-text-field
                          @keydown.enter="
                            e => {
                              if (ind < examenFisico.length - 1) simulateTab(e);
                            }
                          "
                          :loading="isLoading"
                          type="text"
                          :label="examen.text"
                          dense
                          v-model="biometriaHematica[`${examen.attr}`]"
                          :error="examen.hasError"
                          @keydown="
                            () => {
                              examen.hasError = false;
                            }
                          "
                        />
                      </div>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <h4>
                        Otras biometrías de
                        {{ biometriaHematica.nombreMascota }}
                      </h4>
                      <v-simple-table dense fixed-header height="500px">
                        <template v-slot:default>
                          <thead>
                            <tr class="text-left">
                              <th class="pa-2">Acciones</th>
                              <th class="pa-2">
                                Fecha <br />
                                año/mes/día
                              </th>
                              <th class="pa-2">Médico</th>
                              <th class="pa-2">Mascota</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="text-left"
                              v-for="(bio, ind) in otrasBiometriasHematicas"
                              :key="`examen${ind}`"
                            >
                              <td>
                                <v-btn
                                  icon
                                  small
                                  class="pa-2"
                                  color="warning"
                                  @click="changeTo(bio)"
                                >
                                  <v-icon small> mdi-folder </v-icon>
                                </v-btn>
                                <v-btn
                                  icon
                                  small
                                  class="pa-2"
                                  color="info"
                                  @click="printBiometria(bio)"
                                >
                                  <v-icon small> mdi-file </v-icon>
                                </v-btn>
                              </td>
                              <td class="pa-2">{{ bio.fecha }}</td>
                              <td class="pa-2">{{ bio.nombreMvz }}</td>
                              <td class="pa-2">{{ bio.nombreMascota }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="biometriasHematicas"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.nombreCliente`]="{ item }">
            <NombreCliente :cliente="{ ...item, nombre: item.nombreCliente }" />
          </template>
          <template v-slot:[`item.nombreMascota`]="{ item }">
            <NombreMascota :mascota="{ ...item, nombre: item.nombreMascota }" />
          </template>
          <template v-slot:[`item.detalles`]="{ item }">
            <v-btn icon color="info" @click="editItem(item)">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon color="info" @click="printBiometria(item)">
              <v-icon small> mdi-file </v-icon>
            </v-btn>
            <v-btn disabled icon @click="deleteItem(item)">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="indigo white--text my-6" rounded @click="initialize">
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { bioAutotable } from "../tools/examenesClinicos";
import { proString } from "../tools/proStrings";
import { matchStrings } from "../tools/proStrings";
import NombreCliente from "./Clientes/NombreCliente.vue";
import NombreMascota from "./Mascotas/NombreMascota.vue";
import { mapState } from "vuex";

export default {
  name: "BiometriaHematica",
  components: { NombreCliente, NombreMascota },
  data: () => ({
    isLoading: false,
    dialog: false,
    search: "",
    tipoRequest: "",
    valid: false,
    empleados: [],
    biometriaHematica: {
      anamnesis: ""
    },
    formData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigoInterno: "",
      rfc: "",
      codigoPostal: "",
      direccion: "",
      departamento: "",
      cargo: ""
    },
    emptyData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigosInternos: [],
      rfc: "",
      codigoPostal: "",
      direccion: "",
      departamento: "",
      cargo: ""
    },
    headers: [
      { text: "id Cliente", value: "idCliente" },
      { text: "Cliente", value: "nombreCliente" },
      { text: "id Mascota", value: "idMascota" },
      { text: "Mascota", value: "nombreMascota" },
      { text: "MVZ", value: "nombreMvz" },
      { text: "Fecha", value: "fecha" },
      { text: "Acciones", value: "detalles", align: "right" }
    ],
    examenFisico: [
      { text: "WBC % X 10^9/L", attr: "wbcP" },
      { text: "LYM %", attr: "lymP" },
      { text: "MID %", attr: "midP" },
      { text: "NEU %", attr: "neuP" },
      { text: "EOS %", attr: "eosP" },
      { text: "BASO %", attr: "basoP" },
      { text: "LYM # X 10^9/L", attr: "lymN" },
      { text: "MID # X 10^9/L", attr: "midN" },
      { text: "NEU # X 10^9/L", attr: "neuN" },
      { text: "EOS # X 10^9/L", attr: "eosN" },
      { text: "BASO # X 10^9/L", attr: "basoN" },
      { text: "RBC X 10^9/L", attr: "rbc" },
      { text: "HGB g/dL", attr: "hgb" },
      { text: "HCT %", attr: "hctP" },
      { text: "MCV fL", attr: "mcv" },
      { text: "MCH pg", attr: "mch" },
      { text: "MCHC g/dL", attr: "mchc" },
      { text: "RDW_CV %", attr: "rdwcvP" },
      { text: "RDW_SD fL", attr: "rdwsd" },
      { text: "PLT X 10^9/L", attr: "plt" },
      { text: "MPV fL", attr: "mpv" },
      { text: "PDW fL", attr: "pdw" },
      { text: "PCT %", attr: "pctP" },
      { text: "P_LCR %", attr: "plcrP" },
      { text: "P_LCC X 10^9/L", attr: "plcc" }
    ],
    biometriasHematicas: [],
    otrasBiometriasHematicas: []
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapState("clientes", ["clientes"]),
    ...mapState("mascotas", ["mascotas"]),
    mascotasFiltradas() {
      return this.mascotas.filter(
        mascota => mascota.idPropietario == this.biometriaHematica.idCliente
      );
    }
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "biometriasHematicas",
        search: val
      });
    },
    async dialog(val) {
      if (val) {
        this.fetchClientes(this.biometriaHematica.idCliente);
        this.fetchMascotas(this.biometriaHematica.idCliente);
        this.getOtrasBiometriasHematicas();
      }
    }
  },
  methods: {
    initialize() {
      this.fetchBiometriasHematicas();
      this.fetchEmpleados();
      this.fetchClientes();
      this.fetchMascotas();
      this.search = this.$store.state.searchParams["biometriasHematicas"];
    },
    handleClienteSearchInput(e) {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchClientes(e);
      }, 500);
    },
    simulateTab(event) {
      const focusableElements = document.querySelectorAll(
        "a[href], button, input, select, textarea"
      );
      const currentIndex = Array.from(focusableElements).indexOf(event.target);
      const nextIndex = currentIndex + 1;
      if (nextIndex < focusableElements.length) {
        focusableElements[nextIndex].focus();
      }
    },
    filtroClientes(item, queryText) {
      const textOne = item.nombre.toLowerCase();
      const textTwo = item.tel1.toLowerCase();
      const textThree = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThree.indexOf(searchText) > -1
      );
    },
    changeTo(item) {
      this.isLoading = true;
      let timer = Math.random();
      setTimeout(() => {
        this.isLoading = false;
        this.editItem(item);
      }, 200 + timer * 1000);
    },

    async getOtrasBiometriasHematicas() {
      this.isLoading = true;
      await this.$nextTick();

      this.otrasBiometriasHematicas = this.biometriasHematicas.filter(
        biometriaHematica =>
          biometriaHematica.idMascota == this.biometriaHematica.idMascota
      );

      this.isLoading = false;
    },
    async fetchClientes(idCliente = null) {
      this.isLoading = true;
      if (idCliente) await this.$store.dispatch("clientes/getById", idCliente);
      else await this.$store.dispatch("clientes/getIniciales");
      this.isLoading = false;
    },
    async fetchMascotas(idCliente = null) {
      this.isLoading = true;
      if (idCliente)
        await this.$store.dispatch("mascotas/getByOwnerId", idCliente);
      else await this.$store.dispatch("mascotas/getIniciales");
      this.isLoading = false;
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllMvz",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") this.empleados = respuesta.data.rows;
      this.isLoading = false;
    },
    async fetchBiometriasHematicas() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "biometriasHematicas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.biometriasHematicas = respuesta.data.rows;
      this.isLoading = false;
    },
    async send() {
      this.isLoading = true;
      let tipo = "insert";
      let msg = "Química sanguinea agregada";

      const incompletedFields = this.examenFisico.filter(analito => {
        if (!this.biometriaHematica[`${analito.attr}`]) {
          analito.hasError = true;
          return true;
        }
      });
      if (incompletedFields.length) {
        incompletedFields.forEach(field => {
          this.$toasted.error(`${field.text} no tiene un valor.`, {
            position: "bottom-right",
            duration: 5000
          });
        });
        this.isLoading = false;
        return;
      }

      if (this.biometriaHematica.id !== 0) {
        tipo = "update";
        msg = "Química sanguinea actualizada";
      }
      let myUrl = process.env.VUE_APP_API_URL + "biometriasHematicas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: tipo,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.biometriaHematica]
      });
      console.log(this.biometriaHematica);
      if (respuesta.data.status == "Ok") {
        this.isLoading = false;

        this.$toasted.success(msg, {
          position: "bottom-right",
          duration: 5000
        });
        this.dialog = false;
        this.fetchBiometriasHematicas();
      }
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "biometriasHematicas",
        item: {},
        callback: this.fetchBiometriasHematicas
      });

      this.$store.commit("openModal", {
        modal: "biometriasHematicas",
        open: true
      });
    },

    async printBiometria(item) {
      this.biometriaHematica = item;
      //let pdfName = "test";
      let lineHeight = 3;
      let totalWidth = 160;
      let actualLine = 10;
      let sangria = 4;
      //let space = 5;
      var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [totalWidth, 200]
      });
      doc.setFont("helvetica");
      doc.setFontSize(7);
      doc.text("Abel Alfonso Ugalde Pérez", sangria, actualLine);
      actualLine += lineHeight;
      doc.text("RFC: UAPA810924HK6", sangria, actualLine);
      actualLine += lineHeight;
      doc.text("Calle 8 x 15 y 15-B #213 Vergel", sangria, actualLine);
      actualLine += lineHeight;
      doc.text("C.P. 97173", sangria, actualLine);
      actualLine += lineHeight;
      doc.line(sangria, actualLine, totalWidth - sangria, actualLine);
      actualLine += lineHeight;
      let myFecha = moment(this.biometriaHematica.fecha).format("DD/MM/yyyy");
      doc.text(
        `Fecha: ${myFecha} Hora: ${this.biometriaHematica.hora}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.text(
        `Cliente:${proString(
          this.biometriaHematica.nombreCliente,
          {}
        )} | Tel: ${this.biometriaHematica.telCliente}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.text(
        `${proString(this.biometriaHematica.direccionCliente, {})}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      actualLine += lineHeight;
      let nacimientoMascota = moment(
        this.biometriaHematica.nacimientoMascota
      ).format("DD/MM/yyyy");
      doc.text(
        `${this.biometriaHematica.grupoMascota} |  ${
          this.biometriaHematica.especificoMascota
        } | ${proString(
          this.biometriaHematica.nombreMascota,
          {}
        )} | Nació ${nacimientoMascota}  | ${
          this.biometriaHematica.sexoMascota
        } | ${this.biometriaHematica.esterilMascota} esterilizado `,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.line(sangria, actualLine, totalWidth - sangria, actualLine);
      actualLine += lineHeight;
      doc.text(`Biometría hemática`, sangria, actualLine);

      actualLine += lineHeight;

      // Verifica si la mascota no tiene asignada una raza ni un grupo específico
      if (
        !this.biometriaHematica.grupoMascota ||
        !this.biometriaHematica.especificoMascota
      ) {
        actualLine += lineHeight;
        doc.text(
          "Esta mascota no tiene asignada una raza ni un grupo específico (esto es necesario para mostrar la columna de observaciones)",
          sangria,
          actualLine
        );
        actualLine += lineHeight;
        doc.line(sangria, actualLine, totalWidth - sangria, actualLine);
        actualLine += lineHeight;
      }

      // Aquí determinamos los analitos
      let analitos = bioAutotable(this.biometriaHematica);
      // Aquí determinamos los analitos

      let tableHeight = 0;
      doc.autoTable({
        styles: {
          fontSize: "7",
          fillColor: "#fff",
          textColor: "#111",
          cellPadding: 1
        },
        theme: "striped",
        startY: actualLine,
        margin: {
          top: sangria,
          left: sangria,
          right: sangria,
          bottom: sangria
        },
        body: analitos,
        columns: [
          {
            header: "Analito",
            dataKey: "analito"
          },
          {
            header: "Resultado",
            dataKey: "resultado"
          },
          {
            header: "Rango mín.",
            dataKey: "rangoMin"
          },
          {
            header: "Rango máx.",
            dataKey: "rangoMax"
          },
          {
            header: "Observaciones",
            dataKey: "observaciones"
          }
        ],
        didDrawCell: data => {
          tableHeight = data.cursor.y + data.row.height;
        }
      });

      actualLine = tableHeight + actualLine / 3;
      actualLine += lineHeight;

      doc.line(sangria, actualLine, totalWidth - sangria, actualLine);

      actualLine += lineHeight;

      doc.text(
        `Atentamente: ${this.biometriaHematica.nombreMvz}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;

      let img = new Image();
      img.src = require("@/assets/zhLogo.png");
      doc.addImage(img, "png", totalWidth - 30 - sangria, sangria, 30, 10); //doc.save(pdfName + ".pdf");
      doc.autoPrint();
      //This is a key for printing
      doc.output("dataurlnewwindow");
    },

    async editItem(myItem) {
      /* this.$store.commit("setItemModal", {
        modal: "biometriasHematicas",
        item: { ...myItem },
        callback: this.fetchBiometriasHematicas
      });
      this.$store.commit("openModal", { modal: "biometriasHematicas", open: true }); */
      this.biometriaHematica = { ...myItem };
      this.dialog = true;
    },
    resetBiometriaHematica() {
      for (let key in this.biometriaHematica) {
        this.biometriaHematica[key] = "";
        this.biometriaHematica.id = 0;
      }
      this.examenFisico.forEach(analito => (analito.hasError = false));
      this.biometriaHematica.fecha = moment().format("YYYY-MM-DD");
      this.biometriaHematica.hora = moment().format("HH:mm");
    },
    async deleteItem(id) {
      let myUrl = process.env.VUE_APP_API_URL + "biometriasHematicas.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id,
        callback: this.fetchBiometriasHematicas
      });
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },
    async fetchSearchClientes(e) {
      this.isLoadingCliente = true;
      await this.$store.dispatch("clientes/getBySearch", e.target.value);
      this.isLoadingCliente = false;
    }
  }
};
</script>
