import jsPDF from "jspdf";
import { proString } from "../proStrings";
import moment from "moment";

export const printVenta = (venta, item, saldoPendiente) => {
  //let pdfName = "test";
  let lineHeight = 3;
  let totalWidth = 80;
  let actualLine = 10;
  let sangria = 4;
  //let space = 5;
  var doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [totalWidth, 160]
  });
  doc.setFont("helvetica");
  doc.setFontSize(7);
  doc.text("Abel Alfonso Ugalde Pérez", sangria, actualLine);
  actualLine += lineHeight;
  doc.text("RFC: UAPA810924HK6", sangria, actualLine);
  actualLine += lineHeight;
  doc.text("Calle 8 x 15 y 15-B #213 Vergel", sangria, actualLine);
  actualLine += lineHeight;
  doc.text("C.P. 97173", sangria, actualLine);
  actualLine += lineHeight;
  doc.line(sangria, actualLine, 80 - sangria, actualLine);
  actualLine += lineHeight + lineHeight / 2;
  doc.text(`Nuestro cliente: ${venta.clientes[0].nombre}`, sangria, actualLine);
  actualLine += lineHeight;
  doc.text(`Tel: ${venta.clientes[0].tel1}`, sangria, actualLine);
  actualLine += lineHeight;
  doc.text(`Dirección: ${venta.clientes[0].direccion}`, sangria, actualLine);
  actualLine += lineHeight;
  doc.line(sangria, actualLine, 80 - sangria, actualLine);
  actualLine += lineHeight + lineHeight / 2;
  doc.text(`Nota de venta no. ${item.id}`, sangria, actualLine);
  actualLine += lineHeight;
  doc.text(
    `Fecha: ${moment(item?.fechaHora, "YYYY-MM-DD")
      .locale("es-mx")
      .format("DD/MM/yyyy")}`,
    sangria,
    actualLine
  );
  actualLine += lineHeight;
  doc.text(
    `Hora: ${moment(item?.fechaHora, "YYYY-MM-DDTHH:mm:ss")
      .toDate()
      .toLocaleTimeString("es-mx", {
        hour: "2-digit",
        minute: "numeric",
        hour12: true
      })}`,
    sangria,
    actualLine
  );
  actualLine += lineHeight;
  doc.line(sangria, actualLine, 80 - sangria, actualLine);
  actualLine += lineHeight + lineHeight / 2;

  let nombresMascotas = "";
  let mascotasTicket = venta.mascotas.filter(mascota => {
    let isIn = false;
    venta.items.map(item => {
      if (item.idMascota == mascota.id) {
        isIn = true;
      }
    });
    return isIn;
  });
  mascotasTicket.map((mascota, ind) => {
    if (ind != 0) {
      nombresMascotas += `, `;
    }
    nombresMascotas += `${mascota.nombre} `;
  });
  doc.text(`Mascota(s): ${nombresMascotas}`, sangria, actualLine);
  actualLine += lineHeight;
  doc.text("Le atendió: " + venta.empleados[0].nombre, sangria, actualLine);
  actualLine += lineHeight;

  doc.line(sangria, actualLine, 80 - sangria, actualLine);

  let tableHeight = 0;
  doc.autoTable({
    styles: {
      fontSize: "7",
      fillColor: "#fff",
      textColor: "#111",
      cellPadding: 1
    },
    theme: "striped",
    startY: actualLine,
    margin: {
      top: sangria,
      left: sangria,
      right: sangria,
      bottom: sangria
    },
    body: venta.items.map(item => {
      item.total = parseFloat(
        item.precioAplicado - item.descuentoAplicado
      ).toFixed(2);
      return item;
    }),
    columns: [
      {
        header: "Cant.",
        dataKey: "cantidad"
      },
      {
        header: "Producto",
        dataKey: "nombre"
      },
      {
        header: "Precio",
        dataKey: "precioAplicado"
      },
      {
        header: "Desc.",
        dataKey: "descuentoAplicado"
      },
      {
        header: "Total",
        dataKey: "total"
      }
    ],
    didDrawCell: data => {
      tableHeight = data.cursor.y + data.row.height;
    }
  });

  actualLine = lineHeight * 2 + tableHeight;

  let totalVenta = 0;
  venta.items.forEach(item => {
    totalVenta +=
      parseFloat(item.precioAplicado) - parseFloat(item.descuentoAplicado);
  });

  let totalAbonado = 0;
  venta.ingresos.forEach(ingreso => {
    totalAbonado += parseFloat(ingreso.monto);
  });
  saldoPendiente = totalVenta - totalAbonado;

  let myActualText = `Total:  ${totalVenta.toFixed(2)}`;
  doc.text(
    myActualText,
    totalWidth * 0.5 - myActualText.length * 0.5,
    actualLine
  );
  actualLine += lineHeight;
  actualLine += lineHeight;
  doc.line(sangria, actualLine, 80 - sangria, actualLine);
  actualLine += lineHeight + lineHeight * 0.5;
  doc.text(`Formas de pago:`, sangria, actualLine);
  actualLine += lineHeight;
  venta.ingresos.map(ingreso => {
    myActualText = `${proString(ingreso.tipo, { firstCap: true })} : ${
      ingreso.monto
    }`;
    doc.text(
      myActualText,
      totalWidth * 0.5 - myActualText.length * 0.5,
      actualLine
    );
    actualLine += lineHeight;
  });

  if (saldoPendiente > 0) {
    let textoSaldo = `Saldo pendiente: ${saldoPendiente.toFixed(2)}`;
    doc.text(
      textoSaldo,
      totalWidth * 0.5 - textoSaldo.length * 0.5,
      actualLine
    );
    actualLine += lineHeight;
  }

  doc.line(sangria, actualLine, 80 - sangria, actualLine);
  actualLine += lineHeight + lineHeight * 0.5;
  if (item?.status == "uso interno") {
    myActualText = `Uso interno`;
  } else {
    myActualText = `¡Gracias por su preferencia!`;
  }
  doc.text(
    myActualText,
    totalWidth * 0.5 - myActualText.length * 0.5,
    actualLine
  );
  let img = new Image();
  img.src = require("@/assets/zhLogo.png");
  doc.addImage(img, "png", totalWidth - 30 - sangria, sangria, 30, 10); //doc.save(pdfName + ".pdf");
  doc.autoPrint();
  //This is a key for printing
  doc.output("dataurlnewwindow");
};

export default printVenta;
