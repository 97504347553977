var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"items":_vm.movimientos,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"capitalized"},[_vm._v(_vm._s(_vm.getStatusMovimiento(item)))])]}},{key:"item.idVenta",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(Number(item.idVenta) > 0 ? item.idVenta : "-"))])]}},{key:"item.idCompra",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(Number(item.idCompra) > 0 ? item.idCompra : "-"))])]}},{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getCantidad(item)))])]}},{key:"item.monto",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.monto * Math.sign(item.cantidad)))])]}},{key:"item.netoUnidad",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.netoUnidad * Math.sign(_vm.getCantidad(item))))])]}},{key:"item.proveedorCliente",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"capitalized"},[_vm._v(_vm._s(_vm.getNombreClienteProveedor(item)))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }