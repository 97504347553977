<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Rangos fondo fijo</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="pink darken-2"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="fondos"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="pink white--text darken-2 my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../../tools/proStrings";
export default {
  name: "FondoFijo",
  data: () => ({
    search: "",
    isLoading: false,
    isEditing: false,
    isCierre: false,
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Hora", value: "hora" },
      { text: "Concepto", value: "concepto" },
      { text: "Proveedor", value: "nombreProveedor" },
      { text: "No. Documento", value: "noDocumento" },
      { text: "Monto", value: "monto" },
      { text: "Forma de pago", value: "tipo" },
      { text: "Tipo de egreso", value: "tipoEgreso" },
      { text: "Departamento", value: "departamento" },
      { text: "Empleado", value: "nombreEmpleado" }
    ],
    fondo: {
      idCliente: 0,
      idVenta: 0
    },
    fondos: [],
    gastosAdmin: [],
    gastosEmpleados: [],
    proveedores: [],
    empleados: []
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "gastos",
        search: val
      });
    }
  },
  computed: {
    isAdmin() {
      return localStorage.getItem("id") == "29";
    }
  },
  methods: {
    initialize() {
      this.fetchFondoFijo();
      this.fetchEmpleados();
      this.search = this.$store.state.searchParams["gastos"];
    },
    async fetchFondoFijo() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "cajas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllManejoCajas",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.fondos = respuesta.data.rows;
        this.fondos.forEach(fondo => {
          fondo.fecha = fondo.fechaHora.substring(0, 10);
          fondo.hora = fondo.fechaHora.substring(11);
        });
      }
      this.isLoading = false;
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.empleados = respuesta.data.rows;
      this.isLoading = false;
    },
    async editItem(myItem) {
      this.gasto = { ...myItem };
      this.dialog = true;
    },
    async send(idEditing) {
      this.isLoading = true;
      let tipo = "insert";
      let msg = "Gasto agregado";
      let myUrl = process.env.VUE_APP_API_URL + "gastos.php";
      if (idEditing != "0") {
        tipo = "update";
        msg = "Gasto Actualizado";
      }
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: tipo,
        id: idEditing,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.gasto]
      });
      if (respuesta.data.status == "Ok") {
        this.isLoading = false;
        this.isEditing = false;
        this.$toasted.success(msg, {
          position: "bottom-right",
          duration: 5000
        });
        this.dialog = false;
      }
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    }
  }
};
</script>
