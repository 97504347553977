<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="6">
          <h1 v-if="idCompra == 0">Nueva compra</h1>
          <h1 v-else>Editando compra {{ $route.params.id }}</h1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="9" @drop="drop" @dragover="allowDrop">
          <v-row>
            <v-col cols="12" sm="6">
              <SelectEmpleado
                :callback="setCajero"
                ref="selectCajero"
                placeholder="Selecciona un cajero"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <SelectProveedor
                :callback="setProveedor"
                ref="selectProveedor"
                placeholder="Selecciona un proveedor"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="No. documento"
                @change="setNoDocumento"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                label="Tipo"
                @change="setTipoCompra"
                :items="['Remisión', 'Factura']"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                label="Agregar stock a:"
                @change="setUbicacion"
                item-value="id"
                item-text="nombre"
                :items="ubicaciones"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <NuevaCompraTabla />
            </v-col>
            <v-col cols="12" md="12">
              <div class="d-flex justify-space-around my-6">
                <v-btn
                  color="success white--text"
                  v-if="idCompra != 0"
                  @click="resetNuevaCompra()"
                  :disabled="isLoading"
                >
                  <v-icon left dark>mdi-cash</v-icon>Nueva compra
                </v-btn>
                <v-btn
                  color="danger white--text"
                  v-if="idCompra == 0"
                  @click="clearNuevaCompra()"
                  :disabled="isLoading"
                >
                  <v-icon left dark>mdi-cancel</v-icon>Cancelar
                </v-btn>
                <v-btn
                  color="success"
                  v-if="idCompra == 0"
                  @click="cerrada"
                  :disabled="isLoading"
                >
                  <v-icon left dark>mdi-cash-lock</v-icon>Cerrada
                </v-btn>
                <v-btn
                  color="black white--text"
                  v-if="idCompra != 0"
                  @click="cerrada"
                  :disabled="isLoading"
                >
                  <v-icon left dark>mdi-reload</v-icon>Actualizar
                </v-btn>
                <v-btn
                  color="info white--text"
                  v-if="idCompra != 0"
                  @click="print"
                  :disabled="isLoading"
                  :loading="isPrinting"
                >
                  <v-icon left dark>mdi-ticket</v-icon>Ticket
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <NuevaCompraListaProductosStock />
        </v-col>
        <v-col cols="12" md="12">
          <NuevaCompraModalPagos
            ref="NuevaCompraModalPagos"
            :callback="sendCompra"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import NuevaCompraTabla from "./NuevaCompraTabla.vue";
import NuevaCompraListaProductosStock from "./NuevaCompraListaProductosStock.vue";
import NuevaCompraModalPagos from "./NuevaCompraModalPagos.vue";
import SelectEmpleado from "../../components/SelectEmpleado.vue";
import printCompra from "../../tools/printTickets/printTicketCompra";
import { mapActions, mapMutations, mapState } from "vuex";
import axios from "axios";
import SelectProveedor from "../../components/SelectProveedor.vue";

export default {
  data() {
    return {
      modoCompra: "por cobrar",
      isPrinting: false,
      ubicaciones: [
        { id: 1, nombre: "Almacén" },
        { id: 2, nombre: "Tienda" }
      ]
    };
  },
  components: {
    NuevaCompraTabla,
    NuevaCompraListaProductosStock,
    NuevaCompraModalPagos,
    SelectEmpleado,
    SelectProveedor
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapState({
      idCompra: state => state.nuevaCompra.idCompra,
      isLoading: state => state.nuevaCompra.isLoading,
      cajero: state => state.nuevaCompra.cajero,
      proveedor: state => state.nuevaCompra.proveedor,
      pagos: state => state.nuevaCompra.pagos,
      productos: state => state.productos.productos
    })
  },
  methods: {
    ...mapActions("nuevaCompra", [
      "fetchCompraById",
      "terminarCompra",
      "addProduct"
    ]),
    ...mapMutations("nuevaCompra", [
      "setCajero",
      "setProveedor",
      "setNoDocumento",
      "setTipoCompra",
      "setUbicacion",
      "clearNuevaCompra",
      "setIdCompra",
      "resetNuevaCompra",
      "setStatusCompra"
    ]),
    allowDrop(ev) {
      ev.preventDefault();
    },
    drop(ev) {
      ev.preventDefault();
      let id = ev.dataTransfer.getData("productId");
      this.addProduct({
        product: { id }
      });
    },
    porCobrar() {
      this.$refs.NuevaCompraModalPagos.open();
    },
    cerrada() {
      this.$refs.NuevaCompraModalPagos.open();
    },
    async usoInterno() {
      if (confirm("¿Deseas registrar ésta compra cómo de Uso Interno?")) {
        this.$toasted.show("Guardando... no cierres la comprana", {
          position: "bottom-right",
          duration: 5000
        });

        this.setStatusCompra({ status: "uso interno" });
        const response = await this.terminarCompra({});
        if (response) {
          this.setIdCompra(response.idInsertado);
          this.$toasted.success("Compra agregada", {
            position: "bottom-right",
            duration: 5000
          });

          this.$refs.NuevaCompraModalPagos.close();
        }
      }
    },
    async initialize() {
      if (this.$route.params.id) {
        await this.fetchCompraById({
          id: this.$route.params.id
        });

        //this.$refs.selectCajero.setEmpleadoById(this.cajero.id);
        this.$refs.NuevaCompraModalPagos.setPagos(this.pagos);
      } else {
        this.resetNuevaCompra();
      }
      await this.$store.dispatch("productos/getProductos");
    },
    async print() {
      this.isPrinting = true;
      let myUrl = process.env.VUE_APP_API_URL + "compras.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getOne",
        id: this.idCompra,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        const empleados = [];
        let compra = {
          ...respuesta.data.compras.find(compra => compra.id == this.idCompra),
          ...respuesta.data,
          empleados: [...empleados, respuesta.data.empleados]
        };
        printCompra(compra, compra);
      }
      this.isPrinting = false;
    },
    async sendCompra() {
      if (localStorage.idCaja == "0" || !localStorage.idCaja) {
        let todoEsCero = true;
        for (const pago in this.pagos) {
          if (this.pagos[pago] > 0) {
            todoEsCero = false;
          }
        }
        if (!todoEsCero) {
          this.$toasted.error(
            "Abre una caja antes de hacer una compra y registrar egresos",
            {
              position: "bottom-right",
              duration: 5000
            }
          );
          this.$refs.NuevaCompraModalPagos.close();
          return;
        }
      }
      if (!this.cajero?.id) {
        this.$toasted.error("Selecciona un cajero", {
          position: "bottom-right",
          duration: 5000
        });
        this.$refs.NuevaCompraModalPagos.close();
        return;
      }
      if (!this.proveedor?.id) {
        this.$toasted.error("Selecciona un proveedor", {
          position: "bottom-right",
          duration: 5000
        });
        this.$refs.NuevaCompraModalPagos.close();
        return;
      }

      this.$toasted.show("Guardando... no cierres la ventana", {
        position: "bottom-right",
        duration: 5000
      });

      const response = await this.terminarCompra({
        pagos: this.pagos
      });
      if (response) {
        this.setIdCompra(response.idInsertado);
        this.$toasted.success("Compra agregada", {
          position: "bottom-right",
          duration: 5000
        });

        this.$refs.NuevaCompraModalPagos.close();
      }
    }
  }
};
</script>
