<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12">
              <h1>Productos</h1>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="search"
                :loading="isLoadingSearch"
                @keyup="handleSearchInput"
                label="Buscar"
                append-icon="mdi-magnify"
                color="pink darken-4"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="2">
              <v-select
                v-model="idCategoria"
                label="Categoría"
                color="pink darken-4"
                dense
                item-value="id"
                item-text="nombre"
                @change="fetchByCategoria()"
                :items="categorias"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="2">
              <v-select
                v-model="idSubcategoria"
                label="Subcategoría"
                color="pink darken-4"
                item-value="id"
                item-text="nombre"
                dense
                @change="fetchBySubcategoria()"
                :items="subcategoriasFiltradas"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="2">
              <v-select
                v-model="selectOcultos"
                label="Mostrar ocultos o visibles"
                :items="['ambos', 'visibles', 'ocultos']"
                color="pink darken-4"
                @change="refilterProducts"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" class="text-right">
              <v-btn
                elevation="2"
                v-if="selected.length"
                class="mr-2"
                small
                color="green darken-4"
                dark
                @click="descargarReporte()"
              >
                Reporte
              </v-btn>
              <v-btn
                elevation="2"
                small
                color="pink darken-4"
                dark
                v-if="canAddProduct"
                @click="addItem()"
              >
                Agregar producto
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="productosFiltrados"
          :items-per-page="7"
          :search="delayedSearch"
          :custom-filter="guessSearch"
          v-model="selected"
          item-key="id"
          show-select
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon color="info" link :to="`/DetallesProducto/${item.id}`">
              <v-icon small>mdi-text-box</v-icon>
            </v-btn>
            <v-btn icon @click="setVisible(item)">
              <v-icon small v-if="item.isVisible == '1'" color="success">
                mdi-eye
              </v-icon>
              <v-icon small v-if="item.isVisible !== '1'" color="error">
                mdi-eye-off
              </v-icon>
            </v-btn>
            <v-btn icon @click="setEstetica(item)">
              <v-icon
                small
                :color="item.isEstetica == '1' ? 'success' : 'error'"
              >
                mdi-content-cut
              </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="editItem(item)"
              v-if="canEditProduct"
              color="info"
            >
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon @click="deleteItem(item)" color="danger">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="pink darken-4 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <modal-productos />
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
import { mapState } from "vuex";
import ModalProductos from "./AgregarEditar/ModalProductos.vue";
import XLSX from "xlsx";
import {
  getAllCategorias,
  getAllSubcategorias
} from "../axios-calls/categorias";

export default {
  name: "Productos",
  components: { ModalProductos },
  data: () => ({
    selectOcultos: "ambos",
    all_columns: false,
    search: "",
    searchCategoria: "",
    searchSubcategoria: "",
    delayedSearch: "",
    isLoading: false,
    isLoadingSearch: false,
    canAddProduct: false,
    canEditProduct: false,
    debouncing: null,
    selected: [],
    productosFiltrados: [],
    categorias: [],
    subcategorias: [],
    idCategoria: 0,
    idSubcategoria: 0
  }),
  created() {
    this.initialize();
    this.checkPermissons();
  },
  computed: {
    ...mapState("productos", ["productos"]),
    headers() {
      if (!this.all_columns) {
        return [
          { text: "Nombre", value: "nombre" },
          { text: "Compuesto", value: "compuesto" },
          {
            text: "Categoría",
            value: "categoria",
            filter: f => {
              return (f + "")
                .toLowerCase()
                .includes(this["searchCategoria"].toLowerCase());
            }
          },
          {
            text: "Subcategoría",
            value: "subcategoria",
            filter: f => {
              return (f + "")
                .toLowerCase()
                .includes(this["searchSubcategoria"].toLowerCase());
            }
          },
          { text: "Marca", value: "marca" },
          { text: "Código", value: "codigo" },
          { text: "Precio Venta Con Impuesto", value: "precio" },
          { text: "Stock en Tienda", value: "stock" },
          { text: "Stock en Almacén", value: "stockAlmacen" },
          { text: "Impuesto", value: "impuesto" },
          { text: "Mínimo", value: "minimo" },
          { text: "Máximo", value: "maximo" },
          {
            text: "Acciones",
            value: "acciones",
            align: "right",
            width: "140px"
          }
        ];
      }

      return [
        { text: "Nombre", value: "nombre" },
        { text: "Compuesto", value: "compuesto" },
        {
          text: "Categoría",
          value: "categoria",
          filter: f => {
            return (
              (f + "")
                .toLowerCase()
                .includes(this["searchCategoria"].toLowerCase()) ||
              this["searchSubcategoria"] == "Todas"
            );
          }
        },
        {
          text: "Subcategoría",
          value: "subcategoria",
          filter: f => {
            return (
              (f + "")
                .toLowerCase()
                .includes(this["searchSubcategoria"].toLowerCase()) ||
              this["searchSubcategoria"] == "Todas"
            );
          }
        },
        { text: "Marca", value: "marca" },
        { text: "Código", value: "codigo" },
        { text: "Precio venta con impuesto", value: "precio" },
        { text: "Stock en Tienda", value: "stock" },
        { text: "Stock en Almacén", value: "stockAlmacen" },
        {
          text: "Precio compra con impuesto",
          value: "precioCompraConImpuesto"
        },
        { text: "Impuesto", value: "impuesto" },
        {
          text: "Precio compra sin impuesto",
          value: "precioCompraSinImpuesto"
        },
        { text: "Unidad", value: "unidad" },
        { text: "Concepto SAT", value: "conceptoSat" },
        { text: "Código SAT", value: "codigoSat" },
        { text: "Mínimo", value: "minimo" },
        { text: "Máximo", value: "maximo" },
        { text: "Control Stock", value: "controlStock" },
        { text: "Acciones", value: "acciones", align: "right", width: "140px" }
      ];
    },
    subcategoriasFiltradas() {
      if (this.idCategoria == 0 || !this.idCategoria) return this.subcategorias;
      return this.subcategorias.filter(
        subcategoria =>
          subcategoria.idCategoria == this.idCategoria || subcategoria.id == 0
      );
    }
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", { name: "productos", search: val });
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.delayedSearch = val;
      }, 600);
    }
  },
  methods: {
    initialize() {
      this.testColumnsPermisson();
      this.fetchProductos();
      this.fetchCategorias();
      this.fetchSubcategorias();
      this.search = this.$store.state.searchParams["productos"];
    },
    refilterProducts() {
      const toShow = this.selectOcultos;
      this.productosFiltrados = this.productos.filter(producto => {
        if (toShow == "ambos") return true;
        if (toShow == "ocultos") return producto.isVisible == false;
        if (toShow == "visibles") return producto.isVisible == true;
      });
      this.productosFiltrados = this.productosFiltrados.filter(
        producto =>
          producto.idCategoria == this.idCategoria || this.idCategoria == 0
      );
      this.productosFiltrados = this.productosFiltrados.filter(
        producto =>
          producto.idSubcategoria == this.idSubcategoria ||
          this.idSubcategoria == 0
      );
    },
    async fetchCategorias() {
      const todas = [{ id: 0, nombre: "Todas" }];
      this.categorias = [...todas, ...(await getAllCategorias())];
    },
    async fetchSubcategorias() {
      const todas = [{ id: 0, nombre: "Todas" }];
      this.subcategorias = [...todas, ...(await getAllSubcategorias())];
    },
    async setVisible(item) {
      if (item.isVisible == "1") item.isVisible = "0";
      else item.isVisible = "1";
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update",
        items: [item],
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        if (item.isVisible == "1") {
          this.$toasted.success("Ahora es VISIBLE en buscadores", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Ahora es INVISIBLE en buscadores", {
            position: "bottom-right",
            duration: 5000
          });
        }
      }
      this.$store.commit("productos/refreshCache");
    },
    async setEstetica(item) {
      if (item.isEstetica == "1") item.isEstetica = "0";
      else item.isEstetica = "1";
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update",
        items: [item],
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) {
        if (item.isEstetica == "1") {
          this.$toasted.success("Ahora es seleccionable en estética", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.error("Ahora no es seleccionable en estética", {
            position: "bottom-right",
            duration: 5000
          });
        }
      }
    },
    async testColumnsPermisson() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "testColumnsPermisson",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.permisson == "Ok") {
        this.all_columns = true;
      }
      this.isLoading = false;
    },
    handleSearchInput() {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchProductos();
      }, 500);
    },
    async fetchByCategoria() {
      this.isLoadingSearch = true;
      await this.$store.dispatch("productos/getByCategoria", this.idCategoria);
      this.refilterProducts();
      this.isLoadingSearch = false;
    },
    async fetchBySubcategoria() {
      this.isLoadingSearch = true;
      await this.$store.dispatch(
        "productos/getBySubcategoria",
        this.idSubcategoria
      );
      this.refilterProducts();
      this.isLoadingSearch = false;
    },
    async fetchSearchProductos() {
      this.isLoadingSearch = true;
      await this.$store.dispatch("productos/getBySearch", this.search);
      this.refilterProducts();
      this.isLoadingSearch = false;
    },
    async fetchProductos() {
      this.isLoading = true;
      await this.$store.dispatch("productos/getProductos");
      this.refilterProducts();
      this.isLoading = false;
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "productos",
        item: {},
        callback: this.fetchProductos
      });

      this.$store.commit("openModal", { modal: "productos", open: true });
    },
    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "productos",
        item: { ...myItem },
        callback: this.fetchProductos
      });
      this.$store.commit("openModal", { modal: "productos", open: true });
    },
    async deleteItem({ id, nombre }) {
      if (!confirm("¿Seguro que deseas eliminar " + nombre + "?")) return;
      const myUrl = process.env.VUE_APP_API_URL + "productos.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id
      });
      await this.$store.dispatch("productos/resetProducts");
      this.fetchProductos();
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var selected = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, selected, "selected");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.selected.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            // Verificar si el valor es numérico
            let valor = registro[header.value];
            if (!isNaN(valor) && valor !== "") {
              // Convertir el valor a número
              valor = Number(valor);
            }
            XLSX.utils.sheet_add_aoa(sheet, [[valor]], {
              // Agregar el valor al archivo
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-selected.xlsx`);
    },

    checkPermissons() {
      if (!localStorage?.permisos) return;
      this.canAddProduct = JSON.parse(localStorage.permisos)?.some(
        permiso => permiso.id == 58
      );
      this.canEditProduct = JSON.parse(localStorage.permisos)?.some(
        permiso => permiso.id == 59
      );
    }
  }
};
</script>
