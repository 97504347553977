<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      @keydown.esc="cerrar"
      @click:outside="cerrar"
      max-width="600px"
    >
      <!--<template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template>-->
      <v-card>
        <v-form
          ref="entryForm"
          color="teal"
          v-model="valid"
          @submit.prevent="submitHandler"
        >
          <v-card-title>
            <span class="headline">
              <span v-if="servicioEstetica.id != undefined">Actualizar</span>
              <span v-else>Agregar</span> Servicio de Estética</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    @dblclick="resetearCampos"
                    label="Cliente"
                    v-model="servicioEstetica.idPropietario"
                    :rules="nameRules"
                    :items="clientesFiltrados"
                    :loading="isLoading"
                    item-text="nombre"
                    item-value="id"
                    required
                    ref="cliente"
                    @keydown="handleClienteSearchInput"
                    @keydown.enter="
                      focusNext({ actualRef: 'cliente', nextRef: 'mascota' })
                    "
                  >
                    <template v-slot:item="{ item }">
                      <template v-if="testIfObject(item)">
                        <v-list-item>
                          <v-list-item-content
                            v-text="item"
                          ></v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-list-item>
                        <perfil
                          :item="{
                            nombre: item.nombre,
                            imagen: '',
                            tipo: 'clientes',
                            codigosInternos: codigosInternos(
                              item.codigosInternos
                            ),
                            tamano: 'sm'
                          }"
                        ></perfil>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="item.nombre"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="item.tipo"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    @dblclick="resetearCampos"
                    label="Mascota"
                    v-model="servicioEstetica.idMascota"
                    :rules="nameRules"
                    :items="mascotasFiltradas"
                    :loading="isLoading"
                    item-text="nombre"
                    item-value="id"
                    required
                    ref="mascota"
                    @keydown.enter="
                      focusNext({ actualRef: 'tamano', nextRef: 'direccion' })
                    "
                    @focus="
                      () => {
                        fetchMascotas(servicioEstetica.idPropietario);
                      }
                    "
                  >
                    <template v-slot:item="{ item }">
                      <template v-if="testIfObject(item)">
                        <v-list-item>
                          <v-list-item-content
                            v-text="item"
                          ></v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-list-item>
                        <perfil
                          :item="{
                            nombre: item.nombre,
                            imagen: '',
                            tipo: 'mascotas',
                            codigosInternos: codigosInternos(
                              item.codigosInternos
                            ),
                            tamano: 'sm'
                          }"
                        ></perfil>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="item.nombre"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="item.propietario"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Tamaño"
                    v-model="servicioEstetica.tamano"
                    :items="tamanos"
                    ref="tamano"
                    @keydown.enter="
                      focusNext({ actualRef: 'tamano', nextRef: 'direccion' })
                    "
                    disabled
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-if="mostrarDireccion"
                    label="Direccion"
                    v-model="servicioEstetica.direccion"
                    rows="2"
                    ref="direccion"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'direccion',
                        nextRef: 'serviciosEstetica'
                      })
                    "
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Servicios"
                    chips
                    v-model="servicioEstetica.idsServicios"
                    item-value="id"
                    item-text="nombre"
                    multiple
                    :items="serviciosEstetica"
                    ref="serviciosEstetica"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'serviciosEstetica',
                        nextRef: 'detallesCorte'
                      })
                    "
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-if="servicioEstetica.idsServicios"
                    label="Detalles del corte"
                    v-model="servicioEstetica.detallesCorte"
                    rows="2"
                    ref="detallesCorte"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'detallesCorte',
                        nextRef: 'serviciosAdicionales'
                      })
                    "
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Adicionales"
                    chips
                    v-model="servicioEstetica.idsAdicionales"
                    :items="serviciosAdicionales"
                    item-value="id"
                    item-text="nombre"
                    multiple
                    ref="serviciosAdicionales"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'serviciosAdicionales',
                        nextRef: 'serviciosADomicilio'
                      })
                    "
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="SAD"
                    chips
                    v-model="servicioEstetica.idsSAD"
                    item-value="id"
                    item-text="nombre"
                    :items="serviciosADomicilio"
                    multiple
                    ref="serviciosADomicilio"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'serviciosADomicilio',
                        nextRef: 'cuidadoMedico'
                      })
                    "
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Cuidado médico"
                    v-model="servicioEstetica.cuidadoMedico"
                    :items="['Si', 'No']"
                    ref="cuidadoMedico"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'cuidadoMedico',
                        nextRef: 'observaciones'
                      })
                    "
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Observaciones"
                    v-model="servicioEstetica.observaciones"
                    rows="2"
                    ref="observaciones"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'observaciones',
                        nextRef: 'fuente'
                      })
                    "
                  ></v-textarea>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Fuente"
                    v-model="servicioEstetica.fuente"
                    ref="fuente"
                    :items="['Redes', 'Prospección', 'Recepción']"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'fuente'
                      })
                    "
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-if="servicioEstetica.id == undefined"
              :disabled="isSending"
            >
              Agregar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-else
              :disabled="isSending"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Perfil from "../../components/Perfil";
export default {
  name: "ModalEstetica",
  components: { Perfil },
  data: () => ({
    isLoading: false,
    isSending: false,
    valid: false,
    tipoRequest: "",
    especificos: [],
    servicios: [],
    idServicioEstetica: "0",
    date: new Date().toISOString().substr(0, 10),
    grupos: [],
    nameRules: [v => !!v || "El nombre es requerido"],
    espRules: [v => !!v || "El específico es requerido"],
    sexoRules: [v => !!v || "El sexo es requerido"],
    tamanoRules: [v => !!v || "El tamaño es requerido"],
    esterilRules: [v => !!v || "Este campo es requerido"],
    bajaRules: [v => !!v || "Este campo es requerido"],
    propietarioRules: [v => !!v || "El nombre del propietario es requerido"],
    sexos: [{ nombre: "Macho" }, { nombre: "Hembra" }],
    tamanos: [
      "Cachorro",
      "Chico",
      "Mediano",
      "Intermedio",
      "Grande",
      "Gigante"
    ],
    binario: [{ nombre: "Si" }, { nombre: "No" }],
    objCliente: {},
    telRules: [
      v => !!v || "El num. telefónico es requerido",
      v =>
        /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
        "Ingrese un número de teléfono válido"
    ],
    emailRules: [
      v => !!v || "El email es requerido",
      v => /.+@.+/.test(v) || "El email debe ser válido"
    ],
    addressRules: [v => !!v || "La dirección es requerida"]
  }),
  watch: {
    clientesFiltrados(misClientesFiltrados) {
      let miMascota = this.mascotas.filter(
        mascota => this.servicioEstetica.idMascota == mascota.id
      )[0];
      if (miMascota) {
        misClientesFiltrados = this.clientes.filter(
          cliente => cliente.id == miMascota.idPropietario
        );

        if (misClientesFiltrados) {
          this.servicioEstetica.tamano = miMascota.tamano;
          this.servicioEstetica.idPropietario = misClientesFiltrados[0]?.id;
        } else {
          this.servicioEstetica.idPropietario = 0;
        }
      }
    },
    mascotasFiltradas() {
      let miCliente = this.clientes.filter(
        cliente => this.servicioEstetica.idPropietario == cliente.id
      )[0];
      if (miCliente) {
        this.servicioEstetica.direccion = miCliente.direccion;
      }
    }
  },
  computed: {
    ...mapState("clientes", ["clientes"]),
    ...mapState("mascotas", ["mascotas"]),
    open() {
      return this.$store.state.modals.servicioEstetica.open;
    },
    serviciosEstetica() {
      return this.servicios.filter(
        servicio =>
          servicio.idSubcategoria == "5" || servicio.idSubcategoria == "6"
      );
    },
    serviciosAdicionales() {
      return this.servicios.filter(servicio => servicio.idSubcategoria == "7");
    },
    serviciosADomicilio() {
      return this.servicios.filter(servicio => servicio.idSubcategoria == "8");
    },

    servicioEstetica() {
      return this.$store.state.modals.servicioEstetica.item;
    },
    mostrarDireccion() {
      return true;
    },
    mascotasFiltradas() {
      if (!this.servicioEstetica.idPropietario) {
        return this.mascotas;
      } else {
        if (this.servicioEstetica) {
          return this.mascotas.filter(
            mascota =>
              mascota.idPropietario == this.servicioEstetica.idPropietario
          );
        }
        return this.mascotas;
      }
    },
    clientesFiltrados() {
      if (!this.servicioEstetica.idMascota) {
        return this.clientes;
      } else {
        let miMascota = this.mascotas.filter(
          mascota => this.servicioEstetica.idMascota == mascota.id
        )[0];
        if (miMascota) {
          let misClientesFiltrados = this.clientes.filter(
            cliente => cliente.id == miMascota.idPropietario
          );
          return misClientesFiltrados;
        }
        return this.clientes;
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.fetchClientes();
      this.fetchMascotas();
      this.fetchServicios();
    },
    focusNext(parameters) {
      let { actualRef, nextRef } = {
        actualRef: null,
        nextRef: null,
        ...parameters
      };
      if (actualRef) this.$refs[actualRef].blur();
      if (nextRef) this.$refs[nextRef].focus();
    },
    testIfObject(item) {
      return typeof item !== "object";
    },
    resetearCampos() {
      this.servicioEstetica.idPropietario = 0;
      this.servicioEstetica.idMascota = 0;
    },
    async fetchServicios() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "productosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) {
        this.servicios = respuesta.data.rows;
      }
      this.isLoading = false;
    },
    handleClienteSearchInput(e) {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchClientes(e);
      }, 500);
    },
    async fetchClientes() {
      this.isLoading = true;
      await this.$store.dispatch("clientes/getIniciales");
      this.isLoading = false;
    },
    async fetchMascotas(idCliente = null) {
      this.isLoading = true;
      if (idCliente)
        await this.$store.dispatch("mascotas/getByOwnerId", idCliente);
      else await this.$store.dispatch("mascotas/getIniciales");
      this.isLoading = false;
    },
    async fetchGrupos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "grupos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.grupos = respuesta.data.rows;
      this.isLoading = false;
    },
    async fetchEspecificos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "especificos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.especificos = respuesta.data.rows;
      this.isLoading = false;
    },
    async insertServicioEstetica() {
      this.isSending = true;
      if (this.servicioEstetica.id != undefined) {
        this.tipoRequest = "update";
      } else {
        this.tipoRequest = "insert";
      }
      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: this.tipoRequest,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.servicioEstetica]
      });
      if (respuesta) {
        this.isSending = false;
        this.$store.state.modals["servicioEstetica"].callback();
        if (this.servicioEstetica.id == undefined) {
          this.$toasted.success("Servicio de Estetica agregado", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Servicio de Estetica actualizado", {
            position: "bottom-right",
            duration: 5000
          });
        }
        this.cerrar();
      }
    },
    async submitHandler() {
      let valido = await this.$refs.entryForm.validate();
      if (valido) {
        this.insertServicioEstetica();
      }
    },
    cerrar() {
      this.$store.commit("openModal", {
        modal: "servicioEstetica",
        open: false
      });
    },

    codigosInternos(cods) {
      if (cods?.length && cods?.length > 1) {
        try {
          const parsedCods = JSON.parse(cods);

          if (Array.isArray(parsedCods)) {
            return [...parsedCods];
          }
          return [];
        } catch (error) {
          return [];
        }
      }
      return [];
    },
    async fetchSearchClientes(e) {
      this.isLoadingCliente = true;
      await this.$store.dispatch("clientes/getBySearch", e.target.value);
      this.isLoadingCliente = false;
    }
  }
};
</script>
