var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Stock rápido")]),_c('br'),_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","color":"pink darken-4","dense":""},on:{"keyup":_vm.handleSearchInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"elevation":"2","small":"","color":"pink darken-4","dark":""},on:{"click":function($event){return _vm.addItem()}}},[_vm._v("Agregar producto")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.productos,"items-per-page":7,"search":_vm.search,"sort-by":"id","sort-desc":true,"footer-props":{
          'items-per-page-text': 'Items por pagina',
          'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
        },"loading":_vm.isLoading,"dense":""},scopedSlots:_vm._u([{key:"item.precio",fn:function(ref){
        var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.precio}})]}},{key:"item.stock",fn:function(ref){
        var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.stock},on:{"change":function (e) {
                _vm.handleStockChange(e, item, 2);
              }}})]}},{key:"item.stockAlmacen",fn:function(ref){
              var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.stockAlmacen},on:{"change":function (e) {
                _vm.handleStockChange(e, item, 1);
              }}})]}},{key:"item.minimo",fn:function(ref){
              var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.minimo},on:{"change":function (e) {
                _vm.handleMinimoChange(e, item);
              }}})]}},{key:"item.maximo",fn:function(ref){
              var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.maximo},on:{"change":function (e) {
                _vm.handleMaximoChange(e, item);
              }}})]}},{key:"item.controlStock",fn:function(ref){
              var item = ref.item;
return [_c('select',{attrs:{"type":"number"},domProps:{"value":item.controlStock}},[_c('option',{attrs:{"value":"Si"}},[_vm._v("Si")]),_c('option',{attrs:{"value":"No"}},[_vm._v("No")])])]}},{key:"item.acciones",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setVisible(item)}}},[(item.isVisible == '1')?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" mdi-eye ")]):_vm._e(),(item.isVisible !== '1')?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(" mdi-eye-off ")]):_vm._e()],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setEstetica(item)}}},[_c('v-icon',{attrs:{"small":"","color":item.isEstetica == '1' ? 'success' : 'error'}},[_vm._v(" mdi-content-cut ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"pink darken-4 white--text my-6","rounded":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")])],1)]},proxy:true}],null,true)})],1)],1),_c('modal-productos')],1)}
var staticRenderFns = []

export { render, staticRenderFns }