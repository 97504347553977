var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('ModalSaldoCliente',{ref:"ModalSaldoCliente",attrs:{"cliente":this.cliente,"callback":_vm.fetchClientes}}),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Clientes")]),_c('br'),_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","color":"blue","dense":""},on:{"keydown":_vm.handleSearchInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"elevation":"2","small":"","color":"blue white--text "},on:{"click":function($event){return _vm.addItem()}}},[_vm._v("Agregar Cliente")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.clientes,"items-per-page":7,"search":_vm.search,"custom-filter":_vm.guessSearch,"sort-by":"id","sort-desc":true,"header-props":_vm.headerProps,"footer-props":{
          'items-per-page-text': 'Items por pagina',
          'items-per-page-options': [7, 25, 50, 100, 250, 500, -1]
        },"dense":""},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
        var item = ref.item;
return [_c('NombreCliente',{attrs:{"cliente":item}})]}},{key:"item.detalles",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function () {
                _vm.cliente = item;
                _vm.toggleModal();
              }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-hand-coin-outline ")])],1),(_vm.canAddMascotas)?_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function () {
                _vm.openModalMascota(item.id);
              }}},[_c('v-icon',{staticClass:"mr-0",attrs:{"small":""}},[_vm._v("mdi-dog")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","color":"info","link":"","to":("/DetallesCliente/" + (item.id))}},[_c('v-icon',{staticClass:"mr-0",attrs:{"small":""}},[_vm._v("mdi-text-box")])],1),_c('v-btn',{attrs:{"icon":"","color":"blue darken-1"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),(_vm.canDeleteCliente)?_c('v-btn',{attrs:{"icon":"","color":"danger"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"blue my-6","rounded":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")])],1)]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }