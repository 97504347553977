<style>
@import "./tools/styles/parpadeo.css";
.toasted-container {
  font-family: Arial, Helvetica, sans-serif;
}
.myInput:hover {
  border: 1px solid transparent;
}
.myInput:hover {
  cursor: pointer;
  border: 2px solid lightgrey;
  border-radius: 2px;
}
</style>

<template>
  <v-app>
    <router-view :key="$route.fullPath" />
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({
    drawer: null,
  }),
};
</script>
