import moment from "moment/moment";
import { getVentasIniciales, getVentasBySearch } from "../axios-calls/ventas";

export default {
  namespaced: true,
  state: {
    ventas: [],
    lastGet: "none"
  },
  mutations: {
    refreshCache(state) {
      state.lastGet = "none";
    },
    setLastGet(state) {
      state.lastGet = moment();
    },
    setVentas(state, ventas) {
      state.ventas = [...ventas];
    }
  },
  actions: {
    async getIniciales({ commit, state }) {
      try {
        const nuevasVentas = await getVentasIniciales();
        const nuevasVentasIds = new Set(nuevasVentas.map(venta => venta.id));
        const ventasActualizadas = state.ventas
          .filter(venta => !nuevasVentasIds.has(venta.id))
          .concat(nuevasVentas);

        commit("setVentas", ventasActualizadas);

        return ventasActualizadas;
      } catch (error) {
        console.error("Error al obtener las ventas iniciales:", error);
        throw error;
      }
    },
    async getBySearch({ commit, state }, search) {
      if (!search) return state.ventas;
      try {
        const nuevasVentas = await getVentasBySearch(search);

        const nuevasVentasIds = new Set(nuevasVentas.map(venta => venta.id));
        const ventasActualizadas = state.ventas
          .filter(venta => !nuevasVentasIds.has(venta.id))
          .concat(nuevasVentas);

        commit("setVentas", ventasActualizadas);
        return ventasActualizadas;
      } catch (error) {
        console.error("Error al obtener las ventas por búsqueda:", error);
        throw error;
      }
    },
    async getUpdatedById({ commit, state }, id) {
      try {
        const ventasById = await getVentasBySearch(id);
        const ventaById = ventasById.find(() => true);
        const index = state.ventas.findIndex(
          venta => venta.id === ventaById.id
        );
        if (index !== -1) {
          state.ventas[index] = ventaById;
        } else {
          state.ventas.push(ventaById);
        }
        commit("setVentas", state.ventas);
        return state.ventas;
      } catch (error) {
        console.error("Error al actualizar una venta:", error);
        throw error;
      }
    }
  },
  modules: {}
};
