<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <h1>Ventas concentradas</h1>
              <br />
              <v-row>
                <v-col cols="12" sm="5" md="5">
                  <label>Desde: </label>
                  <input type="date" v-model="desde" />
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <label>Hasta: </label>
                  <input type="date" v-model="hasta" />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-btn
                    elevation="2"
                    small
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="ventas"
          :items-per-page="7"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
export default {
  name: "ReportesVentasConcentradas",
  data: () => ({
    isLoading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    headers: [
      { text: "Id", value: "id" },
      { text: "Fecha", value: "ventaFecha", width: "110px" },
      { text: "Hora", value: "ventaHora" },
      { text: "Status", value: "status" },
      { text: "Cajero", value: "nombreEmpleado" },
      { text: "Vendedor", value: "nombreVendedor" },
      { text: "Cliente", value: "nombreCliente" },
      { text: "Total", value: "montoTotal" },
      { text: "Cheque", value: "cheque" },
      { text: "Efectivo", value: "efectivo" },
      { text: "Tarjeta crédito", value: "tarjetaCredito" },
      { text: "Tarjeta débito", value: "tarjetaDebito" },
      { text: "Transferencia", value: "transferencia" },
      { text: "Ize. crédito", value: "izCredito" },
      { text: "Ize. débito", value: "izDebito" },
      { text: "Puntos usados", value: "puntosSaldo" }
    ],
    ventas: [],
    placeholderVentas: [
      {
        id: "48322",
        fechaHora: "23/10/2021	11:46:39",
        fechaSola: "23/10/2021",
        hora: "11:46:39",
        status: "Por cobrar",
        vendedor: "Silvia Flor Rosales Poot",
        cliente: "Maria Jose Arjona",
        total: "165.00",
        cheque: "0.00",
        efectivo: "0.00",
        tarjetaCredito: "0.00",
        tarjetaDebito: "0.00",
        transferencia: "0.00",
        izeCredito: "0.00",
        izeDebito: "0.00",
        puntosUsados: "0.00"
      },
      {
        id: "48175",
        fechaHora: "21/10/2021	12:36:31",
        fechaSola: "21/10/2021",
        hora: "12:36:31",
        status: "Cerrada",
        vendedor: "Miguel Angel Puch Baas",
        cliente: "Elisa Escalante Mex",
        total: "165.00",
        cheque: "0.00",
        efectivo: "195.00",
        tarjetaCredito: "0.00",
        tarjetaDebito: "0.00",
        transferencia: "0.00",
        izeCredito: "0.00",
        izeDebito: "0.00",
        puntosUsados: "0.00"
      },
      {
        id: "48321",
        fechaHora: "23/10/2021	11:46:39",
        fechaSola: "23/10/2021",
        hora: "11:46:39",
        status: "Por cobrar",
        vendedor: "Silvia Flor Rosales Poot",
        cliente: "Maria Jose Arjona",
        total: "165.00",
        cheque: "0.00",
        efectivo: "0.00",
        tarjetaCredito: "0.00",
        tarjetaDebito: "0.00",
        transferencia: "0.00",
        izeCredito: "0.00",
        izeDebito: "0.00",
        puntosUsados: "0.00"
      }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchVentas(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchVentas(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  methods: {
    async fetchVentas(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllConcentradas",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta) {
        this.ventas = respuesta.data.rows;
        this.isLoading = false;
      }
      //this.ventas = this.placeholderVentas;
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var ventasConcentradas = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ventasConcentradas, "ventas");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.ventas.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            // Verificar si el valor es numérico
            let valor = registro[header.value];
            if (!isNaN(valor) && valor !== "") {
              // Convertir el valor a número
              valor = Number(valor);
            }
            XLSX.utils.sheet_add_aoa(sheet, [[valor]], {
              // Agregar el valor al archivo
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-ventas-concentradas.xlsx`);
    },
    initialize() {
      this.fetchVentas(
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }
};
</script>
