<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      @keydown.esc="cerrar"
      @click:outside="cerrar"
      max-width="600px"
      :retain-focus="false"
    >
      <!--<template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template>-->
      <v-card :loading="isLoading">
        <v-form
          ref="entryForm"
          color="teal"
          v-model="valid"
          @submit.prevent="submitHandler"
        >
          <v-card-title>
            <span class="headline">
              {{ mascota.id == undefined ? "Agregar" : "Actualizar" }}
              Mascota</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre"
                    v-model="mascota.nombre"
                    :rules="nameRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    :items="grupos"
                    item-text="nombre"
                    item-value="id"
                    label="Grupos/Especies"
                    v-model="mascota.idGrupo"
                    :rules="espRules"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    :items="especificosFiltrados"
                    item-text="nombre"
                    item-value="id"
                    label="Especificos/Razas"
                    v-model="mascota.idEspecifico"
                    :rules="espRules"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    type="date"
                    v-model="mascota.fechaNacimiento"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    type="text"
                    label="Edad"
                    v-model="stringCalcularEdad"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    :items="sexos"
                    item-text="nombre"
                    item-value="nombre"
                    label="Sexo"
                    v-model="mascota.sexo"
                    :rules="sexoRules"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    :items="tamanos"
                    item-text="nombre"
                    item-value="id"
                    label="Tamaño"
                    v-model="mascota.tamano"
                    :rules="tamanoRules"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    :items="binario"
                    item-text="nombre"
                    item-value="id"
                    label="Esterilizado"
                    v-model="mascota.esteril"
                    :rules="esterilRules"
                  ></v-autocomplete>
                </v-col>

                <SelectCodInternos
                  :valor="{ tipo: 'mascotas' }"
                  ref="selectCodInternos"
                />

                <v-col cols="12">
                  <v-autocomplete
                    :items="clientes"
                    item-text="nombre"
                    item-value="id"
                    v-model="mascota.idPropietario"
                    label="Propietario"
                    :search-input.sync="searchCliente"
                    @keydown="handlePropietarioSearchInput"
                    :rules="propietarioRules"
                    :loading="isLoadingCliente"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Observaciones"
                    v-model="mascota.observaciones"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-if="mascota.id == undefined"
            >
              Agregar
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitHandler" v-else>
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import SelectCodInternos from "../../components/SelectCodInternos.vue";
import moment from "moment";

export default {
  name: "ModalMascotas",
  components: { SelectCodInternos },
  data: () => ({
    isLoading: false,
    isLoadingCliente: false,
    isSending: false,
    valid: false,
    searchCliente: "",
    tipoRequest: "",
    especificos: [],
    idMascota: "0",
    mascota: {
      codigoPostal: "",
      correo: "",
      direccion: "",
      nombre: "",
      idGrupo: "",
      idEspecifico: "",
      tel1: "",
      tel2: "",
      codigosInternos: [],
      fechaNacimiento: ""
    },
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    menu3: false,
    menu4: false,
    grupos: [],
    nameRules: [v => !!v || "El nombre es requerido"],
    espRules: [v => !!v || "El específico es requerido"],
    sexoRules: [v => !!v || "El sexo es requerido"],
    tamanoRules: [v => !!v || "El tamaño es requerido"],
    esterilRules: [v => !!v || "Este campo es requerido"],
    bajaRules: [v => !!v || "Este campo es requerido"],
    propietarioRules: [v => !!v || "El nombre del propietario es requerido"],
    sexos: [{ nombre: "Macho" }, { nombre: "Hembra" }],
    tamanos: [
      { nombre: "Cachorro" },
      { nombre: "Pequeño" },
      { nombre: "Mediano" },
      { nombre: "Intermedio" },
      { nombre: "Grande" },
      { nombre: "Gigante" }
    ],
    binario: [{ nombre: "Si" }, { nombre: "No" }],
    telRules: [
      v => !!v || "El num. telefónico es requerido",
      v =>
        /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
        "Ingrese un número de teléfono válido"
    ],
    emailRules: [
      v => !!v || "El email es requerido",
      v => /.+@.+/.test(v) || "El email debe ser válido"
    ],
    addressRules: [v => !!v || "La dirección es requerida"]
  }),
  computed: {
    ...mapState("clientes", ["clientes"]),
    open() {
      return this.$store.state.modals.mascotas.open;
    },
    fechaNacimiento() {
      if (this.mascota.fechaNacimiento != undefined) {
        return this.mascota.fechaNacimiento.substr(0, 10);
      } else {
        return new Date().toISOString().substr(0, 10);
      }
    },
    especificosFiltrados() {
      if (!this.mascota.idGrupo) return this.especificos;
      return this.especificos.filter(
        especifico => especifico.idGrupo == this.mascota.idGrupo
      );
    },
    stringCalcularEdad() {
      if (this.mascota.fechaNacimiento) {
        const edad = moment().diff(this.mascota.fechaNacimiento, "months");
        const years = parseInt(edad / 12);
        const months = edad % 12;
        let stringEdad = "";
        years != 1
          ? (stringEdad = years + " años")
          : (stringEdad = years + "año");
        months != 1
          ? (stringEdad = stringEdad + " y " + months + " meses")
          : (stringEdad = stringEdad + " y " + months + " mes");
        return stringEdad;
      } else {
        return "";
      }
    }
  },
  created() {
    this.initialize();
  },
  watch: {
    open(val) {
      if (val) {
        this.setMascotaFromStore();
        this.fetchClientes(
          this.$store.state.modals.mascotas.item.idPropietario
        );
      }
    }
  },
  methods: {
    initialize() {
      this.fetchClientes();
      this.fetchGrupos();
      this.fetchEspecificos();
    },
    setCliente(cliente) {
      this.mascota.idPropietario = cliente.id;
    },
    setMascotaFromStore() {
      this.mascota = JSON.parse(
        JSON.stringify(this.$store.state.modals.mascotas.item) || "null"
      );
      this.mascota.codigosInternos = JSON.parse(
        this.mascota.codigosInternos || "[]"
      );
      setTimeout(() => {
        this.$refs.selectCodInternos.setArray(this.mascota.codigosInternos);
      }, 100);
    },
    async fetchClientes(idCliente = null) {
      this.isLoading = true;
      if (idCliente) await this.$store.dispatch("clientes/getById", idCliente);
      else await this.$store.dispatch("clientes/getIniciales");
      this.isLoading = false;
    },
    async fetchGrupos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "grupos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") this.grupos = respuesta.data.rows;
      this.isLoading = false;
    },
    async fetchEspecificos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "especificos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") this.especificos = respuesta.data.rows;
      this.isLoading = false;
    },
    async insertMascota() {
      console.log(this.mascota);
      this.isSending = true;
      if (this.mascota.id != undefined) {
        this.tipoRequest = "update";
      } else {
        this.tipoRequest = "insert";
      }
      this.mascota.codigosInternos = this.$refs.selectCodInternos.getArray();
      let codigosInternos = JSON.stringify(this.mascota.codigosInternos);
      let myUrl = process.env.VUE_APP_API_URL + "mascotas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: this.tipoRequest,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [{ ...this.mascota, codigosInternos }]
      });
      if (respuesta.data.status == "Ok") {
        this.isSending = false;
        if (this.mascota.id == undefined) {
          this.$toasted.success("Mascota agregado", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Mascota actualizado", {
            position: "bottom-right",
            duration: 5000
          });
        }
        this.$refs.entryForm.reset();
        await this.$store.state.modals["mascotas"].callback();
        this.cerrar();
      }
    },
    async submitHandler() {
      let valido = await this.$refs.entryForm.validate();
      if (valido) {
        this.insertMascota();
      }
    },
    cerrar() {
      this.$store.commit("openModal", { modal: "mascotas", open: false });
    },

    handlePropietarioSearchInput() {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchClientes();
      }, 500);
    },
    async fetchSearchClientes() {
      this.isLoadingCliente = true;
      await this.$store.dispatch("clientes/getBySearch", this.searchCliente);
      this.isLoadingCliente = false;
    }
  }
};
</script>
