import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({

    options: {
        customProperties: true
    },
    theme: {
        themes: {
            light: {
                rest: colors.grey.lighten2,
                accent: colors.red.lighten2,
                error: colors.red.darken1,
                danger: colors.red.darken1,
                info: colors.blue.darken1,
                warning: colors.yellow.darken2,
                success: colors.green.darken1,
            },
            dark: {
                rest: colors.grey.darken4,
                accent: colors.red.lighten2,
                error: colors.red.lighten1,
                danger: colors.red.lighten1,
                info: colors.blue.lighten1,
                warning: colors.yellow.lighten1,
                success: colors.green.lighten1,
            },
        },
    },
});
