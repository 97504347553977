<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <h1>Cierres de Cajas</h1>
              <br />
              <v-row>
                <v-col cols="12" sm="5" md="5">
                  <label>Desde: </label>
                  <input type="datetime-local" v-model="desde" />
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <label>Hasta: </label>
                  <input type="datetime-local" v-model="hasta" />
                </v-col>
                <!-- <v-col cols="12" sm="12" md="2" lg="2">
                  <v-btn
                    elevation="2"
                    small
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col> -->
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="cierresCajas"
          :items-per-page="7"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          calculate-widths
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="printTicket(item)" color="info">
              <v-icon small> mdi-ticket </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDate } from "../tools/timeUtils/formatDate";
import { formatHour } from "../tools/timeUtils/formatHour";

export default {
  name: "CierresCajas",
  data: () => ({
    isLoading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    headers: [
      { text: "Id", value: "id" },
      { text: "Fecha apertura", value: "fechaApertura", width: "110px" },
      { text: "Hora apertura", value: "horaApertura" },
      { text: "Fecha cierre", value: "fechaCierre", width: "110px" },
      { text: "Hora cierre", value: "horaCierre" },
      { text: "Turno declarado", value: "turno" },
      {
        text: "Empleado de apertura",
        value: "empleadoApertura",
        width: "140px"
      },
      { text: "Empleado de cierre", value: "empleadoCierre", width: "140px" },
      { text: "Caja", value: "caja", width: "140px" },
      /* { text: "Efectivo", value: "efectivo", width: "130px" },
      { text: "Credito", value: "credito", width: "160px" },
      { text: "Debito", value: "debito", width: "160px" },
      { text: "Cheques", value: "cheque", width: "160px" },
      { text: "Transferencias", value: "transferencia", width: "160px" },
      { text: "Gastos", value: "gastos", width: "160px" },
      { text: "Compras", value: "compras", width: "160px" }, */
      { text: "Acciones", value: "actions", width: "160px" }
    ],
    cierresCajas: [],
    placeholderCierresCajas: [
      {
        id: "6034",
        fechaHora: "24/10/2021	14:09:58",
        fecha: "24/10/2021",
        hora: "14:09:58",
        empleadoApertura: "Diana Laura Campos Aguilar",
        empleadoCierre: "Diana Laura Campos Aguilar",
        caja: "1",
        status: "Cierre",
        turno: "Turno 2",
        efectivo: "0.00",
        credito: "0.00",
        debito: "0.00",
        cheques: "0.00",
        transferencias: "0.00",
        gastos: "0.00",
        compras: "0.00",
        fondoCaja: "0.00",
        efectivoVenta: "0.00",
        efectivoFondo: "0.00",
        efectivoTotal: "0.00"
      }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchCierresCajas(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchCierresCajas(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  methods: {
    async fetchCierresCajas(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "cajas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllReporte",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta.data.status == "Ok") {
        this.cierresCajas = respuesta.data.rows;
        this.isLoading = false;
      }
      //this.cierresCajas = this.placeholderCierresCajas;
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var cierresCajas = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, cierresCajas, "Cierres cajas");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.cierresCajas.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            XLSX.utils.sheet_add_aoa(sheet, [[registro[header.value]]], {
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-CierreCajas.xlsx`);
    },
    initialize() {
      this.fetchCierresCajas(
        moment()
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment()
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss")
      );
    },

    printTicket(cierre) {
      //let pdfName = "test";
      let lineHeight = 3;
      let totalWidth = 80;
      let actualLine = 10;
      let sangria = 4;
      //let space = 5;
      var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [totalWidth, 160]
      });
      doc.setFont("helvetica");
      doc.setFontSize(7);
      actualLine += lineHeight * 3;
      doc.text(`Cierre de caja ${cierre.id}`, sangria, actualLine);
      actualLine += lineHeight;
      doc.text(
        `Responsable apertura: ${cierre.empleadoApertura}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.text(
        `Responsable cierre: ${cierre.empleadoCierre}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.line(sangria, actualLine, 80 - sangria, actualLine);
      actualLine += lineHeight + lineHeight / 2;
      doc.text(`Turno: ${cierre.turno}`, sangria, actualLine);
      actualLine += lineHeight;
      doc.text(
        `Apertura: ${formatDate(cierre?.fechaApertura)} ${formatHour(
          cierre?.horaApertura
        )}`,
        sangria,
        actualLine
      );
      doc.text(
        `Cierre: ${formatDate(cierre?.fechaCierre)} ${formatHour(
          cierre?.horaCierre
        )}`,
        sangria * 11,
        actualLine
      );
      actualLine += lineHeight;
      doc.line(sangria, actualLine, 80 - sangria, actualLine);
      actualLine += lineHeight / 2;
      //let tableHeight = 0;
      let myMontos = [
        { tipo: "INGRESOS", monto: " " },
        { tipo: "Efectivo", monto: cierre.efectivo },
        { tipo: "Cheques", monto: cierre.cheque },
        { tipo: "Transferencias", monto: cierre.transferencia },
        { tipo: "Crédito", monto: cierre.credito },
        { tipo: "Débito", monto: cierre.debito },
        { tipo: "Deposito en efectivo", monto: cierre.depEfectivo },
        { tipo: "Iz. Crédito", monto: cierre.izCredito },
        { tipo: "Iz. Débito", monto: cierre.izDebito },
        {
          tipo: "Total:",
          monto: (
            parseFloat(cierre.efectivo) +
            parseFloat(cierre.cheque) +
            parseFloat(cierre.transferencia) +
            parseFloat(cierre.credito) +
            parseFloat(cierre.debito) +
            parseFloat(cierre.depEfectivo) +
            parseFloat(cierre.izCredito) +
            parseFloat(cierre.izDebito)
          ).toFixed(2)
        },
        { tipo: " ", monto: " " },
        { tipo: "EGRESOS", monto: " " },
        { tipo: "Gastos", monto: cierre.gastos },
        { tipo: "Compras", monto: cierre.compras }
      ];
      doc.autoTable({
        styles: {
          fontSize: "7",
          fillColor: "#fff",
          textColor: "#111",
          cellPadding: 1
        },
        theme: "striped",
        startY: actualLine,
        margin: {
          top: sangria,
          left: sangria,
          right: sangria,
          bottom: sangria
        },
        body: myMontos,
        columns: [
          {
            header: " ",
            dataKey: "tipo"
          },
          {
            header: " ",
            dataKey: "monto"
          }
        ],
        didDrawCell: () => {
          //data tableHeight = data.cursor.y + data.row.height;
        }
      });

      let img = new Image();
      img.src = require("@/assets/zhLogo.png");
      doc.addImage(img, "png", totalWidth - 30 - sangria, sangria, 30, 10); //doc.save(pdfName + ".pdf");
      doc.autoPrint();
      //This is a key for printing
      doc.output("dataurlnewwindow");
    }
  }
};
</script>
