var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-dialog',{attrs:{"fullscreen":"","scrollable":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-toolbar',{attrs:{"dark":"","color":"primary","rounded":""}},[_c('v-toolbar-title',[_vm._v("Estados de la venta con id "+_vm._s(_vm.venta.id))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-card-text',_vm._l((_vm.edicionesDeVenta),function(edicion){return _c('v-row',{key:edicion.id,staticClass:"d-flex justify-center ma-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isLoading,"headers":_vm.edicionItemsHeaders,"items":edicion.items,"items-per-page":10,"full-width":"","sort-by":"id","sort-desc":true,"footer-props":{
              'items-per-page-text': 'Items por pagina',
              'items-per-page-options': [10, -1]
            },"fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('h2',{staticClass:"ma-4"},[_vm._v(" "+_vm._s(edicion.fechaHora)+" - "+_vm._s(edicion.status)+" ")])]},proxy:true}],null,true)})],1)}),1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" ¿Realmente deseas eliminar la venta "),_c('br'),_vm._v("con el ID: "+_vm._s(_vm.venta.id)+" ?"),_c('br'),_c('br')]),_c('v-card-subtitle',[_vm._v(" Al hacerlo, los productos asociados a la venta se marcarán cómo devueltos a stock. "),_c('br'),_vm._v(" Los ingresos asociados en el registro serán eliminados. "),_c('br'),_c('span',{staticClass:"dangerBlink"},[_vm._v("Los datos de la venta, ingresos y movimientos de productos asociados no podrán ser recuperados. ")]),_c('br'),_c('span',{staticClass:"infoBlink"},[_vm._v(" Sugerencia : Imprime el ticket de la venta que vas a eliminar. ")]),_c('br'),_vm._v("Además, necesitas permisos de administrador para realizar ésta acción. "),_c('br')]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Usuario","type":"text","autocomplete":"off"},model:{value:(_vm.usuario),callback:function ($$v) {_vm.usuario=$$v},expression:"usuario"}}),_c('v-text-field',{attrs:{"label":"Contraseña","type":"password","autocomplete":"off"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"danger","text":"","loading":_vm.isLoading},on:{"click":function () {
              _vm.pedirPermiso();
            }}},[_vm._v(" Sí, eliminar ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Historial de ventas editadas")]),_c('br'),_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","color":"red darken-3","dense":""},on:{"keydown":_vm.handleSearchInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[(false)?_c('v-btn',{attrs:{"elevation":"2","small":"","color":"red darken-3 white--text ","disabled":""}},[_vm._v(" Agregar venta ")]):_vm._e()],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ventasFiltradas,"items-per-page":7,"search":_vm.search,"custom-filter":_vm.guessSearch,"sort-by":"id","sort-desc":true,"footer-props":{
          'items-per-page-text': 'Items por pagina',
          'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
        },"fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"item.acciones",fn:function(ref){
        var item = ref.item;
return [(item.noEdiciones > 0)?_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function () {
                _vm.venta = Object.assign({}, item);
                _vm.dialog = true;
              }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-details ")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"red white--text darken-3 my-6","rounded":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")])],1)]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }