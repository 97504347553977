/* eslint-disable prettier/prettier */
<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <v-row align="end">
                <v-col cols="12" md="6" sm="12">
                  <h1>Ventas totales</h1>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="text-right">
                  <v-btn
                    elevation="2"
                    small
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <label>Desde: </label>
                  <input
                    type="date"
                    v-model="desde"
                    style="width: 100%; font-size: 18px"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <label>Hasta: </label>
                  <input
                    type="date"
                    v-model="hasta"
                    style="width: 100%; font-size: 18px"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-autocomplete
                    prepend-icon="mdi-shape"
                    append-icon="mdi-magnify"
                    :items="categorias"
                    label="Categoría"
                    item-text="nombre"
                    item-value="nombre"
                    v-model="busquedaCategoria"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-autocomplete
                    prepend-icon="mdi-triangle"
                    append-icon="mdi-magnify"
                    :items="subcategorias"
                    label="Subcategoría"
                    item-text="nombre"
                    item-value="nombre"
                    v-model="busquedaSubcategoria"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-autocomplete
                    prepend-icon="mdi-tag"
                    append-icon="mdi-magnify"
                    :items="marcas"
                    label="Marca"
                    item-text="nombre"
                    item-value="nombre"
                    v-model="busquedaMarca"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" v-if="false">
                  <v-autocomplete
                    dense
                    prepend-icon="mdi-fountain-pen"
                    append-icon="mdi-magnify"
                    :items="clientes"
                    label="Concepto"
                    item-text="nombre"
                    item-value="nombre"
                    v-model="busquedaCliente"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="ventasFiltradas"
          :items-per-page="7"
          sort-by="movimientoId"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          key="movimientoId"
          class="elevation-1"
          calculate-widths
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { matchStrings } from "../tools/proStrings";
import { generarReporteExcel } from "../tools/xlsxTools";

export default {
  name: "ReportesVentasTotales",
  data: () => ({
    loading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    busquedaCliente: "",
    busquedaCategoria: "",
    busquedaSubcategoria: "",
    busquedaMarca: "",
    categorias: [],
    subcategorias: [],
    marcas: [],
    headers: [
      { text: "Id de movimiento", value: "movimientoId" },
      { text: "Id de Venta", value: "idVenta" },
      { text: "Fecha", value: "ventaFecha", width: "110px" },
      { text: "Hora", value: "ventaHora" },
      { text: "Status", value: "statusVenta", width: "140px" },
      { text: "Cajero", value: "nombreEmpleado", width: "140px" },
      { text: "Vendedor", value: "nombreVendedor", width: "140px" },
      { text: "Mascota", value: "nombreMascota", width: "140px" },
      { text: "Cliente", value: "nombreCliente", width: "140px" },
      { text: "Marca", value: "marca", width: "140px" },
      { text: "Categoría", value: "categoria", width: "140px" },
      { text: "Subcategoría", value: "subcategoria", width: "140px" },
      {
        text: "Producto/Servicio",
        value: "nombreProductoServicio",
        width: "180px"
      },
      {
        text: "Precio de compra a/i (unitario)",
        value: "precioCompra",
        width: "180px"
      },
      {
        text: "Precio de compra d/i (unitario)",
        value: "precioDespuesImpuestos",
        width: "180px"
      },
      { text: "Cantidad", value: "cantidad", width: "140px" },
      {
        text: "Precio de compra a/i (por cantidad)",
        value: "precioCompraPorCantidad",
        width: "180px"
      },
      {
        text: "Precio de compra d/i (por cantidad)",
        value: "precioDespuesImpuestosPorCantidad",
        width: "180px"
      },
      { text: "Impuesto %", value: "impuesto", width: "140px" },
      {
        text: "Impuesto (en venta)",
        value: "impuestoMonto",
        width: "140px"
      },
      { text: "Subtotal", value: "subtotal", width: "140px" },
      { text: "Total", value: "total", width: "140px" },
      { text: "Descuento %", value: "descuentoPorcentaje", width: "140px" },
      { text: "Descuento", value: "descuentoAplicado", width: "140px" },
      { text: "Neto", value: "neto", width: "140px" },
      { text: "Cheque", value: "cheque", width: "140px" },
      { text: "Efectivo", value: "efectivo", width: "140px" },
      { text: "Tarjeta Crédito", value: "tarjetaCredito", width: "140px" },
      { text: "Tarjeta Débito", value: "tarjetaDebito", width: "140px" },
      { text: "Transferencia", value: "transferencia", width: "140px" },
      { text: "Ize. Crédito", value: "izCredito", width: "140px" },
      { text: "Ize. Débito", value: "izDebito", width: "140px" },
      { text: "Puntos usados", value: "puntosSaldo", width: "140px" }
    ],
    ventas: [],
    empleados: [],
    placeholderVentas: [
      {
        id: "48459",
        fecha: "24/10/2021",
        hora: "20:15:00",
        status: "Por cobrar",
        vendedor: "Adiel Jadai Euán Baeza",
        mascota: "Chato(c)",
        cliente: "Sergio Alejandro May Zarraga",
        marca: "Incipets",
        categoria: "Clinica",
        subcategoria: "Baños",
        productoServicio: "Incineracion tradicional 2 a 5kg",
        precioCompraAI: "297.41",
        precioCompraDI: "345.00",
        cantidad: "1",
        subtotal: "706.90",
        impuestoPorcentaje: "16%",
        impuesto: "113.10",
        total: "820.00",
        descuentoPorcentaje: "0%",
        descuento: "0.00",
        neto: "820.00",
        cheque: "0.00",
        efectivo: "820.00",
        tarjetaCredito: "0.00",
        tarjetaDebito: "0.00",
        transferencia: "0.00",
        izeCredito: "0.00",
        izeDebito: "0.00",
        puntosUsados: "0.00"
      }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchVentas(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchVentas(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  computed: {
    ventasFiltradas() {
      let registrosSorteados = [...this.ventas];
      if (this.loading) return;

      registrosSorteados = registrosSorteados.filter(registro => {
        return (
          (matchStrings({
            s: this.busquedaCategoria,
            str: registro.categoria
          }) &&
            (matchStrings({
              s: this.busquedaSubcategoria,
              str: registro.subcategoria
            }) ||
              this.busquedaSubcategoria == "") &&
            (matchStrings({
              s: this.busquedaMarca,
              str: registro.marca
            }) ||
              this.busquedaMarca == "")) ||
          this.busquedaCategoria == ""
        );
      });
      return registrosSorteados;
    }
  },
  methods: {
    async fetchVentas(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllTotales",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta.data.status == "Ok") {
        this.ventas = respuesta.data.rows;
        this.isLoading = false;
      } /* 
      this.ventas = this.placeholderVentas; */
    },
    async fetchCategorias() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "categorias.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok")
        this.categorias = [{ nombre: "" }, ...respuesta.data.rows];
      this.isLoading = false;
    },
    async fetchSubcategorias() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "subcategorias.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok")
        this.subcategorias = [{ nombre: "" }, ...respuesta.data.rows];
      this.isLoading = false;
    },
    async fetchMarcas() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "marcas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok")
        this.marcas = [{ nombre: "" }, ...respuesta.data.rows];
      this.isLoading = false;
    },
    async descargarReporte() {
      generarReporteExcel(
        this.ventasFiltradas,
        this.headers,
        "Reporte-VentasTotales"
      );
    },
    initialize() {
      this.fetchVentas(
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
      this.fetchCategorias();
      this.fetchSubcategorias();
      this.fetchMarcas();
    }
  }
};
</script>
