var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cerrar($event)},"click:outside":_vm.cerrar},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',[_c('v-form',{ref:"entryForm",attrs:{"color":"teal"},on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[(_vm.servicioCita.id != undefined)?_c('span',[_vm._v("Actualizar")]):_c('span',[_vm._v("Agregar")]),_vm._v(" Cita médica")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"responsableCita",attrs:{"label":"Citado por","rules":_vm.nameRules,"items":_vm.empleados,"item-text":"nombre","item-value":"id","required":"","loading":_vm.isLoading},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'responsableCita',
                      nextRef: 'empleado'
                    })}},model:{value:(_vm.servicioCita.idResponsableCita),callback:function ($$v) {_vm.$set(_vm.servicioCita, "idResponsableCita", $$v)},expression:"servicioCita.idResponsableCita"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"cliente",attrs:{"label":"Cliente","rules":_vm.nameRules,"items":_vm.clientesFiltrados,"loading":_vm.isLoading || _vm.isLoadingCliente,"item-text":"nombre","item-value":"id","required":""},on:{"dblclick":_vm.resetearCampos,"keydown":[_vm.handleClienteSearchInput,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({ actualRef: 'cliente', nextRef: 'mascota' })}]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [(_vm.testIfObject(item))?[_c('v-list-item',[_c('v-list-item-content',{domProps:{"textContent":_vm._s(item)}})],1)]:_vm._e(),_c('v-list-item',[_c('perfil',{attrs:{"item":{
                          nombre: item.nombre,
                          imagen: '',
                          tipo: 'clientes',
                          codigosInternos: _vm.codigosInternos(
                            item.codigosInternos
                          ),
                          tamano: 'sm'
                        }}}),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.tipo)}})],1)],1)]}}]),model:{value:(_vm.servicioCita.idPropietario),callback:function ($$v) {_vm.$set(_vm.servicioCita, "idPropietario", $$v)},expression:"servicioCita.idPropietario"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"mascota",attrs:{"label":"Mascota","rules":_vm.nameRules,"items":_vm.mascotasFiltradas,"loading":_vm.isLoading,"item-text":"nombre","item-value":"id","required":""},on:{"dblclick":_vm.resetearCampos,"change":function($event){return _vm.setMyPet()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({ actualRef: 'mascota', nextRef: 'direccion' })},"focus":function () {
                      _vm.fetchMascotas(_vm.servicioCita.idPropietario);
                    }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [(_vm.testIfObject(item))?[_c('v-list-item',[_c('v-list-item-content',{domProps:{"textContent":_vm._s(item)}})],1)]:_vm._e(),_c('v-list-item',{on:{"click":function () {
                          _vm.servicioCita.epecie = item.epecie;
                          _vm.servicioCita.sexo = item.sexo;
                          _vm.servicioCita.edad = item.edad;
                          _vm.servicioCita.peso = item.peso;
                        }}},[_c('perfil',{attrs:{"item":{
                          nombre: item.nombre,
                          imagen: '',
                          tipo: 'mascotas',
                          codigosInternos: _vm.codigosInternos(
                            item.codigosInternos
                          ),
                          tamano: 'sm'
                        }}}),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.propietario)}})],1)],1)]}}]),model:{value:(_vm.servicioCita.idMascota),callback:function ($$v) {_vm.$set(_vm.servicioCita, "idMascota", $$v)},expression:"servicioCita.idMascota"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{ref:"grupo",attrs:{"label":"Grupo/Especie","item-value":"id","item-text":"nombre","items":_vm.grupos},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'grupo',
                      nextRef: 'sexo'
                    })}},model:{value:(_vm.grupoEspecie),callback:function ($$v) {_vm.grupoEspecie=$$v},expression:"grupoEspecie"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{ref:"sexo",attrs:{"disabled":"","label":"Sexo","rows":"2"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'sexo',
                      nextRef: 'edad'
                    })}},model:{value:(_vm.servicioCita.sexo),callback:function ($$v) {_vm.$set(_vm.servicioCita, "sexo", $$v)},expression:"servicioCita.sexo"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Edad"},model:{value:(_vm.stringCalcularEdad),callback:function ($$v) {_vm.stringCalcularEdad=$$v},expression:"stringCalcularEdad"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{ref:"peso",attrs:{"label":"Peso","rows":"2"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'peso',
                      nextRef: 'direccion'
                    })}},model:{value:(_vm.servicioCita.peso),callback:function ($$v) {_vm.$set(_vm.servicioCita, "peso", $$v)},expression:"servicioCita.peso"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.mostrarDireccion)?_c('v-textarea',{ref:"direccion",attrs:{"label":"Direccion","rows":"2"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'direccion',
                      nextRef: 'servicio'
                    })}},model:{value:(_vm.servicioCita.direccion),callback:function ($$v) {_vm.$set(_vm.servicioCita, "direccion", $$v)},expression:"servicioCita.direccion"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{ref:"servicio",attrs:{"label":"Servicio","items":_vm.serviciosSelect,"item-nombre":"nombre","item-text":"nombre"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'servicio',
                      nextRef: 'tipoServicio'
                    })}},model:{value:(_vm.servicioCita.servicio),callback:function ($$v) {_vm.$set(_vm.servicioCita, "servicio", $$v)},expression:"servicioCita.servicio"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{ref:"tipoServicio",attrs:{"label":"Tipo de servicio","items":_vm.tiposServicio ? _vm.tiposServicio : []},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'tipoServicio',
                      nextRef: 'motivoServicio'
                    })}},model:{value:(_vm.servicioCita.tipoServicio),callback:function ($$v) {_vm.$set(_vm.servicioCita, "tipoServicio", $$v)},expression:"servicioCita.tipoServicio"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"MVZ","items":[
                    { id: '0', nombre: 'Sin asignar' } ].concat( _vm.empleadosMVZ
                  ),"item-value":"id","item-text":"nombre"},model:{value:(_vm.servicioCita.idEmpleado),callback:function ($$v) {_vm.$set(_vm.servicioCita, "idEmpleado", $$v)},expression:"servicioCita.idEmpleado"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{ref:"motivoServicio",attrs:{"label":"Motivo del servicio","rows":"2"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'motivoServicio',
                      nextRef: 'serviciosADomicilio'
                    })}},model:{value:(_vm.servicioCita.motivoServicio),callback:function ($$v) {_vm.$set(_vm.servicioCita, "motivoServicio", $$v)},expression:"servicioCita.motivoServicio"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{ref:"serviciosADomicilio",attrs:{"label":"SAD","chips":"","item-value":"id","item-text":"nombre","items":_vm.serviciosADomicilio,"multiple":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'serviciosADomicilio',
                      nextRef: 'cuidadoMedico'
                    })}},model:{value:(_vm.servicioCita.idsSAD),callback:function ($$v) {_vm.$set(_vm.servicioCita, "idsSAD", $$v)},expression:"servicioCita.idsSAD"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{ref:"observaciones",attrs:{"label":"Observaciones","rows":"2"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'observaciones',
                      nextRef: 'fechaHoraCita'
                    })}},model:{value:(_vm.servicioCita.observaciones),callback:function ($$v) {_vm.$set(_vm.servicioCita, "observaciones", $$v)},expression:"servicioCita.observaciones"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{ref:"fechaCita",attrs:{"label":"Fecha de cita","type":"date","rows":"2"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'fechaCita',
                      nextRef: 'horaCita'
                    })}},model:{value:(_vm.servicioCita.fechaCita),callback:function ($$v) {_vm.$set(_vm.servicioCita, "fechaCita", $$v)},expression:"servicioCita.fechaCita"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{ref:"horaCita",attrs:{"label":"Hora de cita","type":"time","rows":"2"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'horaCita',
                      nextRef: 'citaConfirmada'
                    })}},model:{value:(_vm.servicioCita.horaCita),callback:function ($$v) {_vm.$set(_vm.servicioCita, "horaCita", $$v)},expression:"servicioCita.horaCita"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{ref:"citaConfirmada",attrs:{"label":"Cita confirmada","item-value":"value","item-text":"nombre","items":[
                    { nombre: 'No', value: 0 },
                    { nombre: 'Si', value: 1 }
                  ]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'citaConfirmada',
                      nextRef: 'fuente'
                    })}},model:{value:(_vm.servicioCita.citaConfirmada),callback:function ($$v) {_vm.$set(_vm.servicioCita, "citaConfirmada", $$v)},expression:"servicioCita.citaConfirmada"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{ref:"fuente",attrs:{"label":"Fuente","items":['Redes', 'Prospección', 'Recepción']},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'fuente'
                    })}},model:{value:(_vm.servicioCita.fuente),callback:function ($$v) {_vm.$set(_vm.servicioCita, "fuente", $$v)},expression:"servicioCita.fuente"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cerrar}},[_vm._v(" Cerrar ")]),(_vm.servicioCita.id == undefined)?_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.isSending},on:{"click":_vm.submitHandler}},[_vm._v(" Agregar ")]):_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.isSending},on:{"click":_vm.submitHandler}},[_vm._v(" Actualizar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }