<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      @keydown.esc="cerrar"
      @click:outside="cerrar"
      max-width="600px"
    >
      <v-card :loading="isLoading">
        <v-form
          ref="entryForm"
          color="teal"
          v-model="valid"
          @submit.prevent="submitHandler"
        >
          <v-card-title>
            <span class="headline" v-if="marca.id != undefined"
              >Editar Marca</span
            >
            <span class="headline" v-else>Agregar Marca</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre"
                    v-model="marca.nombre"
                    :rules="nameRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-if="marca.id == undefined"
            >
              Agregar
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitHandler" v-else>
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "ModalMarcas",
  props: ["fetchMarcas"],
  data: () => ({
    isLoading: false,
    isSending: false,
    valid: false,
    tipoRequest: "",
    idMarca: "0",
    isEditing: false,
    grupos: [],
    nameRules: [v => !!v || "El nombre es requerido"]
  }),
  computed: {
    open() {
      return this.$store.state.modals.marcas.open;
    },
    marca() {
      return this.$store.state.modals.marcas.item;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    toggleModal() {
      this.$store.commit("openModal", { modal: "marcas", open: !this.open });
    },
    async insertMarca() {
      this.isSending = true;
      if (this.marca.id != undefined) {
        this.tipoRequest = "update";
      } else {
        this.tipoRequest = "insert";
      }
      let myUrl = process.env.VUE_APP_API_URL + "marcas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: this.tipoRequest,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.marca]
      });
      if (respuesta) {
        this.isSending = false;
        this.$store.state.modals["marcas"].callback();
        this.fetchMarcas();
        if (this.marca.id == undefined) {
          this.$toasted.success("Marca agregado", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Marca actualizado", {
            position: "bottom-right",
            duration: 5000
          });
        }
        this.$refs.entryForm.reset();
        this.cerrar();
      }
    },
    async submitHandler() {
      let valido = await this.$refs.entryForm.validate();
      if (valido) {
        this.insertMarca();
      }
    },
    cerrar() {
      this.$store.commit("openModal", { modal: "marcas", open: false });
      this.$store.commit("setItemModal", {
        modal: "marcas",
        item: {},
        callback: async () => {}
      });
      this.$refs.entryForm.reset();
    }
  }
};
</script>
