<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <h1>Reporte de Comisiones Calculadas</h1>
              <br />
              <v-row>
                <v-col cols="12" sm="5" md="3" lg="3">
                  <label>Desde: </label>
                  <input type="date" v-model="desde" />
                </v-col>
                <v-col cols="12" sm="5" md="3" lg="3">
                  <label>Hasta: </label>
                  <input type="date" v-model="hasta" />
                </v-col>
                <v-col cols="12" sm="5" md="3" lg="3">
                  <v-autocomplete
                    prepend-icon="mdi-badge-account-horizontal"
                    append-icon="mdi-magnify"
                    label="Empleado"
                    :items="empleados"
                    item-text="nombre"
                    item-value="nombre"
                    v-model="busquedaEmpleado"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                  <v-btn
                    elevation="2"
                    small
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-simple-table class="mb-5" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">
                  Clínica
                </th>
                <th class="text-center">
                  Estética
                </th>
                <th class="text-center">
                  Farmacia
                </th>
                <th class="text-center">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">{{ sumas["Clinica"] }}</td>
                <td class="text-center">{{ sumas["Estetica"] }}</td>
                <td class="text-center">{{ sumas["Farmacia"] }}</td>
                <td class="text-center">{{ sumas["Total"] }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-data-table
          :headers="headers"
          :items="comisionesFiltradas"
          :items-per-page="7"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          calculate-widths
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
import { matchStrings } from "../tools/proStrings";
export default {
  name: "ReportesComisiones",
  data: () => ({
    loading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    busquedaEmpleado: "",
    headers: [
      { text: "Id Venta", value: "idVenta" },
      { text: "Status de venta", value: "statusVenta" },
      { text: "Id Movimiento", value: "id" },
      { text: "Tipo de comision", value: "tipoComision" },
      { text: "Fecha", value: "fecha", width: "110px" },
      { text: "Hora", value: "hora" },
      { text: "Vendedor", value: "nombreEmpleado", width: "140px" },
      { text: "Cliente", value: "nombreCliente", width: "140px" },
      { text: "Mascota", value: "nombreMascota", width: "140px" },
      { text: "Categoría", value: "categoria", width: "140px" },
      { text: "Subcategoría", value: "subcategoria", width: "140px" },
      { text: "Producto/Servicio", value: "productoServicio", width: "180px" },
      { text: "Precio Neto", value: "monto", width: "180px" },
      { text: "Cantidad", value: "cantidad", width: "110px" },
      { text: "Comisión", value: "comisionGenerada", width: "160px" }
    ],
    comisiones: [],
    empleados: [],
    placeholderComisiones: [
      {
        id: "47842",
        fechaHora: "16/10/2021	14:31:48",
        fecha: "16/10/2021",
        hora: "14:31:48",
        status: "Cerrada",
        vendedor: "Yauri Beatriz Ley Segovia",
        cliente: "Reposición de fondo Marketing",
        mvz: "Mvz Gustavo Gil",
        mascota: "Ninguna",
        categoria: "Clinica",
        subcategoria: "Biometria Externa",
        productoServicio: "Biometria hematica externa",
        cantidad: "1",
        neto: "130.00",
        comision: "1000.00"
      },
      {
        id: "47841",
        fechaHora: "16/10/2021	14:31:48",
        fecha: "16/10/2021",
        hora: "14:31:48",
        status: "Cerrada",
        vendedor: "Enrique Alonso Chacón Ley",
        cliente: "Reposición de fondo Marketing",
        mvz: "Mvz Gustavo Gil",
        mascota: "Ninguna",
        categoria: "Farmacia",
        subcategoria: "Biometria Externa",
        productoServicio: "Biometria hematica externa",
        cantidad: "1",
        neto: "130.00",
        comision: "100.00"
      },
      {
        id: "47840",
        fechaHora: "16/10/2021	14:31:48",
        fecha: "16/10/2021",
        hora: "14:31:48",
        status: "Cerrada",
        vendedor: "Javier Francisco Magaña Teyer",
        cliente: "Reposición de fondo Marketing",
        mvz: "Mvz Gustavo Gil",
        mascota: "Ninguna",
        categoria: "Estetica",
        subcategoria: "Biometria Externa",
        productoServicio: "Biometria hematica externa",
        cantidad: "1",
        neto: "130.00",
        comision: "10.00"
      },
      {
        id: "47839",
        fechaHora: "16/10/2021	14:31:48",
        fecha: "16/10/2021",
        hora: "14:31:48",
        status: "Cerrada",
        vendedor: "Javier Francisco Magaña Teyer",
        cliente: "Reposición de fondo Marketing",
        mvz: "Mvz Gustavo Gil",
        mascota: "Ninguna",
        categoria: "Estetica",
        subcategoria: "Biometria Externa",
        productoServicio: "Biometria hematica externa",
        cantidad: "1",
        neto: "130.00",
        comision: "25.00"
      }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchComisiones(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchComisiones(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  computed: {
    comisionesFiltradas() {
      let registrosSorteados = [...this.comisiones];
      if (this.loading) return;

      registrosSorteados = registrosSorteados.filter(registro => {
        return (
          matchStrings({
            s: this.busquedaEmpleado,
            str: registro.nombreEmpleado
          }) || this.busquedaEmpleado == ""
        );
      });
      return registrosSorteados;
    },
    sumas() {
      let suma = { Clinica: 0.0, Estetica: 0.0, Farmacia: 0.0 };
      let total = 0.0;
      let result = {};
      let atributos = ["Clinica", "Estetica", "Farmacia"];
      this.comisionesFiltradas.map(comision => {
        atributos.map(atributo => {
          if (matchStrings({ s: atributo, str: comision.categoria }))
            suma[atributo] = suma[atributo] + parseFloat(comision.comision);
        });
        total = total + parseFloat(comision.comision);
      });
      suma["Clinica"] = suma["Clinica"].toFixed(2);
      suma["Estetica"] = suma["Estetica"].toFixed(2);
      suma["Farmacia"] = suma["Farmacia"].toFixed(2);
      suma["Total"] = total.toFixed(2);
      result = suma;

      return result;
    }
  },
  methods: {
    async fetchComisiones(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "comisiones.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllReporte",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta.data.status == "Ok") {
        this.comisiones = respuesta.data.rows;
        this.isLoading = false;
        this.comisiones.map(comision => {
          comision.comisionGenerada = parseFloat(
            comision.comisionGenerada
          ).toFixed(2);
        });
      }
      //this.comisiones = this.placeholderComisiones;
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.empleados = respuesta.data.rows;
      this.isLoading = false;
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var comisiones = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, comisiones, "Comisiones");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.comisionesFiltradas.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            // Verificar si el valor es numérico
            let valor = registro[header.value];
            if (!isNaN(valor) && valor !== "") {
              // Convertir el valor a número
              valor = Number(valor);
            }
            XLSX.utils.sheet_add_aoa(sheet, [[valor]], {
              // Agregar el valor al archivo
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-Comisiones.xlsx`);
    },
    initialize() {
      this.fetchComisiones(
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
      this.fetchEmpleados();
      this.hasta = moment().valueOf();
      this.desde = moment()
        .subtract(1, "year")
        .valueOf();
    }
  }
};
</script>
