import axios from "axios";

export const getOneCompra = async id => {
  let myUrl = process.env.VUE_APP_API_URL + "compras.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getOne",
    id: id,
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") {
    return respuesta.data;
  }
};

export const sendCompra = async ({
  idCompra,
  status,
  fechaHora,
  pagos,
  idCliente,
  idEmpleado,
  idVendedor,
  productos,
  noDocumento,
  tipoCompra,
  idProveedor,
  ubicacionDestino
}) => {
  let myUrl = process.env.VUE_APP_API_URL + "compras.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: idCompra == 0 ? "insert" : "update",
    token: localStorage.token,
    geo: localStorage.geo,
    compra: {
      id: idCompra,
      status,
      fechaHora,
      noDocumento,
      tipoCompra
    },
    pagos,
    idCliente,
    idEmpleado,
    idVendedor,
    idProveedor,
    idCaja: localStorage.idCaja,
    items: productos,
    ubicacionDestino
  });
  if (respuesta.data.status == "Ok") {
    return respuesta.data;
  }
  return respuesta;
};
