<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="6">
          <h1 v-if="idVenta == 0 || !canEditSale">Nueva venta</h1>
          <h1 v-else>Editando venta {{ $route.params.id }}</h1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-dialog
        v-model="mostrarDialogoCredenciales"
        persistent
        max-width="520px"
      >
        <v-card>
          <v-card-title>
            Por favor, ingresa credenciales de administrador <br />
            para editar esta venta o presiona el botón de <br />
            nueva venta para hacer una nueva
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="usuario" label="Usuario"></v-text-field>
            <v-text-field
              v-model="password"
              label="Contraseña"
              type="password"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success white--text"
              v-if="idVenta != 0"
              @click="
                () => {
                  mostrarDialogoCredenciales = false;
                  resetNuevaVenta();
                }
              "
              :disabled="isLoading || isAskingForPermisson"
            >
              <v-icon left dark>mdi-cash</v-icon>Nueva venta
            </v-btn>
            <v-btn
              color="primary"
              @click="pedirPermiso"
              :disabled="isAskingForPermisson"
              ><v-icon left dark>mdi-pencil</v-icon> Editar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col cols="12" md="8" @drop="drop" @dragover="allowDrop">
          <v-row>
            <v-col cols="12" md="4">
              <SelectEmpleado
                :callback="setCajero"
                ref="selectCajero"
                placeholder="Selecciona un cajero"
              />
            </v-col>
            <v-col cols="12" md="4">
              <SelectEmpleado
                v-if="setVendedor"
                :callback="setVendedor"
                ref="selectVendedor"
                placeholder="Selecciona un vendedor"
              />
            </v-col>
            <v-col cols="12" md="4">
              <SelectCliente
                ref="selectCliente"
                v-if="setCliente"
                :callback="setCliente"
              />
            </v-col>
            <v-col cols="12" md="12">
              <NuevaVentaTabla />
            </v-col>
            <v-col cols="12" md="12">
              <div class="d-flex justify-space-around my-6">
                <v-btn
                  color="success white--text"
                  v-if="idVenta != 0"
                  @click="resetNuevaVenta()"
                  :disabled="isLoading || isAskingForPermisson"
                >
                  <v-icon left dark>mdi-cash</v-icon>Nueva venta
                </v-btn>
                <v-btn
                  color="danger white--text"
                  v-if="idVenta == 0"
                  @click="clearNuevaVenta"
                  :disabled="isLoading || isAskingForPermisson"
                >
                  <v-icon left dark>mdi-cancel</v-icon>Cancelar
                </v-btn>
                <v-btn
                  color="success"
                  v-if="idVenta == 0"
                  @click="cerrada"
                  :disabled="isLoading || isAskingForPermisson"
                >
                  <v-icon left dark>mdi-cash-lock</v-icon>Cerrada
                </v-btn>
                <v-btn
                  color="purple white--text"
                  v-if="idVenta == 0"
                  @click="usoInterno"
                  :disabled="isLoading || isAskingForPermisson"
                >
                  <v-icon left dark>mdi-ice-pop</v-icon>Uso interno
                </v-btn>
                <v-btn
                  color="black white--text"
                  v-if="idVenta != 0 && canEditSale"
                  @click="sendVenta"
                  :disabled="isLoading || isAskingForPermisson"
                >
                  <v-icon left dark>mdi-reload</v-icon>Actualizar
                </v-btn>
                <v-btn
                  color="info white--text"
                  v-if="idVenta != 0"
                  @click="print"
                  :disabled="isLoading || isAskingForPermisson"
                  :loading="isPrinting"
                >
                  <v-icon left dark>mdi-ticket</v-icon>Ticket
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <NuevaVentaListaProductosStock />
        </v-col>
        <v-col cols="12" md="12">
          <NuevaVentaModalPagos
            ref="NuevaVentaModalPagos"
            v-if="sendVenta"
            :callback="sendVenta"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import NuevaVentaTabla from "./NuevaVentaTabla.vue";
import NuevaVentaListaProductosStock from "./NuevaVentaListaProductosStock.vue";
import NuevaVentaModalPagos from "./NuevaVentaModalPagos.vue";
import SelectEmpleado from "../../components/SelectEmpleado.vue";
import SelectCliente from "../../components/SelectCliente.vue";
import printVenta from "../../tools/printTickets/printTicketVenta";
import { mapActions, mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      modoVenta: "por cobrar",
      isPrinting: false,
      canEditSale: false,
      mostrarDialogoCredenciales: false,
      isAskingForPermisson: false,
      usuario: "",
      password: ""
    };
  },
  components: {
    NuevaVentaTabla,
    NuevaVentaListaProductosStock,
    NuevaVentaModalPagos,
    SelectEmpleado,
    SelectCliente
  },
  mounted() {
    this.initialize();
    this.checkPermissons();
    this.askForPassword();
  },
  watch: {
    idVenta(val) {
      this.askForPassword();
    },
    mostrarDialogoCredenciales(val) {
      if (!val) return;
      this.usuario = "usuario";
      this.password = "contraseña";
    }
  },
  computed: {
    ...mapState({
      idVenta: state => state.nuevaVenta.idVenta,
      isLoading: state => state.nuevaVenta.isLoading,
      cajero: state => state.nuevaVenta.cajero,
      vendedor: state => state.nuevaVenta.vendedor,
      cliente: state => state.nuevaVenta.cliente,
      pagos: state => state.nuevaVenta.pagos,
      productos: state => state.productos.productos,
      productosNuevaVenta: state => state.nuevaVenta.productosNuevaVenta
    })
  },
  methods: {
    ...mapActions("nuevaVenta", [
      "fetchVentaById",
      "terminarVenta",
      "clearNuevaVenta",
      "addProduct",
      "resetNuevaVenta"
    ]),
    ...mapMutations("nuevaVenta", [
      "setCliente",
      "setCajero",
      "setVendedor",
      "setIdVenta",
      "setStatusVenta"
    ]),
    askForPassword() {
      if (this.idVenta != 0 && this.canEditSale && false)
        this.mostrarDialogoCredenciales = true;
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
    drop(ev) {
      ev.preventDefault();
      let id = ev.dataTransfer.getData("productId");
      this.addProduct({
        product: { id },
        shouldSubtract: false
      });
    },
    porCobrar() {
      this.$refs.NuevaVentaModalPagos.open();
    },
    cerrada() {
      this.$refs.NuevaVentaModalPagos.open();
    },
    async usoInterno() {
      if (confirm("¿Deseas registrar ésta venta cómo de Uso Interno?")) {
        const hasStock = await this.checkStocks();
        if (!hasStock) {
          return;
        }

        this.$toasted.show("Guardando... no cierres la ventana", {
          position: "bottom-right",
          duration: 5000
        });

        this.setStatusVenta({ status: "uso interno" });
        const response = await this.terminarVenta({});
        if (response.data?.status == "error") {
          if (response.data.items.length)
            response.data.items.forEach(item =>
              this.$toasted.error(
                item.nombre +
                  " No tiene stock y no es posible venderlo sin stock",
                {
                  position: "bottom-right",
                  duration: 5000
                }
              )
            );

          this.$refs.NuevaVentaModalPagos.close();
          return;
        }
        if (response) {
          this.setIdVenta(response.idInsertado);
          this.$toasted.success("Venta agregada", {
            position: "bottom-right",
            duration: 5000
          });

          this.$refs.NuevaVentaModalPagos.close();
          return;
        }
      }
    },
    async initialize() {
      if (this.$route.params.id) {
        await this.fetchVentaById({
          id: this.$route.params.id
        });

        this.$refs.selectCajero.setEmpleadoById(this.cajero.id);
        this.$refs.selectVendedor.setEmpleadoById(this.vendedor.id);

        await this.$store.dispatch("clientes/getById", this.cliente.id);
        this.$refs.selectCliente.setClienteById(this.cliente.id);
        this.$refs.NuevaVentaModalPagos.setPagos(this.pagos);
      } else {
        this.resetNuevaVenta();
      }
      await this.$store.dispatch("productos/getProductos");
    },
    async print() {
      this.isPrinting = true;
      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getOne",
        id: this.idVenta,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        const empleados = [];
        if (this.vendedor) empleados.push(this.vendedor);
        let venta = {
          ...respuesta.data.ventas.find(venta => venta.id == this.idVenta),
          ...respuesta.data,
          empleados: [...empleados, respuesta.data.empleados]
        };
        printVenta(venta, venta);
      }
      this.isPrinting = false;
    },
    async checkStocks() {
      if (this.canEditSale || this.idVenta != 0) return true;
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "checkStocks",
        token: localStorage.token,
        geo: localStorage.geo,
        productos: this.productosNuevaVenta
      });
      if (respuesta.data.status == "Ok") {
        const productosRespuesta = respuesta.data.rows;
        let shouldComplete = true;
        this.productosNuevaVenta.forEach(productoNuevaVenta => {
          const stockProduct = productosRespuesta.find(
            productoRespuesta => productoRespuesta.id == productoNuevaVenta.id
          );
          if (
            Number(productoNuevaVenta.cantidad) > Number(stockProduct.stock) &&
            stockProduct.controlStock == "Si"
          ) {
            this.$toasted.error(
              `Stock Insuficiente | ${productoNuevaVenta.nombre} | Stock Actual en Tienda: ${stockProduct.stock}`,
              {
                position: "bottom-right",
                duration: 5000
              }
            );
            shouldComplete = false;
          }
        });
        return shouldComplete;
      }
    },
    async sendVenta() {
      this.setStatusVenta({ status: "por cobrar" });
      const hasStock = await this.checkStocks();
      if (!hasStock) {
        this.$refs.NuevaVentaModalPagos.close();
        return;
      }
      if (localStorage.idCaja == "0" || !localStorage.idCaja) {
        let todoEsCero = true;
        for (const pago in this.pagos) {
          if (this.pagos[pago] > 0) {
            todoEsCero = false;
          }
        }
        if (!todoEsCero) {
          this.$toasted.error(
            "Abre una caja antes de hacer una venta y registrar ingresos",
            {
              position: "bottom-right",
              duration: 5000
            }
          );
          this.$refs.NuevaVentaModalPagos.close();
          return;
        }
      }
      const stringArrayPermisos = localStorage.getItem("permisos");
      const arrayPermisos = JSON.parse(stringArrayPermisos);
      let isAdmin = false;
      isAdmin = arrayPermisos.find(permiso => {
        if (permiso.id == "17") return true;
      });
      if (isAdmin) {
        if (!(this.vendedor.id > 0 && this.cliente.id > 0)) {
          this.$toasted.error("Selecciona un vendedor y un cliente", {
            position: "bottom-right",
            duration: 5000
          });
          this.$refs.NuevaVentaModalPagos.close();
          return;
        }
      } else {
        if (
          !(this.cajero.id > 0 && this.vendedor.id > 0 && this.cliente.id > 0)
        ) {
          this.$toasted.error(
            "Selecciona un cajero, un vendedor y un cliente",
            {
              position: "bottom-right",
              duration: 5000
            }
          );
          this.$refs.NuevaVentaModalPagos.close();
          return;
        }
      }

      this.$toasted.show("Guardando... no cierres la ventana", {
        position: "bottom-right",
        duration: 5000
      });

      const response = await this.terminarVenta({ pagos: this.pagos });
      if (response.data?.status == "error") {
        if (response.data.items.length)
          response.data.items.forEach(item =>
            this.$toasted.error(item.nombre + " No tiene stock suficiente", {
              position: "bottom-right",
              duration: 5000
            })
          );

        this.$refs.NuevaVentaModalPagos.close();
        return;
      }
      if (response) {
        this.setIdVenta(response.idInsertado);
        this.$toasted.success("Venta agregada", {
          position: "bottom-right",
          duration: 5000
        });

        this.$refs.NuevaVentaModalPagos.close();
        return;
      }
    },

    checkPermissons() {
      if (!localStorage?.permisos) return;
      this.canEditSale = JSON.parse(localStorage.permisos)?.some(
        permiso => permiso.id == 60 || permiso.id == 1
      );
    },

    async pedirPermiso() {
      if (!this.usuario || !this.password) {
        this.$toasted.error("Introduce un usuario y/o contraseña", {
          position: "bottom-right",
          duration: 1
        });
      }
      this.isAskingForPermisson = true;
      this.$toasted.show("Cargando...", {
        position: "bottom-right",
        duration: 5000
      });

      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "testUpdatePermisson",
        token: localStorage.token,
        geo: localStorage.geo,
        usuario: this.usuario.trim(),
        password: this.password
      });
      if (respuesta.data.permisson == "Ok") {
        this.mostrarDialogoCredenciales = false;
        this.$toasted.success("Datos verificados", {
          position: "bottom-right",
          duration: 2000
        });
      } else {
        this.$toasted.error("Datos incorrectos", {
          position: "bottom-right",
          duration: 2000
        });
      }
      this.isAskingForPermisson = false;
    }
  }
};
</script>
