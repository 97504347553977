<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Reglas de Comisiones</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="orange darken-4"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="empleados"
                v-model="idEmpleado"
                item-value="id"
                item-text="nombre"
                placeholder="Selecciona un empleado para ver comisiones"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3" class="text-right">
              <v-btn
                elevation="2"
                small
                color="orange darken-4 white--text "
                @click="addItem()"
                >Agregar Comision</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="comisiones"
          :items-per-page="7"
          :search="search"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :header-props="headerProps"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [10, 25, 50, 100, 250, 500, -1]
          }"
          dense
        >
          <template v-slot:[`item.detalles`]="{ item }">
            <v-btn icon @click="editItem(item)" v-if="false">
              <v-icon small color="blue"> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon @click="deleteItem(item.id)">
              <v-icon small color="danger"> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.monto`]="{ item }">
            <span>$ {{ item.monto }}</span>
          </template>
          <template v-slot:no-data>
            <v-btn color="orange darken-4 my-6" rounded @click="initialize">
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <ModalComisiones />
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
import ModalComisiones from "../layouts/AgregarEditar/ModalComisiones";
import moment from "moment";

export default {
  name: "Comisiones",
  components: { ModalComisiones },
  data: () => ({
    search: "", // modalComisiones : false TAMBIEN AQUÍ DEBE EXISTIR ESA VARIABLE, EN CASO DE QUE CASO DE QUE SE LLAME SEARCH, LLÁMALA modalComisiones es booleano
    isLoading: false,
    cargando: false,
    isSending: false,
    dialog: false,
    idComision: "",
    tipoRequest: "",
    fechaInicial: "",
    fechaFinal: "",
    valid: false,
    idEmpleado: 0,
    totalMonto: 0,
    totalComision: 0,
    empleados: [],
    formData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigoInterno: "",
      rfc: "",
      codigoPostal: "",
      direccion: "",
      observaciones: ""
    },
    headerProps: {
      sortByText: "Ordenar por"
    },
    headers: [
      { text: "Id comisión asignada", value: "id" },
      { text: "Empleado", value: "empleado" },
      { text: "Categoría", value: "categoria", align: "center" },
      { text: "Subcategoría", value: "subcategoria", align: "center" },
      { text: "Concepto", value: "producto", align: "center" },
      { text: "Tipo", value: "tipo", align: "center" },
      { text: "Monto", value: "monto", align: "center" },
      { text: "Porcentaje", value: "porcentaje", align: "center" },
      { text: "Acciones", value: "detalles", align: "right" }
    ],
    comisiones: [],
    areas: ["Ventas", "Clínica", "Estética", "Transporte"],
    cli: {}
  }),
  created() {
    this.initialize();
  },

  watch: {
    search(val) {
      this.$store.commit("setSearchParams", {
        name: "comisiones",
        search: val
      });
    },
    fechaInicial() {
      this.fetchComisiones();
    },
    fechaFinal() {
      this.fetchComisiones();
    },
    idEmpleado() {
      this.fetchComisiones();
    }
  },

  methods: {
    initialize() {
      this.fechaInicial = moment()
        .subtract(15, "days")
        .format("YYYY-MM-DD");
      this.fechaFinal = moment().format("YYYY-MM-DD");
      this.fetchComisiones();
      this.fetchEmpleados();
      //this.search = this.$store.state.searchParams["comisiones"];
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "comisiones",
        item: {},
        callback: this.fetchComisiones
      });

      this.$store.commit("openModal", { modal: "comisiones", open: true });
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.empleados = respuesta.data.rows;
      this.isLoading = false;
    },
    async fetchComisiones() {
      this.isLoading = true;
      let tipo = "getAllAsignadas";
      let myIdEmpleado = 0;
      if (this.idEmpleado) {
        tipo = "getAllAsignadasEmpleado";
        myIdEmpleado = this.idEmpleado;
      }
      let myUrl = process.env.VUE_APP_API_URL + "comisiones.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: tipo,
        token: localStorage.token,
        geo: localStorage.geo,
        idEmpleado: myIdEmpleado,
        fechaInicial: this.fechaInicial,
        fechaFinal: this.fechaFinal
      });
      if (respuesta.data.status == "Ok") {
        this.totalMonto = 0;
        this.totalComision = 0;
        this.comisiones = respuesta.data.rows.map(comision => {
          comision.comisionGenerada = parseFloat(
            comision.comisionGenerada
          ).toFixed(2);
          return comision;
        });
        this.comisiones.map(comision => {
          this.totalMonto =
            parseFloat(this.totalMonto) + parseFloat(comision.monto);
          this.totalComision =
            parseFloat(this.totalComision) +
            parseFloat(comision.comisionGenerada);
        });
      }
      this.isLoading = false;
    },

    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "comisiones",
        item: { ...myItem },
        callback: this.fetchComisiones
      });
      this.$store.commit("openModal", { modal: "comisiones", open: true });
    },
    async deleteItem(id) {
      let myUrl = process.env.VUE_APP_API_URL + "comisiones.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id
      });
      if (respuesta.data.status == "Ok") {
        this.fetchComisiones();
      }
    },

    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    }
  }
};
</script>
