<style scoped>
.camposEditables {
  min-width: 240px;
}
.status-select {
  width: 150px;
}
</style>
<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Servicio a Domicilio</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="#0097A7"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-chip style="background-color: #0097a7; color: #fff"
                >Mascotas en SAD: {{ contadorSAD }}</v-chip
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-simple-table fixed-header dense>
          <template v-slot:default>
            <tbody>
              <!-- SAD -->
              <tr class="indigo white--text">
                <th class="text-center" colspan="15">SAD ESTÉTICA</th>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-data-table
          :headers="headers"
          :items="soloSAD"
          :items-per-page="7"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
          :item-class="blinkRowClass"
        >
          <template v-slot:[`item.horaRegistro`]="{ item }">
            {{
              timeConvert({
                time: item.horaRegistro.slice(-8),
                seconds: false
              })
            }}
          </template>
          <template v-slot:[`item.propietario`]="{ item }">
            <NombreCliente
              :cliente="{
                nombre: item.nombreCliente,
                codigosInternos: item.codigosInternosCliente
              }"
            />
          </template>
          <template v-slot:[`item.mascota`]="{ item }">
            <NombreMascota
              :mascota="{
                nombre: item.nombre,
                codigosInternos: item.codigosInternosMascota
              }"
            />
          </template>
          <template v-slot:[`item.servicios`]="{ item }">
            <ul>
              <li
                class="py-2"
                v-for="(servicio, index) in item.conceptos.filter(
                  concepto =>
                    concepto.idSubcategoria == '5' ||
                    concepto.idSubcategoria == '6'
                )"
                :key="index"
              >
                {{ servicio.nombre }}
              </li>
            </ul>
          </template>
          <template v-slot:[`item.adicionales`]="{ item }">
            <ul>
              <li
                class="py-2"
                v-for="(servicio, index) in item.conceptos.filter(
                  concepto => concepto.idSubcategoria == '7'
                )"
                :key="index"
              >
                {{ servicio.nombre }}
              </li>
            </ul>
          </template>
          <template v-slot:[`item.choferEntrega`]="{ item }">
            <v-select
              v-model="item.idEmpleadoEntrega"
              :items="choferes"
              item-text="nombre"
              item-value="id"
              @change="updateFields(item)"
            />
          </template>
          <template v-slot:[`item.choferRecolecta`]="{ item }">
            <v-select
              v-model="item.idEmpleadoRecolecta"
              :items="choferes"
              item-text="nombre"
              item-value="id"
              @change="updateFields(item)"
            />
          </template>
          <template v-slot:[`item.detallesCorte`]="{ item }">
            <v-text-field
              v-model="item.detallesCorte"
              placeholder="Detalles del corte"
              class="camposEditables"
              @blur="updateFields(item)"
            ></v-text-field>
          </template>
          <template v-slot:[`item.observaciones`]="{ item }">
            <v-text-field
              v-model="item.observaciones"
              placeholder="Observaciones"
              class="camposEditables"
              @blur="updateFields(item)"
            ></v-text-field>
          </template>
          <template v-slot:[`item.horaEntrega`]="{ item }">
            {{
              timeConvert({
                time: item.horaEntrega.slice(-8),
                seconds: false
              })
            }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-select
              v-model="item.status"
              :items="listaStatusEstetica"
              @change="actualizarStatusEstetica(item)"
              class="status-select"
            />
          </template>
          <template v-slot:[`item.costo`]="{ item }">
            {{ sumaCostos(item) }}
          </template>
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <v-simple-table fixed-header dense class="pt-8">
          <template v-slot:default>
            <tbody>
              <!-- SAD -->
              <tr class="indigo white--text">
                <th class="text-center" colspan="15">SAD CLÍNICA</th>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-data-table
          :headers="headersClinica"
          :items="clinicaSoloSAD"
          :items-per-page="7"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.horaRegistro`]="{ item }">
            {{
              timeConvert({
                time: item.horaRegistro.slice(-8),
                seconds: false
              })
            }}
          </template>
          <template v-slot:[`item.propietario`]="{ item }">
            <NombreCliente
              :cliente="{
                nombre: item.nombreCliente,
                codigosInternos: item.codigosInternosCliente
              }"
            />
          </template>
          <template v-slot:[`item.mascota`]="{ item }">
            <NombreMascota
              :mascota="{
                nombre: item.nombre,
                codigosInternos: item.codigosInternosMascota
              }"
            />
          </template>
          <template v-slot:[`item.choferEntrega`]="{ item }">
            <v-select
              v-model="item.idEmpleadoEntrega"
              :items="choferes"
              item-text="nombre"
              item-value="id"
              @change="updateFieldsClinica(item)"
            />
          </template>
          <template v-slot:[`item.choferRecolecta`]="{ item }">
            <v-select
              v-model="item.idEmpleadoRecolecta"
              :items="choferes"
              item-text="nombre"
              item-value="id"
              @change="updateFieldsClinica(item)"
            />
          </template>
          <template v-slot:[`item.observaciones`]="{ item }">
            <v-text-field
              v-model="item.observaciones"
              placeholder="Observaciones"
              class="camposEditables"
              @blur="updateFieldsClinica(item)"
            ></v-text-field>
          </template>
          <template v-slot:[`item.horaEntrega`]="{ item }">
            {{
              timeConvert({
                time: item.horaEntrega.slice(-8),
                seconds: false
              })
            }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-select
              v-model="item.status"
              :items="listaStatusClinica"
              @change="actualizarStatusClinica(item)"
              width="100%"
            />
          </template>
          <template v-slot:[`item.costo`]="{ item }">
            {{ sumaCostos(item) }}
          </template>
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings, ampmConvert } from "../tools/proStrings";
import NombreCliente from "./Clientes/NombreCliente.vue";
import NombreMascota from "./Mascotas/NombreMascota.vue";
export default {
  name: "RegistrosSAD",
  components: { NombreCliente, NombreMascota },
  data: () => ({
    search: "",
    isLoading: false,
    tipoRequest: "",
    valid: false,
    registros: [],
    registrosSAD: [],
    serviciosClinica: [],
    serviciosClinicaSAD: [],
    choferes: [],
    listaStatusEstetica: [
      "Cancelado",
      "En Recolecta",
      "Recolectado",
      "En Servicio",
      "En Espera",
      "En Entrega",
      "Entregado",
      "Finalizado"
    ],
    listaStatusClinica: [
      "Cancelado",
      "En Recolecta",
      "Recolectado",
      "En Consulta",
      "Sala De Espera",
      "Atendido",
      "En Entrega",
      "Entregado",
      "Finalizado"
    ],
    servicios: ["Baño", "Baño y cepillado", "Limpieza dental"],
    headers: [
      { text: "Id", value: "idCount" },
      { text: "Hora de registro", value: "horaRegistro" },
      { text: "Propietario", value: "propietario" },
      { text: "Dirección", value: "direccion" },
      { text: "Teléfono", value: "clienteTel" },
      { text: "Mascota", value: "mascota" },
      { text: "Específico", value: "raza" },
      { text: "Servicios", value: "servicios" },
      { text: "Adicionales", value: "adicionales" },
      { text: "Chofer de recolecta", value: "choferRecolecta" },
      { text: "Chofer de entrega", value: "choferEntrega" },
      { text: "Detalles del corte", value: "detallesCorte" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Hora de entrega", value: "horaEntrega" },
      { text: "Estatus", value: "status" },
      { text: "Costo", value: "costo" }
    ],
    headersClinica: [
      { text: "Id", value: "idCount" },
      { text: "Hora de registro", value: "horaRegistro" },
      { text: "Propietario", value: "propietario" },
      { text: "Dirección", value: "direccion" },
      { text: "Teléfono", value: "clienteTel" },
      { text: "Mascota", value: "mascota" },
      { text: "Específico", value: "raza" },
      { text: "Servicio", value: "servicio" },
      { text: "Chofer de recolecta", value: "choferRecolecta" },
      { text: "Chofer de entrega", value: "choferEntrega" },
      { text: "Motivo de servicio", value: "motivoServicio" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Hora de entrega", value: "horaEntrega" },
      { text: "Estatus", value: "status" },
      { text: "Costo", value: "costo" }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", { name: "empleados", search: val });
    }
  },
  computed: {
    soloSAD() {
      return this.registros.filter(registro => {
        let esSAD = registro.conceptos.some(
          concepto => concepto.idSubcategoria == "8"
        );
        let noEsFinalizado = registro.status !== "Finalizado";
        return esSAD && noEsFinalizado;
      });
    },
    clinicaSoloSAD() {
      return this.serviciosClinica.filter(registro => {
        let esSADClinica = registro.conceptos.some(
          concepto => concepto.idSubcategoria == "16"
        );
        let noEsFinalizado = registro.status !== "Finalizado";
        return esSADClinica && noEsFinalizado;
      });
    },
    contadorSAD() {
      let count = 0;
      this.registros.map(registro => {
        console.log(registro);
        let itHas = registro.conceptos.filter(
          concepto =>
            matchStrings({ s: "domicilio", str: concepto.nombre }) ||
            (matchStrings({ s: "sad", str: concepto.nombre }) &&
              registro.status != "Finalizado")
        );
        itHas.length ? count++ : null;
      });
      return count;
    }
  },
  methods: {
    initialize() {
      this.fetchRegistros();
      this.fetchServiciosClinica();
      this.fetchChoferes();
      this.search = this.$store.state.searchParams["empleados"];
    },
    blinkRowClass(item) {
      return {
        infoBlink:
          item?.status?.toLowerCase().includes("recolectado") ||
          item?.status?.toLowerCase().includes("en entrega") ||
          item?.status?.toLowerCase().includes("entregado"),
        infoRow:
          item?.status?.toLowerCase().includes("recolectado") ||
          item?.status?.toLowerCase().includes("en entrega") ||
          item?.status?.toLowerCase().includes("entregado"),
        successRow: item?.status?.toLowerCase().includes("en servicio")
      };
    },
    timeConvert(time) {
      return ampmConvert(time);
    },
    async fetchChoferes() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllAbsolute",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.choferes = respuesta.data.rows.filter(empleado => {
          return empleado.cargo.toLowerCase().includes("chofer");
        });
      }
      this.isLoading = false;
    },
    async fetchRegistros() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllToday",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.registros = respuesta.data.rows.filter(
          registro => registro.status != "Cancelado"
        );
        this.registros.sort((a, b) => {
          if (a.status === "En Recolecta" && b.status !== "En Recolecta")
            return -1;
          if (a.status === "Recolectado" && b.status !== "Recolectado")
            return 1;
          return 0;
        });
        this.isLoading = false;
      }
    },
    async fetchServiciosClinica() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "serviciosClinica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllToday",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.serviciosClinica = respuesta.data.rows
          .filter(registro => registro.status != "Cancelado")
          .map(itm => {
            itm.conceptos.filter(concepto => concepto.idSubcategoria == "16")
              .length > 0
              ? this.serviciosClinicaSAD.push(itm)
              : null;
            return itm;
          });
        this.serviciosClinica.sort((a, b) => {
          if (a.status === "En Recolecta" && b.status !== "En Recolecta")
            return -1;
          if (a.status === "Recolectado" && b.status !== "Recolectado")
            return 1;
          return 0;
        });
        this.isLoading = false;
      }
    },
    async updateFields(item) {
      this.isSending = true;
      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update minor",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta.data.status == "Ok") {
        this.isLoading = false;
        this.$toasted.success("Registro actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    async updateFieldsClinica(item) {
      this.isSending = true;
      let myUrl = process.env.VUE_APP_API_URL + "serviciosClinica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update minor",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta.data.status == "Ok") {
        this.isLoading = false;
        this.$toasted.success("Registro actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "empleados",
        item: {},
        callback: this.fetchEmpleados
      });

      this.$store.commit("openModal", { modal: "empleados", open: true });
    },
    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "empleados",
        item: { ...myItem },
        callback: this.fetchEmpleados
      });
      this.$store.commit("openModal", { modal: "empleados", open: true });
    },
    async deleteItem(id) {
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id,
        callback: this.fetchEmpleados
      });
    },
    async actualizarStatusEstetica(item) {
      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update minor",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Status actualizado", {
          position: "bottom-right",
          duration: 5000
        });
        this.fetchRegistros();
        this.fetchServiciosClinica();
      } else {
        this.$toasted.error("Ocurrió un problema", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    async actualizarStatusClinica(item) {
      let myUrl = process.env.VUE_APP_API_URL + "serviciosClinica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update minor",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Status actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      } else {
        this.$toasted.error("Ocurrió un problema", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },

    codigosInternos(cods) {
      if (cods?.length && cods?.length > 1) {
        try {
          const parsedCods = JSON.parse(cods);

          if (Array.isArray(parsedCods)) {
            return [...parsedCods];
          }
          return [];
        } catch (error) {
          return [];
        }
      }
      return [];
    },
    sumaCostos(item) {
      let sumaPrecios = 0;
      item.conceptos.map(concepto => {
        sumaPrecios = sumaPrecios + parseFloat(concepto.precio);
      });
      return sumaPrecios.toFixed(2);
    }
  }
};
</script>
