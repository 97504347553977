import { getOneCompra, sendCompra } from "../axios-calls/compras";

export default {
  namespaced: true,
  state: {
    idCompra: 0,
    isLoading: false,
    productosNuevaCompra: [],
    cajero: { id: 0 },
    proveedor: { id: 0 },
    pagos: {
      efectivo: 0,
      credito: 0,
      debito: 0,
      izCredito: 0,
      izDebito: 0,
      transferencia: 0,
      puntosSaldo: 0,
      depEfectivo: 0,
      cambio: 0
    },
    ubicacionDestino: 1,
    lastGet: "none",
    status: "por cobrar",
    noDocumento: "",
    tipoCompra: ""
  },
  mutations: {
    setIdCompra(state, idCompra) {
      state.idCompra = idCompra;
    },
    setNoDocumento(state, noDocumento) {
      state.noDocumento = noDocumento;
    },
    setTipoCompra(state, tipo) {
      state.tipoCompra = tipo;
    },
    setUbicacion(state, ubicacion) {
      state.ubicacionDestino = ubicacion;
    },
    setPagos(state, pagos) {
      state.pagos = { ...state.pagos, ...pagos };
    },
    resetPagos(state) {
      state.status = "por cobrar";
      state.pagos = {
        ...state.pagos,
        efectivo: 0,
        credito: 0,
        debito: 0,
        izCredito: 0,
        izDebito: 0,
        transferencia: 0,
        puntosSaldo: 0,
        depEfectivo: 0
      };
    },
    setCajero(state, cajero) {
      state.cajero = cajero;
    },
    setProveedor(state, proveedor) {
      state.proveedor = proveedor;
    },
    setCliente(state, cliente) {
      state.cliente = cliente;
    },
    removeProduct(state, uniqueId) {
      const index = state.productosNuevaCompra.findIndex(
        p => p.uniqueId === uniqueId
      );
      if (index !== -1) {
        state.productosNuevaCompra.splice(index, 1);
      }
    },
    setQuantity(state, { id, cantidad }) {
      let product = state.productosNuevaCompra.find(p => p.id === id);
      product.cantidad = cantidad;
      product.descuentoPorcentaje = parseFloat(
        (
          (parseFloat(product.descuentoMonto) /
            (parseFloat(product.cantidad) *
              parseFloat(product.precioCompraSinImpuesto))) *
          100
        ).toFixed(2)
      );
    },
    setprecioCompraSinImpuesto(state, { uniqueId, precioCompraSinImpuesto }) {
      let product = state.productosNuevaCompra.find(
        p => p.uniqueId === uniqueId
      );
      product.precioCompraSinImpuesto = precioCompraSinImpuesto;
      product.descuentoPorcentaje = parseFloat(
        (
          (product.descuentoMonto /
            (product.cantidad * product.precioCompraSinImpuesto)) *
          100
        ).toFixed(2)
      );
    },
    updateDescuentoPorcentaje(state, { uniqueId }) {
      let product = state.productosNuevaCompra.find(
        p => p.uniqueId === uniqueId
      );

      product.descuentoPorcentaje = parseFloat(
        (
          (product.descuentoMonto /
            (product.cantidad * product.precioCompraSinImpuesto)) *
          100
        ).toFixed(2)
      );
    },
    updateDescuentoPorcentajeInAll(state) {
      state.productosNuevaCompra.forEach(product => {
        product.descuentoPorcentaje = parseFloat(
          (
            (product.descuentoMonto /
              (product.cantidad * product.precioCompraSinImpuesto)) *
            100
          ).toFixed(2)
        );
      });
    },
    updateDescuentoMonto(state, { uniqueId }) {
      let product = state.productosNuevaCompra.find(
        p => p.uniqueId === uniqueId
      );

      product.descuentoMonto = parseFloat(
        (
          (product.descuentoPorcentaje / 100) *
          (product.cantidad * product.precioCompraSinImpuesto)
        ).toFixed(2)
      );
    },
    calculateTotal(state, { uniqueId }) {
      let product = state.productosNuevaCompra.find(
        p => p.uniqueId === uniqueId
      );
      product.totalAntesImpuesto = parseFloat(
        (
          parseFloat(product.precioCompraSinImpuesto) *
          parseFloat(product.cantidad)
        ).toFixed(2)
      );
      product.totalAntesImpuestoConDescuento = parseFloat(
        (
          parseFloat(product.totalAntesImpuesto) -
          parseFloat(product.descuentoMonto)
        ).toFixed(2)
      );
      product.ivaMonto = parseFloat(
        (
          parseFloat(product.totalAntesImpuestoConDescuento) *
          (parseFloat(product.impuesto) / 100)
        ).toFixed(2)
      );
      product.totalConImpuestoConDescuento = parseFloat(
        (
          parseFloat(product.totalAntesImpuestoConDescuento) +
          (product.totalAntesImpuestoConDescuento *
            parseFloat(product.impuesto)) /
            100
        ).toFixed(2)
      ); // Redondear los totales hacia arriba a un decimal
      product.totalAntesImpuesto = roundIfNearWhole(product.totalAntesImpuesto);
      product.totalAntesImpuestoConDescuento = roundIfNearWhole(
        product.totalAntesImpuestoConDescuento
      );
      product.totalConImpuestoConDescuento = roundIfNearWhole(
        product.totalConImpuestoConDescuento
      );

      state.productosNuevaCompra = [...state.productosNuevaCompra];
    },
    calculateTotalInAll(state) {
      state.productosNuevaCompra.forEach(product => {
        product.totalAntesImpuesto = parseFloat(
          (
            parseFloat(product.precioCompraSinImpuesto) *
            parseFloat(product.cantidad)
          ).toFixed(2)
        );
        product.totalAntesImpuestoConDescuento = parseFloat(
          (
            parseFloat(product.totalAntesImpuesto) -
            parseFloat(product.descuentoMonto)
          ).toFixed(2)
        );
        product.ivaMonto = parseFloat(
          (
            parseFloat(product.totalAntesImpuestoConDescuento) *
            (parseFloat(product.impuesto) / 100)
          ).toFixed(2)
        );
        product.totalConImpuestoConDescuento = parseFloat(
          (
            parseFloat(product.totalAntesImpuestoConDescuento) +
            (product.totalAntesImpuestoConDescuento *
              parseFloat(product.impuesto)) /
              100
          ).toFixed(2)
        );
        // Redondear los totales hacia arriba a un decimal
        product.totalAntesImpuesto = roundIfNearWhole(
          product.totalAntesImpuesto
        );
        product.totalAntesImpuestoConDescuento = roundIfNearWhole(
          product.totalAntesImpuestoConDescuento
        );
        product.totalConImpuestoConDescuento = roundIfNearWhole(
          product.totalConImpuestoConDescuento
        );
      });

      state.productosNuevaCompra = [...state.productosNuevaCompra];
    },
    clearNuevaCompra(state) {
      state.productosNuevaCompra = [];
    },
    resetNuevaCompra(state) {
      state.productosNuevaCompra = [];
      state.idCompra = 0;
      state.pagos = {
        efectivo: 0,
        credito: 0,
        debito: 0,
        izCredito: 0,
        izDebito: 0,
        transferencia: 0,
        puntosSaldo: 0,
        depEfectivo: 0,
        cambio: 0
      };
    },
    refreshMutation(state) {
      state.productosNuevaCompra = [...state.productosNuevaCompra];
    }
  },
  actions: {
    addProduct({ state, rootState }, { product }) {
      let existingProductStock = rootState.productos.productos.find(
        p => p.id === product.id
      );

      existingProductStock.cantidad = 1;
      existingProductStock.descuentoPorcentaje = 0;
      existingProductStock.descuentoMonto = 0;
      existingProductStock.ivaMonto = parseFloat(
        (
          parseFloat(existingProductStock.precioCompraSinImpuesto) *
          (parseFloat(existingProductStock.impuesto) / 100)
        ).toFixed(2)
      );

      existingProductStock.totalAntesImpuesto = parseFloat(
        (
          parseFloat(existingProductStock.precioCompraSinImpuesto) *
          parseFloat(existingProductStock.cantidad)
        ).toFixed(2)
      );
      existingProductStock.totalAntesImpuestoConDescuento = parseFloat(
        (
          parseFloat(existingProductStock.totalAntesImpuesto) -
          parseFloat(existingProductStock.descuentoMonto)
        ).toFixed(2)
      );
      existingProductStock.totalConImpuestoConDescuento = parseFloat(
        (
          parseFloat(existingProductStock.totalAntesImpuestoConDescuento) +
          parseFloat(existingProductStock.ivaMonto)
        ).toFixed(2)
      );

      // Redondear los totales hacia arriba a un decimal
      existingProductStock.totalAntesImpuesto = roundIfNearWhole(
        existingProductStock.totalAntesImpuesto
      );
      existingProductStock.totalAntesImpuestoConDescuento = roundIfNearWhole(
        existingProductStock.totalAntesImpuestoConDescuento
      );
      existingProductStock.totalConImpuestoConDescuento = roundIfNearWhole(
        existingProductStock.totalConImpuestoConDescuento
      );

      existingProductStock.idMascota = 0;

      const uniqueId = `${existingProductStock.id}-${Date.now()}`;

      state.productosNuevaCompra.push({
        ...existingProductStock,
        id: `${existingProductStock.id}-${generateRandomKey()}`,
        uniqueId: uniqueId
      });
    },
    async fetchCompraById({ commit, state }, { id }) {
      state.isLoading = true;
      const response = await getOneCompra(id);
      state.idCompra = id;
      state.productosNuevaCompra = response.items.map(producto => {
        producto.descuentoMonto = producto.descuentoAplicado;
        return producto;
      });
      state.cajero = response.cajeros.find(cajero => cajero.id != null);
      state.proveedor = response.proveedores.find(
        proveedor => proveedor.id != null
      );
      state.cliente = response.clientes.find(cliente => cliente.id != null);
      response.ingresos.forEach(ingreso => {
        state.pagos[ingreso.tipo] = parseFloat(ingreso.monto);
      });

      commit("updateDescuentoPorcentajeInAll");
      commit("calculateTotalInAll");
      state.isLoading = false;
    },
    async terminarCompra({ state }, { pagos }) {
      if (!pagos) pagos = state.pagos;
      if (state.status != "uso interno") {
        const sumaMonto = state.productosNuevaCompra.reduce(
          (acumulado, producto) =>
            parseFloat(acumulado) +
            parseFloat(producto.totalConImpuestoConDescuento),
          0
        );
        let totalPagado = 0;
        for (const key in pagos) totalPagado += state.pagos[key];
        if (totalPagado >= sumaMonto) state.status = "cerrada";
      }
      return await sendCompra({
        idCompra: state.idCompra,
        status: state.status,
        fechaHora: null,
        pagos,
        idEmpleado: state.cajero.id,
        idProveedor: state.proveedor.id,
        tipoCompra: state.tipoCompra,
        noDocumento: state.noDocumento,
        productos: state.productosNuevaCompra,
        ubicacionDestino: state.ubicacionDestino
      });
    }
  },
  modules: {}
};

function roundIfNearWhole(num) {
  if (num < 1) return num;
  const rounded = Math.round(num);
  return Math.abs(rounded - num) <= 0.01 ? rounded : num;
}

function generateRandomKey() {
  return Math.random()
    .toString(36)
    .slice(2, 11);
}
