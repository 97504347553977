<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Empleados</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="teal"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                elevation="2"
                small
                color="teal white--text "
                @click="addItem()"
                >Agregar empleado</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="empleados"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.detalles`]="{ item }">
            <v-btn icon color="info" link :to="`/DetallesEmpleado/${item.id}`">
              <v-icon small>mdi-text-box</v-icon>
            </v-btn>
            <v-btn icon @click="editItem(item)" color="info">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="ocultarItem(item)"
              :color="item.oculto == 'no' ? 'success' : 'danger'"
            >
              <v-icon small> mdi-eye </v-icon>
            </v-btn>
            <v-btn icon @click="deleteItem(item)">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="teal my-6" rounded @click="initialize">
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <modal-empleados />
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
import ModalEmpleados from "../layouts/AgregarEditar/ModalEmpleados";
export default {
  name: "Empleados",
  components: { ModalEmpleados },
  data: () => ({
    search: "",
    isLoading: false,
    tipoRequest: "",
    valid: false,
    formData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigoInterno: "",
      rfc: "",
      codigoPostal: "",
      direccion: "",
      departamento: "",
      cargo: ""
    },
    emptyData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigosInternos: [],
      rfc: "",
      codigoPostal: "",
      direccion: "",
      departamento: "",
      cargo: ""
    },
    headerProps: {
      sortByText: "Ordenar por"
    },
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Tel1", value: "tel1" },
      { text: "Tel2", value: "tel2" },
      { text: "Correo", value: "correo" },
      { text: "RFC", value: "rfc" },
      { text: "Codigo Postal", value: "codigoPostal" },
      { text: "Direccion", value: "direccion" },
      { text: "Departamento", value: "departamento" },
      { text: "Cargo", value: "cargo" },
      { text: "Acciones", value: "detalles", width: "12%" }
    ],
    empleados: []
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", { name: "empleados", search: val });
    }
  },
  methods: {
    initialize() {
      this.fetchEmpleados();
      this.search = this.$store.state.searchParams["empleados"];
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllAbsolute",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.empleados = respuesta.data.rows.reverse();
      this.isLoading = false;
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "empleados",
        item: {},
        callback: this.fetchEmpleados
      });

      this.$store.commit("openModal", { modal: "empleados", open: true });
    },
    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "empleados",
        item: { ...myItem },
        callback: this.fetchEmpleados
      });
      this.$store.commit("openModal", { modal: "empleados", open: true });
    },
    async deleteItem(id) {
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id,
        callback: this.fetchEmpleados
      });
    },
    async ocultarItem(item) {
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let new_val = item.oculto == "no" ? "si" : "no";
      let response = await axios.post(myUrl, {
        request: 1,
        tipo: "ocultar",
        token: localStorage.token,
        geo: localStorage.geo,
        id: item.id,
        valor: new_val
      });
      if (response.data.status == "Ok") {
        this.$toasted.success("Se modificó la visibilidad del empleado", {
          position: "bottom-right",
          duration: 5000
        });
        this.fetchEmpleados();
      }
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    }
  }
};
</script>
