<template>
  <v-autocomplete
    :items="empleados"
    :value="empleadoSeleccionado"
    :search-input.sync="search"
    :label="placeholder"
    item-text="nombre"
    return-object
    v-on:change="handleChange"
    :prepend-inner-icon="'mdi-card-account-details-outline'"
    :append-outer-icon="'mdi-magnify'"
  >
    <template v-slot:item="{ item }">
      <Perfil
        :item="{
          nombre: item.nombre,
          imagen: '',
          tipo: 'empleados',
          codigosInternos: codigosInternos(item.codigosInternos),
          tamano: 'sm'
        }"
      ></Perfil>
      <v-list-item-content>
        <v-list-item-title v-text="item.nombre"></v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Perfil from "../components/Perfil.vue";
export default {
  components: { Perfil },
  name: "EmpleadoSelector",
  props: {
    callback: {
      type: Function,
      required: false,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: "Seleccione un empleado"
    }
  },
  data() {
    return {
      search: "",
      empleadoSeleccionado: null
    };
  },
  mounted() {
    this.getEmpleados();
  },
  computed: {
    ...mapState({
      empleados: state => state.empleados.empleados
    })
  },
  methods: {
    ...mapActions("empleados", ["getEmpleados"]),
    setEmpleadoById(idEmpleado) {
      this.empleadoSeleccionado =
        this.empleados.find(empleado => empleado.id == idEmpleado) || null;
    },
    getEmpleado() {
      if (this.empleadoSeleccionado) return this.empleadoSeleccionado;
      return (
        this.empleados.find(empleado =>
          empleado.nombre.includes(this.search)
        ) || null
      );
    },
    handleChange(val) {
      this.empleadoSeleccionado = val;
      this.callback(val);
    },

    codigosInternos(cods) {
      if (cods?.length && cods?.length > 1) {
        try {
          const parsedCods = JSON.parse(cods);

          if (Array.isArray(parsedCods)) {
            return [...parsedCods];
          }
          return [];
        } catch (error) {
          return [];
        }
      }
      return [];
    }
  }
};
</script>
