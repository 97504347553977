<template>
  <v-card>
    <v-card-title>No hemos podido encontrar lo que buscabas</v-card-title>
  </v-card>
</template>
<script>
export default {
  name: "NotFound",
};
</script>
