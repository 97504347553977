<template>
  <v-col cols="12" sm="12">
    <v-row>
      <v-col cols="12" sm="5">
        <v-card :loading="isLoading">
          <v-card-title>
            <h1>
              {{ toCaps(mascotas[0].nombre, { nombre: true }) }}
              <v-btn icon color="info" @click="editItem(mascotas[0])">
                <v-icon small> mdi-pencil </v-icon>
              </v-btn>
            </h1>
          </v-card-title>
          <v-card-subtitle>
            <h4>
              {{ toCaps(mascotas[0].propietario, { nombre: true }) }}
              <v-btn
                icon
                color="info"
                link
                :to="`/DetallesCliente/${mascotas[0].idPropietario}`"
              >
                <v-icon small class="mr-0">mdi-account</v-icon>
              </v-btn>
            </h4>
          </v-card-subtitle>

          <v-card-text>
            <p><b>Raza: </b> {{ mascotas[0].raza }}</p>
            <p><b>Edad </b>{{ mascotas[0].edad }}</p>

            <p><b>Sexo: </b>{{ mascotas[0].sexo }}</p>
            <p><b>Esteril: </b>{{ mascotas[0].esteril }}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="7">
        <v-card>
          <v-card-title>
            <h3>Hermanos de {{ mascotas[0].nombre }}</h3>
          </v-card-title>
          <v-container fluid>
            <v-data-table
              :headers="headers"
              :items="hermanos"
              :items-per-page="7"
              sort-by="id"
              :sort-desc="true"
              :footer-props="{
                'items-per-page-text': 'Items por pagina',
                'items-per-page-options': [7, 15, -1]
              }"
              class="elevation-1"
            >
              <template v-slot:[`item.acciones`]="{ item }">
                <v-btn
                  icon
                  color="info"
                  link
                  :to="`/DetallesMascota/${item.id}`"
                >
                  <v-icon small class="mr-0">mdi-text-box</v-icon>
                </v-btn>
                <v-btn icon @click="editItem(item)">
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn icon @click="deleteItem(item)">
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <h2 class="my-6">
                  Es posible que {{ mascotas[0].nombre }} no tenga hermanos
                </h2>
                <v-btn
                  color="pink white--text mb-6"
                  rounded
                  @click="initialize"
                >
                  <v-icon color="white"> mdi-refresh </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5">
        <v-card>
          <v-card-title>
            <h3>Últimos productos</h3>
          </v-card-title>
          <v-data-table
            :items="ultimosProductos"
            :headers="productsHeaders"
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
import { proString } from "../../tools/proStrings";
export default {
  name: "DetallesMascota",
  data: () => ({
    ultimosProductos: [],
    headers: [
      {
        text: "Nombre",
        align: "start",
        value: "nombre"
      },
      { text: "Raza", value: "raza" },
      { text: "Edad", value: "edad" },
      { text: "Sexo", value: "sexo" },
      { text: "Esteril", value: "esteril" },
      { text: "Acciones", value: "acciones" }
    ],
    productsHeaders: [
      { text: "Fecha", align: "start", value: "fecha" },
      {
        text: "Id venta",
        value: "idVenta"
      },
      {
        text: "Concepto",
        value: "nombre"
      },
      {
        text: "Cantidad",
        value: "cantidad"
      },
      {
        text: "Precio",
        value: "monto"
      }
    ],
    hermanos: [],
    mascotas: [
      {
        nombre: "Cargando...",
        raza: "Cargando...",
        propietario: "Cargando...",
        sexo: "Cargando...",
        esteril: "Cargando..."
      }
    ]
  }),
  watch: {
    $route() {
      this.initialize();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.fetchMascota();
      this.fetchHermanos();
    },
    toCaps(myString, myOptions) {
      return proString(myString, myOptions);
    },
    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "mascotas",
        item: { ...myItem },
        callback: this.fetchMascotas
      });
      this.$store.commit("openModal", { modal: "mascotas", open: true });
    },
    async fetchMascota() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "mascotas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getOne",
        id: this.$route.params.id,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.mascotas = respuesta.data.rows;
        this.fetchProductos();
      }
      this.isLoading = false;
    },
    async fetchProductos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getProductosMascota",
        token: localStorage.token,
        geo: localStorage.geo,
        mascota: this.mascotas[0]
      });
      if (respuesta.data.status == "Ok") {
        this.ultimosProductos = respuesta.data.rows;
        this.isLoading = false;
      }
    },
    async fetchHermanos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "mascotas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      this.hermanos = respuesta.data.rows;
      this.hermanos = this.hermanos.filter(
        item =>
          item.idPropietario == this.mascotas[0].idPropietario &&
          item.id != this.mascotas[0].id
      );
      this.isLoading = false;
    }
  }
};
</script>
