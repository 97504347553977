<style scoped>
.cita-bg {
  background-color: #eee;
}
.long-col {
  min-width: 150px;
}
.short-col {
  max-width: 100px;
  width: min-content;
}
td {
  font-size: 13.5px !important;
  text-align: left;
  vertical-align: top;
  border-left: solid 1px #eee;
}
</style>
<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="4">
              <h1>Citas médicas</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="black"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Fecha"
                v-model="fecha"
                type="date"
                rows="2"
                @input="fetchServiciosClinica"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="text-right">
              <div class="text-left">
                <!--  <v-chip
                  style="background-color: #333; color: #fff; margin-right: 5px;"
                  >Mascotas Registradas: {{ registros.length }}</v-chip
                >
                <v-chip
                  style="background-color: #333; color: #fff; margin-right: 5px;"
                  >Baños: {{ contadorBanos }}</v-chip
                > -->
              </div>

              <v-btn
                elevation="2"
                small
                color="black white--text "
                @click="addItem()"
                >Agregar nueva cita</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-simple-table :items="registros" fixed-header dense height="70vh">
          <template v-slot:default>
            <thead></thead>
            <tbody class="custom-table">
              <!-- Por confirmar -->
              <tr class="amber white--text">
                <th class="text-center" colspan="19">Por confirmar</th>
              </tr>
              <tr class="amber white--text">
                <th class="text-left">Id</th>
                <th class="text-left">Registro</th>
                <th class="text-left">Fecha Cita</th>
                <th class="text-left">Hora Cita</th>
                <th class="text-left">Citado por</th>
                <th class="text-left">Propietario</th>
                <th class="text-left">Teléfono</th>
                <th class="text-left">Mascota</th>
                <th class="text-left">Raza</th>
                <th class="text-left">Sexo</th>
                <th class="text-left">Peso</th>
                <th class="text-left">Servicio</th>
                <th class="text-left">Tipo</th>
                <th class="text-left" colspan="2">Motivo</th>
                <th class="text-left">MVZ</th>
                <th class="text-left">SAD</th>
                <th class="text-left">Status</th>
                <th class="text-left"></th>
              </tr>
              <tr
                v-for="(item, ind) in registros.filter(
                  registro => registro.citaConfirmada == '0'
                )"
                :key="`confir${ind}`"
                :class="item.status == 'Cita' ? 'cita-bg' : null"
              >
                <td>
                  {{ item.idDiario }}
                </td>
                <td>
                  {{ item.horaRegistro }}
                </td>
                <td :class="item.status == 'Cita' ? 'infoBlink' : null">
                  {{ item.fechaCita }}
                </td>
                <td
                  class="short-col"
                  :class="item.status == 'Cita' ? 'infoBlink' : null"
                >
                  {{
                    timeConvert({
                      time: item.horaCita.slice(-8),
                      seconds: false
                    })
                  }}
                </td>
                <td class="long-col">
                  {{ item.nombreResponsableCita }}
                </td>
                <td>
                  <NombreCliente
                    :cliente="{
                      nombre: item.nombreCliente,
                      codigosInternos: item.codigosInternosCliente
                    }"
                  />
                </td>
                <td>{{ item.clienteTel }}</td>
                <td>
                  <NombreMascota
                    :mascota="{
                      nombre: item.nombre,
                      codigosInternos: item.codigosInternosMascota
                    }"
                  />
                </td>
                <td class="short-col">{{ item.raza }}</td>
                <td class="short-col">{{ item.sexo }}</td>
                <td class="short-col">{{ item.peso }}</td>

                <td>
                  {{ item.servicio }}
                </td>
                <td>
                  {{ item.tipoServicio }}
                </td>
                <td class="long-col" colspan="2">
                  {{ item.motivoServicio }}
                </td>
                <td>
                  <v-select
                    v-model="item.idEmpleado"
                    :items="[{ id: '0', nombre: 'Sin asignar' }, ...empleados]"
                    item-value="id"
                    item-text="nombre"
                    dense
                    @change="actualizarStatus(item)"
                  />
                </td>

                <td class="long-col">
                  <span
                    v-for="servicio in item.conceptos.filter(
                      concepto => concepto.idSubcategoria == '16'
                    )"
                    :key="`${item.idDiario}s${servicio.id}`"
                  >
                    {{ servicio.nombre }} <br />
                  </span>
                </td>
                <td>
                  <!-- 
                  <v-select
                    v-model="item.status"
                    :items="statusArray"
                    dense
                    @change="actualizarStatus(item)"
                  /> -->
                  <v-btn
                    icon
                    color="success"
                    @click="
                      () => {
                        item.citaConfirmada = '1';
                        actualizarStatus(item);
                      }
                    "
                  >
                    <v-icon small> mdi-check </v-icon>
                  </v-btn>
                </td>
                <td style="width: 60px">
                  <v-btn icon color="blue darken-1" @click="editItem(item)">
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                  <!-- <v-btn icon color="danger" @click="deleteItem(item)">
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn> -->
                </td>
                <!-- <td>none</td> -->
              </tr>

              <!-- SAD -->

              <tr class="indigo white--text">
                <th class="text-center" colspan="19">SAD</th>
              </tr>
              <tr class="indigo white--text">
                <th class="text-left">Id</th>
                <th class="text-left">Registro</th>
                <th class="text-left">Fecha Cita</th>
                <th class="text-left">Hora Cita</th>
                <th class="text-left">Citado por</th>
                <th class="text-left">Propietario</th>
                <th class="text-left">Teléfono</th>
                <th class="text-left">Mascota</th>
                <th class="text-left">Raza</th>
                <th class="text-left">Sexo</th>
                <th class="text-left">Peso</th>
                <th class="text-left">Servicio</th>
                <th class="text-left">Tipo</th>
                <th class="text-left">Motivo</th>
                <th class="text-left">Direccion</th>
                <th class="text-left">MVZ</th>
                <th class="text-left">SAD</th>
                <th class="text-left">Status</th>
                <th class="text-left"></th>
              </tr>
              <tr
                v-for="(item, ind) in registros.filter(
                  registro => registro.isSAD && registro.status == 'Cita'
                )"
                :key="`sad${ind}`"
                :class="item.status == 'Cita' ? 'cita-bg' : null"
              >
                <td>
                  {{ item.idDiario }}
                </td>
                <td>
                  {{ item.horaRegistro }}
                </td>
                <td :class="item.status == 'Cita' ? 'infoBlink' : null">
                  {{ item.fechaCita }}
                </td>
                <td
                  class="short-col"
                  :class="item.status == 'Cita' ? 'infoBlink' : null"
                >
                  {{
                    timeConvert({
                      time: item.horaCita.slice(-8),
                      seconds: false
                    })
                  }}
                </td>
                <td class="long-col">
                  {{ item.nombreResponsableCita }}
                </td>
                <td>
                  <NombreCliente
                    :cliente="{
                      nombre: item.nombreCliente,
                      codigosInternos: item.codigosInternosCliente
                    }"
                  />
                </td>
                <td>{{ item.clienteTel }}</td>
                <td>
                  <NombreMascota
                    :mascota="{
                      nombre: item.nombre,
                      codigosInternos: item.codigosInternosMascota
                    }"
                  />
                </td>
                <td class="short-col">{{ item.raza }}</td>
                <td class="short-col">{{ item.sexo }}</td>
                <td class="short-col">{{ item.peso }}</td>

                <td>
                  {{ item.servicio }}
                </td>
                <td>
                  {{ item.tipoServicio }}
                </td>
                <td class="long-col">
                  {{ item.motivoServicio }}
                </td>
                <td>
                  {{ item.direccion }}
                </td>
                <td>
                  <v-select
                    v-model="item.idEmpleado"
                    :items="[{ id: '0', nombre: 'Sin asignar' }, ...empleados]"
                    item-value="id"
                    item-text="nombre"
                    dense
                    @change="actualizarStatus(item)"
                  />
                </td>

                <td class="long-col">
                  <span
                    v-for="servicio in item.conceptos.filter(
                      concepto => concepto.idSubcategoria == '16'
                    )"
                    :key="`${item.idDiario}con${servicio.id}`"
                  >
                    {{ servicio.nombre }} <br />
                  </span>
                </td>
                <td>
                  <v-select
                    v-model="item.status"
                    :items="statusArray"
                    dense
                    @change="actualizarStatus(item)"
                  />
                </td>
                <td style="width: 60px">
                  <v-btn icon color="blue darken-1" @click="editItem(item)">
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                </td>
              </tr>

              <!-- Confirmadas -->
              <tr class="teal white--text">
                <th class="text-center" colspan="19">Confirmadas</th>
              </tr>
              <tr class="teal white--text">
                <th class="text-left">Id</th>
                <th class="text-left">Registro</th>
                <th class="text-left">Fecha Cita</th>
                <th class="text-left">Hora Cita</th>
                <th class="text-left">Citado por</th>
                <th class="text-left">Propietario</th>
                <th class="text-left">Teléfono</th>
                <th class="text-left">Mascota</th>
                <th class="text-left">Raza</th>
                <th class="text-left">Sexo</th>
                <th class="text-left">Peso</th>
                <th class="text-left">Servicio</th>
                <th class="text-left">Tipo</th>
                <th class="text-left" colspan="2">Motivo</th>
                <th class="text-left">MVZ</th>
                <th class="text-left">SAD</th>
                <th class="text-left">Status</th>
                <th class="text-left"></th>
              </tr>
              <tr
                v-for="(item, ind) in registros.filter(
                  registro => registro.citaConfirmada != '0'
                )"
                :key="`reg${ind}`"
                :class="item.status == 'Cita' ? 'cita-bg' : null"
              >
                <td>
                  {{ item.idDiario }}
                </td>
                <td>
                  {{ item.horaRegistro }}
                </td>
                <td :class="item.status == 'Cita' ? 'infoBlink' : null">
                  {{ item.fechaCita }}
                </td>
                <td
                  class="short-col"
                  :class="item.status == 'Cita' ? 'infoBlink' : null"
                >
                  {{
                    timeConvert({
                      time: item.horaCita.slice(-8),
                      seconds: false
                    })
                  }}
                </td>
                <td class="long-col">
                  {{ item.nombreResponsableCita }}
                </td>
                <td>
                  <NombreCliente
                    :cliente="{
                      nombre: item.nombreCliente,
                      codigosInternos: item.codigosInternosCliente
                    }"
                  />
                </td>
                <td>{{ item.clienteTel }}</td>
                <td>
                  <NombreMascota
                    :mascota="{
                      nombre: item.nombre,
                      codigosInternos: item.codigosInternosMascota
                    }"
                  />
                </td>
                <td class="short-col">{{ item.raza }}</td>
                <td class="short-col">{{ item.sexo }}</td>
                <td class="short-col">{{ item.peso }}</td>

                <td>
                  {{ item.servicio }}
                </td>
                <td>
                  {{ item.tipoServicio }}
                </td>
                <td class="long-col" colspan="2">
                  {{ item.motivoServicio }}
                </td>
                <td>
                  <v-select
                    v-model="item.idEmpleado"
                    :items="[{ id: '0', nombre: 'Sin asignar' }, ...empleados]"
                    item-value="id"
                    item-text="nombre"
                    dense
                    @change="actualizarStatus(item)"
                  />
                </td>

                <td class="long-col">
                  <span
                    v-for="servicio in item.conceptos.filter(
                      concepto => concepto.idSubcategoria == '16'
                    )"
                    :key="`concep${servicio.id}`"
                  >
                    {{ servicio.nombre }} <br />
                  </span>
                </td>
                <td>
                  <v-select
                    v-model="item.status"
                    :items="statusArray"
                    dense
                    @change="actualizarStatus(item)"
                  >
                    <template slot="prepend">
                      <v-icon
                        small
                        color="error"
                        @click="
                          () => {
                            item.citaConfirmada = '0';
                            actualizarStatus(item);
                          }
                        "
                        >mdi-close</v-icon
                      >
                    </template>
                  </v-select>
                </td>
                <td style="width: 60px">
                  <v-btn icon color="blue darken-1" @click="editItem(item)">
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                  <!-- <v-btn icon color="danger" @click="deleteItem(item)">
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn> -->
                </td>
                <!-- <td>none</td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
    <modal-citas-medicas />
  </v-row>
</template>

<script>
import axios from "axios";
import ModalCitasMedicas from "../layouts/AgregarEditar/ModalCitasMedicas";
import { matchStrings, ampmConvert } from "../tools/proStrings";
import moment from "moment";
import NombreCliente from "./Clientes/NombreCliente.vue";
import NombreMascota from "./Mascotas/NombreMascota.vue";
export default {
  name: "RegistrosCitasClínica",
  components: { ModalCitasMedicas, NombreCliente, NombreMascota },
  data: () => ({
    search: "",
    isLoading: false,
    tipoRequest: "",
    valid: false,
    fecha: "",
    registrosIniciales: [
      { conceptos: [], idDiario: 1, horaCita: "08:00" },
      { conceptos: [], idDiario: 2, horaCita: "08:00" },
      { conceptos: [], idDiario: 3, horaCita: "08:00" },
      { conceptos: [], idDiario: 4, horaCita: "08:00" },
      { conceptos: [], idDiario: 5, horaCita: "09:00" },
      { conceptos: [], idDiario: 6, horaCita: "09:00" },
      { conceptos: [], idDiario: 7, horaCita: "09:00" },
      { conceptos: [], idDiario: 8, horaCita: "09:00" },
      { conceptos: [], idDiario: 9, horaCita: "10:00" },
      { conceptos: [], idDiario: 10, horaCita: "10:00" },
      { conceptos: [], idDiario: 11, horaCita: "10:00" },
      { conceptos: [], idDiario: 12, horaCita: "10:00" },
      { conceptos: [], idDiario: 13, horaCita: "11:00" },
      { conceptos: [], idDiario: 14, horaCita: "11:00" },
      { conceptos: [], idDiario: 15, horaCita: "11:00" },
      { conceptos: [], idDiario: 16, horaCita: "11:00" },
      { conceptos: [], idDiario: 17, horaCita: "12:00" },
      { conceptos: [], idDiario: 18, horaCita: "12:00" },
      { conceptos: [], idDiario: 19, horaCita: "12:00" },
      { conceptos: [], idDiario: 20, horaCita: "12:00" },
      { conceptos: [], idDiario: 21, horaCita: "13:00" },
      { conceptos: [], idDiario: 22, horaCita: "13:00" },
      { conceptos: [], idDiario: 23, horaCita: "13:00" },
      { conceptos: [], idDiario: 24, horaCita: "13:00" },
      { conceptos: [], idDiario: 25, horaCita: "14:00" },
      { conceptos: [], idDiario: 26, horaCita: "14:00" },
      { conceptos: [], idDiario: 27, horaCita: "14:00" },
      { conceptos: [], idDiario: 28, horaCita: "14:00" },
      { conceptos: [], idDiario: 29, horaCita: "15:00" },
      { conceptos: [], idDiario: 30, horaCita: "15:00" },
      { conceptos: [], idDiario: 31, horaCita: "15:00" },
      { conceptos: [], idDiario: 32, horaCita: "15:00" },
      { conceptos: [], idDiario: 33, horaCita: "16:00" },
      { conceptos: [], idDiario: 34, horaCita: "16:00" },
      { conceptos: [], idDiario: 35, horaCita: "16:00" },
      { conceptos: [], idDiario: 36, horaCita: "16:00" },
      { conceptos: [], idDiario: 37, horaCita: "17:00" },
      { conceptos: [], idDiario: 38, horaCita: "17:00" },
      { conceptos: [], idDiario: 39, horaCita: "17:00" },
      { conceptos: [], idDiario: 40, horaCita: "17:00" },
      { conceptos: [], idDiario: 41, horaCita: "18:00" },
      { conceptos: [], idDiario: 42, horaCita: "18:00" },
      { conceptos: [], idDiario: 43, horaCita: "18:00" },
      { conceptos: [], idDiario: 44, horaCita: "18:00" },
      { conceptos: [], idDiario: 45, horaCita: "19:00" },
      { conceptos: [], idDiario: 46, horaCita: "19:00" },
      { conceptos: [], idDiario: 47, horaCita: "19:00" },
      { conceptos: [], idDiario: 48, horaCita: "19:00" },
      { conceptos: [], idDiario: 49, horaCita: "20:00" },
      { conceptos: [], idDiario: 50, horaCita: "20:00" },
      { conceptos: [], idDiario: 51, horaCita: "20:00" },
      { conceptos: [], idDiario: 52, horaCita: "20:00" },
      { conceptos: [], idDiario: 53, horaCita: "21:00" },
      { conceptos: [], idDiario: 54, horaCita: "21:00" },
      { conceptos: [], idDiario: 55, horaCita: "21:00" },
      { conceptos: [], idDiario: 56, horaCita: "21:00" }
    ],

    registros: [],
    contBanos: [],
    contCortes: [],
    empleados: [],
    statusArray: ["Cancelado", "Cita", "Registro"],
    servicios: ["Baño", "Baño y cepillado", "Limpieza dental"]
  }),
  created() {
    this.initialize();
  },

  computed: {
    /* 
    registrosSorteados() {
      let registrosNoFinalizados = [...this.registros].filter(
        item => item.status != "Finalizado"
      );
      let registrosFinalizados = [...this.registros].filter(
        item => item.status == "Finalizado"
      );

      let registrosSorteados = registrosNoFinalizados.concat(
        registrosFinalizados
      );
      return registrosSorteados;
    } */
    /*  contadorBanos() {
      let count = 0;
      this.registros.map(registro => {
        let itHas = registro.conceptos?.filter(concepto =>
          matchStrings({ s: "baño", str: concepto.nombre })
        );
        itHas?.length ? count++ : null;
      });
      return count;
    },
    contadorCortes() {
      let count = 0;
      this.registros.map(registro => {
        let itHas = registro.conceptos?.filter(concepto =>
          matchStrings({ s: "pelo", str: concepto.nombre })
        );
        itHas.length ? count++ : null;
      });
      return count;
    } */
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "servicioCita",
        search: val
      });
    }
  },
  methods: {
    initialize() {
      this.search = this.$store.state.searchParams["servicioCita"];
      this.fecha = this.fechaHoy();
      this.fetchServiciosClinica();
      this.fetchEmpleados();
    },
    timeConvert(time) {
      return ampmConvert(time);
    },
    fechaHoy() {
      return moment().format("yyyy-MM-DD");
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.empleados = respuesta.data.rows?.filter(
          empleado =>
            empleado.departamento == "clinica" ||
            empleado.departamento == "Clínica" ||
            empleado.departamento == "Clinica"
        );
      }
      this.isLoading = false;
    },
    async fetchServiciosClinica() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "serviciosClinica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllCitas",
        fecha: this.fecha,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        let responseHolder = respuesta.data.rows;
        this.registrosIniciales.sort((a, b) => b.idDiario - a.idDiario);
        this.registros = this.registrosIniciales.map(itemInicial => {
          responseHolder.forEach(itemApi => {
            const partes = itemApi.horaCita.split(" ");
            const horaItemApi = partes.length > 1 ? partes[1] : [0, 0];
            if (
              `${itemInicial.horaCita[0]}${itemInicial.horaCita[1]}` ==
                `${horaItemApi[0]}${horaItemApi[1]}` &&
              !itemApi.seteado &&
              !itemInicial.nombreCliente
            ) {
              itemInicial = { ...itemInicial, ...itemApi };
              itemInicial.conceptos.filter(
                concepto => concepto.idSubcategoria == "16"
              ).length > 0
                ? (itemInicial.isSAD = true)
                : (itemInicial.isSAD = false);
              itemApi.seteado = true;
            }
            return itemApi;
          });
          return itemInicial;
        });
        this.registrosIniciales.sort((a, b) => a.idDiario - b.idDiario);
        this.registros.sort((a, b) => a.idDiario - b.idDiario);
        this.isLoading = false;
      }
    },

    async actualizarStatus(item) {
      let myUrl = process.env.VUE_APP_API_URL + "serviciosClinica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "cita a registro",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta) {
        this.$toasted.success("Status actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      } else {
        this.$toasted.error("Ocurrió un problema", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    async actualizarMvz(item) {
      let myUrl = process.env.VUE_APP_API_URL + "serviciosClinica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update minor",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Médico actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      } else {
        this.$toasted.error("Ocurrió un problema", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "servicioCita",
        item: {},
        callback: this.fetchServiciosClinica
      });

      this.$store.commit("openModal", {
        modal: "servicioCita",
        open: true
      });
    },
    async editItem(myItem) {
      myItem.idsServicios = myItem.conceptos
        .map(itm => {
          if (itm.idSubcategoria == "5" || itm.idSubcategoria == "6")
            return itm.idProductoServicio;
        })
        ?.filter(item => item);
      myItem.idsAdicionales = myItem.conceptos
        .map(itm => {
          if (itm.idSubcategoria == "7") return itm.idProductoServicio;
        })
        ?.filter(item => item);
      myItem.idsSAD = myItem.conceptos
        .map(itm => {
          if (itm.idSubcategoria == "16") return itm.idProductoServicio;
        })
        ?.filter(item => item);

      this.$store.commit("setItemModal", {
        modal: "servicioCita",
        item: { ...myItem },
        callback: this.fetchServiciosClinica
      });
      this.$store.commit("openModal", {
        modal: "servicioCita",
        item: { ...myItem },
        open: true
      });
    },
    async deleteItem(id) {
      let myUrl = process.env.VUE_APP_API_URL + "servicioClinica.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id,
        callback: this.fetchServiciosClinica
      });
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },

    codigosInternos(cods) {
      if (cods?.length && cods?.length > 1) {
        try {
          const parsedCods = JSON.parse(cods);

          if (Array.isArray(parsedCods)) {
            return [...parsedCods];
          }
          return [];
        } catch (error) {
          return [];
        }
      }
      return [];
    }
  }
};
</script>
