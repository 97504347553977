<style>
.container-badge-container {
  position: relative;
  margin-right: 10px;
}
.badge-container {
  height: 15px;
  width: auto;
  overflow-x: visible;
  position: absolute;
  top: -3.75px;
  left: -3.75px;
}
.badge {
  border-radius: 50%;
  border: solid #9e9e9e 1px;
  height: 10px;
  width: 10px;
  display: inline-block;
  margin-right: 1px;
  color: white;
  font-size: 7px;
  padding-left: 1px;
  padding-bottom: 2px;
}
.v-avatar {
  font-size: 13px;
}
</style>
<template v-slot:default="{ item }">
  <div v-if="item.onlyDots != 'true'">
    <div v-if="item.imagen != ''" class="container-badge-container">
      <v-avatar :size="`${cambiarTamano(item.tamano)}`" class="white--text">
        <img :src="item.imagen" />
      </v-avatar>
      <div class="badge-container">
        <div
          v-for="(codInt, codI) in item.codigosInternos"
          :key="codI"
          :class="
            `badge ${internalColor({
              tipo: item.tipo,
              codigoInterno: codInt
            })}`
          "
        ></div>
      </div>
    </div>
    <div v-else class="container-badge-container">
      <v-avatar
        color="#e3e3e3"
        :size="`${cambiarTamano(item.tamano)}`"
        class="black--text"
      >
        {{ item.nombre.toUpperCase()[0] }}{{ item.nombre.toUpperCase()[1]
        }}{{ item.nombre.toUpperCase()[2] }}
      </v-avatar>
      <div class="badge-container">
        <div
          v-for="(codInt, codI) in item.codigosInternos"
          :key="codI"
          :class="
            `badge ${internalColor({
              tipo: item.tipo,
              codigoInterno: codInt
            })}`
          "
        ></div>
      </div>
    </div>
  </div>
  <div v-else style="padding-top: 0px">
    <div v-if="item.imagen != ''" class="container-badge-container">
      <div class="badge-container">
        <div
          v-for="(codInt, codI) in item.codigosInternos"
          :key="codI"
          :class="
            `badge ${internalColor({
              tipo: item.tipo,
              codigoInterno: codInt
            })}`
          "
        ></div>
      </div>
    </div>
    <div v-else class="container-badge-container">
      <div class="badge-container">
        <div
          v-for="(codInt, codI) in item.codigosInternos"
          :key="codI"
          :class="
            `badge ${internalColor({
              tipo: item.tipo,
              codigoInterno: codInt
            })}`
          "
        >
          {{ codInt }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getRandomColor from "../tools/randomColor";
import { internalCodeColor } from "../tools/randomColor";
export default {
  name: "Perfil",
  data: () => ({}),
  props: ["item"],
  methods: {
    randomColor(props) {
      return getRandomColor(props);
    },
    internalColor(props) {
      return internalCodeColor(props);
    },
    cambiarTamano(size) {
      let tamanos = {
        sm: "36",
        md: "42",
        lg: "48"
      };
      if (size == "" || !(size == "sm" || size == "md" || size == "lg")) {
        return "36";
      }
      return tamanos[size];
    }
  }
};
</script>
