let conceptos = [
  {
    codigo: "00000000",
    nombre: "Selecciona un concepto"
  },
  {
    codigo: "10111300",
    nombre: "Accesorios, equipo y tratamientos para los animales domésticos"
  },
  {
    codigo: "10111301",
    nombre: "Juguetes para mascotas"
  },
  {
    codigo: "10111302",
    nombre: "Productos para el aseo y cuidado de mascotas"
  },
  {
    codigo: "10111303",
    nombre: "Equipo para el manejo de desperdicios de las mascotas"
  },
  {
    codigo: "10111304",
    nombre: "Tazones o equipo para alimentación de mascotas"
  },
  {
    codigo: "10111305",
    nombre: "Tratamientos medicados para mascotas"
  },
  {
    codigo: "10111306",
    nombre: "Kits para el entrenamiento de mascotas domésticas"
  },
  {
    codigo: "10111307",
    nombre: "Cobijas para mascotas"
  },
  {
    codigo: "10131600",
    nombre: "Recipientes para animales"
  },
  {
    codigo: "10131500",
    nombre: "Cobertizos para animales"
  },
  {
    codigo: "10131507",
    nombre: "Casas para mascotas domesticadas"
  },
  {
    codigo: "10131508",
    nombre: "Camas para mascotas"
  },
  {
    codigo: "10121800",
    nombre: "Alimento para perros y gatos"
  },
  {
    codigo: "10121801",
    nombre: "Comida seca para perros"
  },
  {
    codigo: "10121802",
    nombre: "Comida húmeda para perros"
  },
  {
    codigo: "10121803",
    nombre: "Leche para perros o gatos"
  },
  {
    codigo: "10121806",
    nombre: "Botanas o comida recreacional para gatos o perros"
  },
  {
    codigo: "10121804",
    nombre: "Comida seca para gatos, Croquetas para gatos"
  },
  {
    codigo: "10121805",
    nombre: "Comida húmeda para gatos"
  },
  {
    codigo: "10131604",
    nombre: "Correas para perros"
  },
  {
    codigo: "42121600",
    nombre: "Productos veterinarios"
  },
  {
    codigo: "42121601",
    nombre:
      "Medicamentos contra problemas gastrointestinales de uso veterinario"
  },
  {
    codigo: "42121602",
    nombre:
      "Medicamentos para sangre o formación de sangre para uso veterinario"
  },
  {
    codigo: "42121603",
    nombre: "Medicamentos para el sistema respiratorio para uso veterinario"
  },
  {
    codigo: "42121604",
    nombre:
      "Medicamentos para el sistema musculo esquelético o nervioso para uso veterinario"
  },
  {
    codigo: "42121605",
    nombre: "Medicamentos para el sistema cardiovascular para uso veterinario"
  },
  {
    codigo: "42121606",
    nombre:
      "Medicamentos dermatológicos o anti protozoarios para uso veterinario"
  },
  {
    codigo: "42121607",
    nombre:
      "Medicamentos para el sistema sexual genital urinario u hormonales para uso veterinario"
  },
  {
    codigo: "42121608",
    nombre: "Medicamentos dentales para uso veterinario"
  },
  {
    codigo: "70122000",
    nombre: "Salud animal"
  },
  {
    codigo: "70122006",
    nombre: "Servicios de vacunación animal"
  },
  {
    codigo: "70121802",
    nombre: "Servicio de cuidados de animales domesticos"
  },
  {
    codigo: "85121808",
    nombre: "Servicios de laboratorio de rayos x"
  },
  {
    codigo: "76121604",
    nombre: "Servicio de disposición de animales muertos"
  },
  {
    codigo: "85121807",
    nombre: "Servicio de laboratorio de ultrasonido"
  },
  {
    codigo: "85101605",
    nombre: "Auxiliares de salud a domicilio"
  },
  {
    codigo: "70122009",
    nombre: "Servicios hospitalarios para animales"
  }
];
export default conceptos;
