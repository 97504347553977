import axios from "axios";

export const getAllEmpleados = async () => {
  let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getAll",
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") return respuesta.data.rows;
  return [];
};
