var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalProveedores',{ref:"modalProveedores"}),_c('v-autocomplete',{attrs:{"hide-details":"","prepend-icon":"mdi-badge-account-horizontal","append-icon":"mdi-magnify","append-outer-icon":"mdi-account-plus","color":"teal","rules":_vm.rules,"label":"Proveedor","search-input":_vm.search,"items":_vm.proveedoresFiltrados,"value":_vm.proveedor,"return-object":"","item-text":"nombre"},on:{"click:append-outer":function($event){return _vm.$refs.modalProveedores.toggleModal()},"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.handleChange},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Intenta buscar algún proveedor ")])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.testIfObject(item))?[_c('v-list-item',[_c('v-list-item-content',{domProps:{"textContent":_vm._s(item)}})],1)]:_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.selectProveedor(item)}}},[_c('perfil',{attrs:{"item":{
            nombre: item.nombre,
            imagen: '',
            tipo: 'mascotas',
            codigosInternos: _vm.codigosInternos(item.codigosInternos),
            tamano: 'sm'
          }}}),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.tipo)}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }