<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Marcas</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="red darken-3"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                elevation="2"
                small
                color="red darken-3 white--text "
                @click="$refs.modalMarcas.toggleModal()"
                >Agregar marca</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="marcas"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon color="blue darken-1" @click="editItem(item)">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon @click="deleteItem(item.id)" color="danger">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="red white--text darken-3 my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <ModalMarcas ref="modalMarcas" :fetchMarcas="fetchMarcas" />
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
import ModalMarcas from "../layouts/AgregarEditar/ModalMarcas";
export default {
  name: "Marcas",
  components: {
    ModalMarcas
  },
  data: () => ({
    search: "",
    isLoading: false,
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Acciones", value: "acciones", align: "right" }
    ],
    marcas: []
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "marcas",
        search: val
      });
    }
  },
  methods: {
    initialize() {
      this.fetchMarcas();
      this.search = this.$store.state.searchParams["marcas"];
    },
    async fetchMarcas() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "marcas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.marcas = respuesta.data.rows;
      this.isLoading = false;
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },
    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "marcas",
        item: { ...myItem },
        callback: async () => {
          this.fetchMarcas;
        }
      });
      this.$store.commit("openModal", { modal: "marcas", open: true });
    },

    async deleteItem(id) {
      if (!confirm("¿Deseas eliminar esta marca?")) {
        return;
      }
      let myUrl = process.env.VUE_APP_API_URL + "marcas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Eliminado", {
          position: "bottom-right",
          duration: 5000
        });
        this.$store.commit("clientes/refreshCache");
        this.fetchMarcas();
      }
    }
  }
};
</script>
