import axios from "axios";

export const getAllMascotas = async () => {
  let myUrl = process.env.VUE_APP_API_URL + "mascotas.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getAll",
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") return respuesta.data.rows;
  return [];
};

export const getMascotasIniciales = async () => {
  let myUrl = process.env.VUE_APP_API_URL + "mascotas.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getInitials",
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") return respuesta.data.rows;
  return [];
};

export const getMascotasBySearch = async search => {
  let myUrl = process.env.VUE_APP_API_URL + "mascotas.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getBySearch",
    search,
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") return respuesta.data.rows;
  return [];
};
export const getMascotasByOwnerId = async ownerId => {
  let myUrl = process.env.VUE_APP_API_URL + "mascotas.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getByOwnerId",
    ownerId,
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") return respuesta.data.rows;
  return [];
};
