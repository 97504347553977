var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cerrar($event)},"click:outside":_vm.cerrar},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-form',{ref:"entryForm",attrs:{"color":"teal"},on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Agregar Producto o Servicio")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre","rules":_vm.nameRules,"required":""},model:{value:(_vm.producto.nombre),callback:function ($$v) {_vm.$set(_vm.producto, "nombre", $$v)},expression:"producto.nombre"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Compuesto"},model:{value:(_vm.producto.compuesto),callback:function ($$v) {_vm.$set(_vm.producto, "compuesto", $$v)},expression:"producto.compuesto"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Categoría","items":_vm.categorias,"item-value":"id","item-text":"nombre","rules":_vm.nameRules,"required":""},model:{value:(_vm.producto.idCategoria),callback:function ($$v) {_vm.$set(_vm.producto, "idCategoria", $$v)},expression:"producto.idCategoria"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Subcategoría","items":_vm.subcategoriasFiltradas,"item-value":"id","item-text":"nombre","rules":_vm.nameRules,"required":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.nombre)}})],1),(
                        item.id == '6' || item.id == '7' || item.id == '8'
                      )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":"","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v("Los productos o servicios de ésta subcategoría aparecen disponibles en la sección de estética")])]):_vm._e()]}}]),model:{value:(_vm.producto.idSubcategoria),callback:function ($$v) {_vm.$set(_vm.producto, "idSubcategoria", $$v)},expression:"producto.idSubcategoria"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Marca","items":_vm.marcas,"item-value":"id","item-text":"nombre","rules":_vm.nameRules,"required":""},model:{value:(_vm.producto.idMarca),callback:function ($$v) {_vm.$set(_vm.producto, "idMarca", $$v)},expression:"producto.idMarca"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":"Código","required":""},model:{value:(_vm.producto.codigo),callback:function ($$v) {_vm.$set(_vm.producto, "codigo", $$v)},expression:"producto.codigo"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Precio","type":"number"},model:{value:(_vm.producto.precio),callback:function ($$v) {_vm.$set(_vm.producto, "precio", $$v)},expression:"producto.precio"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Precio de compra","type":"number"},model:{value:(_vm.producto.precioCompra),callback:function ($$v) {_vm.$set(_vm.producto, "precioCompra", $$v)},expression:"producto.precioCompra"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":"Impuesto","type":"number","items":[0, 6, 7, 16],"suffix":"%"},model:{value:(_vm.producto.impuesto),callback:function ($$v) {_vm.$set(_vm.producto, "impuesto", $$v)},expression:"producto.impuesto"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":"Unidad","items":[
                    'bolsa',
                    'caja',
                    'g',
                    'granel',
                    'kg',
                    'l',
                    'mililitro',
                    'pza',
                    'servicio'
                  ]},model:{value:(_vm.producto.unidad),callback:function ($$v) {_vm.$set(_vm.producto, "unidad", $$v)},expression:"producto.unidad"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Concepto SAT","item-text":"nombre","items":_vm.conceptosSAT,"return-object":""},model:{value:(_vm.codigoYconcepto),callback:function ($$v) {_vm.codigoYconcepto=$$v},expression:"codigoYconcepto"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Código SAT","readonly":""},model:{value:(_vm.producto.codigoSAT),callback:function ($$v) {_vm.$set(_vm.producto, "codigoSAT", $$v)},expression:"producto.codigoSAT"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Mínimo","type":"number"},model:{value:(_vm.producto.minimo),callback:function ($$v) {_vm.$set(_vm.producto, "minimo", $$v)},expression:"producto.minimo"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Máximo","type":"number"},model:{value:(_vm.producto.maximo),callback:function ($$v) {_vm.$set(_vm.producto, "maximo", $$v)},expression:"producto.maximo"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Control stock","items":['Si', 'No']},model:{value:(_vm.producto.controlStock),callback:function ($$v) {_vm.$set(_vm.producto, "controlStock", $$v)},expression:"producto.controlStock"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cerrar}},[_vm._v(" Cerrar ")]),(_vm.producto.id == undefined)?_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.submitHandler}},[_vm._v(" Agregar ")]):_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.submitHandler}},[_vm._v(" Actualizar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }