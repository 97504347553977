<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <h1>Abonos</h1>
              <br />
              <v-row>
                <v-col cols="12" sm="12" md="5" lg="5">
                  <label>Desde: </label>
                  <input type="datetime-local" v-model="desde" />
                </v-col>
                <v-col cols="12" sm="12" md="5" lg="5">
                  <label>Hasta: </label>
                  <input type="datetime-local" v-model="hasta" />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-btn
                    elevation="2"
                    small
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-simple-table class="mb-5" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">Cheque</th>
                <th class="text-center">Efectivo</th>
                <th class="text-center">Crédito</th>
                <th class="text-center">Débito</th>
                <th class="text-center">Transferencia</th>
                <th class="text-center">Izettle crédito</th>
                <th class="text-center">Izettle débito</th>
                <th class="text-center">Puntos saldo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">{{ sumas[0].toFixed(2) }}</td>
                <td class="text-center">{{ sumas[1].toFixed(2) }}</td>
                <td class="text-center">{{ sumas[2].toFixed(2) }}</td>
                <td class="text-center">{{ sumas[3].toFixed(2) }}</td>
                <td class="text-center">{{ sumas[4].toFixed(2) }}</td>
                <td class="text-center">{{ sumas[5].toFixed(2) }}</td>
                <td class="text-center">{{ sumas[6].toFixed(2) }}</td>
                <td class="text-center">{{ sumas[7].toFixed(2) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-data-table
          :headers="headers"
          :items="abonos"
          :items-per-page="7"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          calculate-widths
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
export default {
  name: "ReportesAbonos",
  data: () => ({
    isLoading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    headers: [
      { text: "Id", value: "id" },
      { text: "Id venta", value: "idVenta", width: "140px" },
      { text: "Fecha", value: "fechaVenta" },
      { text: "Hora", value: "horaVenta" },
      { text: "Cheque", value: "cheque" },
      { text: "Efectivo", value: "efectivo", width: "200px" },
      { text: "Crédito", value: "credito" },
      { text: "Débito", value: "debito" },
      { text: "Transferencia", value: "transferencia" },
      { text: "Ize crédito", value: "izCredito", width: "130px" },
      { text: "Ize débito", value: "izDebito" },
      { text: "Puntos saldo", value: "puntosSaldo" },
      { text: "Caja", value: "caja" }
    ],
    abonos: [],
    placeholderAbonos: [
      {
        id: "53879",
        idVenta: "48299",
        fecha: "23/10/2021",
        hora: "19:56:30",
        cheque: "0.00",
        efectivo: "0.00",
        credito: "0.00",
        debito: "0.00",
        transferencia: "255.00",
        limpiezaDental: "0.00",
        izeCredito: "0.00",
        izeDebito: "0.00",
        caja: "1"
      },
      {
        id: "53878",
        idVenta: "48384",
        fecha: "23/10/2021",
        hora: "19:37:34",
        cheque: "0.00",
        efectivo: "0.00",
        credito: "2084.00",
        debito: "0.00",
        transferencia: "0.00",
        limpiezaDental: "0.00",
        izeCredito: "0.00",
        izeDebito: "0.00",
        caja: "1"
      },
      {
        id: "53877",
        idVenta: "48299",
        fecha: "23/10/2021",
        hora: "19:56:30",
        cheque: "0.00",
        efectivo: "0.00",
        credito: "0.00",
        debito: "0.00",
        transferencia: "255.00",
        limpiezaDental: "0.00",
        izeCredito: "0.00",
        izeDebito: "0.00",
        caja: "1"
      }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchAbonos(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchAbonos(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  computed: {
    numeroAbonos() {
      return this.abonos.length;
    },
    sumas() {
      let suma = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0];
      let result = {};
      let atributos = [
        "cheque",
        "efectivo",
        "credito",
        "debito",
        "transferencia",
        "izCredito",
        "izDebito",
        "puntosSaldo"
      ];
      this.abonos.map(abono => {
        atributos.map((atributo, i) => {
          suma[i] = suma[i] + parseFloat(abono[atributo]);
        });
      });
      result = suma;

      return result;
    }
  },
  methods: {
    async fetchAbonos(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "abonos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllReporte",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta) {
        this.abonos = respuesta.data.rows;
        this.isLoading = false;
      }
      //this.abonos = this.placeholderAbonos;
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var abonos = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, abonos, "Abonos");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.abonos.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            // Verificar si el valor es numérico
            let valor = registro[header.value];
            if (!isNaN(valor) && valor !== "") {
              // Convertir el valor a número
              valor = Number(valor);
            }
            XLSX.utils.sheet_add_aoa(sheet, [[valor]], {
              // Agregar el valor al archivo
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-abonos.xlsx`);
    },
    initialize() {
      this.fetchAbonos(
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }
};
</script>
