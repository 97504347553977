<template>
  <v-dialog max-width="500px" v-model="isOpen">
    <v-card>
      <v-card-title> {{ modalTitle }} </v-card-title>

      <v-card-text>
        <v-container>
          <v-text-field
            v-model="prevFondoFijo.fondoInicial"
            :disabled="prevFondoFijo.status == 'apertura'"
            type="number"
            label="Fondo inicial"
          ></v-text-field>
          <v-text-field
            v-if="aperturaOCierre == 'cierre'"
            type="number"
            label="Total egresos"
            v-model="totalEgresos"
            disabled
          ></v-text-field>
          <v-text-field
            v-if="aperturaOCierre == 'cierre'"
            type="number"
            label="Fondo resultante"
            :value="prevFondoFijo.fondoInicial - totalEgresos"
            disabled
          ></v-text-field>
          <SelectEmpleado ref="SelectEmpleado" />
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="toggleModal"> Cerrar </v-btn>
        <v-btn color="blue darken-1" text @click="agregarFondoFijo">
          Agregar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import SelectEmpleado from "../../components/SelectEmpleado.vue";
export default {
  name: "ModalAgregarFondoFijo",
  components: { SelectEmpleado },
  props: ["aperturaOCierre", "callback"],
  data: () => ({
    isOpen: false,
    initialPrevFondoFijo: {
      id: 0,
      fondoInicial: 0
    },
    idEmpleado: 0,
    totalEgresos: 0,
    prevFondoFijo: {
      id: 0,
      fondoInicial: 0
    }
  }),
  computed: {
    modalTitle() {
      return "Agregar " + this.aperturaOCierre + " de fondo fijo";
    }
  },
  methods: {
    async agregarFondoFijo() {
      let empleado = this.$refs.SelectEmpleado.getEmpleado();
      if (!empleado.id) {
        this.$toasted.error("Selecciona un empleado válido", {
          position: "bottom-right",
          duration: 5000
        });
        return;
      }
      let myUrl = process.env.VUE_APP_API_URL + "fondosFijos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "insert",
        token: localStorage.token,
        geo: localStorage.geo,
        fondoFijo: {
          monto: this.prevFondoFijo.fondoInicial,
          idEmpleado: empleado.id
        }
      });
      if (respuesta.data.status == "Ok") {
        this.resetPrevFondoFijo();
        this.toggleModal();
        this.callback();
      }
    },

    async setFondoFijo(myFondoFijo) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "fondosFijos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        myFondoFijo,
        id: myFondoFijo.id,
        tipo: "getGastosInFondoFijo",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        let totalEgresos = 0;
        respuesta.data.gastos.forEach(gasto => {
          totalEgresos += Number(gasto.monto);
        });
        this.totalEgresos = totalEgresos;
      }
      this.isLoading = false;
    },
    setPrevFondoFijo(prevFondoFijo) {
      this.prevFondoFijo = {
        ...prevFondoFijo,
        fondoInicial: Number(prevFondoFijo.montoApertura)
      };
    },
    resetPrevFondoFijo() {
      this.totalEgresos = 0;
      this.prevFondoFijo = { ...this.initialPrevFondoFijo };
    },
    toggleModal() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>
