<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      @keydown.esc="cerrar"
      @click:outside="cerrar"
      max-width="600px"
    >
      <!--  <template v-slot:activator="{ on, attrs }">
      <v-btn color="pink darken-4" dark v-bind="attrs" v-on="on">
        Agregar Producto
      </v-btn>
    </template> -->
      <v-card :loading="isLoading">
        <v-form
          ref="entryForm"
          color="teal"
          v-model="valid"
          @submit.prevent="submitHandler"
        >
          <v-card-title>
            <span class="headline">Agregar Producto o Servicio</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre"
                    v-model="producto.nombre"
                    :rules="nameRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Compuesto"
                    v-model="producto.compuesto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Categoría"
                    v-model="producto.idCategoria"
                    :items="categorias"
                    item-value="id"
                    item-text="nombre"
                    :rules="nameRules"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Subcategoría"
                    v-model="producto.idSubcategoria"
                    :items="subcategoriasFiltradas"
                    item-value="id"
                    item-text="nombre"
                    :rules="nameRules"
                    required
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.nombre"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-tooltip
                        bottom
                        v-if="
                          item.id == '6' || item.id == '7' || item.id == '8'
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </template>
                        <span
                          >Los productos o servicios de ésta subcategoría
                          aparecen disponibles en la sección de estética</span
                        >
                      </v-tooltip>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Marca"
                    v-model="producto.idMarca"
                    :items="marcas"
                    item-value="id"
                    item-text="nombre"
                    :rules="nameRules"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    label="Código"
                    v-model="producto.codigo"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Precio"
                    type="number"
                    v-model="producto.precio"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Precio de compra"
                    type="number"
                    v-model="producto.precioCompra"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-select
                    label="Impuesto"
                    type="number"
                    v-model="producto.impuesto"
                    :items="[0, 6, 7, 16]"
                    suffix="%"
                  ></v-select>
                </v-col>

                <v-col cols="4">
                  <v-select
                    label="Unidad"
                    v-model="producto.unidad"
                    :items="[
                      'bolsa',
                      'caja',
                      'g',
                      'granel',
                      'kg',
                      'l',
                      'mililitro',
                      'pza',
                      'servicio'
                    ]"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-select
                    label="Concepto SAT"
                    v-model="codigoYconcepto"
                    item-text="nombre"
                    :items="conceptosSAT"
                    return-object
                  >
                  </v-select>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Código SAT"
                    readonly
                    v-model="producto.codigoSAT"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Mínimo"
                    type="number"
                    v-model="producto.minimo"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Máximo"
                    type="number"
                    v-model="producto.maximo"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Control stock"
                    v-model="producto.controlStock"
                    :items="['Si', 'No']"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-if="producto.id == undefined"
            >
              Agregar
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitHandler" v-else>
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import conceptos from "./conceptos";
export default {
  name: "ModalProductos",
  data: () => ({
    isLoading: false,
    isSending: false,
    valid: false,
    codigoYconcepto: { codigo: "", nombre: "" },
    tipoRequest: "",
    idProducto: "0",
    grupos: [],
    categorias: [],
    subcategorias: [],
    conceptosSAT: [...conceptos],
    marcas: [],
    nameRules: [v => !!v || "El nombre es requerido"],
    telRules: [
      v => !!v || "El num. telefónico es requerido",
      v =>
        /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
        "Ingrese un número de teléfono válido"
    ],
    emailRules: [
      v => !!v || "El email es requerido",
      v => /.+@.+/.test(v) || "El email debe ser válido"
    ],
    addressRules: [v => !!v || "La dirección es requerida"]
  }),
  watch: {
    codigoYconcepto(value) {
      this.producto.conceptoSAT = value.nombre;
      this.producto.codigoSAT = value.codigo;
    }
  },
  computed: {
    open() {
      return this.$store.state.modals.productos.open;
    },
    producto() {
      return this.$store.state.modals.productos.item;
    },
    subcategoriasFiltradas() {
      if (this.producto.idCategoria) {
        return this.subcategorias.filter(
          item => item.idCategoria == this.producto.idCategoria
        );
      }
      return this.subcategorias;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.fetchCategorias();
      this.fetchSubcategorias();
      this.fetchMarcas();
    },
    async insertProducto() {
      this.isSending = true;
      if (this.producto.id != undefined) {
        this.tipoRequest = "update";
      } else {
        this.tipoRequest = "insert";
      }
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: this.tipoRequest,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.producto]
      });
      if (respuesta) {
        this.isSending = false;
        this.$store.state.modals["productos"].callback();
        if (this.producto.id == undefined) {
          this.$toasted.success("Producto agregado", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Producto actualizado", {
            position: "bottom-right",
            duration: 5000
          });
        }
        this.$refs.entryForm.reset();
        this.cerrar();
      }
    },
    async submitHandler() {
      let valido = await this.$refs.entryForm.validate();
      if (valido) {
        this.insertProducto();
      }
    },
    async fetchCategorias() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "categorias.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.categorias = respuesta.data.rows;
      this.isLoading = false;
    },
    async fetchSubcategorias() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "subcategorias.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.subcategorias = respuesta.data.rows;
      this.isLoading = false;
    },
    async fetchMarcas() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "marcas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.marcas = respuesta.data.rows;
      this.isLoading = false;
    },

    cerrar() {
      this.$store.commit("openModal", { modal: "productos", open: false });
    }
  }
};
</script>
