var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"items":_vm.empleados,"value":_vm.empleadoSeleccionado,"search-input":_vm.search,"label":_vm.placeholder,"item-text":"nombre","return-object":"","prepend-inner-icon":'mdi-card-account-details-outline',"append-outer-icon":'mdi-magnify'},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.handleChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('Perfil',{attrs:{"item":{
        nombre: item.nombre,
        imagen: '',
        tipo: 'empleados',
        codigosInternos: _vm.codigosInternos(item.codigosInternos),
        tamano: 'sm'
      }}}),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.nombre)}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }