var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.productosNuevaCompra,"search":_vm.search,"items-per-page":-1,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.cantidad},on:{"input":function (e) {
          _vm.setQuantity(Object.assign({}, item, {cantidad: Number(e.target.value)}));
          _vm.calculateTotal(Object.assign({}, item, {cantidad: Number(e.target.value)}));
        }}})]}},{key:"item.precioCompraSinImpuesto",fn:function(ref){
        var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.precioCompraSinImpuesto},on:{"input":function (e) {
          _vm.setprecioCompraSinImpuesto(Object.assign({}, item,
            {precioCompraSinImpuesto: e.target.value}));
          _vm.calculateTotal(Object.assign({}, item));
        }}})]}},{key:"item.impuesto",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.impuesto)+" % ")]}},{key:"item.descuentoPorcentaje",fn:function(ref){
        var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.descuentoPorcentaje},on:{"input":function (e) {
          item.descuentoPorcentaje = Number(e.target.value);
          _vm.updateDescuentoMonto(item);
          _vm.calculateTotal(item);
        }}}),_c('span',[_vm._v("%")])]}},{key:"item.descuentoMonto",fn:function(ref){
        var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.descuentoMonto},on:{"input":function (e) {
          item.descuentoMonto = Number(e.target.value);
          _vm.updateDescuentoPorcentaje(item);
          _vm.calculateTotal(item);
        }}})]}},{key:"item.acciones",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"danger","small":""},on:{"click":function($event){return _vm.removeProduct(item.uniqueId)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eraser")])],1)]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"2"}}),_c('td',[_vm._v("Subtotal")]),_c('td',[_vm._v(_vm._s(_vm.subtotal))]),_c('td',[_vm._v("Descuento")]),_c('td',[_vm._v(_vm._s(_vm.descuento))]),_c('td',[_vm._v("IVA")]),_c('td',[_vm._v(_vm._s(_vm.iva))]),_c('td',[_vm._v("Total")]),_c('td',[_vm._v(_vm._s(_vm.total))]),_c('td',{attrs:{"colspan":"1"}})])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }