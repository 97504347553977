<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12">
              <h1>Inventario Almacen</h1>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="green darken-4"
                :loading="isLoadingSearch"
                @keyup="handleSearchInput"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="2">
              <v-select
                v-model="idCategoria"
                label="Categoría"
                color="green darken-4"
                dense
                item-value="id"
                item-text="nombre"
                @change="fetchByCategoria()"
                :items="categorias"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="2">
              <v-select
                v-model="idSubcategoria"
                label="Subcategoría"
                color="green darken-4"
                item-value="id"
                item-text="nombre"
                dense
                @change="fetchBySubcategoria()"
                :items="subcategoriasFiltradas"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="2" class="text-right align-self-center mt-n4">
              <v-btn color="green darken-4" dark @click="openModal">
                Agregar ajuste
              </v-btn>
            </v-col>
            <v-dialog v-model="showModal" width="700">
              <v-card>
                <v-card-title>
                  Ajuste de Almacen
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-row
                        v-for="(product, ind) in selectedProducts"
                        :key="product.id + '_' + ind"
                      >
                        <v-col
                          cols="9"
                          class="d-flex justify-space-between align-center flex-gap"
                        >
                          <v-autocomplete
                            v-model="selectedProducts[ind]"
                            :items="productos"
                            item-text="nombre"
                            return-object
                            clearable
                            dense
                            :menu-props="{ maxWidth: '500px' }"
                            :hint="
                              `Este producto tiene ${selectedProducts[ind].stock} en stock
                                Tienda y ${selectedProducts[ind].stockAlmacen} en Almacen`
                            "
                            @keyup="handleSearchInputModal"
                            persistent-hint
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          cols="3"
                          class="d-flex justify-space-between align-center flex-gap"
                        >
                          <v-text-field
                            v-model="selectedProducts[ind].cantidad"
                            dense
                            label="Cantidad"
                            type="number"
                          ></v-text-field>
                          <v-btn
                            color="danger"
                            dense
                            icon
                            @click="
                              () => {
                                removeProductAt(ind);
                              }
                            "
                          >
                            <v-icon> mdi-minus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center align-center">
                        <v-btn icon color="info" @click="addProductToList">
                          <v-icon> mdi-plus</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center align-center">
                      <v-select
                        :items="ubicaciones"
                        v-model="desde"
                        label="Desde la ubicación:"
                        item-value="id"
                        item-text="nombre"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn color="gray darken-1" text @click="showModal = false"
                    >Cerrar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="enviarAjuste"
                    >Enviar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="productosFiltrados"
          :items-per-page="7"
          :search="delayedSearch"
          :custom-filter="guessSearch"
          v-model="selected"
          item-key="id"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon color="info" link :to="`/DetallesProducto/${item.id}`">
              <v-icon small>mdi-text-box</v-icon>
            </v-btn>
            <v-btn icon @click="setVisible(item)">
              <v-icon small v-if="item.isVisible == '1'" color="success">
                mdi-eye
              </v-icon>
              <v-icon small v-if="item.isVisible !== '1'" color="error">
                mdi-eye-off
              </v-icon>
            </v-btn>
            <v-btn icon @click="setEstetica(item)">
              <v-icon
                small
                :color="item.isEstetica == '1' ? 'success' : 'error'"
              >
                mdi-content-cut
              </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="editItem(item)"
              v-if="canEditProduct"
              color="info"
            >
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon @click="deleteItem(item)">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="green darken-4 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <modal-productos />
  </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { matchStrings } from "../tools/proStrings";
import ModalProductos from "./AgregarEditar/ModalProductos.vue";
import XLSX from "xlsx";
import {
  getAllCategorias,
  getAllSubcategorias
} from "../axios-calls/categorias";

export default {
  name: "Productos",
  components: { ModalProductos },
  data: () => ({
    all_columns: false,
    search: "",
    searchCategoria: "",
    searchSubcategoria: "",
    delayedSearch: "",
    isLoading: false,
    isLoadingSearch: false,
    canAddProduct: false,
    canEditProduct: false,
    debouncing: null,
    selected: [],
    productosFiltrados: [],
    categorias: [],
    subcategorias: [],
    ubicaciones: [
      { id: 0, nombre: "Ninguna" },
      { id: 2, nombre: "Tienda" }
    ],
    desde: 0,
    showModal: false,
    selectedProduct: null,
    selectedProducts: [],
    cantidad: 0,
    stockQuantity: 0,
    firstClick: true,
    idCategoria: 0,
    idSubcategoria: 0
  }),
  created() {
    this.initialize();
    this.checkPermissons();
    this.refilterProducts();
  },
  computed: {
    ...mapState("productos", ["productos"]),
    headers() {
      if (!this.all_columns) {
        return [
          { text: "Nombre", value: "nombre" },
          { text: "Compuesto", value: "compuesto" },
          {
            text: "Categoría",
            value: "categoria",
            filter: f => {
              return (f + "")
                .toLowerCase()
                .includes(this["searchCategoria"].toLowerCase());
            }
          },
          {
            text: "Subcategoría",
            value: "subcategoria",
            filter: f => {
              return (f + "")
                .toLowerCase()
                .includes(this["searchSubcategoria"].toLowerCase());
            }
          },
          { text: "Marca", value: "marca" },
          { text: "Código", value: "codigo" },
          { text: "Stock", value: "stock" }
        ];
      }

      return [
        { text: "Nombre", value: "nombre" },
        { text: "Compuesto", value: "compuesto" },
        {
          text: "Categoría",
          value: "categoria",
          filter: f => {
            return (
              (f + "")
                .toLowerCase()
                .includes(this["searchCategoria"].toLowerCase()) ||
              this["searchSubcategoria"] == "Todas"
            );
          }
        },
        {
          text: "Subcategoría",
          value: "subcategoria",
          filter: f => {
            return (
              (f + "")
                .toLowerCase()
                .includes(this["searchSubcategoria"].toLowerCase()) ||
              this["searchSubcategoria"] == "Todas"
            );
          }
        },
        { text: "Marca", value: "marca" },
        { text: "Código", value: "codigo" },
        { text: "Stock Almacen", value: "stockAlmacen" },
        { text: "Unidad", value: "unidad" },
        { text: "Concepto SAT", value: "conceptoSat" },
        { text: "Control Stock", value: "controlStock" }
      ];
    },
    subcategoriasFiltradas() {
      if (this.idCategoria == 0 || !this.idCategoria) return this.subcategorias;
      return this.subcategorias.filter(
        subcategoria =>
          subcategoria.idCategoria == this.idCategoria || subcategoria.id == 0
      );
    }
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", { name: "productos", search: val });
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.delayedSearch = val;
      }, 600);
    },
    selectedProduct(newVal) {
      if (newVal) {
        this.stockQuantity = newVal.stockAlmacen;
      }
    }
  },
  methods: {
    initialize() {
      this.testColumnsPermisson();
      this.fetchProductos();
      this.fetchCategorias();
      this.fetchSubcategorias();
      this.search = this.$store.state.searchParams["productos"];
    },
    refilterProducts() {
      this.productosFiltrados = this.productos;
      this.productosFiltrados = this.productosFiltrados.filter(
        producto =>
          producto.idCategoria == this.idCategoria || this.idCategoria == 0
      );
      this.productosFiltrados = this.productosFiltrados.filter(
        producto =>
          producto.idSubcategoria == this.idSubcategoria ||
          this.idSubcategoria == 0
      );
    },
    addProductToList() {
      this.selectedProducts.push({ id: 0, cantidad: 0 });
    },
    removeProductAt(ind) {
      this.selectedProducts.splice(ind, 1);
    },
    async fetchCategorias() {
      const todas = [{ id: 0, nombre: "Todas" }];
      this.categorias = [...todas, ...(await getAllCategorias())];
    },
    async fetchSubcategorias() {
      const todas = [{ id: 0, nombre: "Todas" }];
      this.subcategorias = [...todas, ...(await getAllSubcategorias())];
    },
    onFocus() {
      if (this.firstClick) {
        this.cantidad = null;
        this.firstClick = false;
      }
    },
    async setVisible(item) {
      if (item.isVisible == "1") item.isVisible = "0";
      else item.isVisible = "1";
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update",
        items: [item],
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        if (item.isVisible == "1") {
          this.$toasted.success("Ahora es VISIBLE en buscadores", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Ahora es INVISIBLE en buscadores", {
            position: "bottom-right",
            duration: 5000
          });
        }
      }
      this.$store.commit("productos/refreshCache");
    },
    async setEstetica(item) {
      if (item.isEstetica == "1") item.isEstetica = "0";
      else item.isEstetica = "1";
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update",
        items: [item],
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) {
        if (item.isEstetica == "1") {
          this.$toasted.success("Ahora es seleccionable en estética", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.error("Ahora no es seleccionable en estética", {
            position: "bottom-right",
            duration: 5000
          });
        }
      }
    },
    async testColumnsPermisson() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "testColumnsPermisson",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.permisson == "Ok") {
        this.all_columns = true;
      }
      this.isLoading = false;
    },
    async fetchByCategoria() {
      this.isLoadingSearch = true;
      await this.$store.dispatch("productos/getByCategoria", this.idCategoria);
      this.refilterProducts();
      this.isLoadingSearch = false;
    },
    async fetchBySubcategoria() {
      this.isLoadingSearch = true;
      await this.$store.dispatch(
        "productos/getBySubcategoria",
        this.idSubcategoria
      );
      this.refilterProducts();
      this.isLoadingSearch = false;
    },
    handleSearchInputModal(e) {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchProductosModal(e.target.value);
      }, 500);
    },
    async fetchSearchProductosModal(search) {
      this.isLoadingSearch = true;
      await this.$store.dispatch("productos/getBySearch", search);
      this.refilterProducts();
      this.isLoadingSearch = false;
    },
    handleSearchInput() {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchProductos();
      }, 500);
    },
    async fetchSearchProductos() {
      this.isLoadingSearch = true;
      await this.$store.dispatch("productos/getBySearch", this.search);
      this.refilterProducts();
      this.isLoadingSearch = false;
    },
    async fetchProductos() {
      this.isLoading = true;
      await this.$store.dispatch("productos/getProductos");
      this.refilterProducts();
      this.isLoading = false;
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "productos",
        item: {},
        callback: this.fetchProductos
      });

      this.$store.commit("openModal", { modal: "productos", open: true });
    },
    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "productos",
        item: { ...myItem },
        callback: this.fetchProductos
      });
      this.$store.commit("openModal", { modal: "productos", open: true });
    },
    async deleteItem(id) {
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id,
        callback: this.fetchProductos
      });
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var selected = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, selected, "selected");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.selected.map((consulta, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(consulta, header.value)) {
            XLSX.utils.sheet_add_aoa(sheet, [[consulta[header.value]]], {
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-selected.xlsx`);
    },

    async actualizarInventario() {
      const desde = this.desde;
      let myUrl = process.env.VUE_APP_API_URL + "inventario.php";
      const productErrors = [];
      this.selectedProducts.forEach(p => {
        if (!p.cantidad) {
          productErrors.push(p);
        }
      });
      if (productErrors.length > 0) {
        productErrors.forEach(p => {
          this.$toasted.error("Selecciona una cantidad para " + p.nombre, {
            position: "bottom-right",
            duration: 5000
          });
        });
        return;
      }

      try {
        let respuesta = await axios.post(myUrl, {
          request: 1,
          tipo: "ajusteDeInventario",
          selectedProducts: this.selectedProducts,
          desde,
          para: 1, // id ubicación almacén
          token: localStorage.token,
          geo: localStorage.geo
        });

        if (respuesta.data.status == "Ok") {
          this.$toasted.success("Inventario actualizado con éxito", {
            position: "bottom-right",
            duration: 5000
          });
          this.showModal = false;
          this.selectedProducts.forEach(p => {
            const productId = p.id;
            const cantidad = p.cantidad;
            let producto = this.productos.find(p => p.id === productId);
            if (producto) {
              let newStock = Number(producto.stockAlmacen) + Number(cantidad);
              producto.stockAlmacen = newStock.toFixed(2);
            }
          });
        } else {
          this.$toasted.error(
            respuesta.data.message || "Error al actualizar el inventario",
            {
              position: "bottom-right",
              duration: 5000
            }
          );
        }
      } catch (error) {
        this.$toasted.error("Error de red o del servidor" + error, {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    checkPermissons() {
      if (!localStorage?.permisos) return;
      this.canAddProduct = JSON.parse(localStorage.permisos)?.some(
        permiso => permiso.id == 58
      );
      this.canEditProduct = JSON.parse(localStorage.permisos)?.some(
        permiso => permiso.id == 59
      );
    },
    openModal() {
      this.showModal = true;
    },

    enviarAjuste() {
      this.actualizarInventario();
    }
  }
};
</script>
