import axios from "axios";

export const getOneVenta = async id => {
  let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getOne",
    id: id,
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") {
    return respuesta.data;
  }
};

export const sendVenta = async ({
  idVenta,
  status,
  fechaHora,
  pagos,
  idCajero,
  idVendedor,
  idCliente,
  productos
}) => {
  let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: idVenta == 0 ? "insert" : "update",
    token: localStorage.token,
    geo: localStorage.geo,
    venta: {
      id: idVenta,
      status,
      fechaHora
    },
    pagos,
    idVenta,
    idCajero,
    idVendedor,
    idCliente,
    idCaja: localStorage.idCaja,
    items: productos
  });
  if (respuesta.data.status == "Ok") {
    return respuesta.data;
  }
  return respuesta;
};

export const getVentasIniciales = async () => {
  const myUrl = process.env.VUE_APP_API_URL + "ventas.php";
  const respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getInitials",
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") {
    const ventas = respuesta.data.rows.map(row => {
      row.montoAbono ? null : (row.montoAbono = 0.0);
      row.montoSaldo ? null : (row.montoSaldo = row.montoTotal);
      row.montoTotal ? null : (row.montoTotal = 0.0);
      return row;
    });
    return ventas;
  }
  return [];
};

export const getVentasBySearch = async search => {
  let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getBySearch",
    search,
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") {
    const ventas = respuesta.data.rows.map(row => {
      row.montoAbono ? null : (row.montoAbono = 0.0);
      row.montoSaldo ? null : (row.montoSaldo = row.montoTotal);
      row.montoTotal ? null : (row.montoTotal = 0.0);
      return row;
    });
    return ventas;
  }
  return [];
};
