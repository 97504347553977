var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Mascotas")]),_c('br'),_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","color":"indigo","dense":""},on:{"keydown":_vm.handleSearchInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[(_vm.canAddMascotas)?_c('v-btn',{attrs:{"elevation":"2","small":"","color":"indigo white--text "},on:{"click":function($event){return _vm.addItem()}}},[_vm._v("Agregar mascota")]):_vm._e()],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.mascotasFiltradas,"items-per-page":7,"sort-by":"id","sort-desc":true,"footer-props":{
          'items-per-page-text': 'Items por pagina',
          'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
        },"dense":""},scopedSlots:_vm._u([{key:"item.edad",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getEdad(item.fechaNacimiento)))])]}},{key:"item.nombre",fn:function(ref){
        var item = ref.item;
return [_c('NombreMascota',{attrs:{"mascota":{
              nombre: item.nombre,
              codigosInternos: item.codigosInternos
            }}})]}},{key:"item.propietario",fn:function(ref){
            var item = ref.item;
return [_c('NombreCliente',{attrs:{"cliente":{
              nombre: item.propietario,
              codigosInternos: item.codigosInternosPropietario
            }}})]}},{key:"item.fechaNacimiento",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getFormattedDate(item.fechaNacimiento)))])]}},{key:"item.detalles",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info","link":"","to":("/DetallesMascota/" + (item.id))}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-text-box")])],1),_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":"","color":"danger"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"indigo white--text my-6","rounded":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")])],1)]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }