import axios from "axios";

export const getProductosIniciales = async () => {
  let myUrl = process.env.VUE_APP_API_URL + "productos.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getIniciales",
    token: localStorage.token,
    geo: localStorage.geo
  });
  if (respuesta.data.status == "Ok") return respuesta.data.rows;
  return [];
};

export const getProductosBySearch = async search => {
  let myUrl = process.env.VUE_APP_API_URL + "productos.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getBySearch",
    token: localStorage.token,
    geo: localStorage.geo,
    search
  });
  if (respuesta.data.status == "Ok") return respuesta.data.rows;
  return [];
};

export const getProductosByCategoria = async idCategoria => {
  let myUrl = process.env.VUE_APP_API_URL + "productos.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getByCategoria",
    token: localStorage.token,
    geo: localStorage.geo,
    idCategoria
  });
  if (respuesta.data.status == "Ok") return respuesta.data.rows;
  return [];
};

export const getProductosBySubcategoria = async idSubcategoria => {
  let myUrl = process.env.VUE_APP_API_URL + "productos.php";
  let respuesta = await axios.post(myUrl, {
    request: 1,
    tipo: "getBySubcategoria",
    token: localStorage.token,
    geo: localStorage.geo,
    idSubcategoria
  });
  if (respuesta.data.status == "Ok") return respuesta.data.rows;
  return [];
};
