var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cerrar($event)},"click:outside":_vm.cerrar},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',[_c('v-form',{ref:"entryForm",attrs:{"color":"teal"},on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[(_vm.llamada.id != undefined)?_c('span',[_vm._v("Actualizar")]):_c('span',[_vm._v("Agregar")]),_vm._v(" Llamada ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"cliente",attrs:{"label":"Cliente","rules":_vm.nameRules,"items":_vm.clientesFiltrados,"loading":_vm.isLoading,"item-text":"nombre","item-value":"id","required":""},on:{"dblclick":_vm.resetearCampos,"keydown":[_vm.handleClienteSearchInput,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({ actualRef: 'cliente', nextRef: 'mascota' })}]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.testIfObject(item))?[_c('v-list-item',[_c('v-list-item-content',{domProps:{"textContent":_vm._s(item)}})],1)]:_vm._e(),_c('v-list-item',[_c('perfil',{attrs:{"item":{
                          nombre: item.nombre,
                          imagen: '',
                          tipo: 'clientes',
                          codigosInternos: _vm.codigosInternos(
                            item.codigosInternos
                          ),
                          tamano: 'sm'
                        }}}),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.tipo)}})],1)],1)]}}]),model:{value:(_vm.llamada.idCliente),callback:function ($$v) {_vm.$set(_vm.llamada, "idCliente", $$v)},expression:"llamada.idCliente"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"mascota",attrs:{"label":"Mascota","rules":_vm.nameRules,"items":_vm.mascotasFiltradas,"loading":_vm.isLoading,"item-text":"nombre","item-value":"id","required":""},on:{"dblclick":_vm.resetearCampos,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({ actualRef: 'tamano', nextRef: 'direccion' })},"focus":function () {
                      _vm.fetchMascotas(_vm.llamada.idCliente);
                    }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [(_vm.testIfObject(item))?[_c('v-list-item',[_c('v-list-item-content',{domProps:{"textContent":_vm._s(item)}})],1)]:_vm._e(),_c('v-list-item',[_c('perfil',{attrs:{"item":{
                          nombre: item.nombre,
                          imagen: '',
                          tipo: 'mascotas',
                          codigosInternos: _vm.codigosInternos(
                            item.codigosInternos
                          ),
                          tamano: 'sm'
                        }}}),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.propietario)}})],1)],1)]}}]),model:{value:(_vm.llamada.idMascota),callback:function ($$v) {_vm.$set(_vm.llamada, "idMascota", $$v)},expression:"llamada.idMascota"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Tipo de llamada","items":_vm.tiposLlamadas,"item-text":"nombre","item-value":"valor"},model:{value:(_vm.llamada.tipo),callback:function ($$v) {_vm.$set(_vm.llamada, "tipo", $$v)},expression:"llamada.tipo"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Fecha de llamada","type":"date"},model:{value:(_vm.llamada.fechaLlamar),callback:function ($$v) {_vm.$set(_vm.llamada, "fechaLlamar", $$v)},expression:"llamada.fechaLlamar"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Motivo de llamada","type":"text"},model:{value:(_vm.llamada.motivo),callback:function ($$v) {_vm.$set(_vm.llamada, "motivo", $$v)},expression:"llamada.motivo"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cerrar}},[_vm._v("Cerrar")]),(_vm.llamada.id == undefined)?_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.isSending},on:{"click":_vm.submitHandler}},[_vm._v(" Agregar ")]):_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.isSending},on:{"click":_vm.submitHandler}},[_vm._v(" Actualizar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }