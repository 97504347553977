<template>
  <v-row>
    <ModalAgregarFondoFijo
      ref="ModalAgregarFondoFijo"
      :aperturaOCierre="aperturaOCierre"
      :callback="
        () => {
          fetchFondoFijo(desde, hasta);
        }
      "
    />
    <ModalImprimirFondoFijo ref="ModalImprimirFondoFijo" />
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5" v-if="!isCierre">Abrir fondo fijo</span>
          <span class="text-h5" v-else>Cerrar fondo fijo</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6" v-if="!isCierre">
                <v-text-field
                  type="number"
                  label="Fondo"
                  v-model="fondo.fondo"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="isCierre">
                <v-text-field
                  type="number"
                  label="Fondo inicial"
                  v-model="fondo.fondoInicial"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="isCierre">
                <v-text-field
                  type="number"
                  label="Total egresos"
                  v-model="fondo.totalEgresos"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="isCierre">
                <v-text-field
                  type="number"
                  label="Fondo restante"
                  v-model="fondo.fondoRestante"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :items="empleados"
                  label="Empleado"
                  item-text="nombre"
                  item-value="id"
                  v-model="fondo.idEmpleado"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text @click="send(0)">
            <span v-if="!isEditing">Agregar</span>
            <span v-else>Editar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <h1>Fondo fijo</h1>
              <br />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="pink darken-2"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                elevation="2"
                small
                color="pink darken-2 white--text "
                @click="toggleModalAgregarFondoFijo"
              >
                Agregar {{ aperturaOCierre }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="fondos"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.fondoRestante`]="{ item }">
            <span>{{ item.montoApertura - Number(item.totalEgresos) }}</span>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn
              icon
              title="Imprimir"
              @click="toggleModalImprimirFondoFijo(item)"
            >
              <v-icon small class="info--text"> mdi-printer </v-icon>
            </v-btn>
            <v-btn
              v-if="false"
              icon
              title="Detalles"
              link
              :to="`/RangosFondoFijo/${item.id}`"
            >
              <v-icon small class="info--text"> mdi-text-box </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="pink white--text darken-2 my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
import moment from "moment";
import ModalImprimirFondoFijo from "./FondoFijo/ModalImprimirFondoFijo.vue";
import ModalAgregarFondoFijo from "./FondoFijo/ModalAgregarFondoFijo.vue";
export default {
  name: "FondoFijo",
  components: { ModalImprimirFondoFijo, ModalAgregarFondoFijo },
  data: () => ({
    search: "",
    isLoading: false,
    isEditing: false,
    isCierre: false,
    dialog: false,
    desde: "",
    hasta: "",
    headers_old: [
      { text: "Status", value: "status" },
      { text: "Fecha", value: "fecha" },
      { text: "Hora", value: "hora" },
      { text: "Empleado", value: "nombreEmpleado" },
      { text: "Fondo inicial", value: "monto" },
      { text: "Total egresos", value: "totalEgresos" },
      { text: "Fondo restante", value: "fondoRestante" },
      { text: "Acciones", value: "acciones", align: "right" }
    ],
    headers: [
      { text: "Id", value: "id" },
      { text: "Fecha", value: "fecha", width: "110px" },
      { text: "Hora", value: "hora" },
      { text: "Status", value: "status" },
      { text: "Empleado", value: "empleadoCierre", width: "140px" },
      { text: "Fondo inicial", value: "montoApertura" },
      { text: "Total egresos", value: "totalEgresos" },
      { text: "Fondo restante", value: "fondoRestante" },
      { text: "Acciones", value: "acciones", align: "right" }
    ],
    fondo: {
      idCliente: 0,
      idVenta: 0
    },
    fondos: [],
    gastosAdmin: [],
    gastosEmpleados: [],
    proveedores: [],
    empleados: []
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "gastos",
        search: val
      });
    },

    desde(val) {
      this.fetchFondoFijo(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchFondoFijo(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  computed: {
    isAdmin() {
      return localStorage.getItem("id") == "29";
    },
    aperturaOCierre() {
      if (this.fondos.length > 0) {
        let lastFondo = this.fondos[this.fondos.length - 1];
        if (lastFondo.status == "apertura") return "cierre";
      }
      return "apertura";
    }
  },
  methods: {
    initialize() {
      this.fetchFondoFijo();
      this.fetchEmpleados();
      this.search = this.$store.state.searchParams["gastos"];
    },
    async fetchFondoFijo(desde, hasta) {
      if (!desde) {
        desde = moment()
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
      }
      if (!hasta) {
        hasta = moment().format("YYYY-MM-DD HH:mm:ss");
      }
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "fondosFijos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllReporte",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta.data.status == "Ok") {
        this.fondos = respuesta.data.rows;
        this.fondos.forEach(item => {
          item.montoApertura = Number(item.montoApertura);
          item.compras = Number(item.compras);
          item.gastos = Number(item.gastos);
        });
      }
      this.isLoading = false;
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.empleados = respuesta.data.rows;
      this.isLoading = false;
    },
    async editItem(myItem) {
      this.gasto = { ...myItem };
      this.dialog = true;
    },
    async send(idEditing) {
      this.isLoading = true;
      let tipo = "insert";
      let msg = "Gasto agregado";
      let myUrl = process.env.VUE_APP_API_URL + "gastos.php";
      if (idEditing != "0") {
        tipo = "update";
        msg = "Gasto Actualizado";
      }
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: tipo,
        id: idEditing,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.gasto]
      });
      if (respuesta.data.status == "Ok") {
        this.isLoading = false;
        this.isEditing = false;
        this.$toasted.success(msg, {
          position: "bottom-right",
          duration: 5000
        });
        this.dialog = false;
      }
    },
    toggleModalImprimirFondoFijo(myFondoFijo) {
      this.$refs.ModalImprimirFondoFijo.setFondoFijo(myFondoFijo);
      this.$refs.ModalImprimirFondoFijo.toggleModal();
    },
    toggleModalAgregarFondoFijo() {
      if (this.fondos.length > 0) {
        if (this.fondos[this.fondos.length - 1].status == "apertura") {
          this.$refs.ModalAgregarFondoFijo.setPrevFondoFijo(
            this.fondos[this.fondos.length - 1]
          );
          this.$refs.ModalAgregarFondoFijo.setFondoFijo(
            this.fondos[this.fondos.length - 1]
          );
        } else {
          this.$refs.ModalAgregarFondoFijo.resetPrevFondoFijo();
        }
      }
      this.$refs.ModalAgregarFondoFijo.toggleModal();
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    }
  }
};
</script>
