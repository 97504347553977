<style scoped>
.small-select {
  width: 90px;
}
.small-select .v-select__selection {
  font-size: 0.75rem; /* Ajusta el tamaño de fuente si es necesario */
}

.small-select .v-select__slot {
  min-height: 2rem; /* Ajusta la altura mínima según sea necesario */
}

.small-select .v-select__selections {
  height: auto; /* Asegura que el contenedor se ajuste al contenido */
}

.small-select .v-select__slot .v-select__selection--comma {
  padding: 0.5rem; /* Ajusta el relleno si es necesario */
}
</style>
<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12">
              <h1>Llamadas</h1>
              <br />
              <label>Día: </label>
              <input type="date" v-model="desde" @change="fetchLlamadas" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="red darken-3"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                elevation="2"
                small
                color="red darken-3 white--text "
                @click="addItem"
                >Agregar llamada</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-dialog v-model="isVerifyModalOpen" max-width="520px">
          <v-card>
            <v-card-title>
              Por favor, ingresa credenciales que tengan permiso para ésta
              acción
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="usuario" label="Usuario"></v-text-field>
              <v-text-field
                v-model="password"
                label="Contraseña"
                type="password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="pedirPermiso()"
                :disabled="isVerifyBtnDisabled"
                >Verificar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
          :headers="headers"
          :items="llamadas"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:item.llamadaSeguimiento="{ item }">
            <v-select
              v-model="item.llamadaSeguimiento"
              :items="yesNoOptions"
              item-text="nombre"
              item-value="valor"
              class="small-select"
              dense
              hide-details
              @change="actualizarCampo(item)"
            ></v-select>
          </template>

          <template v-slot:item.nombreEmpleado="{ item }">
            <v-autocomplete
              :items="empleados"
              v-model="item.idEmpleado"
              item-text="nombre"
              item-value="id"
              dense
              hide-details
              @change="actualizarCampo(item)"
            ></v-autocomplete>
          </template>

          <template v-slot:item.realizada="{ item }">
            <v-select
              v-model="item.realizada"
              :items="yesNoOptions"
              item-text="nombre"
              item-value="valor"
              class="small-select"
              dense
              hide-details
              @change="actualizarCampo(item)"
            ></v-select>
          </template>

          <template v-slot:item.mensaje="{ item }">
            <v-select
              v-model="item.mensaje"
              :items="yesNoOptions"
              item-text="nombre"
              item-value="valor"
              class="small-select"
              dense
              hide-details
              @change="actualizarCampo(item)"
            ></v-select>
          </template>

          <template v-slot:item.estatus="{ item }">
            <v-select
              v-model="item.estatus"
              :items="statusOptions"
              item-text="nombre"
              item-value="valor"
              dense
              hide-details
              @change="actualizarCampo(item)"
            ></v-select>
          </template>

          <template v-slot:item.percepcion="{ item }">
            <v-select
              v-model="item.percepcion"
              :items="perceptionOptions"
              item-text="nombre"
              item-value="valor"
              dense
              hide-details
              @change="actualizarCampo(item)"
            ></v-select>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon @click="openUpdateVerifyModal(item)" color="info">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon @click="openDeleteVerifyModal(item)" color="danger">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="red white--text darken-3 my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <modal-llamadas />
  </v-row>
</template>

<script>
import "jspdf-autotable";
import { matchStrings } from "../tools/proStrings";
import axios from "axios";
import moment from "moment";
import ModalLlamadas from "../layouts/AgregarEditar/ModalLlamadas";
export default {
  name: "llamadas",
  components: { ModalLlamadas },
  data: () => ({
    search: "",
    isLoading: false,
    isVerifyModalOpen: false,
    isVerifyBtnDisabled: false,
    itemModal: {},
    usuario: "",
    password: "",
    desde: "",
    headers: [
      { text: "Id", value: "id" },
      { text: "Fecha de Llamada", value: "fechaLlamar" },
      { text: "Fecha Registro", value: "fechaRegistro" },
      { text: "Cliente", value: "nombreCliente" },
      { text: "Mascota", value: "nombreMascota" },
      { text: "Tipo", value: "tipo" },
      { text: "Motivo", value: "motivo" },
      { text: "Llamada de Seguimiento", value: "llamadaSeguimiento" },
      { text: "Empleado", value: "nombreEmpleado" },
      { text: "Realizada", value: "realizada" },
      { text: "Mensaje", value: "mensaje" },
      { text: "Estatus", value: "estatus" },
      { text: "Percepción", value: "percepcion" }
    ],
    yesNoOptions: [
      { nombre: "Sí", valor: "1" },
      { nombre: "No", valor: "0" }
    ],
    statusOptions: [
      { nombre: "Por llamar", valor: "porLlamar" },
      { nombre: "Cita confirmada", valor: "citaConfirmada" },
      { nombre: "Autoriza FM", valor: "autorizaFM" },
      { nombre: "No autoriza FM", valor: "noAutorizaFM" },
      { nombre: "No confirmó", valor: "noConfirmo" },
      { nombre: "No contestó", valor: "noContesto" },
      { nombre: "Cancelada", valor: "cancelada" }
    ],
    perceptionOptions: [
      { nombre: "Buena", valor: "buena" },
      { nombre: "Regular", valor: "regular" },
      { nombre: "Mala", valor: "mala" }
    ],

    llamadas: [],
    empleados: []
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "llamadas",
        search: val
      });
    },
    isVerifyModalOpen(val) {
      if (!val) return;
      this.usuario = "usuario";
      this.password = "contraseña";
    }
  },
  methods: {
    initialize() {
      this.desde = moment().format("YYYY-MM-DD");
      this.fetchLlamadas();
      this.fetchEmpleados();

      this.search = this.$store.state.searchParams["llamadas"];
    },

    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllMvz",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") this.empleados = respuesta.data.rows;
      this.isLoading = false;
    },
    async fetchLlamadas() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "llamadas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        desde: this.desde,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") this.llamadas = respuesta.data.rows;
      this.isLoading = false;
    },
    async actualizarCampo(item) {
      let myUrl = process.env.VUE_APP_API_URL + "llamadas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      } else {
        this.$toasted.error("Ocurrió un problema", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "llamadas",
        item: {},
        callback: this.fetchLlamadas
      });

      this.$store.commit("openModal", { modal: "llamadas", open: true });
    },
    editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "llamadas",
        item: { ...myItem },
        callback: () => {
          this.fetchLlamadas();
        }
      });
      this.$store.commit("openModal", {
        modal: "llamadas",
        item: { ...myItem },
        open: true
      });
    },

    async deleteItem(item) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "llamadas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Llamada eliminado", {
          position: "bottom-right",
          duration: 5000
        });
        this.fetchLlamadas();
        this.isLoading = false;
      }
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },
    async pedirPermiso() {
      if (!this.usuario || !this.password) {
        return this.$toasted.error("Introduce un usuario y/o contraseña", {
          position: "bottom-right",
          duration: 1
        });
      }
      this.isVerifyBtnDisabled = true;
      this.$toasted.show("Cargando...", {
        position: "bottom-right",
        duration: 5000
      });

      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "testUpdatePermisson",
        token: localStorage.token,
        geo: localStorage.geo,
        usuario: this.usuario.trim(),
        password: this.password
      });
      if (respuesta.data.permisson == "Ok") {
        this.verifyModalCallback();
        this.isVerifyModalOpen = false;
      } else {
        this.$toasted.error("Datos incorrectos", {
          position: "bottom-right",
          duration: 2000
        });
      }
      this.isVerifyBtnDisabled = false;
    },

    openUpdateVerifyModal(item) {
      this.itemModal = item;
      this.isVerifyModalOpen = true;
      this.verifyModalCallback = () => {
        this.editItem(this.itemModal);
      };
    },
    openDeleteVerifyModal(item) {
      this.itemModal = item;
      this.isVerifyModalOpen = true;
      this.verifyModalCallback = () => {
        this.deleteItem(this.itemModal);
      };
    }
  }
};
</script>
