import moment from "moment/moment";
import {
  getProductosIniciales,
  getProductosBySearch,
  getProductosByCategoria,
  getProductosBySubcategoria
} from "../axios-calls/productos";

export default {
  namespaced: true,
  state: {
    productos: [],
    lastGet: "none"
  },
  mutations: {
    refreshCache(state) {
      state.lastGet = "none";
    },
    setLastGet(state) {
      state.lastGet = moment();
    },
    resetProductsList(state) {
      state.productos = [];
    },
    addProductosToTheList(state, nuevosProductos) {
      let productosSinRepetidos = state.productos.filter(
        existingProducto =>
          !nuevosProductos.some(
            nuevoProducto => nuevoProducto.id === existingProducto.id
          )
      );

      state.productos = [...productosSinRepetidos, ...nuevosProductos];
    }
  },
  actions: {
    async resetProducts({ commit }) {
      commit("resetProductsList", null);
      let productos = await getProductosIniciales();
      commit("addProductosToTheList", productos);
      return productos;
    },
    async getProductos({ commit }) {
      let productos = await getProductosIniciales();
      commit("addProductosToTheList", productos);
      return productos;
    },
    async getBySearch({ commit }, search) {
      let productos = await getProductosBySearch(search);
      commit("addProductosToTheList", productos);
      return productos;
    },
    async getByCategoria({ commit }, idCategoria) {
      let productos = await getProductosByCategoria(idCategoria);
      commit("addProductosToTheList", productos);
      return productos;
    },
    async getBySubcategoria({ commit }, idSubcategoria) {
      let productos = await getProductosBySubcategoria(idSubcategoria);
      commit("addProductosToTheList", productos);
      return productos;
    }
  },
  modules: {}
};
