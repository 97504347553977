var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.productosNuevaVenta,"search":_vm.search,"items-per-page":-1,"hide-default-footer":"","dense":"","item-key":"uniqueId"},scopedSlots:_vm._u([{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.cantidad},on:{"input":function (e) {
          item.cantidad = Number(e.target.value);
          _vm.calculateTotal(Object.assign({}, item));
        }}})]}},{key:"item.precio",fn:function(ref){
        var item = ref.item;
return [(item.isEditable == 'true')?_c('input',{attrs:{"type":"number"},domProps:{"value":item.precio},on:{"input":function (e) {
          item.precio = Number(e.target.value);
          _vm.calculateTotal(Object.assign({}, item));
        }}}):_c('span',[_vm._v(_vm._s(item.precio)+" ")])]}},{key:"item.impuesto",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.impuesto)+" % ")]}},{key:"item.descuentoPorcentaje",fn:function(ref){
        var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.descuentoPorcentaje},on:{"input":function (e) {
          item.descuentoPorcentaje = Number(e.target.value);
          _vm.updateDescuentoMonto(item);
          _vm.calculateTotal(item);
        }}}),_c('span',[_vm._v("%")])]}},{key:"item.descuentoMonto",fn:function(ref){
        var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.descuentoMonto},on:{"input":function (e) {
          item.descuentoMonto = Number(e.target.value);
          _vm.updateDescuentoPorcentaje(item);
          _vm.calculateTotal(item);
        }}})]}},{key:"item.mascota",fn:function(ref){
        var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.mascotasFiltradasPorCliente,"item-text":"nombre","item-value":"id"},on:{"focus":function () {
          _vm.fetchMascotas(_vm.cliente.id);
        }},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('Perfil',{attrs:{"item":{
            nombre: data.item.nombre,
            imagen: '',
            tipo: 'mascotas',
            codigosInternos: _vm.codigosInternos(data.item.codigosInternos),
            tamano: 'sm'
          }}}),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.tipo)}})],1)]}}],null,true),model:{value:(item.idMascota),callback:function ($$v) {_vm.$set(item, "idMascota", $$v)},expression:"item.idMascota"}})]}},{key:"item.vendedor",fn:function(ref){
          var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.vendedores,"item-text":"nombre","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.tipo)}})],1)]}}],null,true),model:{value:(item.idVendedor),callback:function ($$v) {_vm.$set(item, "idVendedor", $$v)},expression:"item.idVendedor"}})]}},{key:"item.acciones",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"danger","small":""},on:{"click":function($event){return _vm.removeProduct(item.uniqueId)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eraser")])],1)]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"3"}}),_c('td',[_vm._v("Subtotal")]),_c('td',[_vm._v(_vm._s(_vm.subtotal))]),_c('td',[_vm._v("Descuento")]),_c('td',[_vm._v(_vm._s(_vm.descuento))]),_c('td',[_vm._v("IVA")]),_c('td',[_vm._v(_vm._s(_vm.iva))]),_c('td',[_vm._v("Total")]),_c('td',[_vm._v(_vm._s(_vm.total))])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }