<template>
  <v-row>
    <ModalSaldoCliente
      ref="ModalSaldoCliente"
      :cliente="this.cliente"
      :callback="fetchClientes"
    />
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Clientes</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="blue"
                dense
                @keydown="handleSearchInput"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                elevation="2"
                small
                color="blue white--text "
                @click="addItem()"
                >Agregar Cliente</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="clientes"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :header-props="headerProps"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 250, 500, -1]
          }"
          dense
        >
          <template v-slot:[`item.nombre`]="{ item }">
            <NombreCliente :cliente="item" />
          </template>

          <template v-slot:[`item.detalles`]="{ item }">
            <v-btn
              icon
              color="success"
              @click="
                () => {
                  cliente = item;
                  toggleModal();
                }
              "
            >
              <v-icon small> mdi-hand-coin-outline </v-icon>
            </v-btn>
            <v-btn
              v-if="canAddMascotas"
              icon
              color="info"
              @click="
                () => {
                  openModalMascota(item.id);
                }
              "
            >
              <v-icon small class="mr-0">mdi-dog</v-icon>
            </v-btn>
            <v-btn icon color="info" link :to="`/DetallesCliente/${item.id}`">
              <v-icon small class="mr-0">mdi-text-box</v-icon>
            </v-btn>
            <v-btn icon color="blue darken-1" @click="editItem(item)">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              icon
              color="danger"
              @click="deleteItem(item)"
              v-if="canDeleteCliente"
            >
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="blue my-6" rounded @click="initialize">
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../../tools/proStrings";
import ModalSaldoCliente from "../AgregarEditar/ModalSaldoCliente.vue";
import NombreCliente from "./NombreCliente.vue";
import { mapState } from "vuex";

export default {
  name: "Clientes",
  components: {
    ModalSaldoCliente,
    NombreCliente
  },
  data: () => ({
    search: "", // modalClientes : false TAMBIEN AQUÍ DEBE EXISTIR ESA VARIABLE, EN CASO DE QUE CASO DE QUE SE LLAME SEARCH, LLÁMALA modalClientes es booleano
    isLoading: false,
    cargando: false,
    isSending: false,
    dialog: false,
    idCliente: "",
    tipoRequest: "",
    valid: false,
    cliente: { id: 0 },
    debouncing: null,
    canAddMascotas: null,
    canDeleteCliente: null,
    formData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigoInterno: "",
      rfc: "",
      codigoPostal: "",
      direccion: "",
      observaciones: ""
    },
    emptyData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigosInternos: [],
      rfc: "",
      codigoPostal: "",
      direccion: "",
      observaciones: ""
    },
    nameRules: [],
    phoneRules: [],
    emailRules: [],
    zipcodeRules: [],
    addressRules: [],
    headerProps: {
      sortByText: "Ordenar por"
    },
    headers: [
      { text: "Id", value: "id" },
      { text: "Nombre", value: "nombre" },
      { text: "Teléfono 1", value: "tel1" },
      { text: "Teléfono 2", value: "tel2" },
      { text: "Correo", value: "correo" },
      { text: "Código interno", value: "codigosInternos" },
      { text: "RFC", value: "rfc" },
      { text: "Código postal", value: "codigoPostal" },
      { text: "Dirección", value: "direccion", align: "center" },
      { text: "Observaciones", value: "observaciones", align: "center" },
      { text: "Puntos Saldo", value: "puntosSaldo", align: "center" },
      {
        text: "Acciones",
        value: "detalles",
        align: "right",
        width: "12%"
      }
    ],
    cli: {}
  }),
  created() {
    this.initialize();
  },

  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", { name: "clientes", search: val });
    }
  },

  computed: {
    ...mapState("clientes", ["clientes"])
  },
  methods: {
    initialize() {
      this.fetchClientes();
      this.checkPermissons();
      this.search = this.$store.state.searchParams["clientes"];
    },
    checkPermissons() {
      this.canAddMascotas = JSON.parse(localStorage.permisos)?.some(
        permiso => permiso.id == 63
      );
      this.canDeleteCliente = JSON.parse(localStorage.permisos)?.some(
        permiso => permiso.id == 69
      );
    },

    handleSearchInput() {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchClientes();
      }, 500);
    },
    toggleModal() {
      this.$refs.ModalSaldoCliente.toggleModal();
    },
    openModalMascota(idPropietario) {
      this.$store.commit("openModal", { modal: "mascotas", open: true });
      this.$store.commit("setItemModal", {
        modal: "mascotas",
        item: {
          ...this.$store.state.modals.mascotas.item,
          idPropietario
        }
      });
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "clientes",
        item: {},
        callback: async () => {
          this.$store.commit("clientes/refreshCache");
          this.fetchClientes;
        }
      });

      this.$store.commit("openModal", { modal: "clientes", open: true });
    },
    fakeAdd() {
      let maxId = 0;
      this.clientes.forEach(cliente => {
        if (maxId <= parseInt(cliente.idCliente)) {
          maxId = parseInt(cliente.idCliente) + 1;
        }
      });
      this.clientes.unshift({
        ...this.$refs.modalClientes.getCliente(),
        id: maxId.toString(),
        idCliente: maxId.toString()
      });
      this.clientes = [...this.clientes];
    },
    async fetchClientes() {
      this.isLoading = true;
      await this.$store.dispatch("clientes/getIniciales");
      this.isLoading = false;
    },
    async fetchSearchClientes() {
      this.isLoading = true;
      await this.$store.dispatch("clientes/getBySearch", this.search);
      this.isLoading = false;
    },

    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "clientes",
        item: { ...myItem },
        callback: async () => {
          this.$store.commit("clientes/refreshCache");
          this.fetchClientes;
        }
      });
      this.$store.commit("openModal", { modal: "clientes", open: true });
    },
    async deleteItem(cliente) {
      if (!confirm("¿Deseas eliminar éste registro?")) {
        return;
      }
      let myUrl = process.env.VUE_APP_API_URL + "clientes.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [cliente]
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Eliminado", {
          position: "bottom-right",
          duration: 5000
        });
        this.$store.commit("clientes/delete", cliente);
        this.$store.commit("clientes/refreshCache");
        this.fetchClientes();
      }
    },

    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    }
  }
};
</script>
