<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Expedientes</h1>
              <br />
              <v-text-field
                :loading="isLoading"
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="indigo"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="2" lg="2">
              <label>Desde: </label>
              <input
                type="date"
                v-model="desde"
                @change="filterDates()"
                style="width: 100%; font-size: 18px"
              />
            </v-col>
            <v-col cols="12" sm="12" md="2" lg="2">
              <label>Hasta: </label>
              <input
                type="date"
                v-model="hasta"
                @change="filterDates()"
                style="width: 100%; font-size: 18px"
              />
            </v-col>

            <v-col cols="12" sm="6" md="2" class="text-right">
              <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="2"
                    small
                    color="indigo white--text "
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      () => {
                        dialog = true;
                        resetExpediente();
                      }
                    "
                    >Agregar Expediente</v-btn
                  >
                </template>
                <v-card :loading="isLoading">
                  <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Expediente</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn v-if="expediente.id == 0" dark text @click="send">
                        Agregar
                      </v-btn>
                      <v-btn
                        v-else
                        dark
                        text
                        @click="send"
                        :disabled="isOnlyReadable"
                      >
                        Actualizar
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-form :readonly="isOnlyReadable">
                    <v-row
                      align="start"
                      justify="center"
                      class="container text-center"
                    >
                      <v-col cols="12" sm="3">
                        <h4 class="title">Examen físico</h4>
                        <div
                          v-for="(examen, ind) in examenFisico"
                          :key="`examen${ind}`"
                        >
                          <v-text-field
                            :loading="isLoading"
                            type="text"
                            :label="examen.text"
                            dense
                            v-model="expediente[`${examen.attr}`]"
                            :error="examen.hasError"
                            @keydown="
                              () => {
                                examen.hasError = false;
                              }
                            "
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <h4 class="title">Datos generales</h4>
                        <v-autocomplete
                          :loading="isLoading"
                          label="Cliente"
                          dense
                          :items="clientes"
                          v-model="expediente.idCliente"
                          @keydown="handleSearchInput"
                          @change="handleChange"
                          item-value="id"
                          item-text="nombre"
                        ></v-autocomplete>
                        <v-select
                          :loading="isLoading"
                          label="Mascota"
                          dense
                          :items="mascotasFiltradas"
                          v-model="expediente.idMascota"
                          @focus="
                            () => {
                              fetchMascotas(expediente.idCliente);
                            }
                          "
                          item-value="id"
                          item-text="nombre"
                        ></v-select>
                        <v-select
                          :loading="isLoading"
                          label="MVZ"
                          dense
                          :items="empleados"
                          v-model="expediente.idMvz"
                          item-value="id"
                          item-text="nombre"
                        ></v-select>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              :loading="isLoading"
                              type="text"
                              label="Servicio"
                              v-model="expediente.servicio"
                            ></v-text-field>
                            <v-text-field
                              :loading="isLoading"
                              type="text"
                              label="Tipo de servicio"
                              v-model="expediente.tipoServicio"
                            ></v-text-field>
                            <v-text-field
                              :loading="isLoading"
                              type="text"
                              label="Motivo de servicio"
                              v-model="expediente.motivoServicio"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              :loading="isLoading"
                              type="date"
                              label="Fecha"
                              v-model="expediente.fecha"
                            ></v-text-field>
                            <v-text-field
                              :loading="isLoading"
                              type="time"
                              label="Hora"
                              v-model="expediente.hora"
                            ></v-text-field>
                            <v-text-field
                              :loading="isLoading"
                              type="text"
                              label="Peso"
                              v-model="expediente.peso"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-textarea
                          :loading="isLoading"
                          dense
                          label="Anamnesis"
                          v-model="expediente.anamnesis"
                          rows="3"
                        ></v-textarea>
                        <v-textarea
                          :loading="isLoading"
                          dense
                          label="Observaciones al examen físico"
                          v-model="expediente.observaciones"
                          rows="3"
                        ></v-textarea>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-textarea
                              :loading="isLoading"
                              label="Lista de problemas"
                              v-model="expediente.listaProblemas"
                              rows="3"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-textarea
                              :loading="isLoading"
                              label="Lista maestra"
                              v-model="expediente.listaMaestra"
                              rows="3"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <h4>
                          Otros examenes de {{ expediente.nombreMascota }}
                        </h4>
                        <v-simple-table dense fixed-header height="500px">
                          <template v-slot:default>
                            <thead>
                              <tr class="text-left">
                                <th class="pa-2">Acciones</th>
                                <th class="pa-2">
                                  Fecha <br />
                                  año/mes/día
                                </th>
                                <th class="pa-2">Médico</th>
                                <th class="pa-2">Motivo</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="text-left"
                                v-for="(exp, ind) in otrosExpedientes"
                                :key="`examen${ind}`"
                              >
                                <td>
                                  <v-btn
                                    icon
                                    class="pa-2"
                                    color="warning"
                                    @click="changeTo(exp)"
                                  >
                                    <v-icon small> mdi-folder </v-icon>
                                  </v-btn>
                                </td>
                                <td class="pa-2">{{ exp.fecha }}</td>
                                <td class="pa-2">{{ exp.nombreMvz }}</td>
                                <td class="pa-2">{{ exp.motivoServicio }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-textarea
                              :loading="isLoading"
                              label="Pruebas de diagnóstico"
                              v-model="expediente.pruebasDiagnostico"
                              rows="3"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-textarea
                              :loading="isLoading"
                              label="Diagnóstico presuntivo"
                              v-model="expediente.diagnosticoPresuntivo"
                              rows="3"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-textarea
                              :loading="isLoading"
                              label="Plan"
                              v-model="expediente.plan"
                              rows="3"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-textarea
                              :loading="isLoading"
                              label="Resumen de hospitalización"
                              v-model="expediente.resumenHospitalizacion"
                              rows="3"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="expedientesFiltrados"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.nombreCliente`]="{ item }">
            <NombreCliente :cliente="{ ...item, nombre: item.nombreCliente }" />
          </template>
          <template v-slot:[`item.detalles`]="{ item }">
            <v-btn icon color="info" @click="viewDetails(item)">
              <v-icon small>mdi-text-box</v-icon>
            </v-btn>
            <v-btn icon color="info" @click="editItem(item)">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon color="danger" @click="deleteItem(item)">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="indigo white--text my-6" rounded @click="initialize">
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import NombreCliente from "./Clientes/NombreCliente.vue";
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "Expedientes",
  components: {
    NombreCliente
  },
  data: () => ({
    isLoading: false,
    dialog: false,
    search: "",
    tipoRequest: "",
    desde: "",
    hasta: "",
    valid: false,
    isOnlyReadable: false,
    empleados: [],
    expedientesFiltrados: [],
    expediente: {
      anamnesis: ""
    },
    formData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigoInterno: "",
      rfc: "",
      codigoPostal: "",
      direccion: "",
      departamento: "",
      cargo: ""
    },
    emptyData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigosInternos: [],
      rfc: "",
      codigoPostal: "",
      direccion: "",
      departamento: "",
      cargo: ""
    },
    headers: [
      { text: "Cliente", value: "nombreCliente" },
      { text: "id Cliente", value: "idCliente" },
      { text: "Mascota", value: "nombreMascota" },
      { text: "MVZ", value: "nombreMvz" },
      { text: "fecha", value: "fecha" },
      { text: "Acciones", value: "detalles", align: "right" }
    ],
    examenFisico: [
      { text: "FC", attr: "fc" },
      { text: "Pulso", attr: "pulso" },
      { text: "PR", attr: "pr" },
      { text: "%DH", attr: "dh" },
      { text: "PA", attr: "pa" },
      { text: "MM", attr: "mm" },
      { text: "FR", attr: "fr" },
      { text: "RT", attr: "rt" },
      { text: "LN", attr: "ln" },
      { text: "PYP", attr: "pyp" },
      { text: "TLLC", attr: "tllc" },
      { text: "CP", attr: "cp" },
      { text: "RD", attr: "rd" },
      { text: "TEMP", attr: "temp" },
      { text: "Edo. mental", attr: "edoMental" }
    ],
    expedientes: [],
    otrosExpedientes: []
  }),
  created() {
    this.initialize();
  },

  computed: {
    ...mapState("clientes", ["clientes"]),
    ...mapState("mascotas", ["mascotas"]),
    mascotasFiltradas() {
      return this.mascotas.filter(
        mascota => mascota.idPropietario == this.expediente.idCliente
      );
    }
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "expedientes",
        search: val
      });
    },
    dialog: function(val) {
      if (val) this.getOtrosExpedientes();
    }
  },
  methods: {
    initialize() {
      this.fetchExpedientes();
      this.fetchEmpleados();
      this.fetchClientes();
      this.fetchMascotas();
      this.search = this.$store.state.searchParams["expedientes"];
    },
    handleSearchInput(e) {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchClientes(e.target.value);
      }, 500);
    },
    changeTo(item) {
      this.isLoading = true;
      let timer = Math.random();
      setTimeout(() => {
        this.isLoading = false;
        this.editItem(item);
      }, 200 + timer * 1000);
    },

    async getOtrosExpedientes() {
      this.otrosExpedientes = this.expedientes.filter(
        expediente => expediente.idMascota == this.expediente.idMascota
      );
    },
    async fetchClientes(idCliente = null) {
      this.isLoading = true;
      if (idCliente) await this.$store.dispatch("clientes/getById", idCliente);
      else await this.$store.dispatch("clientes/getIniciales");
      this.isLoading = false;
    },
    async fetchSearchClientes(search = null) {
      this.isLoading = true;
      if (search) await this.$store.dispatch("clientes/getBySearch", search);
      else await this.$store.dispatch("clientes/getIniciales");
      this.isLoading = false;
    },
    async fetchMascotas(idCliente = null) {
      this.isLoading = true;
      if (idCliente)
        await this.$store.dispatch("mascotas/getByOwnerId", idCliente);
      else await this.$store.dispatch("mascotas/getIniciales");
      this.isLoading = false;
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllMvz",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") this.empleados = respuesta.data.rows;
      this.isLoading = false;
    },
    async fetchExpedientes() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "expedientes.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.expedientes = respuesta.data.rows;
        this.filterDates();

        this.$route.params.new ? this.setItem() : null;
      }
      this.isLoading = false;
    },
    async send() {
      this.isLoading = true;
      let tipo = "insert";
      let msg = "Expediente agregado";

      const incompletedFields = this.examenFisico.filter(analito => {
        if (!this.expediente[`${analito.attr}`]) {
          analito.hasError = true;
          return true;
        }
      });
      if (incompletedFields.length) {
        incompletedFields.forEach(field => {
          this.$toasted.error(`${field.text} no tiene un valor.`, {
            position: "bottom-right",
            duration: 5000
          });
        });
        this.isLoading = false;
        return;
      }

      if (this.expediente.id !== 0) {
        tipo = "update";
        msg = "Expediente actualizado";
      }
      let myUrl = process.env.VUE_APP_API_URL + "expedientes.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: tipo,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.expediente]
      });
      if (respuesta.data.status == "Ok") {
        this.isLoading = false;

        this.$toasted.success(msg, {
          position: "bottom-right",
          duration: 5000
        });
        this.dialog = false;
        this.fetchExpedientes();
      }
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "expedientes",
        item: {},
        callback: this.fetchExpedientes
      });

      this.$store.commit("openModal", { modal: "expedientes", open: true });
    },
    async setItem() {
      let myExpediente = this.expedientes.find(
        expediente => expediente.idMascota == this.$route.params.idMascota
      );
      if (this.$route.params.new) {
        this.expediente = {
          id: 0,
          idCliente: this.$store.state.routeObj.idPropietario,
          idMascota: this.$store.state.routeObj.idMascota,
          idMvz: this.$store.state.routeObj.idEmpleado,
          servicio: this.$store.state.routeObj.nombreServicio,
          tipoServicio: this.$store.state.routeObj.tipoServicio,
          motivoServicio: this.$store.state.routeObj.motivoServicio,
          fecha: this.$store.state.routeObj.fecha,
          hora: this.$store.state.routeObj.hora
        };
      } else {
        if (myExpediente == undefined) return;
        this.expediente = { ...myExpediente };
      }
      this.fetchClientes(this.$store.state.routeObj.idPropietario);
      this.fetchMascotas(this.$store.state.routeObj.idPropietario);
      this.dialog = true;
    },
    async editItem(myItem) {
      /* this.$store.commit("setItemModal", {
        modal: "expedientes",
        item: { ...myItem },
        callback: this.fetchExpedientes
      });
      this.$store.commit("openModal", { modal: "expedientes", open: true }); */
      this.isOnlyReadable = false;
      this.expediente = { ...myItem };
      this.fetchClientes(myItem.idCliente);
      this.fetchMascotas(myItem.idCliente);
      this.dialog = true;
    },
    async viewDetails(myItem) {
      this.isOnlyReadable = true;
      this.expediente = { ...myItem };
      this.fetchClientes(myItem.idCliente);
      this.fetchMascotas(myItem.idCliente);
      this.dialog = true;
    },
    resetExpediente() {
      for (let key in this.expediente) {
        this.expediente[key] = "";
        this.expediente.id = 0;
      }
    },
    async deleteItem(item) {
      if (
        !confirm(
          `¿Realmente deseas eliminar el expediente ${item.nombreMascota}?`
        )
      ) {
        return;
      }
      let myUrl = process.env.VUE_APP_API_URL + "expedientes.php";
      let response = await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: item.id
        //callback: this.fetchExpedientes
      });
      if (response.data.status == "Ok") {
        this.$toasted.success("Se eliminó exitosamente el expediente", {
          position: "bottom-right",
          duration: 5000
        });
        this.fetchExpedientes();
      }
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },
    filterDates() {
      let data = [...this.expedientes];
      let inputStartDate = this.desde;
      let inputEndDate = this.hasta;

      if (!inputStartDate && !inputEndDate) {
        return (this.expedientesFiltrados = data);
      } else if (!inputStartDate) {
        inputStartDate = moment().subtract(100, "years");
      } else if (!inputEndDate) {
        inputEndDate = moment().add(100, "years");
      }

      const startDate = moment(inputStartDate);
      const endDate = moment(inputEndDate);

      this.expedientesFiltrados = data.filter(item => {
        const itemDate = moment(item.fecha);
        return (
          itemDate.isSameOrAfter(startDate) && itemDate.isSameOrBefore(endDate)
        );
      });
    }
  }
};
</script>
