<style>
.camposEditables {
  min-width: 240px;
}
</style>
<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="3">
              <h1>Registros de Estética</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="black"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="7">
              <div>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left"></th>
                        <th
                          class="text-left"
                          v-for="item in Object.keys(contadoresEstilistas)"
                          :key="item"
                        >
                          {{ item }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Baños</td>
                        <td
                          v-for="item in Object.keys(contadoresEstilistas)"
                          :key="item + contadoresEstilistas[item].banios"
                        >
                          {{ contadoresEstilistas[item].banios }}
                        </td>
                      </tr>
                      <tr>
                        <td>Cortes</td>
                        <td
                          v-for="item in Object.keys(contadoresEstilistas)"
                          :key="item + contadoresEstilistas[item].cortes"
                        >
                          {{ contadoresEstilistas[item].cortes }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
            <v-col cols="12" sm="2">
              <v-btn
                elevation="2"
                small
                color="black white--text "
                @click="addItem()"
                >Agregar registro</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-simple-table :items="registros" fixed-header dense height="70vh">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Id del día</th>
                <th class="text-left">Hora de registro</th>
                <th class="text-left">Propietario</th>
                <th class="text-left">Teléfono</th>
                <th class="text-left">Mascota</th>
                <th class="text-left">Específico</th>
                <th class="text-left">Servicios</th>
                <th class="text-left">Adicionales</th>
                <th class="text-left">SAD</th>
                <th class="text-left">Hora de entrega</th>
                <th class="text-left">Status</th>
                <th class="text-left">Acciones</th>
                <!-- 
                <th class="text-left">Costo</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, ind) in serviciosEsteticaSorteados"
                :key="ind"
                :class="getRowColor(item)"
              >
                <td>
                  {{ item.idCount }}
                </td>
                <td>
                  {{
                    timeConvert({
                      time: item.horaRegistro.slice(-8),
                      seconds: false
                    })
                  }}
                </td>
                <td>
                  <NombreCliente
                    :cliente="{
                      nombre: item.nombreCliente,
                      codigosInternos: item.codigosInternosCliente
                    }"
                  />
                </td>
                <td>{{ item.clienteTel }}</td>
                <td>
                  <NombreMascota
                    :mascota="{
                      nombre: item.nombre,
                      codigosInternos: item.codigosInternosMascota
                    }"
                  />
                </td>
                <td>{{ item.raza }}</td>
                <td>
                  <span
                    v-for="servicio in item.conceptos.filter(
                      concepto =>
                        concepto.idSubcategoria == '5' ||
                        concepto.idSubcategoria == '6'
                    )"
                    :key="servicio.id"
                  >
                    {{ servicio.nombre }} <br />
                  </span>
                </td>
                <td>
                  <span
                    v-for="servicio in item.conceptos.filter(
                      concepto => concepto.idSubcategoria == '7'
                    )"
                    :key="servicio.id"
                  >
                    {{ servicio.nombre }} <br />
                  </span>
                </td>
                <td>
                  <span
                    v-for="servicio in item.conceptos.filter(
                      concepto => concepto.idSubcategoria == '8'
                    )"
                    :key="servicio.id"
                  >
                    {{ servicio.nombre }} <br />
                  </span>
                </td>
                <td>
                  {{
                    timeConvert({
                      time: item.horaEntrega.slice(-8),
                      seconds: false
                    })
                  }}
                </td>
                <td>
                  <v-select
                    v-model="item.status"
                    :items="statusArray"
                    @change="actualizarStatus(item)"
                  />
                </td>
                <td style="width: 110px">
                  <v-btn icon color="blue darken-1" @click="editItem(item)">
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                  <v-btn icon color="danger" @click="deleteItem(item)">
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </td>
                <!-- <td>none</td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings, ampmConvert } from "../tools/proStrings";
import NombreCliente from "./Clientes/NombreCliente.vue";
import NombreMascota from "./Mascotas/NombreMascota.vue";
export default {
  name: "RegistrosEstética",
  components: { NombreCliente, NombreMascota },
  data: () => ({
    search: "",
    isLoading: false,
    tipoRequest: "",
    valid: false,
    registros: [],
    contBanos: [],
    contCortes: [],
    contadoresEstilistas: {},
    statusArray: [
      "Cancelado",
      "En Recolecta",
      "Recolectado",
      "En Servicio",
      "En Espera",
      "Atendido",
      "En Entrega",
      "Entregado",
      "Finalizado"
    ],
    servicios: ["Baño", "Baño y cepillado", "Limpieza dental"]
  }),
  created() {
    this.initialize();
  },
  computed: {
    serviciosEsteticaSorteados() {
      let registrosNoEnEspera = [...this.registros].filter(
        item => item.status != "En Espera"
      );
      let registrosEnEspera = [...this.registros].filter(
        item => item.status == "En Espera"
      );

      let registrosSorteados = registrosNoEnEspera.concat(registrosEnEspera);

      registrosSorteados = registrosSorteados.filter(servicio => {
        if (this.search == "" || !this.search) return true;
        let searchToLower = this.search.toLowerCase();
        return (
          servicio.nombre.toLowerCase().includes(searchToLower) ||
          servicio.nombreCliente.toLowerCase().includes(searchToLower) ||
          servicio.raza.toLowerCase().includes(searchToLower)
        );
      });

      registrosSorteados.sort(
        (a, b) => Number(b.lastUpdateNumeric) - Number(a.lastUpdateNumeric)
      );

      return registrosSorteados;
    }
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "servicioEstetica",
        search: val
      });
    }
  },
  methods: {
    initialize() {
      this.fetchServiciosEstetica();
    },
    timeConvert(time) {
      return ampmConvert(time);
    },
    async fetchServiciosEstetica() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllToday",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.registros = respuesta.data.rows;
        this.isLoading = false;
        this.contarRegistrosPorEstilista(this.serviciosEsteticaSorteados);
        this.contarTotalesSemanales();
      }
    },

    async actualizarStatus(item) {
      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update minor",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Status actualizado", {
          position: "bottom-right",
          duration: 5000
        });

        this.contarRegistrosPorEstilista(this.serviciosEsteticaSorteados);
      } else {
        this.$toasted.error("Ocurrió un problema", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "servicioEstetica",
        item: {},
        callback: this.fetchServiciosEstetica
      });

      this.$store.commit("openModal", {
        modal: "servicioEstetica",
        open: true
      });
    },
    async editItem(myItem) {
      myItem.idsServicios = myItem.conceptos
        .map(itm => {
          if (itm.idSubcategoria == "5" || itm.idSubcategoria == "6")
            return itm.idProductoServicio;
        })
        .filter(item => item);
      myItem.idsAdicionales = myItem.conceptos
        .map(itm => {
          if (itm.idSubcategoria == "7") return itm.idProductoServicio;
        })
        .filter(item => item);
      myItem.idsSAD = myItem.conceptos
        .map(itm => {
          if (itm.idSubcategoria == "8") return itm.idProductoServicio;
        })
        .filter(item => item);

      this.$store.commit("setItemModal", {
        modal: "servicioEstetica",
        item: { ...myItem },
        callback: this.fetchServiciosEstetica
      });
      this.$store.commit("openModal", {
        modal: "servicioEstetica",
        item: { ...myItem },
        open: true
      });
    },
    async deleteItem(item) {
      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      try {
        let response = await axios.post(myUrl, {
          request: 1,
          tipo: "delete",
          token: localStorage.token,
          geo: localStorage.geo,
          items: [item]
        });

        if (response.data.status == "Ok") {
          this.fetchServiciosEstetica();
        }
      } catch (error) {
        console.log("Error en la solcilitud: " + error);
      }
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },

    codigosInternos(cods) {
      if (cods?.length && cods?.length > 1) {
        try {
          const parsedCods = JSON.parse(cods);

          if (Array.isArray(parsedCods)) {
            return [...parsedCods];
          }
          return [];
        } catch (error) {
          return [];
        }
      }
      return [];
    },

    async contarRegistrosPorEstilista(registrosDeEsteticaSorteados) {
      const resultados = {};
      for (const servicio of registrosDeEsteticaSorteados) {
        const nombreEstilista = servicio.nombreEmpleado || "No asignado";

        if (!resultados[nombreEstilista]) {
          resultados[nombreEstilista] = { banios: 0, cortes: 0 };
        }
        if (servicio.status == "Cancelado") continue;
        for (const concepto of servicio.conceptos) {
          if (!concepto.nombre) continue;
          if (concepto.nombre.toLowerCase().includes("baño")) {
            resultados[nombreEstilista].banios++;
          }
          if (
            concepto.nombre.toLowerCase().includes("corte") ||
            concepto.nombre.toLowerCase().includes("estetica") ||
            concepto.nombre.toLowerCase().includes("estética") ||
            concepto.nombre.toLowerCase().includes("rapad")
          ) {
            resultados[nombreEstilista].cortes++;
          }
        }
      }

      const keyTotalDiario = "Total diario";
      resultados[keyTotalDiario] = { banios: 0, cortes: 0 };
      Object.keys(resultados).forEach(estilista => {
        if (estilista != keyTotalDiario) {
          resultados[keyTotalDiario].banios += resultados[estilista].banios;
          resultados[keyTotalDiario].cortes += resultados[estilista].cortes;
        }
      });
      this.contadoresEstilistas = resultados;
      this.contarTotalesSemanales();
    },
    async contarTotalesSemanales() {
      const keyTotalSemanal = "Total semanal";
      this.contadoresEstilistas[keyTotalSemanal] = {
        banios: this.contadoresEstilistas[keyTotalSemanal]?.banios || 0,
        cortes: this.contadoresEstilistas[keyTotalSemanal]?.cortes || 0
      };
      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getServiciosSemanales",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.contadoresEstilistas[keyTotalSemanal].banios =
          respuesta.data.banios;
        this.contadoresEstilistas[keyTotalSemanal].cortes =
          respuesta.data.cortes;
        this.contadoresEstilistas = { ...this.contadoresEstilistas };
      }
    },
    getRowColor({ isFirstTime, status }) {
      if (
        status.toLowerCase() == "en entrega" ||
        status.toLowerCase() == "recolectado" ||
        status.toLowerCase() == "entregado"
      )
        return "infoBlink";
      else if (status.toLowerCase() == "finalizado") return "successBlink";
      else if (isFirstTime == "1") return "goldenBlink";
      return null;
    }
  }
};
</script>
