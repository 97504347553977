import moment from "moment/moment";
import { getAllEmpleados } from "../axios-calls/empleados";

export default {
  namespaced: true,
  state: {
    empleados: [],
    lastGet: "none"
  },
  mutations: {
    refreshCache(state) {
      state.lastGet = "none";
    },
    setLastGet(state) {
      state.lastGet = moment();
    },
    setEmpleados(state, empleados) {
      state.empleados = empleados;
    }
  },
  actions: {
    async getEmpleados({ commit, state }) {
      if (moment().diff(state.lastGet, "minutes") < 5) {
        return state.empleados;
      } else {
        let empleados = await getAllEmpleados();
        commit("setLastGet");
        commit("setEmpleados", empleados);
        return empleados;
      }
    }
  },
  modules: {}
};
