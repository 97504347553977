export function quimAutotable(quimica) {
  let analitos = [];

  if (
    quimica.grupoMascota == "Canino" ||
    quimica.grupoMascota == "Perros" ||
    quimica.grupoMascota == "Canes" ||
    quimica.grupoMascota == "canino" ||
    quimica.grupoMascota == "perros" ||
    quimica.grupoMascota == "canes"
  ) {
    analitos = [
      {
        analito: "Albumina g/l",
        resultado: quimica.albumina,
        rangoMin: 26,
        rangoMax: 40,
        observaciones: `${
          parseFloat(quimica.albumina) < 26
            ? "Bajo"
            : parseFloat(quimica.albumina) > 40
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "Proteínas g/l",
        resultado: quimica.proteinasTotales,
        rangoMin: 60,
        rangoMax: 82,
        observaciones: `${
          parseFloat(quimica.proteinasTotales) < 60
            ? "Bajo"
            : parseFloat(quimica.albumina) > 82
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "Glucosa mmol/l",
        resultado: quimica.glucosa,
        rangoMin: 3.9,
        rangoMax: 6.1,
        observaciones: `${
          parseFloat(quimica.glucosa) < 3.9
            ? "Bajo"
            : parseFloat(quimica.glucosa) > 6.1
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "ALP U/l",
        resultado: quimica.alp,
        rangoMin: 23,
        rangoMax: 212,
        observaciones: `${
          parseFloat(quimica.alp) < 23
            ? "Bajo"
            : parseFloat(quimica.alp) > 212
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "ALT U/l",
        resultado: quimica.alt,
        rangoMin: 8,
        rangoMax: 48,
        observaciones: `${
          parseFloat(quimica.alt) < 8
            ? "Bajo"
            : parseFloat(quimica.alt) > 48
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Bilirubina total mmol/l",
        resultado: quimica.bilirubinaTotal,
        rangoMin: 3.4,
        rangoMax: 5.2,
        observaciones: `${
          parseFloat(quimica.bilirubinaTotal) < 3.4
            ? "Bajo"
            : parseFloat(quimica.bilirubinaTotal) > 5.2
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Amilasa U/l",
        resultado: quimica.amilasa,
        rangoMin: 220,
        rangoMax: 1700,
        observaciones: `${
          parseFloat(quimica.amilasa) < 220
            ? "Bajo"
            : parseFloat(quimica.amilasa) > 1700
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Urea mmol/l",
        resultado: quimica.urea,
        rangoMin: 3.3,
        rangoMax: 8.7,
        observaciones: `${
          parseFloat(quimica.urea) < 3.3
            ? "Bajo"
            : parseFloat(quimica.urea) > 8.7
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Creatinina mmol/l",
        resultado: quimica.creatinina,
        rangoMin: 50,
        rangoMax: 130,
        observaciones: `${
          parseFloat(quimica.creatinina) < 50
            ? "Bajo"
            : parseFloat(quimica.creatinina) > 130
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Calcio mmol/l",
        resultado: quimica.calcio,
        rangoMin: 2,
        rangoMax: 3,
        observaciones: `${
          parseFloat(quimica.calcio) < 2
            ? "Bajo"
            : parseFloat(quimica.calcio) > 3
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Fósforo mmol/l",
        resultado: quimica.fosforo,
        rangoMin: 0.8,
        rangoMax: 1.6,
        observaciones: `${
          parseFloat(quimica.fosforo) < 0.8
            ? "Bajo"
            : parseFloat(quimica.fosforo) > 1.6
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Sodio mmol/l",
        resultado: quimica.sodio,
        rangoMin: 140,
        rangoMax: 155,
        observaciones: `${
          parseFloat(quimica.sodio) < 140
            ? "Bajo"
            : parseFloat(quimica.sodio) > 155
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Potasio mmol/l",
        resultado: quimica.potasio,
        rangoMin: 3.6,
        rangoMax: 5.8,
        observaciones: `${
          parseFloat(quimica.potasio) < 3.6
            ? "Bajo"
            : parseFloat(quimica.potasio) > 5.8
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Globulina g/l",
        resultado: quimica.globulina,
        rangoMin: 25,
        rangoMax: 45,
        observaciones: `${
          parseFloat(quimica.globulina) < 25
            ? "Bajo"
            : parseFloat(quimica.globulina) > 45
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Sodio/potasio #",
        resultado: quimica.relacionSodioPotasio,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `${
          parseFloat(quimica.relacionSodioPotasio) < 0
            ? "Bajo"
            : parseFloat(quimica.relacionSodioPotasio) > 0
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Albumina/Globulinas #",
        resultado: quimica.albuminaGlobulinas,
        rangoMin: 0.7,
        rangoMax: 1.3,
        observaciones: `${
          parseFloat(quimica.albuminaGlobulinas) < 0.7
            ? "Bajo"
            : parseFloat(quimica.albuminaGlobulinas) > 1.3
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Lipemia +",
        resultado: quimica.lipemia,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `${
          parseFloat(quimica.lipemia) < 0
            ? "Bajo"
            : parseFloat(quimica.lipemia) > 0
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Hemólisis +",
        resultado: quimica.hemolisis,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `${
          parseFloat(quimica.hemolisis) < 0
            ? "Bajo"
            : parseFloat(quimica.hemolisis) > 0
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Ictericia +",
        resultado: quimica.ictericia,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `${
          parseFloat(quimica.ictericia) < 0
            ? "Bajo"
            : parseFloat(quimica.ictericia) > 0
            ? "Alto"
            : "-"
        }`
      }

      // EL ARCHIVO SE LLAMA imprimirHojaDiagnostico.php
    ];
  }

  if (
    quimica.grupoMascota == "Felino" ||
    quimica.grupoMascota == "Felinos" ||
    quimica.grupoMascota == "Gatos" ||
    quimica.grupoMascota == "felinos" ||
    quimica.grupoMascota == "felino" ||
    quimica.grupoMascota == "gatos"
  ) {
    analitos = [
      {
        analito: "Albumina g/l",
        resultado: quimica.albumina,
        rangoMin: 25,
        rangoMax: 40,
        observaciones: `${
          parseFloat(quimica.albumina) < 25
            ? "Bajo"
            : parseFloat(quimica.albumina) > 40
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "Proteínas totales g/l",
        resultado: quimica.proteinasTotales,
        rangoMin: 28,
        rangoMax: 55,
        observaciones: `${
          parseFloat(quimica.proteinasTotales) < 28
            ? "Bajo"
            : parseFloat(quimica.albumina) > 55
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "Glucosa mmol/l",
        resultado: quimica.glucosa,
        rangoMin: 3.5,
        rangoMax: 6,
        observaciones: `${
          parseFloat(quimica.glucosa) < 3.5
            ? "Bajo"
            : parseFloat(quimica.glucosa) > 6
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "ALP U/l",
        resultado: quimica.alp,
        rangoMin: 14,
        rangoMax: 111,
        observaciones: `${
          parseFloat(quimica.alp) < 14
            ? "Bajo"
            : parseFloat(quimica.alp) > 111
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "ALT U/l",
        resultado: quimica.alt,
        rangoMin: 0,
        rangoMax: 70,
        observaciones: `${
          parseFloat(quimica.alt) < 0
            ? "Bajo"
            : parseFloat(quimica.alt) > 70
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Bilirubina total mmol/l",
        resultado: quimica.bilirubinaTotal,
        rangoMin: 0,
        rangoMax: 3.4,
        observaciones: `${
          parseFloat(quimica.bilirubinaTotal) < 0
            ? "Bajo"
            : parseFloat(quimica.bilirubinaTotal) > 3.4
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Amilasa U/l",
        resultado: quimica.amilasa,
        rangoMin: 0,
        rangoMax: 1800,
        observaciones: `${
          parseFloat(quimica.amilasa) < 0
            ? "Bajo"
            : parseFloat(quimica.amilasa) > 1800
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Urea mmol/l",
        resultado: quimica.urea,
        rangoMin: 5,
        rangoMax: 11,
        observaciones: `${
          parseFloat(quimica.urea) < 5
            ? "Bajo"
            : parseFloat(quimica.urea) > 11
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Creatinina mmol/l",
        resultado: quimica.creatinina,
        rangoMin: 40,
        rangoMax: 170,
        observaciones: `${
          parseFloat(quimica.creatinina) < 40
            ? "Bajo"
            : parseFloat(quimica.creatinina) > 170
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Calcio mmol/l",
        resultado: quimica.calcio,
        rangoMin: 2.3,
        rangoMax: 3,
        observaciones: `${
          parseFloat(quimica.calcio) < 2
            ? "Bajo"
            : parseFloat(quimica.calcio) > 3
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Fósforo mmol/l",
        resultado: quimica.fosforo,
        rangoMin: 0.8,
        rangoMax: 1.9,
        observaciones: `${
          parseFloat(quimica.fosforo) < 0.8
            ? "Bajo"
            : parseFloat(quimica.fosforo) > 1.9
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Sodio mmol/l",
        resultado: quimica.sodio,
        rangoMin: 140,
        rangoMax: 158,
        observaciones: `${
          parseFloat(quimica.sodio) < 140
            ? "Bajo"
            : parseFloat(quimica.sodio) > 158
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Potasio mmol/l",
        resultado: quimica.potasio,
        rangoMin: 3,
        rangoMax: 4.8,
        observaciones: `${
          parseFloat(quimica.potasio) < 3.6
            ? "Bajo"
            : parseFloat(quimica.potasio) > 5.8
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Globulina g/l",
        resultado: quimica.globulina,
        rangoMin: 28,
        rangoMax: 55,
        observaciones: `${
          parseFloat(quimica.globulina) < 28
            ? "Bajo"
            : parseFloat(quimica.globulina) > 55
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Sodio/potasio #",
        resultado: quimica.relacionSodioPotasio,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `${
          parseFloat(quimica.relacionSodioPotasio) < 0
            ? "Bajo"
            : parseFloat(quimica.relacionSodioPotasio) > 0
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Albumina/Globulinas #",
        resultado: quimica.albuminaGlobulinas,
        rangoMin: 0.6,
        rangoMax: 1.2,
        observaciones: `${
          parseFloat(quimica.albuminaGlobulinas) < 0.6
            ? "Bajo"
            : parseFloat(quimica.albuminaGlobulinas) > 1.2
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Lipemia +",
        resultado: quimica.lipemia,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `${
          parseFloat(quimica.lipemia) < 0
            ? "Bajo"
            : parseFloat(quimica.lipemia) > 0
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Hemólisis +",
        resultado: quimica.hemolisis,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `${
          parseFloat(quimica.hemolisis) < 0
            ? "Bajo"
            : parseFloat(quimica.hemolisis) > 0
            ? "Alto"
            : "-"
        }`
      },

      {
        analito: "Ictericia +",
        resultado: quimica.ictericia,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `${
          parseFloat(quimica.ictericia) < 0
            ? "Bajo"
            : parseFloat(quimica.ictericia) > 0
            ? "Alto"
            : "-"
        }`
      }

      // EL ARCHIVO SE LLAMA imprimirHojaDiagnostico.php
    ];
  }

  if (quimica.grupoMascota == null) {
    analitos = [
      {
        analito: "Albumina g/l",
        resultado: quimica.albumina,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "Proteínas totales g/l",
        resultado: quimica.proteinasTotales,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "Glucosa mmol/l",
        resultado: quimica.glucosa,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "ALP U/l",
        resultado: quimica.alp,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "ALT U/l",
        resultado: quimica.alt,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Bilirubina total mmol/l",
        resultado: quimica.bilirubinaTotal,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Amilasa U/l",
        resultado: quimica.amilasa,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Urea mmol/l",
        resultado: quimica.urea,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Creatinina mmol/l",
        resultado: quimica.creatinina,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Calcio mmol/l",
        resultado: quimica.calcio,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Fósforo mmol/l",
        resultado: quimica.fosforo,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Sodio mmol/l",
        resultado: quimica.sodio,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Potasio mmol/l",
        resultado: quimica.potasio,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Globulina g/l",
        resultado: quimica.globulina,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Sodio/potasio #",
        resultado: quimica.relacionSodioPotasio,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Albumina/Globulinas #",
        resultado: quimica.albuminaGlobulinas,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Lipemia +",
        resultado: quimica.lipemia,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Hemólisis +",
        resultado: quimica.hemolisis,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },

      {
        analito: "Ictericia +",
        resultado: quimica.ictericia,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      }

      // EL ARCHIVO SE LLAMA imprimirHojaDiagnostico.php
    ];
  }
  return analitos;
}

export function bioAutotable(biometria) {
  typeof biometria;
  let analitos = [];

  if (
    biometria.grupoMascota == "Canino" ||
    biometria.grupoMascota == "Perros" ||
    biometria.grupoMascota == "Canes" ||
    biometria.grupoMascota == "canino" ||
    biometria.grupoMascota == "perros" ||
    biometria.grupoMascota == "canes"
  ) {
    analitos = [
      {
        analito: "WBC X 10^9/L",
        resultado: biometria.wbcP,
        rangoMin: 6,
        rangoMax: 17,
        observaciones: `${
          parseFloat(biometria.wbcP) < 6
            ? "Bajo"
            : parseFloat(biometria.wbcP) > 17
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "LYM %",
        resultado: biometria.lymP,
        rangoMin: 12,
        rangoMax: 30,
        observaciones: `${
          parseFloat(biometria.lymP) < 12
            ? "Bajo"
            : parseFloat(biometria.lymP) > 30
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "MID %",
        resultado: biometria.midP,
        rangoMin: 3,
        rangoMax: 15,
        observaciones: `${
          parseFloat(biometria.midP) < 3
            ? "Bajo"
            : parseFloat(biometria.midP) > 15
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "NEU %",
        resultado: biometria.neuP,
        rangoMin: 60,
        rangoMax: 70,
        observaciones: `${
          parseFloat(biometria.neuP) < 60
            ? "Bajo"
            : parseFloat(biometria.neuP) > 70
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "EOS %",
        resultado: biometria.eosP,
        rangoMin: 1,
        rangoMax: 5,
        observaciones: `${
          parseFloat(biometria.eosP) < 1
            ? "Bajo"
            : parseFloat(biometria.eosP) > 5
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "BASO %",
        resultado: biometria.basoP,
        rangoMin: 0,
        rangoMax: 1,
        observaciones: `${
          parseFloat(biometria.basoP) < 0
            ? "Bajo"
            : parseFloat(biometria.basoP) > 1
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "LYM # X10^9/L",
        resultado: biometria.lymN,
        rangoMin: 0.8,
        rangoMax: 5.1,
        observaciones: `${
          parseFloat(biometria.lymN) < 0.8
            ? "Bajo"
            : parseFloat(biometria.lymN) > 5.1
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "MID # X10^9/L",
        resultado: biometria.midN,
        rangoMin: 0.1,
        rangoMax: 3.4,
        observaciones: `${
          parseFloat(biometria.midN) < 0.1
            ? "Bajo"
            : parseFloat(biometria.midN) > 3.4
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "NEU # X 10^9/L",
        resultado: biometria.neuN,
        rangoMin: 2.5,
        rangoMax: 12,
        observaciones: `${
          parseFloat(biometria.neuN) < 2.5
            ? "Bajo"
            : parseFloat(biometria.neuN) > 12
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "EOS # X 10^9/L",
        resultado: biometria.eosN,
        rangoMin: 0.05,
        rangoMax: 1.5,
        observaciones: `${
          parseFloat(biometria.eosN) < 0.05
            ? "Bajo"
            : parseFloat(biometria.eosN) > 1.5
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "BASO # X 10^9/L",
        resultado: biometria.basoN,
        rangoMin: 0,
        rangoMax: 1.2,
        observaciones: `${
          parseFloat(biometria.basoN) < 0
            ? "Bajo"
            : parseFloat(biometria.basoN) > 1.2
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "RBC X10^9/L",
        resultado: biometria.rbc,
        rangoMin: 5.5,
        rangoMax: 8.5,
        observaciones: `${
          parseFloat(biometria.rbc) < 5.5
            ? "Bajo"
            : parseFloat(biometria.rbc) > 8.5
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "HGB g/dL",
        resultado: biometria.hgb,
        rangoMin: 11,
        rangoMax: 19,
        observaciones: `${
          parseFloat(biometria.hgb) < 11
            ? "Bajo"
            : parseFloat(biometria.hgb) > 19
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "HCT %",
        resultado: biometria.hctP,
        rangoMin: 37,
        rangoMax: 55,
        observaciones: `${
          parseFloat(biometria.hctP) < 37
            ? "Bajo"
            : parseFloat(biometria.hctP) > 55
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "MCV fL",
        resultado: biometria.mcv,
        rangoMin: 62,
        rangoMax: 72,
        observaciones: `${
          parseFloat(biometria.mcv) < 62
            ? "Bajo"
            : parseFloat(biometria.mcv) > 72
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "MCH pg",
        resultado: biometria.mch,
        rangoMin: 20,
        rangoMax: 25,
        observaciones: `${
          parseFloat(biometria.mch) < 20
            ? "Bajo"
            : parseFloat(biometria.mch) > 25
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "MCHC g/dL",
        resultado: biometria.mchc,
        rangoMin: 30,
        rangoMax: 38,
        observaciones: `${
          parseFloat(biometria.mchc) < 30
            ? "Bajo"
            : parseFloat(biometria.mchc) > 38
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "RDW_CV %",
        resultado: biometria.rdwcvP,
        rangoMin: 11,
        rangoMax: 15.5,
        observaciones: `${
          parseFloat(biometria.rdwcvP) < 11
            ? "Bajo"
            : parseFloat(biometria.rdwcvP) > 15.5
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "RDW_SD fL",
        resultado: biometria.rdwsd,
        rangoMin: 0,
        rangoMax: 26.5,
        observaciones: `${
          parseFloat(biometria.rdwsd) < 0
            ? "Bajo"
            : parseFloat(biometria.rdwsd) > 26.5
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "PLT X 10^9/L",
        resultado: biometria.plt,
        rangoMin: 250,
        rangoMax: 500,
        observaciones: `${
          parseFloat(biometria.plt) < 250
            ? "Bajo"
            : parseFloat(biometria.plt) > 500
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "MPV fL",
        resultado: biometria.mpv,
        rangoMin: 7,
        rangoMax: 12,
        observaciones: `${
          parseFloat(biometria.mpv) < 7
            ? "Bajo"
            : parseFloat(biometria.mpv) > 12
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "PDW fL",
        resultado: biometria.pdw,
        rangoMin: 0,
        rangoMax: 11.3,
        observaciones: `${
          parseFloat(biometria.pdw) < 0
            ? "Bajo"
            : parseFloat(biometria.pdw) > 11.3
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "PCT %",
        resultado: biometria.pctP,
        rangoMin: 0,
        rangoMax: 0.29,
        observaciones: `${
          parseFloat(biometria.pctP) < 0
            ? "Bajo"
            : parseFloat(biometria.pctP) > 0.29
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "P_LCR %",
        resultado: biometria.plcrP,
        rangoMin: 0,
        rangoMax: 15.7,
        observaciones: `${
          parseFloat(biometria.plcrP) < 0
            ? "Bajo"
            : parseFloat(biometria.plcrP) > 15.7
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "P_LCC X 10^9/L",
        resultado: biometria.plcc,
        rangoMin: 0,
        rangoMax: 37,
        observaciones: `${
          parseFloat(biometria.plcc) < 0
            ? "Bajo"
            : parseFloat(biometria.plcc) > 37
            ? "Alto"
            : "-"
        }`
      }
      // EL ARCHIVO SE LLAMA imprimirHojaDiagnostico.php
    ];
  }
  /* 
































*/
  if (
    biometria.grupoMascota == "Felino" ||
    biometria.grupoMascota == "Felinos" ||
    biometria.grupoMascota == "Gatos" ||
    biometria.grupoMascota == "felinos" ||
    biometria.grupoMascota == "felino" ||
    biometria.grupoMascota == "gatos"
  ) {
    analitos = [
      {
        analito: "WBC X 10^9/L",
        resultado: biometria.wbcP,
        rangoMin: 5.5,
        rangoMax: 19.5,
        observaciones: `${
          parseFloat(biometria.wbcP) < 5.5
            ? "Bajo"
            : parseFloat(biometria.wbcP) > 19.5
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "LYM %",
        resultado: biometria.lymP,
        rangoMin: 20,
        rangoMax: 55,
        observaciones: `${
          parseFloat(biometria.lymP) < 20
            ? "Bajo"
            : parseFloat(biometria.lymP) > 55
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "MID %",
        resultado: biometria.midP,
        rangoMin: 2.5,
        rangoMax: 18.5,
        observaciones: `${
          parseFloat(biometria.midP) < 2.5
            ? "Bajo"
            : parseFloat(biometria.midP) > 18.5
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "NEU %",
        resultado: biometria.neuP,
        rangoMin: 45,
        rangoMax: 75,
        observaciones: `${
          parseFloat(biometria.neuP) < 45
            ? "Bajo"
            : parseFloat(biometria.neuP) > 75
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "EOS %",
        resultado: biometria.eosP,
        rangoMin: 1,
        rangoMax: 6,
        observaciones: `${
          parseFloat(biometria.eosP) < 1
            ? "Bajo"
            : parseFloat(biometria.eosP) > 6
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "BASO %",
        resultado: biometria.basoP,
        rangoMin: 0,
        rangoMax: 1,
        observaciones: `${
          parseFloat(biometria.basoP) < 0
            ? "Bajo"
            : parseFloat(biometria.basoP) > 1
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "LYM # X10^9/L",
        resultado: biometria.lymN,
        rangoMin: 1.5,
        rangoMax: 7,
        observaciones: `${
          parseFloat(biometria.lymN) < 1.5
            ? "Bajo"
            : parseFloat(biometria.lymN) > 7
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "MID # X10^9/L",
        resultado: biometria.midN,
        rangoMin: 0.1,
        rangoMax: 4,
        observaciones: `${
          parseFloat(biometria.midN) < 0.1
            ? "Bajo"
            : parseFloat(biometria.midN) > 4
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "NEU # X 10^9/L",
        resultado: biometria.neuN,
        rangoMin: 2.5,
        rangoMax: 12,
        observaciones: `${
          parseFloat(biometria.neuN) < 2.5
            ? "Bajo"
            : parseFloat(biometria.neuN) > 12
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "EOS # X 10^9/L",
        resultado: biometria.eosN,
        rangoMin: 0.05,
        rangoMax: 1.5,
        observaciones: `${
          parseFloat(biometria.eosN) < 0.05
            ? "Bajo"
            : parseFloat(biometria.eosN) > 1.5
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "BASO # X 10^9/L",
        resultado: biometria.basoN,
        rangoMin: 0,
        rangoMax: 1.2,
        observaciones: `${
          parseFloat(biometria.basoN) < 0
            ? "Bajo"
            : parseFloat(biometria.basoN) > 1.2
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "RBC X10^9/L",
        resultado: biometria.rbc,
        rangoMin: 5,
        rangoMax: 10,
        observaciones: `${
          parseFloat(biometria.rbc) < 5
            ? "Bajo"
            : parseFloat(biometria.rbc) > 10
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "HGB g/dL",
        resultado: biometria.hgb,
        rangoMin: 8,
        rangoMax: 15,
        observaciones: `${
          parseFloat(biometria.hgb) < 8
            ? "Bajo"
            : parseFloat(biometria.hgb) > 15
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "HCT %",
        resultado: biometria.hctP,
        rangoMin: 24,
        rangoMax: 45,
        observaciones: `${
          parseFloat(biometria.hctP) < 24
            ? "Bajo"
            : parseFloat(biometria.hctP) > 45
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "MCV fL",
        resultado: biometria.mcv,
        rangoMin: 39,
        rangoMax: 55,
        observaciones: `${
          parseFloat(biometria.mcv) < 39
            ? "Bajo"
            : parseFloat(biometria.mcv) > 55
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "MCH pg",
        resultado: biometria.mch,
        rangoMin: 12.5,
        rangoMax: 17.5,
        observaciones: `${
          parseFloat(biometria.mch) < 12.5
            ? "Bajo"
            : parseFloat(biometria.mch) > 17.5
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "MCHC g/dL",
        resultado: biometria.mchc,
        rangoMin: 30,
        rangoMax: 36,
        observaciones: `${
          parseFloat(biometria.mchc) < 30
            ? "Bajo"
            : parseFloat(biometria.mchc) > 36
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "RDW_CV %",
        resultado: biometria.rdwcvP,
        rangoMin: 0.1,
        rangoMax: 99.9,
        observaciones: `${
          parseFloat(biometria.rdwcvP) < 0.1
            ? "Bajo"
            : parseFloat(biometria.rdwcvP) > 99.9
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "RDW_SD fL",
        resultado: biometria.rdwsd,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `${
          parseFloat(biometria.rdwsd) < 0
            ? "Bajo"
            : parseFloat(biometria.rdwsd) > 0
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "PLT X 10^9/L",
        resultado: biometria.plt,
        rangoMin: 250,
        rangoMax: 600,
        observaciones: `${
          parseFloat(biometria.plt) < 250
            ? "Bajo"
            : parseFloat(biometria.plt) > 600
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "MPV fL",
        resultado: biometria.mpv,
        rangoMin: 0.1,
        rangoMax: 12.3,
        observaciones: `${
          parseFloat(biometria.mpv) < 0.1
            ? "Bajo"
            : parseFloat(biometria.mpv) > 12.3
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "PDW fL",
        resultado: biometria.pdw,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `${
          parseFloat(biometria.pdw) < 0
            ? "Bajo"
            : parseFloat(biometria.pdw) > 0
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "PCT %",
        resultado: biometria.pctP,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `${
          parseFloat(biometria.pctP) < 0
            ? "Bajo"
            : parseFloat(biometria.pctP) > 0
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "P_LCR %",
        resultado: biometria.plcrP,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `${
          parseFloat(biometria.plcrP) < 0
            ? "Bajo"
            : parseFloat(biometria.plcrP) > 0
            ? "Alto"
            : "-"
        }`
      },
      {
        analito: "P_LCC X 10^9/L",
        resultado: biometria.plcc,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `${
          parseFloat(biometria.plcc) < 0
            ? "Bajo"
            : parseFloat(biometria.plcc) > 0
            ? "Alto"
            : "-"
        }`
      }

      // EL ARCHIVO SE LLAMA imprimirHojaDiagnostico.php
    ];
  }
  /* 
























































*/
  if (biometria.grupoMascota == null) {
    analitos = [
      {
        analito: "WBC X 10^9/L",
        resultado: biometria.wbcP,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "LYM %",
        resultado: biometria.lymP,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "MID %",
        resultado: biometria.midP,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "LYM # X10^9/L",
        resultado: biometria.lymN,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "MID # X10^9/L",
        resultado: biometria.midN,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "RBC X10^9/L",
        resultado: biometria.rbc,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "HGB g/dL",
        resultado: biometria.hgb,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "HCT %",
        resultado: biometria.hctP,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "MCV fL",
        resultado: biometria.mcv,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "MCH pg",
        resultado: biometria.mch,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "MCHC g/dL",
        resultado: biometria.mchc,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "RDW_CV %",
        resultado: biometria.rdwcvP,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "RDW_SD fL",
        resultado: biometria.rdwsd,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "PLT X 10^9/L",
        resultado: biometria.plt,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "MPV fL",
        resultado: biometria.mpv,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "PDW fL",
        resultado: biometria.pdw,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "PCT %",
        resultado: biometria.pctP,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "P_LCR %",
        resultado: biometria.plcrP,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "P_LCC X 10^9/L",
        resultado: biometria.plcc,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "NEU %",
        resultado: biometria.neuP,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "BASO %",
        resultado: biometria.basoP,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "EOS %",
        resultado: biometria.eosP,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "NEU # X 10^9/L",
        resultado: biometria.neuN,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "BASO # X 10^9/L",
        resultado: biometria.basoN,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      },
      {
        analito: "EOS # X 10^9/L",
        resultado: biometria.eosN,
        rangoMin: 0,
        rangoMax: 0,
        observaciones: `El grupo de ésta mascota debe ser "perros" o "gatos" para obtener mínimos y máximos`
      }
    ];
  }

  return analitos;
}
