var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Perfiles")]),_c('br'),_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","color":"teal","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[(false)?_c('v-btn',{attrs:{"elevation":"2","small":"","color":"teal white--text "},on:{"click":function($event){return _vm.addItem()}}},[_vm._v("Agregar perfil")]):_vm._e()],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.perfiles,"items-per-page":15,"search":_vm.search,"custom-filter":_vm.guessSearch,"sort-by":"id","sort-desc":true,"footer-props":{
          'items-per-page-text': 'Items por pagina',
          'items-per-page-options': [15, 25, 50, 100, 200, 500, -1]
        },"dense":""},scopedSlots:_vm._u([{key:"item.detalles",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function () {
                _vm.editItem(item);
              }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"teal my-6","rounded":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")])],1)]},proxy:true}],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},on:{"click:outside":function($event){_vm.dialogPermisos = false}},model:{value:(_vm.dialogPermisos),callback:function ($$v) {_vm.dialogPermisos=$$v},expression:"dialogPermisos"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('span',[_vm._v("Permisos de "+_vm._s(_vm.usuario.perfil))])]),_c('v-card-subtitle',[_vm._v(" Los cambios en los permisos se verán actualizados la siguiente vez que inicie sesión ")]),_c('v-card-text',[_c('v-container',[_c('v-card',{attrs:{"min-height":"50vh","flat":""}},[_c('v-data-table',{attrs:{"items":_vm.permisos,"headers":_vm.headersDialogTable,"items-per-page":7,"dense":""},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
              var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","color":"success","value":item},model:{value:(_vm.usuario.permisos),callback:function ($$v) {_vm.$set(_vm.usuario, "permisos", $$v)},expression:"usuario.permisos"}})]}}],null,true)})],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end align-end",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.send}},[_vm._v(" Enviar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogPermisos = false}}},[_vm._v("Cancelar")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }