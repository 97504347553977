<template>
  <v-dialog v-model="modalOpen" max-width="500">
    <v-card>
      <v-card-title>Realizar Pago</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Efectivo"
                type="number"
                v-model="pagos.efectivo"
                @dblclick="setRemainingValue('efectivo')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Tarjeta de Crédito"
                type="number"
                v-model="pagos.credito"
                @dblclick="setRemainingValue('credito')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Tarjeta de Débito"
                type="number"
                v-model="pagos.debito"
                @dblclick="setRemainingValue('debito')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Tarjeta Iz Credito"
                type="number"
                v-model="pagos.izCredito"
                @dblclick="setRemainingValue('izCredito')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Tarjeta Iz Debito"
                type="number"
                v-model="pagos.izDebito"
                @dblclick="setRemainingValue('izDebito')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                type="number"
                v-model="pagos.depEfectivo"
                label="Depósito en efectivo"
                value="0.00"
                prefix="$"
                @dblclick="setRemainingValue('depEfectivo')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Transferencia"
                type="number"
                v-model="pagos.transferencia"
                @dblclick="setRemainingValue('transferencia')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Puntos de Saldo"
                type="number"
                v-model="pagos.puntosSaldo"
                @dblclick="setRemainingValue('puntosSaldo')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              Total: <br />
              $ {{ totalPorPagar }}
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <div v-if="montoPagado < totalPorPagar">
                Por pagar: <br />
                $ {{ montoRestantePorPagar.toFixed(2) }}
              </div>
              <div v-else>
                Entrega de cambio: <br />
                $ {{ cambio.toFixed(2) }}
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="close"> Cancelar </v-btn>
        <v-btn text @click="send" :disabled="isSending">
          Listo
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    callback: {
      default: e => {
        return e;
      },
      required: false
    }
  },
  data: () => ({
    modalOpen: false,
    isSending: false,
  }),
  computed: {
    ...mapState({
      productosNuevaVenta: state => state.nuevaVenta.productosNuevaVenta,
      pagos: state => state.nuevaVenta.pagos
    }),
    totalPorPagar() {
      return Number(
        this.productosNuevaVenta
          .reduce(
            (total, producto) => Number(total) + Number(producto.total),
            0
          )
          .toFixed(2)
      );
    },
    montoPagado() {
      return (
        Number(this.pagos.efectivo) +
        Number(this.pagos.credito) +
        Number(this.pagos.debito) +
        Number(this.pagos.izCredito) +
        Number(this.pagos.izDebito) +
        Number(this.pagos.transferencia) +
        Number(this.pagos.puntosSaldo) +
        Number(this.pagos.depEfectivo)
      );
    },
    cambio() {
      const cambio = Number(
        (Number(this.montoPagado) - Number(this.totalPorPagar)).toFixed(2)
      );
      if (cambio > 0) return cambio;
      return 0;
    },
    montoRestantePorPagar() {
      const restante = this.totalPorPagar - this.montoPagado;
      return restante;
    }
  },
  methods: {
    ...mapMutations("nuevaVenta", ["setPagos", "resetPagos"]),
    open() {
      this.modalOpen = true;
      this.isSending = false;
    },
    close() {
      this.modalOpen = false;
    },
    setRemainingValue(tipoPago) {
      let total =
        Number(this.pagos.efectivo) +
        Number(this.pagos.credito) +
        Number(this.pagos.debito) +
        Number(this.pagos.izCredito) +
        Number(this.pagos.izDebito) +
        Number(this.pagos.transferencia) +
        Number(this.pagos.puntosSaldo) +
        Number(this.pagos.depEfectivo);
      if (total <= this.totalPorPagar) {
        this.pagos[tipoPago] = Number(this.totalPorPagar - total).toFixed(2);
      }
    },
    send() {
      this.isSending = true;
      const total =
        Number(this.pagos.efectivo) +
        Number(this.pagos.credito) +
        Number(this.pagos.debito) +
        Number(this.pagos.izCredito) +
        Number(this.pagos.izDebito) +
        Number(this.pagos.transferencia) +
        Number(this.pagos.puntosSaldo) +
        Number(this.pagos.depEfectivo);
      const isDevolution = total < 0;
      if (this.pagos.efectivo >= this.cambio || isDevolution) {
        this.pagos.cambio = -this.cambio;
        this.pagos.efectivo -= this.cambio;
      } else {
        this.$toasted.error(
          "El cliente debe aportar una cantidad mayor al cambio en efectivo para poder entregarle cambio",
          {
            position: "bottom-right",
            duration: 5000
          }
        );
        return;
      }
      this.setPagos(this.pagos);
      this.callback();
    }
  }
};
</script>
