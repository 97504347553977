var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar producto","loading":_vm.isLoading},on:{"keyup":_vm.handleSearchInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-list',[_c('v-virtual-scroll',{attrs:{"height":"500","item-height":"64","items":_vm.productosFiltrados},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.id,staticClass:"py-2",staticStyle:{"cursor":"grab"},attrs:{"draggable":true},on:{"dragstart":function (ev) {
              _vm.drag(ev, item.id);
            },"dblclick":function($event){return _vm.dblClickAdd(item)}}},[_c('v-list-item-avatar',{class:((_vm.randomColor({
                caseBase: item.nombre.toUpperCase()[2]
              })) + " white--text")},[_vm._v(" "+_vm._s(item.nombre.toUpperCase()[0])+_vm._s(item.nombre.toUpperCase()[1])+_vm._s(item.nombre.toUpperCase()[2])+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{class:item.stock <= item.minimo
                  ? item.stock <= 0
                    ? 'dangerBlink'
                    : 'warningBlink'
                  : item.stock >= item.maximo
                  ? 'successBlink'
                  : null,staticStyle:{"white-space":"normal"}},[_vm._v(" $"+_vm._s(item.precio)+" - "+_vm._s(item.nombre)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.compuesto)+" "+_vm._s(item.codigo)+" ")])],1),_c('br'),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""}},[_c('span',{class:item.stock <= item.minimo
                    ? item.stock <= 0
                      ? 'dangerBlink'
                      : 'warningBlink'
                    : item.stock >= item.maximo
                    ? 'successBlink'
                    : null},[_vm._v(" "+_vm._s(item.controlStock == "Si" ? item.stock : "")+" ")])])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }