<template>
  <v-row>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5" v-if="!isEditing">Agregar Gasto</span>
          <span class="text-h5" v-else>Editar Gasto</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Concepto"
                  v-model="gasto.concepto"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :items="proveedores"
                  label="Proveedor"
                  v-model="gasto.idProveedor"
                  item-text="nombre"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :items="empleados"
                  label="Empleados"
                  item-text="nombre"
                  item-value="id"
                  v-model="gasto.idEmpleado"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="No. Documento" v-model="gasto.noDocumento">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :items="['Gastos', 'Gastos (compra)']"
                  label="Tipo de Egreso"
                  v-model="gasto.tipoEgreso"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  type="number"
                  label="Monto"
                  v-model="gasto.monto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :items="[
                    'Estética',
                    'Clínica',
                    'Tienda',
                    'Proporcional',
                    'Proveedores'
                  ]"
                  label="Departamento"
                  v-model="gasto.departamento"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :items="[
                    { nombre: 'Cheque', valor: 'cheque' },
                    { nombre: 'Efectivo', valor: 'efectivo' },
                    { nombre: 'Tarjeta de crédito', valor: 'credito' },
                    { nombre: 'Tarjeta de débito', valor: 'debito' },
                    { nombre: 'Transferencia', valor: 'transferencia' }
                  ]"
                  item-text="nombre"
                  item-value="valor"
                  label="Forma de pago"
                  v-model="gasto.tipo"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text v-if="!isEditing" @click="send(0)">
            <span>Agregar</span>
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            v-if="isEditing"
            @click="send(gasto.id)"
          >
            <span>Editar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Gastos</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="red darken-3"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                elevation="2"
                small
                color="red darken-3 white--text "
                @click="
                  () => {
                    isEditing = false;
                    dialog = !dialog;
                  }
                "
                >Agregar gasto</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="gastosFiltrados"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon @click="editItem(item)">
              <v-icon small class="info--text"> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon @click="deleteItem(item)" color="danger">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="red white--text darken-3 my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
export default {
  name: "Gastos",
  data: () => ({
    search: "",
    isLoading: false,
    isEditing: false,
    isAdministrativos: false,
    dialog: false,
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Hora", value: "hora" },
      { text: "Proveedor", value: "nombreProveedor" },
      { text: "Concepto", value: "concepto" },
      { text: "Empleado", value: "nombreEmpleado" },
      { text: "No. Documento", value: "noDocumento" },
      { text: "Monto", value: "monto" },
      { text: "Forma de pago", value: "tipo" },
      { text: "Tipo de egreso", value: "tipoEgreso" },
      { text: "Departamento", value: "departamento" },
      { text: "Acciones", value: "acciones", align: "right" }
    ],
    gasto: {
      idCliente: 0,
      idVenta: 0,
      concepto: "",
      idProveedor: null,
      idEmpleado: null,
      noDocumento: "",
      tipoEgreso: "",
      monto: 0,
      departamento: "",
      tipo: ""
    },
    gastos: [],
    gastosAdmin: [],
    gastosEmpleados: [],
    proveedores: [],
    empleados: []
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "gastos",
        search: val
      });
    }
  },
  computed: {
    gastosFiltrados() {
      return this.gastos;
      /* if (this.isAdministrativos) {
        return this.gastosAdmin;
      } else {
        return this.gastosEmpleados;
      } */
    },
    isAdmin() {
      return localStorage.getItem("id") == "29";
    }
  },
  methods: {
    initialize() {
      this.fetchGastos();
      this.fetchProveedores();
      this.fetchEmpleados();
      this.search = this.$store.state.searchParams["gastos"];
    },
    async fetchGastos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "gastos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.gastos = respuesta.data.rows;
        this.gastos.forEach(gasto => {
          gasto.fecha = gasto.fechaHora.substring(0, 10);
          gasto.hora = gasto.fechaHora.substring(11);
          gasto.monto = parseFloat(gasto.monto).toFixed(2);
        });
        this.gastosAdmin = this.gastos.filter(
          gasto => gasto.idEmpleado == "29"
        );
        this.gastosEmpleados = this.gastos.filter(
          gasto => gasto.idEmpleado !== "29"
        );
      }
      this.isLoading = false;
    },
    async fetchProveedores() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "proveedores.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.proveedores = respuesta.data.rows;
      this.isLoading = false;
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.empleados = respuesta.data.rows;
      this.isLoading = false;
    },
    async editItem(myItem) {
      this.isEditing = true;
      this.gasto = { ...myItem };
      this.dialog = true;
    },
    async send(idEditing) {
      this.isLoading = true;
      let tipo = "insert";
      let msg = "Gasto agregado";
      let myUrl = process.env.VUE_APP_API_URL + "gastos.php";
      if (idEditing != "0") {
        tipo = "update";
        msg = "Gasto Actualizado";
      }
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: tipo,
        id: idEditing,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.gasto]
      });
      if (respuesta.data.status == "Ok") {
        this.isLoading = false;
        this.isEditing = false;
        this.$toasted.success(msg, {
          position: "bottom-right",
          duration: 5000
        });
        this.gasto = {
          ...this.gasto,
          concepto: "",
          idProveedor: null,
          idEmpleado: null,
          noDocumento: "",
          tipoEgreso: "",
          monto: 0,
          departamento: "",
          tipo: ""
        };
        this.dialog = false;
        this.fetchGastos();
      }
    },
    async deleteItem(item) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "gastos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Gasto eliminado", {
          position: "bottom-right",
          duration: 5000
        });
        this.fetchGastos();
        this.isLoading = false;
      }
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    }
  }
};
</script>
