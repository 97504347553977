export default function getRandomColor(options) {
  let { caseBase, lights, darks } = {
    caseBase: null,
    lights: 0,
    darks: 0,
    ...options
  };
  let myColors = [
    "red",
    "pink",
    "purple",
    "deep-purple",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "light-green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "green",
    "teal",
    "grey"
  ];
  let myIndex = myColors.length - 1;
  let lumen = "";
  if (caseBase) {
    myIndex = caseBase.charCodeAt(0) % (myColors.length - 1);
  } else {
    myIndex = Math.floor(Math.random() * (myColors.length - 1));
  }
  if (lights > 0) {
    lights > 5 ? (lights = 5) : null;
    lumen = " lighten-" + Math.ceil(Math.random() * lights);
  } else if (darks > 0) {
    darks > 5 ? (darks = 5) : null;
    lumen = " darken-" + Math.ceil(Math.random() * darks);
  }

  return `${myColors[myIndex]}${lumen}`;
}

export function internalCodeColor(options) {
  let { tipo, codigoInterno, todos } = {
    tipo: "clientes",
    codigoInterno: 1,
    todos: "false",
    ...options
  };
  if (tipo != "clientes" && tipo != "mascotas" && tipo != "empleados") {
    return null;
  }
  let myCodes = {
    clientes: [
      "orange darken-1",
      "yellow lighten-1",
      "green darken-3",
      "light-blue darken-4",
      "red darken-4",
      "grey darken-1",
      "grey darken-4"
    ],
    empleados: ["light-blue", "cyan", "light-green", "lime", "yellow", "amber"],

    mascotas: [
      "red darken-4",
      "yellow lighten-1",
      "grey darken-4",
      "deep-purple darken-1",
      "lime",
      "orange darken-1",
      "brown darken-3"
    ]
  };
  let myNameColors = {
    clientes: [
      {
        id: 1,
        nombre: "Naranja",
        code: "orange darken-1",
        letter: ""
      },
      {
        id: 2,
        nombre: "Amarillo",
        code: "yellow lighten-1",
        letter: ""
      },
      {
        id: 3,
        nombre: "Verde",
        code: "green darken-3",
        letter: ""
      },
      {
        id: 4,
        nombre: "Azul",
        code: "light-blue darken-4",
        letter: ""
      },
      {
        id: 5,
        nombre: "Rojo",
        code: "red darken-4",
        letter: ""
      }
    ],
    empleados: [
      {
        id: 1,
        nombre: "Azul",
        code: "light-blue",
        letter: ""
      },
      {
        id: 2,
        nombre: "Cyan",
        code: "cyan",
        letter: ""
      },
      {
        id: 3,
        nombre: "Verde",
        code: "light-green",
        letter: ""
      },
      {
        id: 4,
        nombre: "Lima",
        code: "lime",
        letter: ""
      },
      {
        id: 5,
        nombre: "Amarillo",
        code: "yellow",
        letter: ""
      },
      {
        id: 6,
        nombre: "Ambar",
        code: "amber",
        letter: ""
      }
    ],

    mascotas: [
      {
        id: 1,
        nombre: "Rojo",
        code: "red darken-4",
        letter: "A"
      },
      {
        id: 2,
        nombre: "Amarillo",
        code: "yellow lighten-1",
        letter: "B"
      },
      {
        id: 3,
        nombre: "Negro",
        code: "black",
        letter: "C"
      },
      {
        id: 4,
        nombre: "Morado",
        code: "deep-purple darken-1",
        letter: "E"
      },
      {
        id: 4,
        nombre: "Verde",
        code: "light-green",
        letter: "I"
      },
      {
        id: 4,
        nombre: "Naranja",
        code: "orange darken-1",
        letter: "D"
      },
      {
        id: 5,
        nombre: "Café",
        code: "brown darken-3",
        letter: "K"
      }
    ]
  };

  //evalua si el tipo es menor a 1 o mayor a array.length
  if (todos == "true") {
    var arrayObjectsColors = [];
    var objectColor = {};
    for (var i = 0; i < myNameColors[tipo].length; i++) {
      objectColor = {
        id: i,
        nombre: myNameColors[tipo][i].nombre,
        letter: myNameColors[tipo][i].letter
      };
      arrayObjectsColors.push(objectColor);
    }
    return arrayObjectsColors;
  } else {
    let selector = 0;
    myNameColors[tipo].forEach(element => {
      if (element.id == codigoInterno) {
        selector = element.id - 1;
      } else if (element.letter == codigoInterno) {
        selector = element.id - 1;
      }
    });

    return myCodes[tipo][selector];
  }
}

export function getCodigoDeColorDeCliente(index) {
  const coloresDeClientes = {
    1: "orange darken-1",
    2: "yellow lighten-1",
    3: "green darken-3",
    4: "light-blue darken-4",
    5: "red darken-4",
    6: "grey darken-1",
    7: "grey darken-4"
  };

  return coloresDeClientes[index];
}

export function getCodigoDeColorDeMascota(index) {
  const coloresDeMascotas = {
    A: "red darken-4",
    B: "yellow lighten-1",
    C: "grey darken-4",
    D: "orange darken-1",
    E: "red darken-4",
    I: "green darken-3",
    K: "brown lighten-1",
    0: "grey lighten-1"
  };

  return coloresDeMascotas[index];
}
