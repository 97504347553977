<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1 v-if="!isCerradas">Compras por pagar</h1>
              <h1 v-else>Compras cerradas</h1>
              <br />
              <v-text-field
                v-model="search"
                @keydown="handleSearchInput"
                label="Buscar"
                append-icon="mdi-magnify"
                color="red darken-3"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
              <v-select
                :items="['Todas', 'Por pagar', 'Cerradas']"
                v-model="viendo"
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="comprasFiltradas"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          fixed-header
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn color="info" icon :to="`NuevaCompra/${item.id}`">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn color="danger" icon @click="deleteCompra(item)">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="red white--text darken-3 my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
export default {
  name: "compras",
  data: () => ({
    search: "",
    isLoading: false,
    isCerradas: false,
    debouncing: null,
    viendo: "Todas",
    headers: [
      { text: "Id", value: "id" },
      { text: "Proveedor", value: "nombreProveedor" },
      { text: "Status", value: "status" },
      { text: "Fecha", value: "fechaHora" },
      { text: "No. Documento", value: "noDocumento" },
      { text: "Tipo", value: "tipo" },
      { text: "Total", value: "montoTotal" },
      { text: "Acciones", value: "acciones", align: "right" }
    ],
    compras: [],
    comprasCerradas: [],
    comprasPorPagar: []
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "compras",
        search: val
      });
    }
  },
  computed: {
    comprasFiltradas() {
      if (this.viendo == "Todas") {
        return this.compras;
      }
      let status_param = "por pagar";
      if (this.viendo == "Cerradas") {
        status_param = "cerrada";
      }
      return this.compras.filter(compra => compra.status == status_param);
    },
    isAdmin() {
      return localStorage.getItem("id") == "29";
    }
  },
  methods: {
    initialize() {
      this.fetchCompras();
      this.search = this.$store.state.searchParams["compras"];
    },
    handleSearchInput() {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchCompras(this.search);
      }, 500);
    },
    async fetchCompras(search = null) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "compras.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: search ? "getBySearch" : "getAll",
        search,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") this.compras = respuesta.data.rows;

      this.isLoading = false;
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },
    async deleteCompra(compra) {
      if (!confirm(`¿Seguro que deseas eliminar la compra con id ${compra.id}`))
        return;
      if (compra.id == 0 || compra.id == "0") {
        this.$toasted.error(`Selecciona una compra válida para eliminar`, {
          position: "bottom-right",
          duration: 5000
        });
        return;
      }
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "compras.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        id: compra.id,
        token: localStorage.token,
        geo: localStorage.geo,
        compra
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Compra eliminada, id:" + compra.id, {
          position: "bottom-right",
          duration: 2000
        });
        this.usuario = "";
        this.password = "";
        this.fetchCompras();
        this.$store.commit("productos/refreshCache");
        this.deleteDialog = false;
      }
      this.isLoading = false;
    }
  }
};
</script>
