<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Perfiles</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="teal"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                elevation="2"
                small
                color="teal white--text "
                @click="addItem()"
                v-if="false"
                >Agregar perfil</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="perfiles"
          :items-per-page="15"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [15, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.detalles`]="{ item }">
            <v-btn
              icon
              @click="
                () => {
                  editItem(item);
                }
              "
              color="info"
            >
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon disabled>
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="teal my-6" rounded @click="initialize">
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-dialog
      v-model="dialogPermisos"
      persistent
      @click:outside="dialogPermisos = false"
      max-width="900px"
    >
      <v-card :loading="isLoading">
        <v-card-title>
          <span>Permisos de {{ usuario.perfil }}</span>
        </v-card-title>
        <v-card-subtitle>
          Los cambios en los permisos se verán actualizados la siguiente vez que
          inicie sesión
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-card min-height="50vh" flat>
              <v-data-table
                :items="permisos"
                :headers="headersDialogTable"
                :items-per-page="7"
                dense
              >
                <template v-slot:[`item.checkbox`]="{ item }">
                  <v-checkbox
                    v-model="usuario.permisos"
                    dense
                    color="success"
                    :value="item"
                  ></v-checkbox>
                </template>
              </v-data-table>
            </v-card>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                class="d-flex justify-end align-end"
                ><v-btn color="blue darken-1" text @click="send">
                  Enviar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogPermisos = false"
                  >Cancelar</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
export default {
  name: "perfiles",
  data: () => ({
    search: "",
    isLoading: false,
    tipoRequest: "",
    valid: false,
    dialogPermisos: false,
    usuario: {},
    formData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigoInterno: "",
      rfc: "",
      codigoPostal: "",
      direccion: "",
      departamento: "",
      cargo: ""
    },
    emptyData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigosInternos: [],
      rfc: "",
      codigoPostal: "",
      direccion: "",
      departamento: "",
      cargo: ""
    },
    headerProps: {
      sortByText: "Ordenar por"
    },
    headers: [
      { text: "Perfiles", value: "perfil" },
      { text: "Acciones", value: "detalles", width: "12%" }
    ],
    headersDialogTable: [
      { text: "ID", value: "id" },
      { text: "Permiso", value: "permiso" },
      { text: "Tipo", value: "tipo" },
      { text: "Activado", value: "checkbox", width: "12%" }
    ],
    perfiles: [],
    permisos: [],
    permisosActivos: []
  }),
  created() {
    this.initialize();
  },
  watch: {
    search(val) {
      this.$store.commit("setSearchParams", { name: "perfiles", search: val });
    }
  },
  methods: {
    initialize() {
      this.fetchperfiles();
      this.search = this.$store.state.searchParams["perfiles"];
    },
    editItem(item) {
      this.usuario = item;
      this.dialogPermisos = true;
    },
    async fetchperfiles() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "perfiles.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllPerfilesAndPermisos",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.perfiles = respuesta.data.rows;
        this.permisos = respuesta.data.permisos;
        this.isLoading = false;
      }
    },
    async send() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "perfiles.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "updatePermissons",
        perfil: this.usuario,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.isLoading = false;
        this.dialogPermisos = false;
        this.$toasted.success("Permisos actualizados", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    }
  }
};
</script>
