<template>
  <v-dialog
    v-model="isOpen"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="isOpen = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="printTicket"> Imprimir </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-title>
        <v-container>
          <h3>Fondo fijo {{ fondoFijo.id }} - {{ fondoFijo.status }}</h3>
        </v-container>
      </v-card-title>
      <v-card-text>
        <v-container>
          Fondo fijo: $ {{ montoFondoFijo }} | Fondo utilizado: $
          {{ totalEgresos }} | Fondo restante: $
          {{ montoFondoFijo - totalEgresos }}
          <v-data-table :headers="headers" :items="gastos"> </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  data: () => ({
    isOpen: false,
    montoFondoFijo: 0,
    totalEgresos: 0,
    fondoFijo: {
      id: 0,
      status: "apertura"
    },
    gastos: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Hora", value: "hora" },
      { text: "Concepto", value: "concepto" },
      { text: "Proveedor", value: "nombreProveedor" },
      { text: "No. documento", value: "noDocumento" },
      { text: "Monto", value: "monto" },
      { text: "Forma de pago", value: "tipo" },
      { text: "Tipo de egreso", value: "tipoEgreso" },
      { text: "Departamento", value: "departamento" },
      { text: "Empleado", value: "nombreEmpleado" }
    ]
  }),
  methods: {
    async setFondoFijo(myFondoFijo) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "fondosFijos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        myFondoFijo,
        id: myFondoFijo.id,
        tipo: "getGastosInFondoFijo",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.fondoFijo = { ...myFondoFijo };
        this.gastos = respuesta.data.gastos;
        this.montoFondoFijo = myFondoFijo.montoApertura;
        let totalEgresos = 0;
        respuesta.data.gastos.forEach(gasto => {
          totalEgresos += Number(gasto.monto);
        });
        this.totalEgresos = totalEgresos;
      }
      this.isLoading = false;
    },
    toggleModal() {
      this.isOpen = !this.isOpen;
    },

    printTicket() {
      //let pdfName = "test";
      let lineHeight = 3;
      let totalWidth = 160;
      let actualLine = 10;
      let sangria = 4;
      //let space = 5;
      var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [totalWidth, 207]
      });
      doc.setFont("helvetica");
      doc.setFontSize(7);

      let img = new Image();
      img.src = require("@/assets/zhLogo.png");
      doc.addImage(img, "png", totalWidth - 30 - sangria, sangria, 30, 10);
      actualLine += lineHeight * 3;
      doc.text(`Fondo Fijo `, sangria, actualLine);
      actualLine += lineHeight;
      doc.text(
        `Responsable: ${this.fondoFijo.empleadoCierre}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.line(sangria, actualLine, totalWidth - sangria, actualLine);
      actualLine += lineHeight + lineHeight / 2;
      doc.text(
        `Fecha: ${moment(this.fondoFijo?.fecha, "yyyy-MM-DD")
          .locale("es-mx")
          .format("DD-MM-yyyy")}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.text(
        `Hora: ${moment(this.fondoFijo?.hora, "hh:mm:ss")
          .locale("es-mx")
          .format("h:mm a")}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.text(
        `Fondo inicial: ${this.fondoFijo.montoApertura}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.text(`Total egresos: ${this.totalEgresos}`, sangria, actualLine);
      actualLine += lineHeight;
      doc.text(
        `Fondo restante: ${this.fondoFijo.montoApertura - this.totalEgresos}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.line(sangria, actualLine, totalWidth - sangria, actualLine);
      actualLine += lineHeight / 2;
      //let tableHeight = 0;
      let myMontos = [...this.gastos];
      doc.autoTable({
        styles: {
          fontSize: "7",
          fillColor: "#fff",
          textColor: "#111",
          cellPadding: 1
        },
        theme: "striped",
        startY: actualLine,
        margin: {
          top: sangria,
          left: sangria,
          right: sangria,
          bottom: sangria
        },
        body: myMontos,
        columns: [
          {
            header: "id",
            dataKey: "id"
          },
          {
            header: "Fecha",
            dataKey: "fecha"
          },
          {
            header: "Hora",
            dataKey: "hora"
          },
          {
            header: "Concepto",
            dataKey: "concepto"
          },
          {
            header: "Proveedor",
            dataKey: "nombreProveedor"
          },
          {
            header: "No. documento",
            dataKey: "noDocumento"
          },
          {
            header: "Monto",
            dataKey: "monto"
          },
          {
            header: "Forma de pago",
            dataKey: "tipo"
          },
          {
            header: "Tipo de egreso",
            dataKey: "tipoEgreso"
          },
          {
            header: "Departamento",
            dataKey: "departamento"
          },
          {
            header: "Empleado",
            dataKey: "nombreEmpleado"
          }
        ],
        didDrawCell: () => {
          //data tableHeight = data.cursor.y + data.row.height;
        }
      }); //doc.save(pdfName + ".pdf");
      doc.autoPrint();
      //This is a key for printing
      doc.output("dataurlnewwindow");
    }
  }
};
</script>
