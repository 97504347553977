var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Reglas de Comisiones")]),_c('br'),_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","color":"orange darken-4","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.empleados,"item-value":"id","item-text":"nombre","placeholder":"Selecciona un empleado para ver comisiones"},model:{value:(_vm.idEmpleado),callback:function ($$v) {_vm.idEmpleado=$$v},expression:"idEmpleado"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{attrs:{"elevation":"2","small":"","color":"orange darken-4 white--text "},on:{"click":function($event){return _vm.addItem()}}},[_vm._v("Agregar Comision")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.comisiones,"items-per-page":7,"search":_vm.search,"sort-by":"id","sort-desc":true,"header-props":_vm.headerProps,"footer-props":{
          'items-per-page-text': 'Items por pagina',
          'items-per-page-options': [10, 25, 50, 100, 250, 500, -1]
        },"dense":""},scopedSlots:_vm._u([{key:"item.detalles",fn:function(ref){
        var item = ref.item;
return [(false)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":"","color":"blue"}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',{attrs:{"small":"","color":"danger"}},[_vm._v(" mdi-delete ")])],1)]}},{key:"item.monto",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v("$ "+_vm._s(item.monto))])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"orange darken-4 my-6","rounded":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")])],1)]},proxy:true}],null,true)})],1)],1),_c('ModalComisiones')],1)}
var staticRenderFns = []

export { render, staticRenderFns }