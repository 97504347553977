var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Biometrías")]),_c('br'),_c('v-text-field',{attrs:{"loading":_vm.isLoading,"label":"Buscar","append-icon":"mdi-magnify","color":"indigo","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.simulateTab($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"2","small":"","color":"indigo white--text ","dark":""},on:{"click":function () {
                      _vm.dialog = true;
                      _vm.resetBiometriaHematica();
                    }}},'v-btn',attrs,false),on),[_vm._v(" Agregar biometriaHematica ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Biometría hemática")]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.biometriaHematica.id == 0)?_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.send}},[_vm._v(" Agregar ")]):_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.send}},[_vm._v(" Actualizar ")])],1)],1),_c('v-row',{staticClass:"container text-center",attrs:{"align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('h4',{staticClass:"title"},[_vm._v("Datos generales")]),_c('v-autocomplete',{attrs:{"loading":_vm.isLoading,"label":"Cliente","dense":"","items":_vm.clientes,"item-value":"id","item-text":"nombre"},on:{"keydown":_vm.handleClienteSearchInput,"focus":_vm.handleClienteSearchInput},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.tel1)}})],1)]}}]),model:{value:(_vm.biometriaHematica.idCliente),callback:function ($$v) {_vm.$set(_vm.biometriaHematica, "idCliente", $$v)},expression:"biometriaHematica.idCliente"}}),_c('v-select',{attrs:{"loading":_vm.isLoading,"label":"Mascota","dense":"","items":_vm.mascotasFiltradas,"item-value":"id","item-text":"nombre"},on:{"focus":function () {
                          _vm.fetchMascotas(_vm.biometriaHematica.idCliente);
                        }},model:{value:(_vm.biometriaHematica.idMascota),callback:function ($$v) {_vm.$set(_vm.biometriaHematica, "idMascota", $$v)},expression:"biometriaHematica.idMascota"}}),_c('v-select',{attrs:{"loading":_vm.isLoading,"label":"MVZ","dense":"","items":_vm.empleados,"item-value":"id","item-text":"nombre"},model:{value:(_vm.biometriaHematica.idMvz),callback:function ($$v) {_vm.$set(_vm.biometriaHematica, "idMvz", $$v)},expression:"biometriaHematica.idMvz"}}),_c('v-text-field',{attrs:{"loading":_vm.isLoading,"type":"date","label":"Fecha"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.simulateTab($event)}},model:{value:(_vm.biometriaHematica.fecha),callback:function ($$v) {_vm.$set(_vm.biometriaHematica, "fecha", $$v)},expression:"biometriaHematica.fecha"}}),_c('v-text-field',{attrs:{"loading":_vm.isLoading,"type":"time","label":"Hora"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.simulateTab($event)}},model:{value:(_vm.biometriaHematica.hora),callback:function ($$v) {_vm.$set(_vm.biometriaHematica, "hora", $$v)},expression:"biometriaHematica.hora"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h4',{staticClass:"title"},[_vm._v("Examen")]),_vm._l((_vm.examenFisico),function(examen,ind){return _c('div',{key:("examen" + ind)},[_c('v-text-field',{attrs:{"loading":_vm.isLoading,"type":"text","label":examen.text,"dense":"","error":examen.hasError},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (e) {
                            if (ind < _vm.examenFisico.length - 1) { _vm.simulateTab(e); }
                          })($event)},function () {
                            examen.hasError = false;
                          }]},model:{value:(_vm.biometriaHematica[("" + (examen.attr))]),callback:function ($$v) {_vm.$set(_vm.biometriaHematica, ("" + (examen.attr)), $$v)},expression:"biometriaHematica[`${examen.attr}`]"}})],1)})],2),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('h4',[_vm._v(" Otras biometrías de "+_vm._s(_vm.biometriaHematica.nombreMascota)+" ")]),_c('v-simple-table',{attrs:{"dense":"","fixed-header":"","height":"500px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"text-left"},[_c('th',{staticClass:"pa-2"},[_vm._v("Acciones")]),_c('th',{staticClass:"pa-2"},[_vm._v(" Fecha "),_c('br'),_vm._v(" año/mes/día ")]),_c('th',{staticClass:"pa-2"},[_vm._v("Médico")]),_c('th',{staticClass:"pa-2"},[_vm._v("Mascota")])])]),_c('tbody',_vm._l((_vm.otrasBiometriasHematicas),function(bio,ind){return _c('tr',{key:("examen" + ind),staticClass:"text-left"},[_c('td',[_c('v-btn',{staticClass:"pa-2",attrs:{"icon":"","small":"","color":"warning"},on:{"click":function($event){return _vm.changeTo(bio)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-folder ")])],1),_c('v-btn',{staticClass:"pa-2",attrs:{"icon":"","small":"","color":"info"},on:{"click":function($event){return _vm.printBiometria(bio)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file ")])],1)],1),_c('td',{staticClass:"pa-2"},[_vm._v(_vm._s(bio.fecha))]),_c('td',{staticClass:"pa-2"},[_vm._v(_vm._s(bio.nombreMvz))]),_c('td',{staticClass:"pa-2"},[_vm._v(_vm._s(bio.nombreMascota))])])}),0)]},proxy:true}])})],1)],1),_c('v-divider')],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.biometriasHematicas,"items-per-page":7,"search":_vm.search,"custom-filter":_vm.guessSearch,"sort-by":"id","sort-desc":true,"footer-props":{
          'items-per-page-text': 'Items por pagina',
          'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
        },"dense":""},scopedSlots:_vm._u([{key:"item.nombreCliente",fn:function(ref){
        var item = ref.item;
return [_c('NombreCliente',{attrs:{"cliente":Object.assign({}, item, {nombre: item.nombreCliente})}})]}},{key:"item.nombreMascota",fn:function(ref){
        var item = ref.item;
return [_c('NombreMascota',{attrs:{"mascota":Object.assign({}, item, {nombre: item.nombreMascota})}})]}},{key:"item.detalles",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.printBiometria(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file ")])],1),_c('v-btn',{attrs:{"disabled":"","icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"indigo white--text my-6","rounded":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")])],1)]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }