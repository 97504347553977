<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Subcategorías</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="pink"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                elevation="2"
                small
                color="pink  white--text "
                @click="addItem()"
                >Agregar subcategoria</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="subcategorias"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 250, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon @click="editItem(item)">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon @click="deleteItem(item)">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="pink white--text my-6" rounded @click="initialize">
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <br />
    </v-col>
    <modal-subcategorias />
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
import ModalSubcategorias from "./AgregarEditar/ModalSubcategorias";
export default {
  name: "Subcategorias",
  components: { ModalSubcategorias },
  data: () => ({
    search: "",
    isLoading: false,
    headers: [
      { text: "id", value: "id" },
      { text: "Nombre", value: "nombre" },
      { text: "Categoria", value: "nombreCategoria" },
      { text: "Acciones", value: "acciones", align: "right", width: "15%" }
    ],
    subcategorias: []
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "subcategorias",
        search: val
      });
    }
  },
  methods: {
    initialize() {
      this.fetchSubcategorias();
      this.search = this.$store.state.searchParams["subcategorias"];
    },
    async fetchSubcategorias() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "subcategorias.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.subcategorias = respuesta.data.rows;
      this.isLoading = false;
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "subcategorias",
        item: {},
        callback: this.fetchSubcategorias
      });

      this.$store.commit("openModal", { modal: "subcategorias", open: true });
    },
    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "subcategorias",
        item: { ...myItem },
        callback: this.fetchSubcategorias
      });
      this.$store.commit("openModal", { modal: "subcategorias", open: true });
    },
    async deleteItem(id) {
      let myUrl = process.env.VUE_APP_API_URL + "subcategorias.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id,
        callback: this.fetchSubcategorias
      });
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    }
  }
};
</script>
