<style scoped>
input {
  max-width: 65px;
  display: inline-block;
}
</style>

<template>
  <v-data-table
    :headers="headers"
    :items="productosNuevaCompra"
    :search="search"
    :items-per-page="-1"
    hide-default-footer
    dense
  >
    <template v-slot:item.cantidad="{ item }">
      <input
        type="number"
        :value="item.cantidad"
        @input="
          e => {
            setQuantity({ ...item, cantidad: Number(e.target.value) });
            calculateTotal({ ...item, cantidad: Number(e.target.value) });
          }
        "
      />
    </template>
    <template v-slot:item.precioCompraSinImpuesto="{ item }">
      <input
        type="number"
        :value="item.precioCompraSinImpuesto"
        @input="
          e => {
            setprecioCompraSinImpuesto({
              ...item,
              precioCompraSinImpuesto: e.target.value
            });
            calculateTotal({ ...item });
          }
        "
      />
    </template>
    <template v-slot:item.impuesto="{ item }"> {{ item.impuesto }} % </template>
    <template v-slot:item.descuentoPorcentaje="{ item }">
      <input
        type="number"
        :value="item.descuentoPorcentaje"
        @input="
          e => {
            item.descuentoPorcentaje = Number(e.target.value);
            updateDescuentoMonto(item);
            calculateTotal(item);
          }
        "
      />
      <span>%</span>
    </template>
    <template v-slot:item.descuentoMonto="{ item }">
      <input
        type="number"
        :value="item.descuentoMonto"
        @input="
          e => {
            item.descuentoMonto = Number(e.target.value);
            updateDescuentoPorcentaje(item);
            calculateTotal(item);
          }
        "
      />
    </template>
    <template v-slot:item.acciones="{ item }">
      <v-btn icon color="danger" small @click="removeProduct(item.uniqueId)">
        <v-icon small>mdi-eraser</v-icon>
      </v-btn>
    </template>
    <template v-slot:body.append>
      <tr>
        <td colspan="2"></td>
        <td>Subtotal</td>
        <td>{{ subtotal }}</td>
        <td>Descuento</td>
        <td>{{ descuento }}</td>
        <td>IVA</td>
        <td>{{ iva }}</td>
        <td>Total</td>
        <td>{{ total }}</td>
        <td colspan="1"></td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      search: "",
      editing: false,
      editedItem: {},
      headers: [
        {
          text: "Producto",
          value: "nombre"
        },
        {
          text: "Cantidad",
          value: "cantidad"
        },
        {
          text: "Precio de compra",
          value: "precioCompraSinImpuesto"
        },
        {
          text: "Stock Tienda",
          value: "stock"
        },
        {
          text: "Stock Almacen",
          value: "stockAlmacen"
        },
        {
          text: "Impuesto",
          value: "impuesto"
        },
        {
          text: "Descuento %",
          value: "descuentoPorcentaje"
        },
        {
          text: "Descuento Monto",
          value: "descuentoMonto"
        },
        {
          text: "Total antes de impuesto sin desc.",
          value: "totalAntesImpuesto"
        },
        {
          text: "Total antes de impuesto con descuento",
          value: "totalAntesImpuestoConDescuento"
        },
        {
          text: "Total con impuesto y descuento",
          value: "totalConImpuestoConDescuento"
        },
        {
          text: "Acciones",
          value: "acciones"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      productosNuevaCompra: state => state.nuevaCompra.productosNuevaCompra,
      cliente: state => state.nuevaCompra.cliente,
      totalesNuevaCompra: state => state.nuevaCompra.totalesNuevaCompra
    }),
    ...mapState({
      vendedores: state => state.empleados.empleados
    }),
    ...mapState("clientes", ["clientes"]),
    ...mapState("mascotas", ["mascotas"]),
    mascotasFiltradasPorCliente() {
      return (
        this.mascotas?.filter(
          mascota => mascota.idPropietario == this.cliente?.id
        ) || []
      );
    },
    descuento() {
      return this.productosNuevaCompra
        .reduce(
          (sum, producto) => sum + parseFloat(producto.descuentoMonto) || 0,
          0
        )
        .toFixed(2);
    },
    iva() {
      return this.productosNuevaCompra
        .reduce((sum, producto) => sum + parseFloat(producto.ivaMonto) || 0, 0)
        .toFixed(2);
    },
    subtotal() {
      return this.productosNuevaCompra
        .reduce(
          (sum, producto) => sum + parseFloat(producto.totalAntesImpuesto) || 0,
          0
        )
        .toFixed(2);
    },
    total() {
      return this.productosNuevaCompra
        .reduce(
          (sum, producto) =>
            sum + parseFloat(producto.totalConImpuestoConDescuento) || 0,
          0
        )
        .toFixed(2);
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapMutations("nuevaCompra", [
      "updateDescuentoMonto",
      "updateDescuentoPorcentaje",
      "setprecioCompraSinImpuesto",
      "setQuantity",
      "calculateTotal",
      "refreshMutation",
      "setCliente",
      "removeProduct"
    ]),
    ...mapActions("mascotas", ["getIniciales"]),
    ...mapActions("clientes", ["getClientes"]),
    async initialize() {
      await this.getIniciales();
    },

    codigosInternos(cods) {
      if (cods?.length && cods?.length > 1) {
        try {
          const parsedCods = JSON.parse(cods);

          if (Array.isArray(parsedCods)) {
            return [...parsedCods];
          }
          return [];
        } catch (error) {
          return [];
        }
      }
      return [];
    }
  }
};
</script>
