var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.onlyDots != 'true')?_c('div',[(_vm.item.imagen != '')?_c('div',{staticClass:"container-badge-container"},[_c('v-avatar',{staticClass:"white--text",attrs:{"size":("" + (_vm.cambiarTamano(_vm.item.tamano)))}},[_c('img',{attrs:{"src":_vm.item.imagen}})]),_c('div',{staticClass:"badge-container"},_vm._l((_vm.item.codigosInternos),function(codInt,codI){return _c('div',{key:codI,class:("badge " + (_vm.internalColor({
            tipo: _vm.item.tipo,
            codigoInterno: codInt
          })))})}),0)],1):_c('div',{staticClass:"container-badge-container"},[_c('v-avatar',{staticClass:"black--text",attrs:{"color":"#e3e3e3","size":("" + (_vm.cambiarTamano(_vm.item.tamano)))}},[_vm._v(" "+_vm._s(_vm.item.nombre.toUpperCase()[0])+_vm._s(_vm.item.nombre.toUpperCase()[1])+_vm._s(_vm.item.nombre.toUpperCase()[2])+" ")]),_c('div',{staticClass:"badge-container"},_vm._l((_vm.item.codigosInternos),function(codInt,codI){return _c('div',{key:codI,class:("badge " + (_vm.internalColor({
            tipo: _vm.item.tipo,
            codigoInterno: codInt
          })))})}),0)],1)]):_c('div',{staticStyle:{"padding-top":"0px"}},[(_vm.item.imagen != '')?_c('div',{staticClass:"container-badge-container"},[_c('div',{staticClass:"badge-container"},_vm._l((_vm.item.codigosInternos),function(codInt,codI){return _c('div',{key:codI,class:("badge " + (_vm.internalColor({
            tipo: _vm.item.tipo,
            codigoInterno: codInt
          })))})}),0)]):_c('div',{staticClass:"container-badge-container"},[_c('div',{staticClass:"badge-container"},_vm._l((_vm.item.codigosInternos),function(codInt,codI){return _c('div',{key:codI,class:("badge " + (_vm.internalColor({
            tipo: _vm.item.tipo,
            codigoInterno: codInt
          })))},[_vm._v(" "+_vm._s(codInt)+" ")])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }