var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalClientes'),_c('ModalMascotas'),_c('ModalEstetica'),_c('ModalClinica'),_c('ModalReporteErrores',{attrs:{"isOpen":_vm.errorReportDialog,"onClose":_vm.toggleErrorReportDialog}}),_c('v-app-bar',{attrs:{"app":"","elevation":"1"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('buscador-general'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.addCliente}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-supervisor")])],1)]}}])},[_c('span',[_vm._v("Agregar Cliente")])]),(_vm.canAddMascotas)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.addMascota}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dog")])],1)]}}],null,false,1353020811)},[_c('span',[_vm._v("Agregar Mascota")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.shouldShowButton('Estética', 34))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.addRegistroEstetica}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-paw")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Agregar Servicio de Estética")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.shouldShowButton('Clínica', 36))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.addRegistroMedico}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Agregar Registro Médico")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.shouldShowButton('Ventas', 18))?_c('v-btn',_vm._g(_vm._b({attrs:{"to":"/Venta","link":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cash")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Nueva Venta")])]),_c('v-spacer'),_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":_vm.toggleErrorReportDialog}},'v-btn',attrs,false),on),[_vm._v(" Reportar Un Error ")])]}}])},[_c('span',[_vm._v("Reportar Error")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"link":"","color":"info","outlined":"","small":"","to":"/"}},'v-btn',attrs,false),on),[_vm._v(" Ver Errores ")])]}}])},[_c('span',[_vm._v("Ver Errores Resueltos y Por Resolver")])])],1),_c('v-spacer'),_c('ModalCaja'),_c('v-card',{staticClass:"px-4 py-1",attrs:{"elevation":"0"}},[_c('v-img',{attrs:{"lazy-src":require("../assets/zhLogo.png"),"max-width":"120","src":require("../assets/zhLogo.png")}})],1)],1),_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":_vm.randomColor({
              caseBase: _vm.usuario.perfil[0]
            })}},[(_vm.usuario.perfil)?_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.usuario.perfil[0]))]):_vm._e()]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(_vm.usuario.perfil))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.usuario.perfil))])]),_c('br'),_c('v-list-item-subtitle',[_c('router-link',{attrs:{"to":"/Login","color":"danger--text"}},[_vm._v(" Cerrar sesión ")])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[_vm._l((_vm.itemsFiltrados),function(item,i){return [(item.children)?_c('v-list-group',{key:i,attrs:{"active-class":"primary--text"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]},proxy:true}],null,true)},_vm._l((item.children),function(subitem,ind){return _c('v-list-item',{key:ind,attrs:{"link":"","active-class":"primary--text","to":("/" + (subitem.link))}},[_c('v-list-item-icon'),_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{domProps:{"textContent":_vm._s(subitem.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(subitem.text)}})],1)],1)}),1):_vm._e(),(!item.children)?_c('v-list-item',{key:i,attrs:{"link":"","active-class":"primary--text","to":("/" + (item.link))}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1):_vm._e()]})],2),_c('v-divider'),_c('v-list',[_c('v-container',[_c('v-switch',{attrs:{"label":"Modo nocturno"},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1)],1)],1),_c('v-main',{staticClass:"alturaCompleta rest"},[_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }