<style>
.alturaCompleta {
  min-height: 100vh;
}
</style>
<template>
  <div>
    <ModalClientes />
    <ModalMascotas />
    <ModalEstetica />
    <ModalClinica />
    <ModalReporteErrores
      :isOpen="errorReportDialog"
      :onClose="toggleErrorReportDialog"
    />
    <v-app-bar app elevation="1">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <buscador-general></buscador-general>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="addCliente" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-account-supervisor</v-icon>
          </v-btn>
        </template>
        <span>Agregar Cliente</span>
      </v-tooltip>

      <v-tooltip bottom v-if="canAddMascotas">
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="addMascota" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dog</v-icon>
          </v-btn>
        </template>
        <span>Agregar Mascota</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="addRegistroEstetica"
            icon
            v-if="shouldShowButton('Estética', 34)"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-paw</v-icon>
          </v-btn>
        </template>
        <span>Agregar Servicio de Estética</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="addRegistroMedico"
            icon
            v-if="shouldShowButton('Clínica', 36)"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
        </template>
        <span>Agregar Registro Médico</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :to="`/Venta`"
            link
            icon
            v-if="shouldShowButton('Ventas', 18)"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-cash</v-icon>
          </v-btn>
        </template>
        <span>Nueva Venta</span>
      </v-tooltip>

      <v-spacer></v-spacer>
      <div class="d-flex justify-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              color="error"
              outlined
              small
              @click="toggleErrorReportDialog"
            >
              Reportar Un Error
            </v-btn>
          </template>
          <span>Reportar Error</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              link
              v-bind="attrs"
              v-on="on"
              color="info"
              outlined
              small
              to="/"
            >
              Ver Errores
            </v-btn>
          </template>
          <span>Ver Errores Resueltos y Por Resolver</span>
        </v-tooltip>
      </div>

      <v-spacer></v-spacer>
      <ModalCaja />
      <v-card elevation="0" class="px-4 py-1">
        <v-img
          lazy-src="../assets/zhLogo.png"
          max-width="120"
          src="../assets/zhLogo.png"
        ></v-img>
      </v-card>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list>
        <v-list-item
          ><v-list-item-avatar
            :color="
              randomColor({
                caseBase: usuario.perfil[0]
              })
            "
          >
            <span v-if="usuario.perfil" class="white--text">{{
              usuario.perfil[0]
            }}</span>
          </v-list-item-avatar>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-title v-bind="attrs" v-on="on">{{
                usuario.perfil
              }}</v-list-item-title>
            </template>
            <span>{{ usuario.perfil }}</span>
          </v-tooltip>
          <br />
          <v-list-item-subtitle>
            <router-link to="/Login" color="danger--text">
              Cerrar sesión
            </router-link>
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-list nav dense>
        <template v-for="(item, i) in itemsFiltrados">
          <v-list-group
            :key="i"
            v-if="item.children"
            active-class="primary--text"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              link
              v-for="(subitem, ind) in item.children"
              active-class="primary--text"
              :to="`/${subitem.link}`"
              :key="ind"
            >
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-icon class="mr-2">
                <v-icon v-text="subitem.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="subitem.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            link
            active-class="primary--text"
            :to="`/${item.link}`"
            :key="i"
            v-if="!item.children"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <v-divider></v-divider>
      <v-list>
        <v-container>
          <v-switch
            v-model="$vuetify.theme.dark"
            label="Modo nocturno"
          ></v-switch>
        </v-container>
      </v-list>
    </v-navigation-drawer>

    <v-main class="alturaCompleta rest">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import getRandomColor from "../tools/randomColor";
import router from "vue-router";
import BuscadorGeneral from "../components/BuscadorGeneral";
import ModalCaja from "../layouts/AgregarEditar/ModalCaja";
import ModalClientes from "../layouts/AgregarEditar/ModalClientes";
import ModalMascotas from "../layouts/AgregarEditar/ModalMascotas";
import ModalClinica from "../layouts/AgregarEditar/ModalClinica";
import ModalEstetica from "../layouts/AgregarEditar/ModalEstetica";
import ModalReporteErrores from "../layouts/AgregarEditar/ModalReporteErrores";
export default {
  name: "Panel",
  components: {
    BuscadorGeneral,
    ModalCaja,
    ModalClientes,
    ModalMascotas,
    ModalClinica,
    ModalEstetica,
    ModalReporteErrores
  },
  data: () => ({
    errorReportDialog: false,
    usuario: {
      perfil: localStorage.perfil ? localStorage.perfil : "Administrador"
    },
    drawer: null,
    canAddMascotas: null,
    itemsFiltrados: [],
    items: [
      {
        text: "Ventas",
        icon: "mdi-currency-usd",
        children: [
          {
            text: "Nueva Venta",
            icon: "mdi-cash",
            link: "Venta/",
            permiso: 18
          },
          {
            text: "Abonos",
            icon: "mdi-cash-plus",
            link: "Abonos",
            permiso: 19
          },
          {
            text: "Ventas",
            icon: "mdi-view-list-outline",
            link: "Ventas",
            permiso: 20
          },
          {
            text: "Uso interno",
            icon: "mdi-view-list-outline",
            link: "UsoInterno",
            permiso: 21
          },
          {
            text: "Cierres de Cajas",
            icon: "mdi-cash-register",
            link: "CierresCajas",
            permiso: 22
          }
        ],
        permiso: 5
      },
      {
        text: "Productos & Servicios",
        icon: "mdi-format-list-numbered-rtl",
        children: [
          {
            text: "Productos",
            icon: "mdi-bottle-tonic",
            link: "Productos",
            permiso: 23
          },
          {
            text: "Marcas",
            icon: "mdi-tag",
            link: "Marcas",
            permiso: 24
          },
          {
            text: "Categorias",
            icon: "mdi-shape",
            link: "Categorias",
            permiso: 25
          },
          {
            text: "Subcategorias",
            icon: "mdi-shape",
            link: "Subcategorias",
            permiso: 26
          },
          {
            text: "Inventario Tienda",
            icon: "mdi-store-search",
            link: "Inventario",
            permiso: 61
          },
          {
            text: "Inventario Almacén",
            icon: "mdi-store-search",
            link: "InventarioAlmacen",
            permiso: 64
          }
        ],
        permiso: 6
      },
      {
        text: "Gastos",
        icon: "mdi-tag",
        children: [
          {
            text: "Gastos",
            icon: "mdi-tag-outline",
            link: "gastos",
            permiso: 27
          },
          {
            text: "Fondo Fijo",
            icon: "mdi-tag-outline",
            link: "FondoFijo",
            permiso: 28
          }
        ],
        permiso: 7
      },
      {
        text: "Compras",
        icon: "mdi-shopping-outline",
        children: [
          {
            text: "Nueva Compra",
            icon: "mdi-shopping",
            link: "NuevaCompra",
            permiso: 29
          },
          {
            text: "Compras Cerradas y Por Pagar",
            icon: "mdi-tag-outline",
            link: "Compras",
            permiso: 30
          }
        ],
        permiso: 57
      },
      {
        text: "Clientes",
        icon: "mdi-account-supervisor",
        link: "Clientes",
        permiso: 8
      },
      {
        text: "Llamadas",
        icon: "mdi-phone",
        link: "Llamadas",
        permiso: 66
      },
      { text: "Mascotas", icon: "mdi-dog", link: "Mascotas", permiso: 9 },
      {
        text: "Empleados",
        icon: "mdi-badge-account-horizontal",
        children: [
          {
            text: "Personal",
            icon: "mdi-account-multiple",
            link: "Empleados",
            permiso: 31
          },
          {
            text: "Comisiones Calculadas",
            icon: "mdi-percent",
            link: "ComisionesCalculadas",
            permiso: 32
          },
          {
            text: "Reglas de Comisiones",
            icon: "mdi-percent",
            link: "ReglasDeComisiones",
            permiso: 32
          }
        ],
        permiso: 10
      },
      {
        text: "Proveedores",
        icon: "mdi-handshake",
        link: "Proveedores",
        permiso: 11
      },
      {
        text: "Taxonomía",
        icon: "mdi-family-tree",
        children: [
          {
            text: "Especificos",
            icon: "mdi-bird",
            link: "Especificos",
            permiso: 33
          }
        ],
        permiso: 12
      },
      {
        text: "Estética",
        icon: "mdi-content-cut",
        children: [
          {
            text: "Registro Estética",
            icon: "mdi-paw",
            link: "Registros",
            permiso: 34
          },
          {
            text: "Estetica",
            icon: "mdi-content-cut",
            link: "Estetica",
            permiso: 35
          }
        ],
        permiso: 13
      },
      {
        text: "Clínica",
        icon: "mdi-medical-bag",
        children: [
          {
            text: "Registro médico",
            icon: "mdi-plus-circle",
            link: "RegistrosMedicos",
            permiso: 36
          },
          {
            text: "Registro citas",
            icon: "mdi-phone",
            link: "CitasMedicas",
            permiso: 37
          },
          {
            text: "Expedientes",
            icon: "mdi-table",
            link: "Expedientes",
            permiso: 38
          },
          {
            text: "Químicas Sanguíneas",
            icon: "mdi-chemical-weapon",
            link: "QuimicaSanguinea",
            permiso: 39
          },
          {
            text: "Biometría Hemática",
            icon: "mdi-blood-bag",
            link: "BiometriaHematica",
            permiso: 40
          }
        ],
        permiso: 14
      },
      {
        text: "Servicio a Domicilio",
        icon: "mdi-truck-delivery-outline",
        link: "ServicioDomicilio",
        permiso: 56
      },
      {
        text: "Reportes",
        icon: "mdi-file-document-outline",
        children: [
          {
            text: "Historial de Edición de Ventas",
            icon: "mdi-view-list-outline",
            link: "VentasHistorialEdicion",
            permiso: 67
          },
          {
            text: "Ventas concentradas",
            icon: "mdi-cash-multiple",
            link: "ReportesVentasConcentradas",
            permiso: 41
          },
          {
            text: "Llamadas",
            icon: "mdi-phone",
            link: "ReportesLlamadas",
            permiso: 42
          },
          {
            text: "Compras",
            icon: "mdi-cart",
            link: "ReportesCompras",
            permiso: 43
          },
          {
            text: "Estetica",
            icon: "mdi-shower",
            link: "ReportesEstetica",
            permiso: 44
          },
          {
            text: "Consultas",
            icon: "mdi-pill",
            link: "ReportesConsultas",
            permiso: 45
          },
          {
            text: "Abonos",
            icon: "mdi-hand-coin-outline",
            link: "ReportesAbonos",
            permiso: 46
          },
          {
            text: "Uso interno",
            icon: "mdi-bottle-tonic",
            link: "ReportesUsoInterno",
            permiso: 47
          },
          {
            text: "Gastos",
            icon: "mdi-credit-card",
            link: "ReportesGastos",
            permiso: 48
          },
          {
            text: "Comisiones Calculadas",
            icon: "mdi-account-star",
            link: "ReportesComisiones",
            permiso: 49
          },
          {
            text: "Cierres de cajas",
            icon: "mdi-cash-register",
            link: "ReportesCierresCajas",
            permiso: 50
          },
          {
            text: "Clientes",
            icon: "mdi-account",
            link: "ReportesClientes",
            permiso: 51
          },
          {
            text: "Mascotas",
            icon: "mdi-dog",
            link: "ReportesMascotas",
            permiso: 52
          },
          {
            text: "Ventas totales",
            icon: "mdi-table-check",
            link: "ReportesVentasTotales",
            permiso: 53
          },
          {
            text: "Stock Rápido",
            icon: "mdi-swap-vertical",
            link: "StockRapido",
            permiso: 54
          },
          {
            text: "Precio Rápido",
            icon: "mdi-currency-usd",
            link: "PrecioRapido",
            permiso: 55
          },
          {
            text: "Movimientos de productos",
            icon: "mdi-shopping",
            link: "ReportesMovimientosProductos",
            permiso: 62
          },
          {
            text: "Reporte de traspasos",
            icon: "mdi-table",
            link: "ReportesTraspasosAlmacen",
            permiso: 68
          }
        ],
        permiso: 15
      },
      {
        text: "Perfiles y permisos",
        icon: "mdi-lock",
        link: "Permisos",
        permiso: 16
      }
    ]
  }),

  created: function() {
    //AQUI DEBE DE HABER UNA FUNCION QUE EVALUE EL TOKEN
    if (!localStorage.token) {
      router.replace({ path: "Login" });
    }
    if (localStorage.perfil) {
      this.usuario.perfil = localStorage.perfil;
    }
    this.filtrarItems();
    this.guardarGeolocalizacion();
    this.checkPermissons();
  },
  methods: {
    guardarGeolocalizacion() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const geolocalizacion = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            };
            localStorage.setItem("geo", JSON.stringify(geolocalizacion));
          },
          error => {
            console.error(
              "Error al obtener la geolocalización: ",
              error.message
            );
          }
        );
      } else {
        console.error("Geolocalización no soportada por el navegador");
      }
    },

    randomColor(props) {
      return getRandomColor(props);
    },

    toggleErrorReportDialog() {
      this.errorReportDialog = !this.errorReportDialog;
    },
    checkPermissons() {
      this.canAddMascotas = JSON.parse(localStorage.permisos)?.some(
        permiso => permiso.id == 63
      );
    },
    shouldShowButton(sectionName, childrenPermission = -1, deep = true) {
      const section = this.itemsFiltrados.find(
        item => item.text === sectionName
      );

      if (!section) return false;

      if (!deep) return true;
      const children = section.children;

      if (!children || !Array.isArray(children)) return false;

      return children.some(item => item.permiso === childrenPermission);
    },

    filtrarItems() {
      this.itemsFiltrados = this.items.filter(item => {
        let esta = false;
        JSON.parse(localStorage.permisos)?.forEach(permiso => {
          if (permiso.id == item.permiso) esta = true;
        });
        return esta;
      });
      this.itemsFiltrados.forEach(item => {
        if (item.children?.length) {
          item.children = item.children?.filter(child => {
            let esta = false;
            JSON.parse(localStorage.permisos)?.forEach(permiso => {
              if (permiso.id == child.permiso) esta = true;
            });
            return esta;
          });
        }
      });
    },

    async addCliente() {
      this.$store.commit("setItemModal", {
        modal: "clientes",
        item: {},
        callback: () => {}
      });

      this.$store.commit("openModal", { modal: "clientes", open: true });
    },
    async addMascota() {
      this.$store.commit("setItemModal", {
        modal: "mascotas",
        item: {},
        callback: () => {}
      });

      this.$store.commit("openModal", { modal: "mascotas", open: true });
    },
    async addRegistroMedico() {
      this.$store.commit("setItemModal", {
        modal: "servicioClinica",
        item: {},
        callback: () => {}
      });

      this.$store.commit("openModal", { modal: "servicioClinica", open: true });
    },
    async addRegistroEstetica() {
      this.$store.commit("setItemModal", {
        modal: "servicioEstetica",
        item: {},
        callback: () => {}
      });

      this.$store.commit("openModal", {
        modal: "servicioEstetica",
        open: true
      });
    }
  }
};
</script>
