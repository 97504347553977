export function getActualTimeString() {
  let myDate = new Date().toLocaleTimeString("en-US", {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  });
  return myDate;
}

export function compareStringsTimes(options) {
  let { time1, time2, range } = {
    time1: "00:00:00",
    time2: "00:00:00",
    range: "0s",
    ...options
  };
  let unidad = range.slice(range.length - 1);
  let rango = range.slice(0, range.length - 1);

  let hoursTime1 = time1.slice(0, 2);
  let minutesTime1 = time1.slice(3, 5);
  let secondsTime1 = time1.slice(6, 8);

  let hoursTime2 = time2.slice(0, 2);
  let minutesTime2 = time2.slice(3, 5);
  let secondsTime2 = time2.slice(6, 8);

  let time1Secs =
    parseInt(hoursTime1) * 60 * 60 +
    parseInt(minutesTime1) * 60 +
    parseInt(secondsTime1);
  let time2Secs =
    parseInt(hoursTime2) * 60 * 60 +
    parseInt(minutesTime2) * 60 +
    parseInt(secondsTime2);

  let totalDif = parseInt(time1Secs - time2Secs);
  let rangeDif = parseInt(rango) * 60 * 60;

  if (unidad == "m") {
    rangeDif = parseInt(rango) * 60;
  }
  if (unidad == "s") {
    rangeDif = parseInt(rango);
  } 

  if (time1Secs > time2Secs) return true;
  if (Math.abs(totalDif) > Math.abs(rangeDif)) return false;
  return true;
}
