<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <h1>Gastos</h1>
              <br />
              <v-row>
                <v-col cols="12" sm="5" md="5">
                  <label>Desde: </label>
                  <input type="date" v-model="desde" />
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <label>Hasta: </label>
                  <input type="date" v-model="hasta" />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-btn
                    elevation="2"
                    small
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="gastos"
          :items-per-page="7"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          calculate-widths
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
export default {
  name: "ReportesGastos",
  data: () => ({
    isLoading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    headers: [
      { text: "Id", value: "id" },
      { text: "Fecha", value: "fecha", width: "110px" },
      { text: "Hora", value: "hora" },
      { text: "Proveedor", value: "nombreProveedor", width: "140px" },
      { text: "Concepto", value: "concepto", width: "140px" },
      { text: "Empleado", value: "nombreEmpleado", width: "140px" },
      { text: "Monto", value: "monto", width: "110px" },
      { text: "Forma de pago", value: "formaPago", width: "110px" },
      { text: "Tipo de gastos", value: "tipoGasto", width: "130px" },
      { text: "Departamento	", value: "departamento", width: "160px" },
      { text: "Caja", value: "caja", width: "160px" }
    ],
    gastos: [],
    placeholderGastos: [
      {
        id: "4827",
        fechaHora: "22/10/2021	11:15:14",
        fecha: "22/10/2021",
        hora: "11:15:14",
        proveedor: "Mariangel Morales",
        concepto: "Reposición de fondo Marketing",
        empleado: "Moises Venegas Navarro",
        monto: "4067.50",
        formaPago: "Efectivo",
        tipoGasto: "Gastos",
        departamento: "",
        caja: "3"
      },
      {
        id: "4826",
        fechaHora: "22/10/2021	11:14:18",
        fecha: "22/10/2021",
        hora: "11:14:18",
        proveedor: "Loyal Tree",
        concepto: "Incineración Joopie",
        empleado: "Moises Venegas Navarro",
        monto: "750.00",
        formaPago: "Efectivo",
        tipoGasto: "Compras",
        departamento: "",
        caja: "3"
      },
      {
        id: "4825",
        fechaHora: "22/10/2021	11:11:05",
        fecha: "22/10/2021",
        hora: "11:11:05",
        proveedor: "Alejandrina",
        concepto: "Honorarios Alejandrina",
        empleado: "Moises Venegas Navarro",
        monto: "1512.00",
        formaPago: "Efectivo",
        tipoGasto: "Gastos",
        departamento: "",
        caja: "3"
      }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchGastos(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchGastos(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  methods: {
    async fetchGastos(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "gastos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllReporte",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta.data.status == "Ok") {
        this.gastos = respuesta.data.rows;
        this.isLoading = false;
      }
      //this.gastos = this.placeholderGastos;
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var gastos = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, gastos, "Gastos");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.gastos.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            // Verificar si el valor es numérico
            let valor = registro[header.value];
            if (!isNaN(valor) && valor !== "") {
              // Convertir el valor a número
              valor = Number(valor);
            }
            XLSX.utils.sheet_add_aoa(sheet, [[valor]], {
              // Agregar el valor al archivo
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-Gastos.xlsx`);
    },
    initialize() {
      this.fetchGastos(
        moment()
          .subtract(1, "weeks")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }
};
</script>
