<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <v-toolbar dark color="primary" rounded>
            <v-toolbar-title
              >Estados de la venta con id {{ venta.id }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row
            v-for="edicion in edicionesDeVenta"
            :key="edicion.id"
            class="d-flex justify-center ma-4"
          >
            <v-data-table
              :loading="isLoading"
              :headers="edicionItemsHeaders"
              :items="edicion.items"
              :items-per-page="10"
              full-width
              sort-by="id"
              :sort-desc="true"
              :footer-props="{
                'items-per-page-text': 'Items por pagina',
                'items-per-page-options': [10, -1]
              }"
              fixed-header
              class="elevation-1"
              dense
            >
              <template v-slot:top>
                <h2 class="ma-4">
                  {{ edicion.fechaHora }} - {{ edicion.status }}
                </h2>
              </template>
            </v-data-table>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title>
          ¿Realmente deseas eliminar la venta <br />con el ID:
          {{ venta.id }} ?<br /><br />
        </v-card-title>
        <v-card-subtitle>
          Al hacerlo, los productos asociados a la venta se marcarán cómo
          devueltos a stock.
          <br />
          Los ingresos asociados en el registro serán eliminados.
          <br />
          <span class="dangerBlink"
            >Los datos de la venta, ingresos y movimientos de productos
            asociados no podrán ser recuperados.
          </span>
          <br /><span class="infoBlink">
            Sugerencia : Imprime el ticket de la venta que vas a eliminar.
          </span>
          <br />Además, necesitas permisos de administrador para realizar ésta
          acción. <br />
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            label="Usuario"
            type="text"
            v-model="usuario"
            autocomplete="off"
          />
          <v-text-field
            label="Contraseña"
            type="password"
            v-model="password"
            autocomplete="off"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancelar
          </v-btn>
          <v-btn
            color="danger"
            text
            :loading="isLoading"
            @click="
              () => {
                pedirPermiso();
              }
            "
          >
            Sí, eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Historial de ventas editadas</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="red darken-3"
                dense
                @keydown="handleSearchInput"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                elevation="2"
                small
                color="red darken-3 white--text "
                disabled
                v-if="false"
              >
                Agregar venta
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="ventasFiltradas"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          fixed-header
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn
              v-if="item.noEdiciones > 0"
              icon
              color="info"
              @click="
                () => {
                  venta = { ...item };
                  dialog = true;
                }
              "
            >
              <v-icon small> mdi-details </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="red white--text darken-3 my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
import "jspdf-autotable";
import printVenta from "../tools/printTickets/printTicketVenta";
import { mapState } from "vuex";
import { getClientesById } from "../axios-calls/clientes";

export default {
  name: "ventasHistorialEdicion",
  data: () => ({
    search: "",
    isLoading: false,
    isSending: false,
    canEditSale: false,
    viendo: "Todas",
    venta: {
      id: 0,
      status: "cerrada",
      idCliente: 0,
      montoAbono: 0,
      montoSaldo: 0,
      montoTotal: 0
    },
    dialog: false,
    deleteDialog: false,
    usuario: "",
    password: "",
    headers: [
      { text: "Id", value: "id" },
      { text: "No. de ediciones", value: "noEdiciones" },
      { text: "Cliente", value: "nombreCliente" },
      { text: "Status", value: "status" },
      { text: "Cajero", value: "nombreEmpleado" },
      { text: "Vendedor", value: "nombreVendedor" },
      { text: "Total", value: "montoTotal" },
      { text: "Abono", value: "montoAbono" },
      { text: "Saldo", value: "montoSaldo" },
      { text: "Fecha", value: "fechaHora" },
      { text: "Acciones", value: "acciones", align: "right" }
    ],
    monto: 0.0,
    forma_pago: "efectivo",
    idEmpleado: 0,
    edicionesDeVenta: [],
    edicionItemsHeaders: [
      {
        text: "Producto",
        value: "nombre"
      },
      {
        text: "Cantidad",
        value: "cantidad"
      },
      {
        text: "Impuesto",
        value: "impuesto"
      },
      {
        text: "Descuento aplicado (monto)",
        value: "descuentoMonto"
      },
      {
        text: "Monto de venta",
        value: "monto"
      },
      {
        text: "Mascota",
        value: "mascota"
      },
      {
        text: "Vendedor",
        value: "vendedor"
      },
      {
        text: "Cajero",
        value: "cajero"
      }
    ],
    pagos: [
      {
        label: "Efectivo",
        value: "efectivo",
        monto: 0
      },
      {
        label: "Crédito",
        value: "credito",
        monto: 0
      },
      {
        label: "Débito",
        value: "debito",
        monto: 0
      },
      { label: "Transferencia", value: "transferencia", monto: 0 },
      { label: "Ize. crédito", value: "izCredito", monto: 0 },
      { label: "Ize. débito", value: "izDebito", monto: 0 },
      { label: "Depósito en efectivo", value: "depEfectivo", monto: 0 },
      { label: "Puntos", value: "puntosSaldo", monto: 0 }
    ],
    empleados: []
  }),
  created() {
    this.initialize();
    this.checkPermissons();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "ventas",
        search: val
      });
    },
    dialog(val) {
      if (val) {
        this.callEdicionesVenta();
      }
    },
    deleteDialog(val) {
      if (!val) return;
      this.usuario = "usuario";
      this.password = "contraseña";
    }
  },
  computed: {
    ...mapState("ventas", ["ventas"]),
    ventasFiltradas() {
      if (this.viendo == "Todas") {
        return this.ventas;
      }
      let statusParam = "por cobrar";
      if (this.viendo == "Cerradas") {
        statusParam = "cerrada";
      }
      if (this.viendo == "Canceladas") {
        statusParam = "cancelada";
      }
      return this.ventas?.filter(venta => venta.status == statusParam) || [];
    },

    cambio() {
      const sumaPagos = Object.values(this.pagos).reduce(
        (total, pago) => Number(total) + Number(pago.monto),
        0
      );
      if (sumaPagos > this.venta.montoSaldo)
        return -(Number(this.venta.montoSaldo) - sumaPagos);
      return 0;
    }
  },
  methods: {
    initialize() {
      this.fetchVentas();
      this.search = this.$store.state.searchParams["ventas"];
      this.fetchEmpleados();
    },
    handleSearchInput() {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchVentas();
      }, 500);
    },
    async callEdicionesVenta() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getEdicionesDeVenta",
        idVenta: this.venta.id,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok")
        this.edicionesDeVenta = respuesta.data.rows;
      this.isLoading = false;
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") this.empleados = respuesta.data.rows;
      this.isLoading = false;
    },

    async pedirPermiso() {
      if (!this.usuario || !this.password) {
        this.$toasted.error(
          "Introduce un usuario y/o contraseña con permisos para eliminar la venta",
          {
            position: "bottom-right",
            duration: 2000
          }
        );
      }
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "testDeletePermisson",
        token: localStorage.token,
        geo: localStorage.geo,
        usuario: this.usuario.trim(),
        password: this.password
      });
      if (respuesta.data.permisson == "Ok") {
        this.deleteVenta();
      } else {
        this.$toasted.error("Datos incorrectos", {
          position: "bottom-right",
          duration: 2000
        });
        this.isLoading = false;
      }
    },
    async deleteVenta() {
      if (this.venta.id == 0 || this.venta.id == "0") {
        this.$toasted.error(`Selecciona una venta válida para eliminar`, {
          position: "bottom-right",
          duration: 5000
        });
        return;
      }
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        id: this.venta.id,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Venta eliminada " + this.venta.id, {
          position: "bottom-right",
          duration: 2000
        });
        this.usuario = "";
        this.password = "";
        this.fetchVentas();
        this.$store.commit("productos/refreshCache");
        this.deleteDialog = false;
      }
      this.isLoading = false;
    },
    async fetchVentas() {
      this.isLoading = true;
      await this.$store.dispatch("ventas/getIniciales");
      this.isLoading = false;
    },
    async fetchSearchVentas() {
      this.isLoading = true;
      await this.$store.dispatch("ventas/getBySearch", this.search);
      this.isLoading = false;
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },
    cerrarPago(pago) {
      let adeudo = parseFloat(this.venta.montoSaldo);
      let montoTotalAbonado = 0;
      this.pagos.forEach(pago => {
        montoTotalAbonado += parseFloat(pago.monto);
      });
      if (montoTotalAbonado < adeudo) {
        pago.monto -= montoTotalAbonado - adeudo;
      }
    },
    async agregarAbono() {
      if (localStorage.idCaja == "0" || !localStorage.idCaja) {
        this.$toasted.error(
          "Abre una caja antes de hacer una venta y registrar ingresos",
          {
            position: "bottom-right",
            duration: 5000
          }
        );
        this.$refs.NuevaVentaModalPagos.close();
        return;
      }
      this.isSending = true;
      setTimeout(() => {
        this.isSending = false;
      }, 1000);
      let item = this.venta;
      const clientes = await getClientesById(item.idCliente);
      const puntosCliente =
        clientes.find(cliente => cliente.id == item.idCliente).puntosSaldo || 0;
      const pagoEnPuntos =
        this.pagos.find(pago => pago.value == "puntosSaldo").monto || 0;
      if (pagoEnPuntos > puntosCliente) {
        this.$toasted.error(
          `Éste cliente solo cuenta con ${puntosCliente} puntos. No es suficiente.`,
          {
            position: "bottom-right",
            duration: 5000
          }
        );
        return;
      }
      let montoTotalAbonado = 0;
      let efectivoAbonado = 0;
      this.pagos.forEach(pago => {
        montoTotalAbonado += parseFloat(pago.monto);
        if (pago.value == "efectivo") efectivoAbonado += parseFloat(pago.monto);
      });
      const diff =
        parseFloat(this.venta.montoAbono) +
        parseFloat(montoTotalAbonado) -
        parseFloat(this.venta.montoTotal);
      if (efectivoAbonado < this.cambio) {
        this.$toasted.error(
          `Sólo puedes entregar cambio si te han pagado lo suficiente en efectivo`,
          {
            position: "bottom-right",
            duration: 5000
          }
        );
        return;
      }
      if (!montoTotalAbonado) {
        this.$toasted.error(`Verifica el monto ingresado`, {
          position: "bottom-right",
          duration: 5000
        });
        return;
      }
      if (!this.idEmpleado) {
        this.$toasted.error(`Selecciona un empleado`, {
          position: "bottom-right",
          duration: 5000
        });
        return;
      }
      if (
        parseFloat(this.venta.montoAbono) + parseFloat(montoTotalAbonado) >
        parseFloat(this.venta.montoTotal)
      ) {
        const pagoEfectivo = this.pagos.find(pago => pago.value == "efectivo");
        pagoEfectivo.monto -= this.cambio;
      }
      if (diff == 0) {
        this.venta.status = "cerrada";
      }

      this.dialog = false;
      this.isLoading = true;

      const myPagos = this.pagos.filter(pago => pago.monto > 0);
      let myUrl = process.env.VUE_APP_API_URL + "abonos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "insertAbonos",
        id: item.id,
        token: localStorage.token,
        geo: localStorage.geo,
        items: myPagos,
        forma_pago: this.forma_pago,
        idEmpleado: this.idEmpleado,
        idCliente: item.idCliente,
        idCaja: localStorage.idCaja,
        idVenta: item.id,
        status: this.venta.status
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Abono agregado", {
          position: "bottom-right",
          duration: 2000
        });
        await this.$store.dispatch("ventas/getUpdatedById", item.id);
      }
      this.isLoading = false;
    },
    async refreshItem(item) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "refreshOne",
        id: item.id,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        await this.$store.dispatch("ventas/getBySearch", item.id);
        this.$toasted.success("Venta refrescada", {
          position: "bottom-right",
          duration: 2000
        });
      }
      this.isLoading = false;
    },
    async printItem(item) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getOne",
        id: item.id,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        let venta = { ...respuesta.data };
        let saldoPendiente = venta.montoTotal - venta.montoAbono;
        printVenta(venta, item, saldoPendiente);
      }
      this.isLoading = false;
    },

    checkPermissons() {
      if (!localStorage?.permisos) return;
      this.canEditSale = JSON.parse(localStorage.permisos)?.some(
        permiso => permiso.id == 60 || permiso.id == 1
      );
    }
  }
};
</script>
