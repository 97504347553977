import { getOneVenta, sendVenta } from "../axios-calls/ventas";

export default {
  namespaced: true,
  state: {
    idVenta: 0,
    isLoading: false,
    productosNuevaVenta: [],
    cajero: { id: 0 },
    vendedor: { id: 0 },
    cliente: { id: 0 },
    pagos: {
      efectivo: 0,
      credito: 0,
      debito: 0,
      izCredito: 0,
      izDebito: 0,
      transferencia: 0,
      puntosSaldo: 0,
      depEfectivo: 0,
      cambio: 0
    },
    lastGet: "none",
    status: "por cobrar"
  },
  mutations: {
    setIdVenta(state, idVenta) {
      state.idVenta = idVenta;
    },
    setPagos(state, pagos) {
      state.pagos = { ...state.pagos, ...pagos };
    },
    resetPagos(state) {
      state.status = "por cobrar";
      state.pagos = {
        ...state.pagos,
        efectivo: 0,
        credito: 0,
        debito: 0,
        izCredito: 0,
        izDebito: 0,
        transferencia: 0,
        puntosSaldo: 0,
        depEfectivo: 0
      };
    },
    setCajero(state, cajero) {
      state.cajero = cajero;
    },
    setVendedor(state, vendedor) {
      state.vendedor = vendedor;
    },
    setCliente(state, cliente) {
      state.cliente = cliente;
    },
    removeProduct(state, uniqueId) {
      const index = state.productosNuevaVenta.findIndex(
        p => p.uniqueId === uniqueId
      );
      if (index !== -1) {
        state.productosNuevaVenta.splice(index, 1);
      }
    },
    updateDescuentoPorcentaje(state, { id }) {
      let product = state.productosNuevaVenta.find(p => p.id === id);

      product.descuentoPorcentaje = Number(
        (
          (product.descuentoMonto / (product.cantidad * product.precio)) *
          100
        ).toFixed(2)
      );
    },
    updateDescuentoPorcentajeInAll(state) {
      state.productosNuevaVenta.forEach(product => {
        product.descuentoPorcentaje = Number(
          (
            (product.descuentoMonto / (product.cantidad * product.precio)) *
            100
          ).toFixed(2)
        );
      });
    },
    updateDescuentoMonto(state, { uniqueId }) {
      let product = state.productosNuevaVenta.find(
        p => p.uniqueId === uniqueId
      );

      product.descuentoMonto = Number(
        (
          (product.descuentoPorcentaje / 100) *
          (product.cantidad * product.precio)
        ).toFixed(2)
      );
    },
    calculateTotal(state, { uniqueId }) {
      let product = state.productosNuevaVenta.find(
        p => p.uniqueId === uniqueId
      );

      product.descuentoMonto = Number(
        (
          (product.descuentoPorcentaje / 100) *
          (product.cantidad * product.precio)
        ).toFixed(2)
      );
      product.total = Number(
        (product.cantidad * product.precio - product.descuentoMonto).toFixed(2)
      );
      product.ivaMonto = Number(
        (Number(product.total) * (Number(product.impuesto) / 100)).toFixed(2)
      );
      product.subtotal = Number(
        (Number(product.total) - Number(product.ivaMonto)).toFixed(2)
      );
      state.productosNuevaVenta = [...state.productosNuevaVenta];
    },
    calculateTotalInAll(state) {
      state.productosNuevaVenta.find(product => {
        product.total = Number(
          (product.cantidad * product.precio - product.descuentoMonto).toFixed(
            2
          )
        );
      });

      state.productosNuevaVenta = [...state.productosNuevaVenta];
    },
    refreshMutation(state) {
      state.productosNuevaVenta = [...state.productosNuevaVenta];
    },
    setStatusVenta(state, { status }) {
      state.status = status;
    }
  },
  actions: {
    clearNuevaVenta({ state, rootState }) {
      state.productosNuevaVenta.forEach(producto => {
        const productoEnStock = rootState.productos.productos.find(
          p => p.id === producto.id
        );
        if (productoEnStock) {
          productoEnStock.stock += producto.cantidad;
        } else {
          console.error(
            `Producto con ID ${producto.id} no encontrado en stock.`
          );
        }
      });
      state.productosNuevaVenta = [];
    },
    addProduct({ state, rootState }, { product, shouldSubtract = true }) {
      let existingProductStock = rootState.productos.productos.find(
        p => p.id === product.id
      );
      existingProductStock.cantidad = 1;
      existingProductStock.descuentoPorcentaje = 0;
      existingProductStock.descuentoMonto = 0;
      existingProductStock.total = existingProductStock.precio;
      existingProductStock.ivaMonto = Number(
        (
          Number(existingProductStock.total) *
          (Number(existingProductStock.impuesto) / 100)
        ).toFixed(2)
      );
      existingProductStock.precioAntesImpuesto = Number(
        (
          Number(existingProductStock.total) -
          Number(existingProductStock.ivaMonto)
        ).toFixed(2)
      );
      existingProductStock.idMascota = 0;

      // Solo resta la cantidad si shouldSubtract es true
      if (shouldSubtract) {
        existingProductStock.stock--;
      }
      const uniqueId = `${existingProductStock.id}-${Date.now()}`;

      state.productosNuevaVenta.push({
        ...existingProductStock,
        uniqueId: uniqueId
      });
    },
    resetNuevaVenta({ state, rootState }) {
      state.productosNuevaVenta.forEach(product => {
        let productStock = rootState.productos.productos.find(
          p => p.id === product.id
        );
        if (productStock?.stock) productStock.stock += product.cantidad;
        else if (productStock) productStock.stock = 0 + product.cantidad;
      });
      state.productosNuevaVenta = [];
      state.idVenta = 0;
      state.pagos = {
        efectivo: 0,
        credito: 0,
        debito: 0,
        izCredito: 0,
        izDebito: 0,
        transferencia: 0,
        puntosSaldo: 0,
        depEfectivo: 0,
        cambio: 0
      };
    },
    async fetchVentaById({ commit, state }, { id }) {
      state.isLoading = true;
      const response = await getOneVenta(id);
      state.idVenta = id;
      state.productosNuevaVenta = response.items.map((producto, ind) => {
        const uniqueId = `${producto.id}-${Date.now()}-${ind}`;
        producto.precio = producto.precioAplicado;
        producto.descuentoMonto = producto.descuentoAplicado;
        producto.uniqueId = uniqueId;
        return producto;
      });
      state.cajero = response.cajeros.find(cajero => cajero.id != null);
      state.vendedor = response.vendedores.find(
        vendedor => vendedor.id != null
      );
      state.cliente = response.clientes.find(cliente => cliente.id != null);
      response.ingresos.forEach(ingreso => {
        state.pagos[ingreso.tipo] = Number(ingreso.monto);
      });

      commit("updateDescuentoPorcentajeInAll");
      commit("calculateTotalInAll");
      state.isLoading = false;
    },
    async terminarVenta({ state }, { pagos }) {
      if (!pagos) pagos = state.pagos;
      if (state.status != "uso interno") {
        const sumaMonto = state.productosNuevaVenta.reduce(
          (acumulado, producto) => Number(acumulado) + Number(producto.total),
          0
        );
        let totalPagado = 0;
        for (const key in pagos) totalPagado += state.pagos[key];
        if (totalPagado >= sumaMonto) state.status = "cerrada";
      }
      return await sendVenta({
        idVenta: state.idVenta,
        status: state.status,
        fechaHora: null,
        pagos,
        idCliente: state.cliente.id,
        idCajero: state.cajero.id,
        idVendedor: state.vendedor.id,
        productos: state.productosNuevaVenta
      });
    }
  },
  modules: {}
};
