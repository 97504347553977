var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('ModalSaldoCliente',{ref:"ModalSaldoCliente",attrs:{"cliente":_vm.clientes[0],"callback":_vm.initialize}}),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('h1',[_vm._v(" "+_vm._s(_vm.toCaps(_vm.clientes[0].nombre, { nombre: true }))+" ")])]),_c('v-card-text',[_c('br'),_c('h2',[_c('b',[_vm._v("Saldo: ")]),_vm._v(" $"+_vm._s(_vm.clientes[0].puntosSaldo)+" "),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":_vm.toggleModal}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('br'),_c('p',[_c('b',[_vm._v("Teléfono 1: ")]),_vm._v(" "+_vm._s(_vm.clientes[0].tel1))]),_c('p',[_c('b',[_vm._v("Teléfono 2: ")]),_vm._v(_vm._s(_vm.clientes[0].tel2))]),_c('p',[_c('b',[_vm._v("Dirección: ")]),_vm._v(_vm._s(_vm.clientes[0].direccion))]),_c('p',[_c('b',[_vm._v("Observaciones: ")]),_vm._v(_vm._s(_vm.clientes[0].observaciones))])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('v-card',[_c('v-card-title',[_vm._v("Mascotas")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.mascotas,"items-per-page":7,"sort-by":"id","sort-desc":true,"footer-props":{
              'items-per-page-text': 'Items por pagina',
              'items-per-page-options': [7, 15, -1]
            },"dense":""},scopedSlots:_vm._u([{key:"item.acciones",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info","link":"","to":("/DetallesMascota/" + (item.id))}},[_c('v-icon',{staticClass:"mr-0",attrs:{"small":""}},[_vm._v("mdi-text-box")])],1),_c('v-btn',{attrs:{"icon":"","link":"","color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":"","color":"danger"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"pink white--text ","rounded":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")])],1)]},proxy:true}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Ultimas ventas a éste cliente")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersUltimasVentas,"items":_vm.ultimasVentas,"items-per-page":7,"sort-by":"id","sort-desc":true,"footer-props":{
              'items-per-page-text': 'Items por pagina',
              'items-per-page-options': [7, 15, -1]
            },"dense":""},scopedSlots:_vm._u([{key:"item.acciones",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.printItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-printer-outline ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"pink white--text ","rounded":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")])],1)]},proxy:true}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }