import moment from "moment/moment";
import {
  getClientesIniciales,
  getClientesBySearch,
  getClientesById
} from "../axios-calls/clientes";
import { additionStrings } from "../tools/operationsUtils/additionStrings";

export default {
  namespaced: true,
  state: {
    clientes: [],
    lastGet: "none"
  },
  mutations: {
    refreshCache(state) {
      state.lastGet = "none";
    },
    delete(state, cliente) {
      const index = state.clientes.findIndex(c => c.id === cliente.id);
      if (index !== -1) {
        state.clientes.splice(index, 1); // Elimina el cliente en la posición encontrada
      }
    },
    setLastGet(state) {
      state.lastGet = moment();
    },
    setClientes(state, clientes) {
      state.clientes = clientes;
    },
    updateSaldoCliente(state, { clienteId, saldo }) {
      const cliente = state.clientes.find(cliente => cliente.id === clienteId);
      cliente.puntosSaldo = additionStrings(cliente.puntosSaldo, saldo);
    }
  },
  actions: {
    async getIniciales({ commit, state }) {
      const nuevosClientes = await getClientesIniciales();

      const nuevosClientesIds = new Set(
        nuevosClientes.map(cliente => cliente.id)
      );
      const clientesActualizados = state.clientes
        .filter(cliente => !nuevosClientesIds.has(cliente.id))
        .concat(nuevosClientes);

      commit("setClientes", clientesActualizados);
      return clientesActualizados;
    },
    async getBySearch({ commit, state }, search) {
      if (!search) return state.clientes;
      const nuevosClientes = await getClientesBySearch(search);

      const nuevosClientesIds = new Set(
        nuevosClientes.map(cliente => cliente.id)
      );
      const clientesActualizados = state.clientes
        .filter(cliente => !nuevosClientesIds.has(cliente.id))
        .concat(nuevosClientes);

      commit("setClientes", clientesActualizados);
      return clientesActualizados;
    },
    async getById({ commit, state }, id) {
      const nuevosClientes = await getClientesById(id);

      const nuevosClientesIds = new Set(
        nuevosClientes.map(cliente => cliente.id)
      );
      const clientesActualizados = state.clientes
        .filter(cliente => !nuevosClientesIds.has(cliente.id))
        .concat(nuevosClientes);
      commit("setClientes", clientesActualizados);
      return clientesActualizados;
    }
  },
  modules: {}
};
