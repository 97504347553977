<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      @keydown.esc="cerrar"
      @click:outside="cerrar"
      max-width="600px"
    >
      <!--<template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template>-->
      <v-card :loading="isLoading">
        <v-form ref="entryForm" v-model="valid" @submit.prevent="submitHandler">
          <v-card-title>
            <span class="headline">Agregar Especifico</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :items="grupos"
                    item-text="nombre"
                    item-value="id"
                    label="Grupo/Especie"
                    v-model="especifico.idGrupo"
                    :rules="groupRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre"
                    v-model="especifico.nombre"
                    :rules="nameRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-if="especifico.id == undefined"
            >
              Agregar
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitHandler" v-else>
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "ModalEspecificos",
  data: () => ({
    isLoading: false,
    isSending: false,
    valid: false,
    tipoRequest: "",
    idEspecifico: "0",
    grupos: [],
    nameRules: [v => !!v || "El nombre es requerido"],
    groupRules: [v => !!v || "El grupo es requerido"]
  }),
  computed: {
    open() {
      return this.$store.state.modals.especificos.open;
    },
    especifico() {
      return this.$store.state.modals.especificos.item;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.fetchGrupos();
    },
    async fetchGrupos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "grupos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.grupos = respuesta.data.rows;
      this.isLoading = false;
    },
    async insertEspecifico() {
      this.isSending = true;
      if (this.especifico.id != undefined) {
        this.tipoRequest = "update";
      } else {
        this.tipoRequest = "insert";
      }
      let myUrl = process.env.VUE_APP_API_URL + "especificos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: this.tipoRequest,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.especifico]
      });
      if (respuesta) {
        this.isSending = false;
        this.$store.state.modals["especificos"].callback();
        if (this.especifico.id == undefined) {
          this.$toasted.success("Especifico agregado", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Especifico actualizado", {
            position: "bottom-right",
            duration: 5000
          });
        }
        this.$refs.entryForm.reset();
        this.cerrar();
      }
    },
    async submitHandler() {
      let valido = await this.$refs.entryForm.validate();
      if (valido) {
        this.insertEspecifico();
      }
    },
    cerrar() {
      this.$store.commit("openModal", { modal: "especificos", open: false });
    }
  }
};
</script>
