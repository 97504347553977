import Vue from "vue";
import VueRouter from "vue-router";
import Panel from "../views/Panel.vue";
import Login from "../views/Login.vue";
import NotFound from "../layouts/NotFound.vue";
import Dashboard from "../layouts/Dashboard.vue";
import Mascotas from "../layouts/Mascotas/Mascotas.vue";
import Clientes from "../layouts/Clientes/Clientes.vue";
import Llamadas from "../layouts/Llamadas.vue";
import VentasHistorialEdicion from "../layouts/VentasHistorialEdicion.vue";
import Empleados from "../layouts/Empleados.vue";
import RegistrosEstetica from "../layouts/RegistrosEstetica.vue";
import RegistrosMedicos from "../layouts/RegistrosMedicos.vue";
import RegistrosMedicosCitas from "../layouts/RegistrosMedicosCitas.vue";
import ServicioDomicilio from "../layouts/ServicioDomicilio.vue";
import Grupos from "../layouts/Grupos.vue";
import Especificos from "../layouts/Especificos.vue";
import NuevaVenta from "../layouts/NuevaVenta/NuevaVenta.vue";
import Marcas from "../layouts/Marcas.vue";
import Comisiones from "../layouts/Comisiones.vue";
import ComisionesAsignadas from "../layouts/ComisionesAsignadas.vue";
import Gastos from "../layouts/Gastos.vue";
import Ventas from "../layouts/Ventas.vue";
import UsoInterno from "../layouts/UsoInterno.vue";
import Estetica from "../layouts/Estetica.vue";
import NuevaCompra from "../layouts/NuevaCompra/NuevaCompra.vue";
import Abonos from "../layouts/Abonos.vue";
import Productos from "../layouts/Productos.vue";
import Categorias from "../layouts/Categorias.vue";
import Subcategorias from "../layouts/Subcategorias.vue";
import Proveedores from "../layouts/Proveedores.vue";
import DetallesCliente from "../layouts/VerDetalles/DetallesCliente.vue";
import DetallesEmpleado from "../layouts/VerDetalles/DetallesEmpleado.vue";
import DetallesProducto from "../layouts/VerDetalles/DetallesProducto.vue";
import DetallesMascota from "../layouts/VerDetalles/DetallesMascota.vue"; /* 
import QRCodeExample from "../layouts/QRCodeExample.vue"; */
import Contador from "../layouts/Contador.vue";
import Expedientes from "../layouts/Expedientes.vue";
import QuimicaSanguinea from "../layouts/QuimicaSanguinea.vue";
import BiometriaHematica from "../layouts/BiometriaHematica.vue";
import FondoFijo from "../layouts/FondoFijo.vue";
import RangosFondoFijo from "../layouts/VerDetalles/DetallesFondoFijo.vue";
import ComprasCerradasPorPagar from "../layouts/ComprasCerradasPorPagar.vue";
import ReportesVentasConcentradas from "../layouts/ReportesVentasConcentradas.vue";
import ReportesLlamadas from "../layouts/ReportesLlamadas.vue";
import ReportesCompras from "../layouts/ReportesCompras.vue";
import ReportesEstetica from "../layouts/ReportesEstetica.vue";
import ReportesConsultas from "../layouts/ReportesConsultas.vue";
import ReportesAbonos from "../layouts/ReportesAbonos.vue";
import ReportesUsoInterno from "../layouts/ReportesUsoInterno.vue";
import ReportesGastos from "../layouts/ReportesGastos.vue";
import ReportesComisiones from "../layouts/ReportesComisiones.vue";
import ReportesCierresCajas from "../layouts/ReportesCierresCajas.vue";
import ReportesMovimientosProductos from "../layouts/ReportesMovimientosProductos.vue";
import CierresCajas from "../layouts/CierresCajas.vue";
import ReportesClientes from "../layouts/ReportesClientes.vue";
import ReportesMascotas from "../layouts/ReportesMascotas.vue";
import ReportesVentasTotales from "../layouts/ReportesVentasTotales.vue";
import ReportesTraspasosAlmacen from "../layouts/ReportesTraspasosAlmacen.vue";
import StockRapido from "../layouts/StockRapido.vue";
import PrecioRapido from "../layouts/PrecioRapido.vue";
import Permisos from "../layouts/Permisos.vue";
import Inventario from "../layouts/Inventario.vue";
import InventarioAlmacen from "../layouts/InventarioAlmacen.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/Login",
    name: "Login",
    component: Login
  },
  {
    path: "/",
    component: Panel,
    children: [
      {
        path: "/",
        name: "Home",
        component: Dashboard
      },
      {
        path: "/Contador",
        name: "Contador",
        component: Contador
      },
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: Dashboard
      },
      {
        path: "/Mascotas",
        name: "Mascotas",
        component: Mascotas
      },
      {
        path: "/Gastos",
        name: "Gastos",
        component: Gastos
      },
      {
        path: "/Abonos",
        name: "Abonos",
        component: Abonos
      },
      {
        path: "/Clientes",
        name: "Clientes",
        component: Clientes
      },
      {
        path: "/VentasHistorialEdicion",
        name: "VentasHistorialEdicion",
        component: VentasHistorialEdicion
      },
      {
        path: "/Llamadas",
        name: "Llamadas",
        component: Llamadas
      },
      {
        path: "/DetallesCliente/:id",
        name: "DetallesCliente",
        component: DetallesCliente
      },
      {
        path: "/DetallesEmpleado/:id",
        name: "DetallesEmpleado",
        component: DetallesEmpleado
      },
      {
        path: "/DetallesProducto/:id",
        name: "DetallesProducto",
        component: DetallesProducto
      },
      {
        path: "/DetallesMascota/:id",
        name: "DetallesMascota",
        component: DetallesMascota
      },
      {
        path: "/Empleados",
        name: "Empleados",
        component: Empleados
      },
      {
        path: "/Estetica",
        name: "Estetica",
        component: Estetica
      },
      {
        path: "/Registros",
        name: "Registros",
        component: RegistrosEstetica
      },
      {
        path: "/RegistrosMedicos",
        name: "RegistrosMedicos",
        component: RegistrosMedicos
      },
      {
        path: "/CitasMedicas",
        name: "RegistrosMedicosCitas",
        component: RegistrosMedicosCitas
      },
      {
        path: "/ServicioDomicilio",
        name: "ServicioDomicilio",
        component: ServicioDomicilio
      },
      {
        path: "/Venta/:id?",
        name: "Venta",
        component: NuevaVenta
      },
      {
        path: "/Marcas",
        name: "Marcas",
        component: Marcas
      },
      {
        path: "/Productos",
        name: "Productos",
        component: Productos
      },
      {
        path: "/Categorias",
        name: "Categorias",
        component: Categorias
      },
      {
        path: "/Subcategorias",
        name: "Subcategorias",
        component: Subcategorias
      },
      {
        path: "/Inventario",
        name: "Inventario",
        component: Inventario
      },
      {
        path: "/InventarioAlmacen",
        name: "InventarioAlmacen",
        component: InventarioAlmacen
      },
      {
        path: "/Grupos",
        name: "Grupos",
        component: Grupos
      },
      {
        path: "/Especificos",
        name: "Especificos",
        component: Especificos
      },
      {
        path: "/Proveedores",
        name: "Proveedores",
        component: Proveedores
      },
      {
        path: "/NuevaCompra/:id?",
        name: "NuevaCompra",
        component: NuevaCompra
      },
      {
        path: "/Compras",
        name: "ComprasCerradasPorPagar",
        component: ComprasCerradasPorPagar
      },
      {
        path: "/ComisionesCalculadas",
        name: "ComisionesCalculadas",
        component: Comisiones
      },
      {
        path: "/ReglasDeComisiones",
        name: "ReglasDeComisiones",
        component: ComisionesAsignadas
      },
      {
        path: "/Ventas",
        name: "Ventas",
        component: Ventas
      },
      {
        path: "/UsoInterno",
        name: "UsoInterno",
        component: UsoInterno
      },
      {
        path: "/Expedientes/:idMascota?/:idCliente?/:idMvz?/:new?",
        name: "Expedientes",
        component: Expedientes,
        props: true
      },
      {
        path: "/QuimicaSanguinea",
        name: "QuimicaSanguinea",
        component: QuimicaSanguinea
      },
      {
        path: "/BiometriaHematica",
        name: "BiometriaHematica",
        component: BiometriaHematica
      },
      {
        path: "/FondoFijo",
        name: "FondoFijo",
        component: FondoFijo
      },
      {
        path: "/RangosFondoFijo/:id",
        name: "RangosFondoFijo",
        component: RangosFondoFijo
      },
      {
        path: "/ReportesVentasConcentradas/",
        name: "ReportesVentasConcentradas",
        component: ReportesVentasConcentradas
      },
      {
        path: "/ReportesLlamadas/",
        name: "ReportesLlamadas",
        component: ReportesLlamadas
      },
      {
        path: "/ReportesCompras/",
        name: "ReportesCompras",
        component: ReportesCompras
      },
      {
        path: "/ReportesEstetica/",
        name: "ReportesEstetica",
        component: ReportesEstetica
      },
      {
        path: "/ReportesConsultas/",
        name: "ReportesConsultas",
        component: ReportesConsultas
      },
      {
        path: "/ReportesAbonos/",
        name: "ReportesAbonos",
        component: ReportesAbonos
      },
      {
        path: "/ReportesUsoInterno/",
        name: "ReportesUsoInterno",
        component: ReportesUsoInterno
      },
      {
        path: "/ReportesGastos/",
        name: "ReportesGastos",
        component: ReportesGastos
      },
      {
        path: "/ReportesComisiones/",
        name: "ReportesComisiones",
        component: ReportesComisiones
      },
      {
        path: "/ReportesCierresCajas/",
        name: "ReportesCierresCajas",
        component: ReportesCierresCajas
      },
      {
        path: "/CierresCajas/",
        name: "CierresCajas",
        component: CierresCajas
      },
      {
        path: "/ReportesClientes/",
        name: "ReportesClientes",
        component: ReportesClientes
      },
      {
        path: "/ReportesMascotas/",
        name: "ReportesMascotas",
        component: ReportesMascotas
      },
      {
        path: "/ReportesVentasTotales/",
        name: "ReportesVentasTotales",
        component: ReportesVentasTotales
      },
      {
        path: "/StockRapido/",
        name: "StockRapido",
        component: StockRapido
      },
      {
        path: "/PrecioRapido/",
        name: "PrecioRapido",
        component: PrecioRapido
      },
      {
        path: "/ReportesMovimientosProductos/",
        name: "ReportesMovimientosProductos",
        component: ReportesMovimientosProductos
      },
      {
        path: "/ReportesTraspasosAlmacen/",
        name: "ReportesTraspasosAlmacen",
        component: ReportesTraspasosAlmacen
      },
      {
        path: "/Permisos/",
        name: "Permisos",
        component: Permisos
      },
      {
        path: "/*",
        name: "Not Found",
        component: NotFound
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
