<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar producto"
          @keyup="handleSearchInput"
          :loading="isLoading"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-list>
      <v-virtual-scroll
        height="500"
        item-height="64"
        :items="productosFiltrados"
      >
        <template v-slot:default="{ item }">
          <v-list-item
            :key="item.id"
            :draggable="true"
            @dragstart="
              ev => {
                drag(ev, item.id);
              }
            "
            @dblclick="dblClickAdd(item)"
            style="cursor: grab"
            class="py-2"
          >
            <v-list-item-avatar
              :class="
                `${randomColor({
                  caseBase: item.nombre.toUpperCase()[2]
                })} white--text`
              "
            >
              {{ item.nombre.toUpperCase()[0] }}{{ item.nombre.toUpperCase()[1]
              }}{{ item.nombre.toUpperCase()[2] }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  item.stock <= item.minimo
                    ? item.stock <= 0
                      ? 'dangerBlink'
                      : 'warningBlink'
                    : item.stock >= item.maximo
                    ? 'successBlink'
                    : null
                "
                style="white-space: normal;"
              >
                ${{ item.precio }} - {{ item.nombre }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.compuesto }}
                {{ item.codigo }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <br />
            <v-list-item-action>
              <v-btn icon>
                <span
                  :class="
                    item.stock <= item.minimo
                      ? item.stock <= 0
                        ? 'dangerBlink'
                        : 'warningBlink'
                      : item.stock >= item.maximo
                      ? 'successBlink'
                      : null
                  "
                >
                  {{ item.controlStock == "Si" ? item.stock : "" }}
                </span>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-list>
  </div>
</template>

<script>
import getRandomColor from "../../tools/randomColor";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      search: "",
      headers: [],
      debouncing: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      productosStock: state => state.productos.productos
    }),
    productosFiltrados() {
      return this.productosStock.filter(product => {
        return (
          product.nombre.toLowerCase().includes(this.search.toLowerCase()) ||
          product.compuesto.toLowerCase().includes(this.search.toLowerCase()) ||
          product.codigo.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    }
  },
  methods: {
    ...mapActions("nuevaVenta", ["addProduct"]),
    handleSearchInput() {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchProductos();
      }, 500);
    },
    dblClickAdd(product) {
      this.addProduct({ product });
    },
    async fetchSearchProductos() {
      this.isLoading = true;
      await this.$store.dispatch("productos/getBySearch", this.search);
      this.isLoading = false;
    },
    randomColor(props) {
      return getRandomColor(props);
    },
    drag(ev, id) {
      ev.dataTransfer.setData("productId", id);
    }
  }
};
</script>
