<template>
  <v-col cols="12" sm="12">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card :loading="isLoading">
          <v-card-title>
            <h1>
              {{ toCaps(productos[0].nombre, { nombre: true }) }}
            </h1>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="3" v-if="false">
                <b>Categoría: </b> {{ productos[0].categoria }} <br />
                <b>Subcategoría: </b>{{ productos[0].subcategoria }} <br />
                <b>Marca: </b>{{ productos[0].marca }} <br />
                <b>Stock: </b>{{ productos[0].stock }} <br />
                <b>Precio de compra: </b>{{ productos[0].precioCompra }} <br />
                <b>Impuesto: </b>{{ productos[0].impuesto }}<br />
                <b>Unidad: </b> {{ productos[0].unidad }} <br />
                <h2>
                  <p><b>Precio: </b> {{ productos[0].precio }}</p>
                </h2>
              </v-col>
              <v-col cols="12" sm="12">
                <TablaMovimientos :idProducto="$route.params.id" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
import { proString } from "../../tools/proStrings";
import TablaMovimientos from "./DetallesProducto/TablaMovimientosProducto";

export default {
  name: "DetallesProducto",
  components: { TablaMovimientos },
  data: () => ({
    headers: [
      {
        text: "Nombre",
        align: "start",
        value: "nombre"
      },
      { text: "Raza", value: "raza" },
      { text: "Edad", value: "edad" },
      { text: "Sexo", value: "sexo" },
      { text: "Esteril", value: "esteril" },
      { text: "Acciones", value: "acciones" }
    ],
    productos: [
      {
        nombre: "Cargando...",
        tel1: "Cargando...",
        tel2: "Cargando...",
        direccion: "Cargando...",
        observaciones: "Cargando..."
      }
    ],
    mascotas: []
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.fetchProductos();
    },
    toCaps(myString, myOptions) {
      return proString(myString, myOptions);
    },
    async fetchProductos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getOne",
        id: this.$route.params.id,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.productos = respuesta.data.rows;
      this.isLoading = false;
    }
  }
};
</script>
