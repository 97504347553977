<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      @keydown.esc="cerrar"
      @click:outside="cerrar"
      max-width="600px"
    >
      <!--<template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template>-->
      <v-card :loading="isLoading">
        <v-form
          ref="entryForm"
          color="teal"
          v-model="valid"
          @submit.prevent="submitHandler"
        >
          <v-card-title>
            <span class="headline">Agregar Proveedor</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre"
                    v-model="proveedor.nombre"
                    :rules="nameRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Telefono 1"
                    v-model="proveedor.tel1"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Telefono 2"
                    v-model="proveedor.tel2"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Correo"
                    v-model="proveedor.correo"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Código interno"
                    v-model="proveedor.codInterno"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="RFC"
                    v-model="proveedor.rfc"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Código postal"
                    v-model="proveedor.codigoPostal"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Dirección"
                    v-model="proveedor.direccion"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Observaciones"
                    v-model="proveedor.observaciones"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-if="proveedor.id == undefined"
            >
              Agregar
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitHandler" v-else>
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "ModalProveedores",
  data: () => ({
    isLoading: false,
    isSending: false,
    valid: false,
    tipoRequest: "",
    idProveedor: "0",
    grupos: [],
    nameRules: [v => !!v || "El nombre es requerido"]
  }),
  computed: {
    open() {
      return this.$store.state.modals.proveedores.open;
    },
    proveedor() {
      return this.$store.state.modals.proveedores.item;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    toggleModal() {
      this.$store.commit("openModal", {
        modal: "proveedores",
        open: !this.open
      });
    },
    async insertProveedor() {
      this.isSending = true;
      if (this.proveedor.id != undefined) {
        this.tipoRequest = "update";
      } else {
        this.tipoRequest = "insert";
      }
      let myUrl = process.env.VUE_APP_API_URL + "proveedores.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: this.tipoRequest,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.proveedor]
      });
      if (respuesta) {
        this.isSending = false;
        this.$store.state.modals["proveedores"].callback();
        if (this.proveedor.id == undefined) {
          this.$toasted.success("Proveedor agregado", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Proveedor actualizado", {
            position: "bottom-right",
            duration: 5000
          });
        }
        this.$refs.entryForm.reset();
        this.cerrar();
      }
    },
    async submitHandler() {
      let valido = await this.$refs.entryForm.validate();
      if (valido) {
        this.insertProveedor();
      }
    },
    cerrar() {
      this.$store.commit("openModal", { modal: "proveedores", open: false });
    }
  }
};
</script>
