<style>
.parent {
  position: relative;
  overflow: visible;
}
.parent > div {
  position: absolute;
  top: 0;
  left: 0;
}
</style>

<template>
  <div>
    <v-autocomplete
      dense
      hide-details
      prepend-inner-icon="mdi-magnify"
      solo
      placeholder="Busca algo"
      :flat="!focused"
      :search-input.sync="search"
      @focus="prepare"
      @blur="focused = false"
      :items="opciones"
      item-text="nombre"
      item-value="nombre"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Intenta buscar algún cliente o mascota
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item link :to="item.link">
          <v-list-item-avatar
            :class="
              `${randomColor({
                caseBase: item.nombre.toUpperCase()[2]
              })} white--text`
            "
          >
            {{ item.nombre.toUpperCase()[0] }}{{ item.nombre.toUpperCase()[1] }}
          </v-list-item-avatar>
          <v-list-item-content @click="irSeccion(item.link)">
            <v-list-item-title>
              <NombreCliente
                :cliente="item"
                v-if="item.tipo == 'cliente'"
              ></NombreCliente>
              <NombreMascota
                :mascota="item"
                v-if="item.tipo == 'mascota'"
              ></NombreMascota>
            </v-list-item-title>
            <v-list-item-subtitle v-html="item.tipo"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import NombreCliente from "../layouts/Clientes/NombreCliente.vue";
import NombreMascota from "../layouts/Mascotas/NombreMascota.vue";
import getRandomColor from "../tools/randomColor";
import router from "../router";
export default {
  name: "BuscadorGeneral",
  components: { NombreCliente, NombreMascota },
  data: () => ({
    isLoading: false,
    search: "",
    focused: false,
    clientes: [],
    mascotas: [],
    open: false
    //itemsNuevaVenta: this.$store.state.itemsNuevaVenta,
  }),
  created() {
    this.initialize();
  },
  computed: {
    opciones() {
      let opciones = [];
      opciones = this.clientes.map(item => {
        return {
          ...item,
          nombre: item.nombre,
          tipo: "cliente",
          link: `/DetallesCliente/${item.id}`
        };
      });
      opciones = opciones.concat(
        this.mascotas.map(item => {
          return {
            ...item,
            nombre: item.nombre,
            tipo: "mascota",
            link: `/DetallesMascota/${item.id}`
          };
        })
      );
      return opciones;
    }
  },
  methods: {
    initialize() {},
    randomColor(props) {
      return getRandomColor(props);
    },
    irSeccion(link) {
      router.push(link);
    },
    testIfObject(item) {
      return typeof item !== "object";
    },
    async prepare() {
      this.focused = true;
      this.fetchClientes();
      this.fetchMascotas();
    },
    async fetchClientes() {
      this.isLoading = true;
      this.clientes = await this.$store.dispatch("clientes/getIniciales");
      this.isLoading = false;
    },
    async fetchMascotas() {
      this.isLoading = true;
      this.mascotas = await this.$store.dispatch("mascotas/getIniciales");
      this.isLoading = false;
    }
  }
};
</script>
