<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <h1>Reporte de Estética</h1>
              <br />
              <v-row>
                <v-col cols="12" sm="12" md="5" lg="5">
                  <label>Desde: </label>
                  <input type="date" v-model="desde" />
                </v-col>
                <v-col cols="12" sm="12" md="5" lg="5">
                  <label>Hasta: </label>
                  <input type="date" v-model="hasta" />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-btn
                    elevation="2"
                    small
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col>
                <v-col cols="12" sm="12" md="5" lg="5">
                  <v-chip>Número de registros: {{ numeroRegistros }}</v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="registros"
          :items-per-page="7"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          calculate-widths
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
export default {
  name: "ReportesEstetica",
  data: () => ({
    isLoading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    headers: [
      { text: "No. Ord.", value: "id" },
      { text: "Status", value: "status", width: "140px" },
      { text: "Propietario", value: "nombreCliente" },
      { text: "Estilista", value: "nombreEmpleado" },
      { text: "Telefono", value: "clienteTel" },
      { text: "Direccion", value: "direccion", width: "200px" },
      { text: "Mascota", value: "nombreMascota" },
      { text: "Raza", value: "raza" },
      { text: "Servicio", value: "servicio" },
      { text: "SAD", value: "sad" },
      { text: "Tamaño", value: "tamano", width: "110px" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Fuente", value: "fuente" },
      {
        text: "Chofer entrega",
        value: "nombreEmpleadoEntrega",
        sortable: false
      },
      {
        text: "Chofer recolecta",
        value: "nombreEmpleadoRecolecta",
        sortable: false
      },
      { text: "Detalles del corte", value: "detallesCorte" },
      { text: "Fecha registro", value: "fechaRegistro", width: "130px" },
      { text: "Fecha entrega", value: "fechaEntrega", width: "130px" },
      { text: "Código interno", value: "clienteCodigosInternos" },
      { text: "Golden", value: "isFirstTime" }
    ],
    registros: [],
    placeholderRegistros: [
      {
        id: "26760",
        status: "Finalizado",
        propietario: "Gerardo May",
        estilista: "Silvia Flor Rosales Poot",
        telefono: "9993247687",
        direccion: "C 27 N 272 X 10BY 10C VERGEL 2",
        mascota: "lassy",
        raza: "maltes",
        servicio: "Baño y Estética",
        limpiezaDental: "No",
        sad: "No",
        tamano: "Mediano",
        garrapaticida: "no",
        desenrredo: "no",
        fechaRegistro: "2021-10-22 13:28:01",
        fechaEntrega: "2021-10-22 17:00:00",
        codigoInterno: "0",
        costo: "140"
      },
      {
        id: "26759",
        status: "Finalizado",
        propietario: "Liam Ortiz",
        estilista: "Brenda Andrea López Linares",
        telefono: "9992760371",
        direccion: "C 15e #197e X 8b Y 8c Vergel 65",
        mascota: "JAKE",
        raza: "Poodle",
        servicio: "Baño y Estética",
        limpiezaDental: "No",
        sad: "No",
        tamano: "Mediano",
        garrapaticida: "no",
        desenrredo: "no",
        fechaRegistro: "2021-10-22 13:00:04",
        fechaEntrega: "2021-10-22 17:00:00",
        codigoInterno: "0",
        costo: "120"
      },
      {
        id: "26758",
        status: "Finalizado",
        propietario: "Arlene Beatriz Cocom",
        estilista: "Silvia Flor Rosales Poot",
        telefono: "9991014381",
        direccion: "C 29 c # 219 x 10a y 10b vergel 2	",
        mascota: "safari",
        raza: "Schnauzer",
        servicio: "Baño y Estética",
        limpiezaDental: "No",
        sad: "No",
        tamano: "Mediano",
        garrapaticida: "Normal",
        desenrredo: "no",
        fechaRegistro: "2021-10-22 11:46:59",
        fechaEntrega: "2021-10-22 17:00:00",
        codigoInterno: "0",
        costo: "155"
      }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchRegistros(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchRegistros(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  computed: {
    numeroRegistros() {
      return this.registros.length;
    }
  },
  methods: {
    async fetchRegistros(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllReporte",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta.data.status == "Ok") {
        this.registros = respuesta.data.rows;
        this.registros.forEach(registro => {
          registro.servicio = registro.conceptos
            .filter(
              concepto =>
                concepto.idSubcategoria == "5" || concepto.idSubcategoria == "6"
            )
            .map(concepto => concepto.nombre)
            .join(" ");
          registro.sad = registro.conceptos
            .filter(concepto => concepto.idSubcategoria == "8")
            .map(concepto => concepto.nombre)
            .join(" ");
        });
        this.isLoading = false;
      }
      //this.registros = this.placeholderRegistros;
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var registros = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, registros, "Estética");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.registros.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            // Verificar si el valor es numérico
            let valor = registro[header.value];
            if (!isNaN(valor) && valor !== "") {
              // Convertir el valor a número
              valor = Number(valor);
            }
            XLSX.utils.sheet_add_aoa(sheet, [[valor]], {
              // Agregar el valor al archivo
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-Estetica.xlsx`);
    },
    initialize() {
      this.fetchRegistros(
        moment()
          .subtract(1, "weeks")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }
};
</script>
