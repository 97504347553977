<style>
.parent {
  position: relative;
  overflow: visible;
}
.parent > div {
  position: absolute;
  top: 0;
  left: 0;
}
</style>

<template>
  <div>
    <ModalProveedores ref="modalProveedores" />
    <v-autocomplete
      hide-details
      prepend-icon="mdi-badge-account-horizontal"
      append-icon="mdi-magnify"
      append-outer-icon="mdi-account-plus"
      @click:append-outer="$refs.modalProveedores.toggleModal()"
      color="teal"
      :rules="rules"
      label="Proveedor"
      :search-input.sync="search"
      :items="proveedoresFiltrados"
      :value="proveedor"
      return-object
      item-text="nombre"
      v-on:change="handleChange"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Intenta buscar algún proveedor
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <template v-if="testIfObject(item)">
          <v-list-item>
            <v-list-item-content v-text="item"></v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item @click="selectProveedor(item)">
          <perfil
            :item="{
              nombre: item.nombre,
              imagen: '',
              tipo: 'mascotas',
              codigosInternos: codigosInternos(item.codigosInternos),
              tamano: 'sm'
            }"
          ></perfil>
          <v-list-item-content>
            <v-list-item-title v-html="item.nombre"></v-list-item-title>
            <v-list-item-subtitle v-html="item.tipo"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
import perfil from "../components/Perfil.vue";
import ModalProveedores from "../layouts/AgregarEditar/ModalProveedores.vue";
export default {
  components: { perfil, ModalProveedores },
  name: "SelectProveedor",
  props: {
    proveedor: {
      required: false
    },
    callback: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    rules: [v => v !== "" || "Selecciona un proveedor válido"],
    search: "",
    focused: false,
    proveedores: [],
    open: false,
    selectedProveedor: {}
    //itemsNuevaVenta: this.$store.state.itemsNuevaVenta,
  }),
  computed: {
    proveedoresFiltrados() {
      let mySearch = this.search;
      if (!mySearch) return this.proveedores;
      return this.proveedores.filter(item =>
        matchStrings({ s: mySearch, str: item.nombre })
      );
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.fetchProveedores();
    },
    testIfObject(item) {
      return typeof item !== "object";
    },
    async fetchProveedores() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "proveedores.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.proveedores = respuesta.data.rows;
      this.isLoading = false;
    },
    selectProveedor(proveedor) {
      this.$emit("update:proveedor", proveedor);
      this.selectedProveedor = { ...proveedor };
      this.open = false;
    },
    getProveedor() {
      return { ...this.selectedProveedor };
    },
    handleChange(val) {
      this.selectedProveedor = val;
      this.callback(val);
    },

    codigosInternos(cods) {
      if (cods?.length && cods?.length > 1) {
        try {
          const parsedCods = JSON.parse(cods);

          if (Array.isArray(parsedCods)) {
            return [...parsedCods];
          }
          return [];
        } catch (error) {
          return [];
        }
      }
      return [];
    }
  }
};
</script>
