<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <h1>Clientes</h1>
              <br />
              <v-row>
                <v-col cols="12" sm="5" md="3" lg="3">
                  <label>Desde: </label>
                  <input type="date" v-model="desde" />
                </v-col>
                <v-col cols="12" sm="5" md="3" lg="3">
                  <label>Hasta: </label>
                  <input type="date" v-model="hasta" />
                </v-col>
                <v-col cols="12" sm="5" md="3" lg="3">
                  <v-autocomplete
                    prepend-icon="mdi-account"
                    append-icon="mdi-magnify"
                    :items="clientes"
                    label="Cliente"
                    item-text="nombre"
                    item-value="nombre"
                    v-model="busquedaCliente"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                  <v-btn
                    elevation="2"
                    small
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="clientesFiltrados"
          :items-per-page="7"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          calculate-widths
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
import { matchStrings } from "../tools/proStrings";
export default {
  name: "ReportesClientes",
  data: () => ({
    loading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    busquedaCliente: "",
    headers: [
      { text: "Id", value: "id" },
      { text: "Registro", value: "fecha", width: "110px" },
      { text: "Nombre", value: "nombre" },
      { text: "Tel. 1", value: "tel1", width: "140px" },
      { text: "Tel. 2", value: "tel2", width: "140px" },
      { text: "Direccion", value: "direccion", width: "140px" },
      { text: "Puntos en saldo", value: "puntosSaldo", width: "140px" }
    ],
    clientes: [],
    empleados: [],
    placeholderClientes: [
      {
        id: "8594",
        fechaHora: "15/10/2021	14:31:48",
        fecha: "15/10/2021",
        hora: "14:31:48",
        nombre: "Claudia Cecilia Canul Ake",
        tel1: "9992162271",
        tel2: "9999097211",
        direccion: "C.71a No.291 X 65 Y 64a An Antonio Kauah 2",
        puntosSaldo: "0.00"
      },
      {
        id: "8594",
        fechaHora: "15/10/2021	14:31:48",
        fecha: "15/10/2021",
        hora: "14:31:48",
        nombre: "Cecilia Ortiz Lara",
        tel1: "9992500749",
        tel2: "9993173131",
        direccion: "avenida quetzalcoalt no.81 x 16 y 20 col.chichen itza",
        puntosSaldo: "0.00"
      },
      {
        id: "8598",
        fechaHora: "15/10/2021	14:31:48",
        fecha: "15/10/2021",
        hora: "14:31:48",
        nombre: "Fatima Pat Chim",
        tel1: "9995289598",
        tel2: "",
        direccion: "C.67 no.80 x 16 y 22 fidel velazques",
        puntosSaldo: "0.00"
      }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchClientes(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchClientes(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  computed: {
    clientesFiltrados() {
      let registrosSorteados = [...this.clientes];
      if (this.loading) return;

      registrosSorteados = registrosSorteados.filter(registro => {
        return (
          matchStrings({ s: this.busquedaCliente, str: registro.nombre }) ||
          this.busquedaCliente == ""
        );
      });
      return registrosSorteados;
    }
  },
  methods: {
    async fetchClientes(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "clientes.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllReporte",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta) {
        this.clientes = respuesta.data.rows;
        this.isLoading = false;
      }
      /* this.clientes = this.placeholderClientes; */
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var clientes = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, clientes, "Clientes");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.clientesFiltrados.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            // Verificar si el valor es numérico
            let valor = registro[header.value];
            if (!isNaN(valor) && valor !== "") {
              // Convertir el valor a número
              valor = Number(valor);
            }
            XLSX.utils.sheet_add_aoa(sheet, [[valor]], {
              // Agregar el valor al archivo
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-Clientes.xlsx`);
    },
    initialize() {
      this.fetchClientes(
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }
};
</script>
