import moment from "moment/moment";
import {
  getMascotasIniciales,
  getMascotasBySearch,
  getMascotasByOwnerId
} from "../axios-calls/mascotas";

export default {
  namespaced: true,
  state: {
    mascotas: [],
    lastGet: "none"
  },
  mutations: {
    refreshCache(state) {
      state.lastGet = "none";
    },
    setLastGet(state) {
      state.lastGet = moment();
    },
    setMascotas(state, mascotas) {
      state.mascotas = mascotas;
    }
  },
  actions: {
    async getIniciales({ commit, state }) {
      const nuevosMascotas = await getMascotasIniciales();
      const nuevosMascotasIds = new Set(
        nuevosMascotas.map(mascota => mascota.id)
      );
      const mascotasActualizados = state.mascotas
        .filter(mascota => !nuevosMascotasIds.has(mascota.id))
        .concat(nuevosMascotas);

      commit("setMascotas", mascotasActualizados);
      return mascotasActualizados;
    },
    async getBySearch({ commit, state }, search) {
      if (!search) return state.mascotas;
      const nuevosMascotas = await getMascotasBySearch(search);

      const mascotaIds = new Set(state.mascotas.map(mascota => mascota.id));
      const mascotasActualizados = state.mascotas.concat(
        nuevosMascotas.filter(mascota => !mascotaIds.has(mascota.id))
      );

      commit("setMascotas", mascotasActualizados);
      return mascotasActualizados;
    },
    async getByOwnerId({ commit, state }, idmascota) {
      const nuevosMascotas = await getMascotasByOwnerId(idmascota);

      const mascotaIds = new Set(state.mascotas.map(mascota => mascota.id));
      const mascotasActualizados = state.mascotas.concat(
        nuevosMascotas.filter(mascota => !mascotaIds.has(mascota.id))
      );

      commit("setMascotas", mascotasActualizados);
      return mascotasActualizados;
    }
  },
  modules: {}
};
