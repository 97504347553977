<template>
  <v-col cols="12" sm="12">
    <v-row>
      <ModalSaldoCliente
        ref="ModalSaldoCliente"
        :cliente="clientes[0]"
        :callback="initialize"
      />
      <v-col cols="12" sm="5">
        <v-card :loading="isLoading">
          <v-card-title>
            <h1>
              {{ toCaps(clientes[0].nombre, { nombre: true }) }}
            </h1>
          </v-card-title>

          <v-card-text>
            <br />
            <h2>
              <b>Saldo: </b>
              ${{ clientes[0].puntosSaldo }}
              <v-btn color="primary" icon @click="toggleModal"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </h2>
            <br />
            <p><b>Teléfono 1: </b> {{ clientes[0].tel1 }}</p>
            <p><b>Teléfono 2: </b>{{ clientes[0].tel2 }}</p>
            <p><b>Dirección: </b>{{ clientes[0].direccion }}</p>
            <p><b>Observaciones: </b>{{ clientes[0].observaciones }}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="7">
        <v-card>
          <v-card-title>Mascotas</v-card-title>
          <v-container fluid>
            <v-data-table
              :headers="headers"
              :items="mascotas"
              :items-per-page="7"
              sort-by="id"
              :sort-desc="true"
              :footer-props="{
                'items-per-page-text': 'Items por pagina',
                'items-per-page-options': [7, 15, -1]
              }"
              class="elevation-1"
              dense
            >
              <template v-slot:[`item.acciones`]="{ item }">
                <v-btn
                  icon
                  color="info"
                  link
                  :to="`/DetallesMascota/${item.id}`"
                >
                  <v-icon small class="mr-0">mdi-text-box</v-icon>
                </v-btn>
                <v-btn icon link color="info" @click="editItem(item)">
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn icon color="danger" @click="deleteItem(item)">
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <v-btn color="pink white--text " rounded @click="initialize">
                  <v-icon color="white"> mdi-refresh </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-title>Ultimas ventas a éste cliente</v-card-title>
          <v-container fluid>
            <v-data-table
              :headers="headersUltimasVentas"
              :items="ultimasVentas"
              :items-per-page="7"
              sort-by="id"
              :sort-desc="true"
              :footer-props="{
                'items-per-page-text': 'Items por pagina',
                'items-per-page-options': [7, 15, -1]
              }"
              class="elevation-1"
              dense
            >
              <template v-slot:[`item.acciones`]="{ item }">
                <v-btn icon @click="printItem(item)" color="info">
                  <v-icon small> mdi-printer-outline </v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <v-btn color="pink white--text " rounded @click="initialize">
                  <v-icon color="white"> mdi-refresh </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
import { proString } from "../../tools/proStrings";
import ModalSaldoCliente from "../AgregarEditar/ModalSaldoCliente.vue";
import printVenta from "../../tools/printTickets/printTicketVenta";

export default {
  components: { ModalSaldoCliente },
  name: "DetallesCliente",
  data: () => ({
    headers: [
      {
        text: "Nombre",
        align: "start",
        value: "nombre"
      },
      { text: "Raza", value: "especifico" },
      { text: "Grupo/Especifico", value: "grupo" },
      { text: "Edad", value: "edad" },
      { text: "Sexo", value: "sexo" },
      { text: "Esteril", value: "esteril" },
      { text: "Acciones", value: "acciones" }
    ],
    headersUltimasVentas: [
      { text: "Id", value: "id" },
      { text: "Cliente", value: "nombreCliente" },
      { text: "Status", value: "status" },
      { text: "Cajero", value: "nombreEmpleado" },
      { text: "Vendedor", value: "nombreVendedor" },
      { text: "Total", value: "montoTotal" },
      { text: "Abono", value: "montoAbono" },
      { text: "Saldo", value: "montoSaldo" },
      { text: "Fecha", value: "fechaHora" },
      { text: "Acciones", value: "acciones", align: "right" }
    ],
    clientes: [
      {
        nombre: "Cargando...",
        tel1: "Cargando...",
        tel2: "Cargando...",
        direccion: "Cargando...",
        observaciones: "Cargando..."
      }
    ],
    mascotas: [],
    ultimasVentas: []
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.fetchClientes();
      this.fetchUltimasVentas();
    },
    toCaps(myString, myOptions) {
      return proString(myString, myOptions);
    },

    toggleModal() {
      this.$refs.ModalSaldoCliente.toggleModal();
    },
    async actualizarCliente() {
      let myUrl = process.env.VUE_APP_API_URL + "clientes.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.clientes[0]]
      });
      if (respuesta.data.status == "Ok") {
        this.$store.state.modals["clientes"].callback();
        this.$toasted.success("Saldo actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      } else {
        this.$toasted.error("Ocurrió un error", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    async fetchClientes() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "clientes.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getOne",
        id: this.$route.params.id,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.clientes = respuesta.data.rows;
        this.fetchMascotas(this.clientes[0].id);
      }
      this.isLoading = false;
    },
    async fetchMascotas(idCliente) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "mascotas.php";
      const response = await axios.post(myUrl, {
        request: 1,
        tipo: "getMascotasDeCliente",
        token: localStorage.token,
        geo: localStorage.geo,
        idCliente: idCliente
      });
      if (response.data.status == "Ok") this.mascotas = response.data.rows;
      this.isLoading = false;
    },
    async fetchUltimasVentas() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getUltimasVentasDeCliente",
        token: localStorage.token,
        geo: localStorage.geo,
        idCliente: this.clientes[0].id
      });
      this.ultimasVentas = respuesta.data.rows;
      this.isLoading = false;
    },
    async printItem(item) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getOne",
        id: item.id,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        let venta = { ...respuesta.data };
        printVenta(venta, item);
      }
      this.isLoading = false;
    },
    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "mascotas",
        item: { ...myItem },
        callback: () => {
          this.$store.commit("mascotas/refreshCache");
          this.fetchMascotas();
        }
      });
      this.$store.commit("openModal", { modal: "mascotas", open: true });
    },
    async deleteItem(item) {
      if (!confirm(`¿Realmente deseas eliminar el registro ${item.nombre}?`)) {
        return;
      }
      this.$store.commit("mascotas/refreshCache");
      let myUrl = process.env.VUE_APP_API_URL + "mascotas.php";
      let response = await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: item.id
      });
      if (response.data.status == "Ok") {
        this.$toasted.success("Se eliminó exitosamente la mascota", {
          position: "bottom-right",
          duration: 5000
        });
        this.fetchMascotas();
      }
    }
  }
};
</script>
