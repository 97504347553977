<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      @keydown.esc="cerrar"
      @click:outside="cerrar"
      max-width="600px"
    >
      <v-card>
        <v-form
          ref="entryForm"
          color="teal"
          v-model="valid"
          @submit.prevent="submitHandler"
        >
          <v-card-title>
            <span class="headline">
              <span v-if="abono.id != undefined">Actualizar</span>
              <span v-else>Agregar</span> Abono</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Monto"
                    type="number"
                    v-model="abono.monto"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-select
                    label="Tipo de pago"
                    v-model="abono.tipo"
                    :items="tiposPagos"
                    item-text="nombre"
                    item-value="valor"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    :items="empleados"
                    label="Vendedor"
                    item-text="nombre"
                    item-value="id"
                    v-model="abono.idVendedor"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :items="[{ id: 0, nombre: '' }, ...empleados]"
                    label="Cajero"
                    item-text="nombre"
                    item-value="id"
                    v-model="abono.idEmpleado"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-if="abono.id == undefined"
              :disabled="isSending"
            >
              Agregar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-else
              :disabled="isSending"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "ModalAbonos",
  data: () => ({
    isLoading: false,
    isSending: false,
    valid: false,
    tipoRequest: "",
    empleados: [],
    tiposPagos: [
      { nombre: "Efectivo", valor: "efectivo" },
      { nombre: "Transferencia", valor: "transferencia" },
      { nombre: "Ize. Crédito", valor: "izCredito" },
      { nombre: "Ize. Débito", valor: "izDebito" },
      { nombre: "Depósito en efectivo", valor: "depEfectivo" },
      { nombre: "Tarjeta de crédito", valor: "credito" },
      { nombre: "Tarjeta de débito", valor: "debito" },
      { nombre: "Pago con puntos", valor: "puntosSaldo" }
    ]
  }),

  computed: {
    open() {
      return this.$store.state.modals.abonos.open;
    },

    abono() {
      return this.$store.state.modals.abonos.item;
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.fetchEmpleados();
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.empleados = respuesta.data.rows;
      this.isLoading = false;
    },
    async updateAbono() {
      this.isSending = true;

      let myUrl = process.env.VUE_APP_API_URL + "abonos.php";

      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.abono]
      });

      if (respuesta.data.status == "Ok") {
        this.isSending = false;

        this.$store.state.modals["abonos"].callback();

        if (this.abono.id == undefined) {
          this.$toasted.success("Abono agregado", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Abono actualizado", {
            position: "bottom-right",
            duration: 5000
          });
        }

        this.cerrar();
      }
    },
    async submitHandler() {
      let valido = await this.$refs.entryForm.validate();
      if (valido) {
        this.updateAbono();
      }
    },
    cerrar() {
      this.$store.commit("openModal", {
        modal: "abonos",
        open: false
      });
    }
  }
};
</script>
