<script>
import { getCodigoDeColorDeCliente } from "../../tools/randomColor";
export default {
  name: "NombreCliente",
  props: ["cliente"],
  data: () => ({}),
  methods: {
    parseCodigosInternos(str) {
      if (Number(str) > 10) return [];
      if (!str) return [];
      return JSON.parse(str);
    },
    internalColor(props) {
      return getCodigoDeColorDeCliente(props);
    }
  }
};
</script>
<template>
  <div class="nombre-cliente">
    {{ cliente.nombre }}
    <div
      :class="`bolita ${internalColor(codigo)}`"
      :key="index"
      v-for="(codigo, index) in parseCodigosInternos(cliente.codigosInternos)"
    >
      <div class="number-wrapper">
        {{ codigo }}
      </div>
    </div>
  </div>
</template>
<style>
.nombre-cliente {
  display: inline-block;
}
.bolita {
  display: inline-block;
  height: 10px;
  width: 10px;
  border: #999;
  border-radius: 50px;
  margin-left: 2px;
}
.number-wrapper {
  text-align: center;
  font-size: 7px;
  color: white;
}
</style>
