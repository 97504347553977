<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      @keydown.esc="cerrar"
      @click:outside="cerrar"
      max-width="600px"
      :retain-focus="false"
    >
      <v-card :loading="isLoading">
        <v-form ref="entryForm" v-model="valid" @submit.prevent="submitHandler">
          <v-card-title>
            <span class="headline">Agregar Cliente</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre"
                    v-model="cliente.nombre"
                    :rules="nameRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Telefono 1"
                    v-model="cliente.tel1"
                    :rules="telRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Telefono 2"
                    v-model="cliente.tel2"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Correo"
                    v-model="cliente.correo"
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>

                <SelectCodInternos
                  :valor="{ tipo: 'clientes' }"
                  ref="selectCodInternos"
                />

                <v-col cols="6">
                  <v-text-field
                    label="RFC"
                    v-model="cliente.rfc"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Código postal"
                    v-model="cliente.codigoPostal"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Dirección"
                    v-model="cliente.direccion"
                    :rules="addressRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Observaciones"
                    v-model="cliente.observaciones"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-if="cliente.id == undefined"
            >
              Agregar
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitHandler" v-else>
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import { internalCodeColor } from "../../tools/randomColor";
import SelectCodInternos from "../../components/SelectCodInternos.vue";
import { setTimeout } from "timers";
export default {
  name: "ModalClientes",
  components: { SelectCodInternos },
  data: () => ({
    isLoading: false,
    isSending: false,
    valid: false,
    tipoRequest: "",
    codes: [],
    cliente: {
      codigoPostal: "",
      correo: "",
      direccion: "",
      nombre: "",
      tel1: "",
      tel2: "",
      codigosInternos: []
    },
    nameRules: [v => !!v || "El nombre es requerido"],
    telRules: [
      v => !!v || "El num. telefónico es requerido",
      v =>
        /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
        "Ingrese un número de teléfono válido"
    ],
    emailRules: [
      v => /.+@.+/i.test(v) || !v || !v.trim() || "El email debe ser válido"
    ],
    addressRules: [v => !!v || "La dirección es requerida"]
  }),
  computed: {
    open() {
      return this.$store.state.modals.clientes.open;
    },
    codigos() {
      return this.internalCodeColor({
        tipo: "clientes",
        todos: "true"
      });
    }
  },
  created() {
    this.initialize();
  },
  watch: {
    open(val) {
      if (val) this.setClienteFromStore();
    }
  },
  methods: {
    initialize() {},
    setClienteFromStore() {
      this.cliente = JSON.parse(
        JSON.stringify(this.$store.state.modals.clientes.item)
      );
      this.cliente.codigosInternos = JSON.parse(
        this.cliente.codigosInternos || "[]"
      );
      setTimeout(() => {
        this.$refs.selectCodInternos.setArray(
          this.cliente.codigosInternos || "[]"
        );
      }, 100);
    },
    getCliente() {
      return { ...this.cliente };
    },
    async insertCliente() {
      this.isSending = true;

      if (this.cliente.id != undefined) {
        this.tipoRequest = "update";
      } else {
        this.tipoRequest = "insert";
      }
      this.cliente.codigosInternos = this.$refs.selectCodInternos.getArray();
      let codigosInternos = JSON.stringify(this.cliente.codigosInternos);
      let myUrl = process.env.VUE_APP_API_URL + "clientes.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: this.tipoRequest,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [{ ...this.cliente, codigosInternos }]
      });
      if (respuesta.data.status == "Ok") {
        this.isSending = false;
        this.$store.state.modals["clientes"].callback();
        if (this.cliente.id == undefined) {
          this.$toasted.success("Cliente agregado", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Cliente actualizado", {
            position: "bottom-right",
            duration: 5000
          });
        }
        this.cerrar();
      }
    },
    async submitHandler() {
      let valido = await this.$refs.entryForm.validate();
      if (valido) {
        this.insertCliente();
      }
    },
    internalCodeColor(props) {
      return internalCodeColor(props);
    },
    cerrar() {
      this.$store.commit("openModal", { modal: "clientes", open: false });
    }
  }
};
</script>
