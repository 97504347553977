<style>
.capitalized {
  text-transform: capitalize;
}
</style>
<template>
  <div>
    <v-data-table :loading="isLoading" :items="movimientos" :headers="headers">
      <template v-slot:[`item.status`]="{ item }">
        <span class="capitalized">{{ getStatusMovimiento(item) }}</span>
      </template>
      <template v-slot:[`item.idVenta`]="{ item }">
        <span>{{ Number(item.idVenta) > 0 ? item.idVenta : "-" }}</span>
      </template>
      <template v-slot:[`item.idCompra`]="{ item }">
        <span>{{ Number(item.idCompra) > 0 ? item.idCompra : "-" }}</span>
      </template>
      <template v-slot:[`item.cantidad`]="{ item }">
        <span>{{ getCantidad(item) }}</span>
      </template>
      <template v-slot:[`item.monto`]="{ item }">
        <span>{{ item.monto * Math.sign(item.cantidad) }}</span>
      </template>
      <template v-slot:[`item.netoUnidad`]="{ item }">
        <span>{{ item.netoUnidad * Math.sign(getCantidad(item)) }}</span>
      </template>
      <template v-slot:[`item.proveedorCliente`]="{ item }">
        <span class="capitalized">{{ getNombreClienteProveedor(item) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TablaMovimientos",
  props: ["idProducto"],
  data: () => ({
    isLoading: false,
    movimientos: [],
    headers: [
      { text: "Fecha", align: "start", value: "fecha" },
      {
        text: "Movimiento",
        value: "status"
      },
      {
        text: "Id venta",
        value: "idVenta"
      },
      {
        text: "Id compra",
        value: "idCompra"
      },
      {
        text: "Proveedor/Cliente",
        value: "proveedorCliente"
      },
      {
        text: "Concepto",
        value: "nombre"
      },
      {
        text: "Cantidad",
        value: "cantidad"
      },
      {
        text: "Neto",
        value: "monto"
      },
      {
        text: "Neto unidad",
        value: "netoUnidad"
      }
    ]
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.fetchMovimientos();
    },
    getStatusMovimiento(item) {
      if (item.idVenta > 0) return "Venta " + item.status;
      if (item.idCompra > 0) return "Compra " + item.status;

      return item.tipoMovimiento;
    },
    getNombreClienteProveedor(item) {
      if (item.nombreCliente) return item.nombreCliente;
      if (item.nombreProveedor) return item.nombreProveedor;
      return "-";
    },
    getCantidad(item) {
      if (item.idCompra > 0) return Number(item.cantidad);
      if (item.idVenta > 0) return Number(item.cantidad) * -1;
      return Number(item.cantidad);
    },
    async fetchMovimientos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getProductMovements",
        id: this.idProducto,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.movimientos = respuesta.data.rows;
      }
      this.isLoading = false;
    }
  }
};
</script>
