<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table full-width>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID Error</th>
              <th class="text-left">Empleado que reportó</th>
              <th class="text-left">Fecha y hora de reporte</th>
              <th class="text-left">Sección</th>
              <th class="text-left">Error</th>
              <th class="text-left">Cómo Reproducir</th>
              <th class="text-left">Funcionamiento Adecuado</th>
              <th class="text-left">Nivel de urgencia</th>
              <th class="text-left">Resuelto</th>
              <th class="text-left">Respuesta</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(error, index) in erroresReportados" :key="index">
              <td>{{ error.id }}</td>
              <td>{{ error.nombreEmpleado }}</td>
              <td>{{ error.fechaReporte }}</td>
              <td>{{ error.seccion }}</td>
              <td>{{ error.error }}</td>
              <td>{{ error.comoReproducir }}</td>
              <td>{{ error.funcionamientoAdecuado }}</td>
              <td>{{ error.afecta }}</td>
              <td>
                <v-icon v-if="error.resuelto == '1'" color="success" small>
                  mdi-thumb-up
                </v-icon>
                <v-icon v-if="error.resuelto == '-1'" color="error" small>
                  mdi-thumb-down
                </v-icon>
              </td>
              <td class="infoBlink">{{ error.respuesta }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="12" class="d-flex justify-center">
      <v-btn
        color="info"
        outlined
        small
        @click="fetchErroresReportados"
        :disabled="isFetching"
      >
        Cargar errores más recientes
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "Dashboard",
  data: () => ({
    erroresReportados: [],
    isFetching: false
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.fetchErroresReportados();
    },

    async fetchErroresReportados() {
      this.isFetching = true;
      let myUrl = process.env.VUE_APP_API_URL + "erroresReportados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo,
        errorReportado: this.errorReportado
      });
      if (respuesta.data.status == "Ok") {
        this.erroresReportados = respuesta.data.rows;
      }
      setTimeout(() => {
        this.isFetching = false;
      }, 1000);
    }
  }
};
</script>
