<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Precio rápido</h1>
              <br />
              <v-text-field
                v-model="search"
                @keyup="handleSearchInput"
                label="Buscar"
                append-icon="mdi-magnify"
                color="pink darken-4"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="porcentaje"
                :min="0"
                :max="100"
                label="Incrementar precio por %"
                suffix="%"
                type="number"
                dense
              >
                <template v-slot:append-outer>
                  <v-btn icon @click="risePriceByPercent">
                    <v-icon>mdi-arrow-up-bold-outline</v-icon>
                  </v-btn>
                  <v-btn icon @click="revertPriceByPercent">
                    <v-icon>mdi-arrow-down-bold</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="productos"
          :items-per-page="7"
          :search="search"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          :loading="isLoading"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.precio`]="{ item }">
            <input
              type="number"
              :value="item.precio"
              @blur="
                e => {
                  updatePrecio(e, item);
                }
              "
            />
          </template>
          <template v-slot:[`item.precioCompra`]="{ item }">
            <input
              type="number"
              :value="item.precioCompra"
              @blur="
                e => {
                  updatePrecioCompra(e, item);
                }
              "
            />
          </template>
          <template v-slot:[`item.minimo`]="{ item }">
            <input
              type="number"
              :value="item.minimo"
              @change="
                e => {
                  handleMinimoChange(e, item);
                }
              "
            />
          </template>
          <template v-slot:[`item.maximo`]="{ item }">
            <input
              type="number"
              :value="item.maximo"
              @change="
                e => {
                  handleMaximoChange(e, item);
                }
              "
            />
          </template>
          <template v-slot:[`item.controlPrecio`]="{ item }">
            <select type="number" :value="item.controlPrecio">
              <option value="Si">Si</option>
              <option value="No">No</option>
            </select>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="pink darken-4 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
import { mapState } from "vuex";
export default {
  name: "Productos",
  data: () => ({
    search: "",
    isLoading: false,
    porcentaje: 0,
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Compuesto", value: "compuesto" },
      { text: "Categoría", value: "categoria" },
      { text: "Subcategoría", value: "subcategoria" },
      { text: "Marca", value: "marca" },
      { text: "Código", value: "codigo" },
      { text: "Unidad", value: "unidad" },
      { text: "Precio de venta con impuesto", value: "precio" },
      {
        text: "Precio de compra sin impuesto",
        value: "precioCompra"
      },
      { text: "Mínimo", value: "minimo" },
      { text: "Máximo", value: "maximo" }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", { name: "productos", search: val });
    }
  },
  computed: {
    ...mapState("productos", ["productos"])
  },
  methods: {
    initialize() {
      this.fetchProductos();
      this.search = this.$store.state.searchParams["productos"];
    },
    async risePriceByPercent() {
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "risePriceByPercent",
        porcentaje: this.porcentaje,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success(
          "Se cambió el precio de todos los productos por el porcentaje seleccionado",
          {
            position: "bottom-right",
            duration: 5000
          }
        );
        this.fetchProductos();
      }
    },
    async revertPriceByPercent() {
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "revertPriceByPercent",
        porcentaje: this.porcentaje,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Se revirtió el porcentaje dado", {
          position: "bottom-right",
          duration: 5000
        });
        this.fetchProductos();
      }
    },
    async setVisible(item) {
      if (item.isVisible == "1") item.isVisible = "0";
      else item.isVisible = "1";
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update",
        items: [item],
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) {
        if (item.isVisible == "1") {
          this.$toasted.success("Ahora es visible en buscadores", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.error("Ahora es invisible en buscadores", {
            position: "bottom-right",
            duration: 5000
          });
        }
      }
    },
    async setEstetica(item) {
      if (item.isEstetica == "1") item.isEstetica = "0";
      else item.isEstetica = "1";
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update",
        items: [item],
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) {
        if (item.isEstetica == "1") {
          this.$toasted.success("Ahora es seleccionable en estética", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.error("Ahora no es seleccionable en estética", {
            position: "bottom-right",
            duration: 5000
          });
        }
      }
    },
    async fetchProductos() {
      this.isLoading = true;
      await this.$store.dispatch("productos/getProductos");
      this.isLoading = false;
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "productos",
        item: {},
        callback: this.fetchProductos
      });

      this.$store.commit("openModal", { modal: "productos", open: true });
    },
    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "productos",
        item: { ...myItem },
        callback: this.fetchProductos
      });
      this.$store.commit("openModal", { modal: "productos", open: true });
    },
    async deleteItem(id) {
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id,
        callback: this.fetchProductos
      });
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },
    async updatePrecio(e, item) {
      console.log("updating precio");
      const nuevoPrecio = Number(e.target.value);
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "updatePrecio",
        token: localStorage.token,
        geo: localStorage.geo,
        id: item.id,
        nuevoPrecio
      });
      this.$toasted.success("Precio actualizado", {
        position: "bottom-right",
        duration: 5000
      });
    },
    async updatePrecioCompra(e, item) {
      console.log("updating precio compra");
      const nuevoPrecio = Number(e.target.value);
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "updatePrecioCompra",
        token: localStorage.token,
        geo: localStorage.geo,
        id: item.id,
        nuevoPrecio
      });
      this.$toasted.success("Precio actualizado", {
        position: "bottom-right",
        duration: 5000
      });
    },

    async handleMinimoChange(e, item) {
      console.log("manejando minimo");
      const nuevoMinimo = Number(e.target.value);
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "updateMinimo",
        token: localStorage.token,
        geo: localStorage.geo,
        id: item.id,
        nuevoMinimo
      });
      this.$toasted.success("Mínimo actualizado", {
        position: "bottom-right",
        duration: 5000
      });
    },

    async handleMaximoChange(e, item) {
      console.log("manejando maximo");
      const nuevoMaximo = Number(e.target.value);
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "updateMaximo",
        token: localStorage.token,
        geo: localStorage.geo,
        id: item.id,
        nuevoMaximo
      });
      this.$toasted.success("Máximo actualizado", {
        position: "bottom-right",
        duration: 5000
      });
    },

    handleSearchInput() {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchProductos();
      }, 500);
    },
    async fetchSearchProductos() {
      this.isLoadingSearch = true;
      await this.$store.dispatch("productos/getBySearch", this.search);
      this.isLoadingSearch = false;
    }
  }
};
</script>
