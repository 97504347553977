<style>
.camposEditables {
  min-width: 140px;
}

.conceptos {
  min-width: 220px;
}

.nombreEstilista {
  border-bottom: 1px solid grey;
}
</style>
<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="4">
              <h1>Servicios de Estética</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="green darken-3"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <div>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left"></th>
                        <th
                          class="text-left"
                          v-for="item in Object.keys(contadoresEstilistas)"
                          :key="item"
                        >
                          {{ item }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Baños</td>
                        <td
                          v-for="item in Object.keys(contadoresEstilistas)"
                          :key="item + contadoresEstilistas[item].banios"
                        >
                          {{ contadoresEstilistas[item].banios }}
                        </td>
                      </tr>
                      <tr>
                        <td>Cortes</td>
                        <td
                          v-for="item in Object.keys(contadoresEstilistas)"
                          :key="item + contadoresEstilistas[item].cortes"
                        >
                          {{ contadoresEstilistas[item].cortes }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
        </v-card-title>

        <v-simple-table
          :items="serviciosEsteticaSorteados"
          fixed-header
          dense
          height="70vh"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Id</th>
                <th class="text-left">Hora de registro</th>
                <th class="text-left">Propietario</th>
                <th class="text-left">Teléfono</th>
                <th class="text-left">Mascota</th>
                <th class="text-left">Específico</th>
                <th class="text-left">Servicios</th>
                <th class="text-left">Adicionales</th>
                <th class="text-left">Detalles del corte</th>
                <th class="text-left">SAD</th>
                <th class="text-left">Observaciones</th>
                <th class="text-left">Status</th>
                <th class="text-left" style="width: 200px !important">
                  Actualizar hora de entrega
                </th>
                <th class="text-left" style="width: 100px !important;">
                  Estilista
                </th>
                <th class="text-left">Listo</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, ind) in serviciosEsteticaSorteados"
                :key="ind"
                :class="
                  `${getRowColor(item)} ${
                    compareTimes({
                      time1: item.horaEntrega.slice(-8),
                      time2: actualTime(),
                      range: '40m'
                    }) && item.status != 'En Espera'
                      ? ''
                      : null
                  }`
                "
              >
                <td>
                  {{ item.idCount }}
                </td>
                <td>
                  {{
                    timeConvert({
                      time: item.horaRegistro.slice(-8),
                      seconds: false
                    })
                  }}
                </td>
                <td>
                  <NombreCliente
                    :cliente="{
                      nombre: item.nombreCliente,
                      codigosInternos: item.codigosInternosCliente
                    }"
                  />
                </td>
                <td>{{ item.clienteTel }}</td>
                <td>
                  <NombreMascota
                    :mascota="{
                      nombre: item.nombre,
                      codigosInternos: item.codigosInternosMascota
                    }"
                  />
                </td>
                <td>{{ item.raza }}</td>
                <td class="conceptos">
                  <div
                    v-for="(servicio, index) in item.conceptos.filter(
                      concepto =>
                        concepto.idSubcategoria == '5' ||
                        concepto.idSubcategoria == '6'
                    )"
                    :key="index"
                  >
                    <v-btn
                      x-small
                      elevation="0"
                      plain
                      @click="actualizarCumpleDoble(servicio)"
                    >
                      <v-icon
                        small
                        v-if="parseInt(servicio.cumple) > 1"
                        color="success"
                      >
                        mdi-checkbox-marked-circle
                      </v-icon>
                      <v-icon
                        small
                        v-if="servicio.cumple == '1'"
                        color="warning"
                      >
                        mdi-minus-circle
                      </v-icon>
                      <v-icon small v-if="servicio.cumple == '0'" color="error">
                        mdi-circle-outline
                      </v-icon>

                      <div v-if="servicio.nombre.includes('Baño y cepillado')">
                        <v-icon
                          small
                          v-if="servicio.cumple == '4'"
                          color="success"
                        >
                          mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon
                          small
                          v-if="servicio.cumple == '3'"
                          color="warning"
                        >
                          mdi-minus-circle
                        </v-icon>
                        <v-icon
                          small
                          v-if="parseInt(servicio.cumple) < 3"
                          color="error"
                        >
                          mdi-circle-outline
                        </v-icon>
                      </div>
                    </v-btn>
                    {{ servicio.nombre }}
                  </div>
                </td>
                <td class="conceptos">
                  <div
                    v-for="(adicional, index) in item.conceptos.filter(
                      concepto => concepto.idSubcategoria == '7'
                    )"
                    :key="index"
                  >
                    <v-btn x-small icon @click="actualizarCumple(adicional)">
                      <v-icon
                        small
                        v-if="adicional.cumple == '1'"
                        color="success"
                      >
                        mdi-checkbox-marked-circle
                      </v-icon>
                      <v-icon
                        small
                        v-if="adicional.cumple !== '1'"
                        color="error"
                        >mdi-circle-outline
                      </v-icon>
                    </v-btn>
                    {{ adicional.nombre }}
                  </div>
                </td>
                <td>
                  <v-text-field
                    v-model="item.detallesCorte"
                    class="camposEditables"
                    @blur="updateFields(item)"
                  ></v-text-field>
                </td>
                <td class="conceptos">
                  <span
                    v-for="servicio in item.conceptos.filter(
                      concepto => concepto.idSubcategoria == '8'
                    )"
                    :key="servicio.id"
                  >
                    {{ servicio.nombre }} <br />
                  </span>
                </td>
                <td>
                  <v-text-field
                    v-model="item.observaciones"
                    class="camposEditables"
                    @blur="updateFields(item)"
                  ></v-text-field>
                </td>
                <td>{{ item.status }}</td>
                <td>
                  <input
                    type="time"
                    :value="item.horaEntrega.slice(-8).slice(0, -3)"
                    @blur="
                      event => {
                        updateTime(event.target.value, item);
                      }
                    "
                  />
                </td>
                <td>
                  <select
                    class="nombreEstilista"
                    :id="'input-estilista_' + item.id"
                    @focus="
                      event => {
                        inputTouchedId = 'input-estilista_' + item.id;
                        prevVal = event.target.value;
                      }
                    "
                    @change="
                      event => {
                        estilistaIdToUpdate = event.target.value;
                        itemToUpdate = item;
                        isVerifyModalOpen = true;
                        verifyModalCallback = () => {
                          actualizarEstilista(event.target.value, item);
                        };
                      }
                    "
                  >
                    <option value="0"></option>
                    <option
                      v-for="(estilista, ind) in estilistas"
                      :key="ind"
                      :value="estilista.id"
                      :selected="estilista.id == item.idEmpleado"
                    >
                      {{ estilista.nombre }}
                    </option>
                  </select>
                </td>
                <td>
                  <v-btn
                    small
                    icon
                    :color="item.status == 'En Servicio' ? 'error' : 'success'"
                    @click="toggleStatus(item)"
                  >
                    <v-icon v-if="item.status == 'En Servicio'"
                      >mdi-circle-outline</v-icon
                    >
                    <v-icon v-if="item.status == 'En Espera'">
                      mdi-checkbox-marked-circle</v-icon
                    >
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
    <v-dialog v-model="isVerifyModalOpen" max-width="520px">
      <v-card>
        <v-card-title>
          Por favor, ingresa credenciales que tengan permiso para ésta acción
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="usuario" label="Usuario"></v-text-field>
          <v-text-field
            v-model="password"
            label="Contraseña"
            type="password"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :loading="isLoading"
            @click="cancelarCambioEstilista()"
            :disabled="isVerifyBtnDisabled"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="danger"
            text
            :loading="isLoading"
            @click="pedirPermiso()"
            :disabled="isVerifyBtnDisabled"
          >
            Cambiar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import { compareStringsTimes, getActualTimeString } from "../tools/proTimes";
import { matchStrings, ampmConvert } from "../tools/proStrings";
import NombreCliente from "./Clientes/NombreCliente.vue";
import NombreMascota from "./Mascotas/NombreMascota.vue";
export default {
  name: "Estetica",
  components: { NombreCliente, NombreMascota },
  data: () => ({
    search: "",
    isLoading: false,
    tipoRequest: "",
    valid: false,
    statusArray: [
      "Cancelado",
      "En Recolecta",
      "Recolectado",
      "En servicio",
      "En espera",
      "En entrega",
      "Entregado",
      "Finalizado"
    ],
    estilistas: [],
    serviciosEstetica: [],
    contBanos: [],
    contCortes: [],
    contRecolecta: [],
    contServicio: [],
    contEspera: [],
    contEntregadas: [],
    contadoresEstilistas: {},

    servicios: [
      { id: "1", nombre: "Baño y Cepillado", cumple: "0" },
      { id: "2", nombre: "Corte", cumple: "1" }
    ],
    adicionales: [
      { id: "2", nombre: "Limpieza Dental", cumple: "0" },
      { id: "2", nombre: "Garrapaticida", cumple: "1" }
    ],

    /* Para manejar el cambio de estilista, sorry por los nombres culeros de variables, tenía prisa */
    prevVal: 0,
    inputTouchedId: "",
    updateDialog: false,
    estilistaIdToUpdate: 0,
    itemToUpdate: {},
    usuario: " ",
    password: " ",
    isVerifyModalOpen: false,
    isVerifyBtnDisabled: false,
    verifyModalCallback: () => {}
  }),
  created() {
    this.initialize();
  },
  computed: {
    serviciosEsteticaSorteados() {
      let serviciosNoEnEspera = [...this.serviciosEstetica].filter(
        item => item.status != "En Espera"
      );
      let serviciosEnEspera = [...this.serviciosEstetica].filter(
        item => item.status == "En Espera"
      );

      let serviciosSorteados = serviciosNoEnEspera.concat(serviciosEnEspera);

      serviciosSorteados = serviciosSorteados.filter(servicio => {
        if (this.search == "") return true;
        let searchToLower = this.search.toLowerCase();
        return (
          servicio.nombre.toLowerCase().includes(searchToLower) ||
          servicio.nombreCliente.toLowerCase().includes(searchToLower) ||
          servicio.raza.toLowerCase().includes(searchToLower)
        );
      });

      return serviciosSorteados;
    },
    contadorRecolecta() {
      let count = 0;
      this.serviciosEsteticaSorteados.map(item => {
        item.status == "En Recolecta" ? count++ : null;
      });
      return count;
    },
    contadorServicio() {
      let count = 0;
      this.serviciosEsteticaSorteados.map(item => {
        item.status == "En Servicio" ? count++ : null;
      });
      return count;
    },
    contadorEspera() {
      let count = 0;
      this.serviciosEsteticaSorteados.map(item => {
        item.status == "En Espera" ? count++ : null;
      });
      return count;
    },
    contadorEntregadas() {
      let count = 0;
      this.serviciosEsteticaSorteados.map(item => {
        item.status == "Entregado" ? count++ : null;
      });
      return count;
    }
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", { name: "estetica", search: val });
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.delayedSearch = val;
      }, 600);
    }
  },
  methods: {
    initialize() {
      this.fetchServiciosEstetica();
      this.fetchEstilistas();
    },
    actualTime() {
      return getActualTimeString();
    },
    timeConvert(time) {
      return ampmConvert(time);
    },
    compareTimes(options) {
      return compareStringsTimes(options);
    },
    async fetchServiciosEstetica() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllToday",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.serviciosEstetica = respuesta.data.rows.filter(
          row => row.status == "En Espera" || row.status == "En Servicio"
        );
        this.contarServiciosPorEstilista(this.serviciosEsteticaSorteados);
        this.contarTotalesSemanales();
        this.isLoading = false;
      }
    },
    getRowColor({ isFirstTime, status }) {
      if (
        status.toLowerCase() == "en entrega" ||
        status.toLowerCase() == "recolectado" ||
        status.toLowerCase() == "entregado"
      )
        return "infoBlink";
      else if (status.toLowerCase() == "finalizado") return "successBlink";
      else if (isFirstTime == "1") return "goldenBlink";
      return null;
    },
    async fetchEstilistas() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllEstilistas",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.estilistas = respuesta.data.rows;
      }
      this.isLoading = false;
    },
    async actualizarCumple(item) {
      if (item.cumple == "0") {
        item.cumple = "1";
      } else if (item.cumple == "1") {
        item.cumple = "0";
      }

      let myUrl =
        process.env.VUE_APP_API_URL + "serviciosEsteticaConceptos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Cumplimiento actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      } else {
        this.$toasted.error("Ocurrió un problema", {
          position: "bottom-right",
          duration: 5000
        });
      }

      this.contarServiciosPorEstilista(this.serviciosEsteticaSorteados);
    },

    async actualizarCumpleDoble(item) {
      let realInt = 0;

      if (item.nombre.includes("Baño y cepillado")) {
        if (item.cumple == "4") {
          item.cumple = "0";
        } else {
          realInt = parseInt(item.cumple);
          realInt += 1;
          item.cumple = realInt.toString();
        }
      } else {
        if (item.cumple == "2") {
          item.cumple = "0";
        } else {
          realInt = parseInt(item.cumple);
          realInt += 1;
          item.cumple = realInt.toString();
        }
      }

      let myUrl =
        process.env.VUE_APP_API_URL + "serviciosEsteticaConceptos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta) {
        this.$toasted.success("Cumplimiento actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      } else {
        this.$toasted.error("Ocurrió un problema", {
          position: "bottom-right",
          duration: 5000
        });
      }

      this.contarServiciosPorEstilista(this.serviciosEsteticaSorteados);
    },
    async pedirPermiso() {
      if (!this.usuario || !this.password) {
        return this.$toasted.error("Introduce un usuario y/o contraseña", {
          position: "bottom-right",
          duration: 1
        });
      }
      this.isVerifyBtnDisabled = true;
      this.$toasted.show("Cargando...", {
        position: "bottom-right",
        duration: 5000
      });

      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "testUpdatePermisson",
        token: localStorage.token,
        geo: localStorage.geo,
        usuario: this.usuario.trim(),
        password: this.password
      });
      if (respuesta.data.permisson == "Ok") {
        this.verifyModalCallback();
        this.isVerifyModalOpen = false;
      } else {
        this.$toasted.error("Datos incorrectos", {
          position: "bottom-right",
          duration: 2000
        });
      }
      this.isVerifyBtnDisabled = false;
    },
    abrirModalPermiso() {
      this.updateDialog = true;
    },
    cancelarCambioEstilista() {
      document.getElementById(this.inputTouchedId).value = this.prevVal;
      this.isVerifyModalOpen = false;
    },
    async actualizarEstilista(idEmpleado, item) {
      item.idEmpleado = idEmpleado;
      item.nombreEmpleado =
        this.estilistas.filter(estilista => estilista.id == idEmpleado)[0]
          ?.nombre ?? "";
      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update minor",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta) {
        this.$toasted.success("Estilista actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      } else {
        this.$toasted.error("Ocurrió un problema", {
          position: "bottom-right",
          duration: 5000
        });
      }
      this.contarServiciosPorEstilista(this.serviciosEsteticaSorteados);
      this.updateDialog = false;
      this.usuario = " ";
      this.password = " ";
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "empleados",
        item: {},
        callback: this.fetchEmpleados
      });

      this.$store.commit("openModal", { modal: "empleados", open: true });
    },
    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "empleados",
        item: { ...myItem },
        callback: this.fetchEmpleados
      });
      this.$store.commit("openModal", { modal: "empleados", open: true });
    },
    async deleteItem(id) {
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id,
        callback: this.fetchEmpleados
      });
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },
    async updateTime(time, item) {
      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update time",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [{ ...item, horaEntrega: time }]
      });
      if (respuesta.data.status) {
        this.isLoading = false;
        this.$toasted.success("Registro actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    async updateFields(item) {
      this.isSending = true;

      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update minor",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta) {
        this.isLoading = false;
        this.$toasted.success("Registro actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    async toggleStatus(item) {
      if (item.idEmpleado == 0 && item.status == "En Servicio") {
        this.$toasted.error("Seleccione un empleado", {
          position: "bottom-right",
          duration: 5000
        });
      } else {
        this.isSending = true;
        if (item.status == "En Servicio") {
          item.status = "En Espera";
        } else if (item.status == "En Espera") {
          item.status = "En Servicio";
        }
        let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
        let respuesta = await axios.post(myUrl, {
          request: 1,
          tipo: "update minor",
          token: localStorage.token,
          geo: localStorage.geo,
          items: [item]
        });
        if (respuesta) {
          this.isLoading = false;
          this.$toasted.success("Registro actualizado", {
            position: "bottom-right",
            duration: 5000
          });
          this.initialize();
        }
      }
    },

    codigosInternos(cods) {
      if (cods?.length && cods?.length > 1) {
        try {
          const parsedCods = JSON.parse(cods);

          if (Array.isArray(parsedCods)) {
            return [...parsedCods];
          }
          return [];
        } catch (error) {
          return [];
        }
      }
      return [];
    },

    async contarServiciosPorEstilista(serviciosDeEsteticaSorteados) {
      const resultados = {};

      for (const servicio of serviciosDeEsteticaSorteados) {
        // Asigna el nombre del estilista a una cadena vacía si es nulo o undefined
        const nombreEstilista = servicio.nombreEmpleado || "No asignado";

        if (!resultados[nombreEstilista]) {
          resultados[nombreEstilista] = { banios: 0, cortes: 0 };
        }
        if (servicio.status == "Cancelado") continue;
        for (const concepto of servicio.conceptos) {
          if (!concepto.nombre) continue;
          if (concepto.nombre.toLowerCase().includes("baño")) {
            resultados[nombreEstilista].banios++;
          }
          if (concepto.nombre.toLowerCase().includes("corte")) {
            resultados[nombreEstilista].cortes++;
          }
          if (concepto.nombre.toLowerCase().includes("rapado")) {
            resultados[nombreEstilista].cortes++;
          }
          if (concepto.nombre.toLowerCase().includes("rapada")) {
            resultados[nombreEstilista].cortes++;
          }
        }
      }

      const keyTotalDiario = "Total diario";
      resultados[keyTotalDiario] = { banios: 0, cortes: 0 };
      Object.keys(resultados).forEach(estilista => {
        if (estilista != keyTotalDiario) {
          resultados[keyTotalDiario].banios += resultados[estilista].banios;
          resultados[keyTotalDiario].cortes += resultados[estilista].cortes;
        }
      });
      this.contadoresEstilistas = resultados;
      this.contarTotalesSemanales();
    },
    async contarTotalesSemanales() {
      const keyTotalSemanal = "Total semanal";
      this.contadoresEstilistas[keyTotalSemanal] = {
        banios: this.contadoresEstilistas[keyTotalSemanal]?.banios || 0,
        cortes: this.contadoresEstilistas[keyTotalSemanal]?.cortes || 0
      };
      let myUrl = process.env.VUE_APP_API_URL + "serviciosEstetica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getServiciosSemanales",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.contadoresEstilistas[keyTotalSemanal].banios =
          respuesta.data.banios;
        this.contadoresEstilistas[keyTotalSemanal].cortes =
          respuesta.data.cortes;
        this.contadoresEstilistas = { ...this.contadoresEstilistas };
      }
    }
  }
};
</script>
