<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Especificos</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="blue"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                elevation="2"
                small
                color="blue white--text "
                @click="addItem()"
                >Agregar especifico</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="especificos"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :header-props="headerProps"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 250, 500, -1]
          }"
          dense
        >
          <template v-slot:[`item.detalles`]="{ item }">
            <v-btn icon @click="editItem(item)">
              <v-icon small color="blue"> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon @click="deleteItem(item.id)">
              <v-icon small color="danger"> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="blue my-6" rounded @click="initialize">
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <modal-especificos />
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
import ModalEspecificos from "../layouts/AgregarEditar/ModalEspecificos";
export default {
  components: { ModalEspecificos },
  name: "Especificos",
  data: () => ({
    search: "",
    isLoading: false,
    tipoRequest: "",
    valid: false,
    formData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigoInterno: "",
      rfc: "",
      codigoPostal: "",
      direccion: "",
      observaciones: ""
    },
    emptyData: {
      id: "",
      nombre: "",
      tel1: "",
      tel2: "",
      correo: "",
      codigosInternos: [],
      rfc: "",
      codigoPostal: "",
      direccion: "",
      observaciones: ""
    },
    nameRules: [],
    phoneRules: [],
    emailRules: [],
    zipcodeRules: [],
    addressRules: [],
    headerProps: {
      sortByText: "Ordenar por"
    },
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Grupo", value: "nombreGrupo", align: "center" },
      {
        text: "Acciones",
        value: "detalles",
        align: "right",
        width: "12%"
      }
    ],
    especificos: [],
    cli: {}
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "especificos",
        search: val
      });
    }
  },
  methods: {
    initialize() {
      this.fetchEspecificos();
      this.search = this.$store.state.searchParams["especificos"];
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "especificos",
        item: {},
        callback: this.fetchEspecificos
      });

      this.$store.commit("openModal", { modal: "especificos", open: true });
    },
    async fetchEspecificos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "especificos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.especificos = respuesta.data.rows;
      this.isLoading = false;
    },
    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "especificos",
        item: { ...myItem },
        callback: this.fetchEspecificos
      });
      this.$store.commit("openModal", { modal: "especificos", open: true });
    },
    async deleteItem(id) {
      let myUrl = process.env.VUE_APP_API_URL + "especificos.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id,
        callback: this.fetchEspecificos
      });
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    }
  }
};
</script>
