<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Stock rápido</h1>
              <br />
              <v-text-field
                v-model="search"
                @keyup="handleSearchInput"
                label="Buscar"
                append-icon="mdi-magnify"
                color="pink darken-4"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                elevation="2"
                small
                color="pink darken-4"
                dark
                @click="addItem()"
                >Agregar producto</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="productos"
          :items-per-page="7"
          :search="search"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          :loading="isLoading"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.precio`]="{ item }">
            <input type="number" :value="item.precio" />
          </template>
          <template v-slot:[`item.stock`]="{ item }">
            <input
              type="number"
              :value="item.stock"
              @change="
                e => {
                  handleStockChange(e, item, 2);
                }
              "
            />
          </template>
          <template v-slot:[`item.stockAlmacen`]="{ item }">
            <input
              type="number"
              :value="item.stockAlmacen"
              @change="
                e => {
                  handleStockChange(e, item, 1);
                }
              "
            />
          </template>
          <template v-slot:[`item.minimo`]="{ item }">
            <input
              type="number"
              :value="item.minimo"
              @change="
                e => {
                  handleMinimoChange(e, item);
                }
              "
            />
          </template>
          <template v-slot:[`item.maximo`]="{ item }">
            <input
              type="number"
              :value="item.maximo"
              @change="
                e => {
                  handleMaximoChange(e, item);
                }
              "
            />
          </template>
          <template v-slot:[`item.controlStock`]="{ item }">
            <select type="number" :value="item.controlStock">
              <option value="Si">Si</option>
              <option value="No">No</option>
            </select>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon @click="setVisible(item)">
              <v-icon small v-if="item.isVisible == '1'" color="success">
                mdi-eye
              </v-icon>
              <v-icon small v-if="item.isVisible !== '1'" color="error">
                mdi-eye-off
              </v-icon>
            </v-btn>
            <v-btn icon @click="setEstetica(item)">
              <v-icon
                small
                :color="item.isEstetica == '1' ? 'success' : 'error'"
              >
                mdi-content-cut
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="pink darken-4 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <modal-productos />
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings } from "../tools/proStrings";
import ModalProductos from "./AgregarEditar/ModalProductos.vue";
import { mapState } from "vuex";
export default {
  name: "Productos",
  components: { ModalProductos },
  data: () => ({
    search: "",
    isLoading: false,
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Compuesto", value: "compuesto" },
      { text: "Categoría", value: "categoria" },
      { text: "Subcategoría", value: "subcategoria" },
      { text: "Marca", value: "marca" },
      { text: "Código", value: "codigo" },
      { text: "Unidad", value: "unidad" },
      { text: "Precio publico", value: "precio" },
      { text: "Stock en Tienda actual", value: "stock" },
      { text: "Stock en Almacen", value: "stockAlmacen" },
      { text: "Mínimo", value: "minimo" },
      { text: "Máximo", value: "maximo" },
      { text: "Control Stock", value: "controlStock" },
      { text: "Acciones", value: "acciones", align: "right", width: "140px" }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", { name: "productos", search: val });
    }
  },
  computed: {
    ...mapState("productos", ["productos"])
  },
  methods: {
    initialize() {
      this.fetchProductos();
      this.search = this.$store.state.searchParams["productos"];
    },
    async setVisible(item) {
      if (item.isVisible == "1") item.isVisible = "0";
      else item.isVisible = "1";
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update",
        items: [item],
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) {
        if (item.isVisible == "1") {
          this.$toasted.success("Ahora es visible en buscadores", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.error("Ahora es invisible en buscadores", {
            position: "bottom-right",
            duration: 5000
          });
        }
      }
    },
    async setEstetica(item) {
      if (item.isEstetica == "1") item.isEstetica = "0";
      else item.isEstetica = "1";
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update",
        items: [item],
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) {
        if (item.isEstetica == "1") {
          this.$toasted.success("Ahora es seleccionable en estética", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.error("Ahora no es seleccionable en estética", {
            position: "bottom-right",
            duration: 5000
          });
        }
      }
    },
    async fetchProductos() {
      this.isLoading = true;
      await this.$store.dispatch("productos/getProductos");
      this.isLoading = false;
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "productos",
        item: {},
        callback: this.fetchProductos
      });

      this.$store.commit("openModal", { modal: "productos", open: true });
    },
    async editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "productos",
        item: { ...myItem },
        callback: this.fetchProductos
      });
      this.$store.commit("openModal", { modal: "productos", open: true });
    },
    async deleteItem(id) {
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id,
        callback: this.fetchProductos
      });
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },

    async handleMinimoChange(e, item) {
      console.log("manejando minimo");
      const nuevoMinimo = Number(e.target.value);
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "updateMinimo",
        token: localStorage.token,
        geo: localStorage.geo,
        id: item.id,
        nuevoMinimo
      });
      this.$toasted.success("Mínimo actualizado", {
        position: "bottom-right",
        duration: 5000
      });
    },

    async handleMaximoChange(e, item) {
      console.log("manejando maximo");
      const nuevoMaximo = Number(e.target.value);
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "updateMaximo",
        token: localStorage.token,
        geo: localStorage.geo,
        id: item.id,
        nuevoMaximo
      });
      this.$toasted.success("Máximo actualizado", {
        position: "bottom-right",
        duration: 5000
      });
    },

    async handleStockChange(e, item, idUbicacionProducto = 2) {
      // Almacen = 1, Tienda = 2
      console.log("stock cambiando");
      const nuevoStock = Number(e.target.value);
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "updateStock",
        token: localStorage.token,
        geo: localStorage.geo,
        id: item.id,
        nuevoStock,
        idUbicacionProducto
      });
      const succesMsgs = {
        1: "Stock en Almacén actualizado",
        2: "Stock en Tienda actualizado"
      };
      this.$toasted.success(succesMsgs[idUbicacionProducto], {
        position: "bottom-right",
        duration: 5000
      });
    },

    handleSearchInput() {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchProductos();
      }, 500);
    },
    async fetchSearchProductos() {
      this.isLoadingSearch = true;
      await this.$store.dispatch("productos/getBySearch", this.search);
      this.isLoadingSearch = false;
    }
  }
};
</script>
