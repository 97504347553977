<style>
.camposEditables {
  min-width: 240px;
}
</style>
<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Registros médicos</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="black"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                elevation="2"
                small
                color="black white--text "
                @click="addItem()"
                >Agregar registro médico</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-simple-table :items="registros" fixed-header dense height="70vh">
          <template v-slot:default>
            <tbody>
              <!-- SAD -->
              <tr class="indigo white--text">
                <th class="text-center" colspan="19">SAD</th>
              </tr>
              <tr class="indigo white--text">
                <th class="text-left">Id del día</th>
                <th class="text-left">Hora de registro</th>
                <th class="text-left">Registrado por</th>
                <th class="text-left">Id Propietario</th>
                <th class="text-left">Propietario</th>
                <th class="text-left">Dirección</th>
                <th class="text-left">Teléfono</th>
                <th class="text-left">Mascota</th>
                <th class="text-left">Raza</th>
                <th class="text-left">Sexo</th>
                <th class="text-left">Peso</th>
                <th class="text-left">Servicio</th>
                <th class="text-left">Tipo de servicio</th>
                <th class="text-left">Motivo de servicio</th>
                <th class="text-left">Observaciones</th>
                <th class="text-left">MVZ</th>
                <th class="text-left">SAD</th>
                <th class="text-left">Status</th>
                <th class="text-left">Acciones</th>
              </tr>
              <tr
                v-for="(item, ind) in registrosSorteados.filter(
                  registro => registro.isSAD
                )"
                :key="ind"
                :class="
                  `${getRowColor(item)} ${
                    !compareTimes({
                      time1: item.horaSalaEspera.slice(-8),
                      time2: actualTime(),
                      range: '20m'
                    }) && item.status == 'Sala De Espera'
                      ? 'dangerBlink'
                      : null
                  }`
                "
              >
                <td>
                  {{ item.idCount }}
                </td>
                <td>
                  {{
                    timeConvert({
                      time: item.horaRegistro.slice(-8),
                      seconds: false
                    })
                  }}
                </td>
                <td>
                  {{ item.nombreResponsableRegistro }}
                </td>
                <td>
                  {{ item.idCliente }}
                </td>
                <td>
                  <NombreCliente
                    :cliente="{
                      nombre: item.nombreCliente,
                      codigosInternos: item.codigosInternosCliente
                    }"
                  />
                </td>
                <td>
                  {{ item.direccion }}
                </td>
                <td>{{ item.clienteTel }}</td>
                <td>
                  <NombreMascota
                    :mascota="{
                      nombre: item.nombre,
                      codigosInternos: item.codigosInternosMascota
                    }"
                  />
                </td>
                <td>{{ item.raza }}</td>
                <td>{{ item.sexo }}</td>
                <td>{{ item.peso }}</td>

                <td>
                  {{ item.servicio }}
                </td>
                <td>
                  {{ item.tipoServicio }}
                </td>
                <td>
                  {{ item.motivoServicio }}
                </td>
                <td>
                  {{ item.observaciones }}
                </td>
                <td>
                  <v-select
                    v-model="item.idEmpleado"
                    :items="[{ id: '0', nombre: 'Sin asignar' }, ...empleados]"
                    item-value="id"
                    item-text="nombre"
                    dense
                    @change="actualizarStatus(item)"
                  />
                </td>

                <td>
                  <span
                    v-for="servicio in item.conceptos.filter(
                      concepto => concepto.idSubcategoria == '16'
                    )"
                    :key="`concepto${servicio.id}`"
                  >
                    {{ servicio.nombre }} <br />
                  </span>
                </td>
                <td>
                  <v-select
                    style="min-width: 150px;"
                    v-model="item.status"
                    :items="statusArray"
                    dense
                    @change="actualizarStatus(item)"
                  />
                </td>
                <td style="width: 110px">
                  <v-btn icon color="blue darken-1" @click="editItem(item)">
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="warning"
                    @click="
                      () => {
                        redirectExpediente(item);
                      }
                    "
                  >
                    <v-icon small> mdi-folder </v-icon>
                  </v-btn>
                  <!-- <v-btn icon color="danger" @click="deleteItem(item)">
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn> -->
                </td>
                <!-- <td>none</td> -->
              </tr>

              <!-- Registros -->
              <tr class="black white--text">
                <th class="text-center" colspan="19">Registros</th>
              </tr>
              <tr class="black white--text">
                <th class="text-left">Id del día</th>
                <th class="text-left">Hora de registro</th>
                <th class="text-left">Registrado por</th>
                <th class="text-left">Id del propietario</th>
                <th class="text-left">Propietario</th>
                <th class="text-left">Dirección</th>
                <th class="text-left">Teléfono</th>
                <th class="text-left">Mascota</th>
                <th class="text-left">Raza</th>
                <th class="text-left">Sexo</th>
                <th class="text-left">Peso</th>
                <th class="text-left">Servicio</th>
                <th class="text-left">Tipo de servicio</th>
                <th class="text-left">Motivo de servicio</th>
                <th class="text-left">Observaciones</th>
                <th class="text-left">MVZ</th>
                <th class="text-left">SAD</th>
                <th class="text-left">Status</th>
                <th class="text-left">Acciones</th>
              </tr>
              <tr
                v-for="(item, ind) in registrosSorteados.filter(
                  registro => !registro.isSAD
                )"
                :key="`registrosSorteados$${ind}`"
                :class="getRowColor(item)"
              >
                <td>
                  {{ item.idCount }}
                  {{ item.conCita == 1 ? "(con cita)" : null }}
                </td>
                <td>
                  {{
                    timeConvert({
                      time: item.horaRegistro.slice(-8),
                      seconds: false
                    })
                  }}
                </td>
                <td>
                  {{ item.nombreResponsableRegistro }}
                </td>
                <td>
                  {{ item.idCliente }}
                </td>
                <td>
                  <NombreCliente
                    :cliente="{
                      nombre: item.nombreCliente,
                      codigosInternos: item.codigosInternosCliente
                    }"
                  />
                </td>
                <td>
                  {{ item.direccion }}
                </td>
                <td>{{ item.clienteTel }}</td>
                <td>
                  <NombreMascota
                    :mascota="{
                      nombre: item.nombre,
                      codigosInternos: item.codigosInternosMascota
                    }"
                  />
                </td>
                <td>{{ item.raza }}</td>
                <td>{{ item.sexo }}</td>
                <td>{{ item.peso }}</td>

                <td>
                  {{ item.servicio }}
                </td>
                <td>
                  {{ item.tipoServicio }}
                </td>
                <td>
                  {{ item.motivoServicio }}
                </td>
                <td>
                  {{ item.observaciones }}
                </td>
                <td>
                  <v-select
                    v-model="item.idEmpleado"
                    :items="[{ id: '0', nombre: 'Sin asignar' }, ...empleados]"
                    item-value="id"
                    item-text="nombre"
                    dense
                    @change="actualizarStatus(item)"
                  />
                </td>

                <td>
                  <span
                    v-for="servicio in item.conceptos.filter(
                      concepto => concepto.idSubcategoria == '16'
                    )"
                    :key="`${item.id}concepto${servicio.id}`"
                  >
                    {{ servicio.nombre }} <br />
                  </span>
                </td>
                <td>
                  <v-select
                    style="min-width: 150px;"
                    v-model="item.status"
                    :items="statusArray"
                    dense
                    @change="actualizarStatus(item)"
                  />
                </td>
                <td style="width: 110px">
                  <v-btn icon color="blue darken-1" @click="editItem(item)">
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="warning"
                    @click="
                      () => {
                        redirectExpediente(item);
                      }
                    "
                  >
                    <v-icon small> mdi-folder </v-icon>
                  </v-btn>
                  <!-- <v-btn icon color="danger" @click="deleteItem(item)">
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn> -->
                </td>
                <!-- <td>none</td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { matchStrings, ampmConvert } from "../tools/proStrings";
import { compareStringsTimes, getActualTimeString } from "../tools/proTimes";
import NombreCliente from "./Clientes/NombreCliente.vue";
import NombreMascota from "./Mascotas/NombreMascota.vue";
export default {
  name: "RegistrosClínica",
  components: { NombreCliente, NombreMascota },
  data: () => ({
    search: "",
    loading: false,
    isLoading: false,
    tipoRequest: "",
    valid: false,
    registros: [],
    contBanos: [],
    contCortes: [],
    empleados: [],
    statusArray: [
      "Cancelado",
      "En Recolecta",
      "Recolectado",
      "En Consulta",
      "Sala De Espera",
      "Atendido",
      "En Entrega",
      "Entregado",
      "Finalizado"
    ],
    servicios: ["Baño", "Baño y cepillado", "Limpieza dental"]
  }),
  created() {
    this.initialize();
  },
  computed: {
    registrosSorteados() {
      let registrosNoFinalizados = [...this.registros]?.filter(
        item => item.status != "Finalizado"
      );
      let registrosFinalizados = [...this.registros]?.filter(
        item => item.status == "Finalizado"
      );

      let registrosSorteados = registrosNoFinalizados.concat(
        registrosFinalizados
      );
      if (this.loading) return [];
      registrosSorteados = registrosSorteados?.filter(registro => {
        return (
          matchStrings({ s: this.search, str: registro.nombreCliente }) ||
          matchStrings({ s: this.search, str: registro.clienteTel }) ||
          matchStrings({ s: this.search, str: registro.nombreEmpleado }) ||
          this.search == "" ||
          this.search == null
        );
      });
      console.log(registrosSorteados);
      return registrosSorteados;
    }
  },
  watch: {
    search: function(val) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 350);

      this.$store.commit("setSearchParams", {
        name: "servicioClinica",
        search: val
      });
    }
  },
  methods: {
    initialize() {
      this.fetchServiciosClinica();
      this.fetchEmpleados();
      this.search = this.$store.state.searchParams["servicioClinica"];
    },
    timeConvert(time) {
      return ampmConvert(time);
    },
    compareTimes(options) {
      return compareStringsTimes(options);
    },
    actualTime() {
      return getActualTimeString();
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllMvz",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.empleados = respuesta.data.rows.filter(
          empleado =>
            empleado.departamento == "clinica" ||
            empleado.departamento == "Clínica" ||
            empleado.departamento == "Clinica"
        );
      }
      this.isLoading = false;
    },
    async fetchServiciosClinica() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "serviciosClinica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllToday",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.registros = respuesta.data.rows.map(itm => {
          itm.conceptos.filter(concepto => concepto.idSubcategoria == "16")
            .length > 0
            ? (itm.isSAD = true)
            : (itm.isSAD = false);
          return itm;
        });
        this.isLoading = false;
      }
    },

    async actualizarStatus(item) {
      let myUrl = process.env.VUE_APP_API_URL + "serviciosClinica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update minor",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Status actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      } else {
        this.$toasted.error("Ocurrió un problema", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    async actualizarMvz(item) {
      let myUrl = process.env.VUE_APP_API_URL + "serviciosClinica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "update minor",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Médico actualizado", {
          position: "bottom-right",
          duration: 5000
        });
      } else {
        this.$toasted.error("Ocurrió un problema", {
          position: "bottom-right",
          duration: 5000
        });
      }
    },
    redirectExpediente(someObject) {
      someObject.nombreServicio = someObject.servicio;
      someObject.fecha = someObject.horaRegistro.slice(0, -9);
      someObject.hora = this.timeConvert({
        time: someObject.horaRegistro.slice(-8),
        seconds: false
      }).slice(0, -3);

      /* someObject.hora = this.timeConvert({
        time: someObject.horaRegistro.slice(-8),
        seconds: false
      }); */

      this.$store.commit("setRouteObj", {
        ...someObject
      });
      this.$router.push({
        name: "Expedientes",
        params: {
          new: "new"
        }
      });
    },
    async addItem() {
      this.$store.commit("setItemModal", {
        modal: "servicioClinica",
        item: {},
        callback: this.fetchServiciosClinica
      });

      this.$store.commit("openModal", {
        modal: "servicioClinica",
        open: true
      });
    },
    async editItem(myItem) {
      myItem.idsServicios = myItem.conceptos
        .map(itm => {
          if (itm.idSubcategoria == "5" || itm.idSubcategoria == "6")
            return itm.idProductoServicio;
        })
        .filter(item => item);
      myItem.idsAdicionales = myItem.conceptos
        .map(itm => {
          if (itm.idSubcategoria == "7") return itm.idProductoServicio;
        })
        .filter(item => item);
      myItem.idsSAD = myItem.conceptos
        .map(itm => {
          if (itm.idSubcategoria == "16") return itm.idProductoServicio;
        })
        .filter(item => item);

      this.$store.commit("setItemModal", {
        modal: "servicioClinica",
        item: { ...myItem },
        callback: this.fetchServiciosClinica
      });
      this.$store.commit("openModal", {
        modal: "servicioClinica",
        item: { ...myItem },
        open: true
      });
    },
    async deleteItem(id) {
      let myUrl = process.env.VUE_APP_API_URL + "servicioClinica.php";
      await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        id: id,
        callback: this.fetchServiciosClinica
      });
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },
    getRowColor({ isFirstTime, status }) {
      if (
        status.toLowerCase() == "en entrega" ||
        status.toLowerCase() == "recolectado" ||
        status.toLowerCase() == "entregado"
      )
        return "infoBlink";
      else if (status.toLowerCase() == "finalizado") return "successBlink";
      else if (isFirstTime == "1") return "goldenBlink";
      return null;
    },
    codigosInternos(cods) {
      if (cods?.length && cods?.length > 1) {
        try {
          const parsedCods = JSON.parse(cods);

          if (Array.isArray(parsedCods)) {
            return [...parsedCods];
          }
          return [];
        } catch (error) {
          return [];
        }
      }
      return [];
    }
  }
};
</script>
