var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Servicio a Domicilio")]),_c('br'),_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","color":"#0097A7","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-chip',{staticStyle:{"background-color":"#0097a7","color":"#fff"}},[_vm._v("Mascotas en SAD: "+_vm._s(_vm.contadorSAD))])],1)],1)],1),_c('v-simple-table',{attrs:{"fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{staticClass:"indigo white--text"},[_c('th',{staticClass:"text-center",attrs:{"colspan":"15"}},[_vm._v("SAD ESTÉTICA")])])])]},proxy:true}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.soloSAD,"items-per-page":7,"footer-props":{
          'items-per-page-text': 'Items por pagina',
          'items-per-page-options': [50, 100, 200, 500, -1]
        },"dense":"","item-class":_vm.blinkRowClass},scopedSlots:_vm._u([{key:"item.horaRegistro",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeConvert({ time: item.horaRegistro.slice(-8), seconds: false }))+" ")]}},{key:"item.propietario",fn:function(ref){
        var item = ref.item;
return [_c('NombreCliente',{attrs:{"cliente":{
              nombre: item.nombreCliente,
              codigosInternos: item.codigosInternosCliente
            }}})]}},{key:"item.mascota",fn:function(ref){
            var item = ref.item;
return [_c('NombreMascota',{attrs:{"mascota":{
              nombre: item.nombre,
              codigosInternos: item.codigosInternosMascota
            }}})]}},{key:"item.servicios",fn:function(ref){
            var item = ref.item;
return [_c('ul',_vm._l((item.conceptos.filter(
                function (concepto) { return concepto.idSubcategoria == '5' ||
                  concepto.idSubcategoria == '6'; }
              )),function(servicio,index){return _c('li',{key:index,staticClass:"py-2"},[_vm._v(" "+_vm._s(servicio.nombre)+" ")])}),0)]}},{key:"item.adicionales",fn:function(ref){
              var item = ref.item;
return [_c('ul',_vm._l((item.conceptos.filter(
                function (concepto) { return concepto.idSubcategoria == '7'; }
              )),function(servicio,index){return _c('li',{key:index,staticClass:"py-2"},[_vm._v(" "+_vm._s(servicio.nombre)+" ")])}),0)]}},{key:"item.choferEntrega",fn:function(ref){
              var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.choferes,"item-text":"nombre","item-value":"id"},on:{"change":function($event){return _vm.updateFields(item)}},model:{value:(item.idEmpleadoEntrega),callback:function ($$v) {_vm.$set(item, "idEmpleadoEntrega", $$v)},expression:"item.idEmpleadoEntrega"}})]}},{key:"item.choferRecolecta",fn:function(ref){
              var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.choferes,"item-text":"nombre","item-value":"id"},on:{"change":function($event){return _vm.updateFields(item)}},model:{value:(item.idEmpleadoRecolecta),callback:function ($$v) {_vm.$set(item, "idEmpleadoRecolecta", $$v)},expression:"item.idEmpleadoRecolecta"}})]}},{key:"item.detallesCorte",fn:function(ref){
              var item = ref.item;
return [_c('v-text-field',{staticClass:"camposEditables",attrs:{"placeholder":"Detalles del corte"},on:{"blur":function($event){return _vm.updateFields(item)}},model:{value:(item.detallesCorte),callback:function ($$v) {_vm.$set(item, "detallesCorte", $$v)},expression:"item.detallesCorte"}})]}},{key:"item.observaciones",fn:function(ref){
              var item = ref.item;
return [_c('v-text-field',{staticClass:"camposEditables",attrs:{"placeholder":"Observaciones"},on:{"blur":function($event){return _vm.updateFields(item)}},model:{value:(item.observaciones),callback:function ($$v) {_vm.$set(item, "observaciones", $$v)},expression:"item.observaciones"}})]}},{key:"item.horaEntrega",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeConvert({ time: item.horaEntrega.slice(-8), seconds: false }))+" ")]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('v-select',{staticClass:"status-select",attrs:{"items":_vm.listaStatusEstetica},on:{"change":function($event){return _vm.actualizarStatusEstetica(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.costo",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.sumaCostos(item))+" ")]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"green darken-2 white--text my-6","rounded":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")])],1)]},proxy:true}],null,true)}),_c('v-simple-table',{staticClass:"pt-8",attrs:{"fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{staticClass:"indigo white--text"},[_c('th',{staticClass:"text-center",attrs:{"colspan":"15"}},[_vm._v("SAD CLÍNICA")])])])]},proxy:true}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersClinica,"items":_vm.clinicaSoloSAD,"items-per-page":7,"footer-props":{
          'items-per-page-text': 'Items por pagina',
          'items-per-page-options': [50, 100, 200, 500, -1]
        },"dense":""},scopedSlots:_vm._u([{key:"item.horaRegistro",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeConvert({ time: item.horaRegistro.slice(-8), seconds: false }))+" ")]}},{key:"item.propietario",fn:function(ref){
        var item = ref.item;
return [_c('NombreCliente',{attrs:{"cliente":{
              nombre: item.nombreCliente,
              codigosInternos: item.codigosInternosCliente
            }}})]}},{key:"item.mascota",fn:function(ref){
            var item = ref.item;
return [_c('NombreMascota',{attrs:{"mascota":{
              nombre: item.nombre,
              codigosInternos: item.codigosInternosMascota
            }}})]}},{key:"item.choferEntrega",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.choferes,"item-text":"nombre","item-value":"id"},on:{"change":function($event){return _vm.updateFieldsClinica(item)}},model:{value:(item.idEmpleadoEntrega),callback:function ($$v) {_vm.$set(item, "idEmpleadoEntrega", $$v)},expression:"item.idEmpleadoEntrega"}})]}},{key:"item.choferRecolecta",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.choferes,"item-text":"nombre","item-value":"id"},on:{"change":function($event){return _vm.updateFieldsClinica(item)}},model:{value:(item.idEmpleadoRecolecta),callback:function ($$v) {_vm.$set(item, "idEmpleadoRecolecta", $$v)},expression:"item.idEmpleadoRecolecta"}})]}},{key:"item.observaciones",fn:function(ref){
            var item = ref.item;
return [_c('v-text-field',{staticClass:"camposEditables",attrs:{"placeholder":"Observaciones"},on:{"blur":function($event){return _vm.updateFieldsClinica(item)}},model:{value:(item.observaciones),callback:function ($$v) {_vm.$set(item, "observaciones", $$v)},expression:"item.observaciones"}})]}},{key:"item.horaEntrega",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeConvert({ time: item.horaEntrega.slice(-8), seconds: false }))+" ")]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.listaStatusClinica,"width":"100%"},on:{"change":function($event){return _vm.actualizarStatusClinica(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.costo",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.sumaCostos(item))+" ")]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"green darken-2 white--text my-6","rounded":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")])],1)]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }