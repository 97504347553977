<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      @keydown.esc="cerrar"
      @click:outside="cerrar"
      max-width="600px"
    >
      <!--<template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template>-->
      <v-card>
        <v-form
          ref="entryForm"
          color="teal"
          v-model="valid"
          @submit.prevent="submitHandler"
        >
          <v-card-title>
            <span class="headline">
              <span v-if="servicioCita.id != undefined">Actualizar</span>
              <span v-else>Agregar</span> Cita médica</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    label="Citado por"
                    v-model="servicioCita.idResponsableCita"
                    :rules="nameRules"
                    :items="empleados"
                    item-text="nombre"
                    item-value="id"
                    required
                    ref="responsableCita"
                    :loading="isLoading"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'responsableCita',
                        nextRef: 'empleado'
                      })
                    "
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    @dblclick="resetearCampos"
                    label="Cliente"
                    v-model="servicioCita.idPropietario"
                    :rules="nameRules"
                    :items="clientesFiltrados"
                    :loading="isLoading || isLoadingCliente"
                    item-text="nombre"
                    item-value="id"
                    required
                    ref="cliente"
                    @keydown="handleClienteSearchInput"
                    @keydown.enter="
                      focusNext({ actualRef: 'cliente', nextRef: 'mascota' })
                    "
                  >
                    <template v-slot:item="{ item }">
                      <template v-if="testIfObject(item)">
                        <v-list-item>
                          <v-list-item-content
                            v-text="item"
                          ></v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-list-item>
                        <perfil
                          :item="{
                            nombre: item.nombre,
                            imagen: '',
                            tipo: 'clientes',
                            codigosInternos: codigosInternos(
                              item.codigosInternos
                            ),
                            tamano: 'sm'
                          }"
                        ></perfil>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="item.nombre"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="item.tipo"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    @dblclick="resetearCampos"
                    label="Mascota"
                    v-model="servicioCita.idMascota"
                    :rules="nameRules"
                    :items="mascotasFiltradas"
                    :loading="isLoading"
                    item-text="nombre"
                    item-value="id"
                    required
                    ref="mascota"
                    @change="setMyPet()"
                    @keydown.enter="
                      focusNext({ actualRef: 'mascota', nextRef: 'direccion' })
                    "
                    @focus="
                      () => {
                        fetchMascotas(servicioCita.idPropietario);
                      }
                    "
                  >
                    <template v-slot:item="{ item }">
                      <template v-if="testIfObject(item)">
                        <v-list-item>
                          <v-list-item-content
                            v-text="item"
                          ></v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-list-item
                        @click="
                          () => {
                            servicioCita.epecie = item.epecie;
                            servicioCita.sexo = item.sexo;
                            servicioCita.edad = item.edad;
                            servicioCita.peso = item.peso;
                          }
                        "
                      >
                        <perfil
                          :item="{
                            nombre: item.nombre,
                            imagen: '',
                            tipo: 'mascotas',
                            codigosInternos: codigosInternos(
                              item.codigosInternos
                            ),
                            tamano: 'sm'
                          }"
                        ></perfil>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="item.nombre"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="item.propietario"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Grupo/Especie"
                    v-model="grupoEspecie"
                    ref="grupo"
                    item-value="id"
                    item-text="nombre"
                    :items="grupos"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'grupo',
                        nextRef: 'sexo'
                      })
                    "
                  >
                  </v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    disabled
                    label="Sexo"
                    v-model="servicioCita.sexo"
                    rows="2"
                    ref="sexo"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'sexo',
                        nextRef: 'edad'
                      })
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Edad"
                    v-model="stringCalcularEdad"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Peso"
                    v-model="servicioCita.peso"
                    rows="2"
                    ref="peso"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'peso',
                        nextRef: 'direccion'
                      })
                    "
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-if="mostrarDireccion"
                    label="Direccion"
                    v-model="servicioCita.direccion"
                    rows="2"
                    ref="direccion"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'direccion',
                        nextRef: 'servicio'
                      })
                    "
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Servicio"
                    v-model="servicioCita.servicio"
                    :items="serviciosSelect"
                    item-nombre="nombre"
                    item-text="nombre"
                    ref="servicio"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'servicio',
                        nextRef: 'tipoServicio'
                      })
                    "
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Tipo de servicio"
                    v-model="servicioCita.tipoServicio"
                    :items="tiposServicio ? tiposServicio : []"
                    ref="tipoServicio"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'tipoServicio',
                        nextRef: 'motivoServicio'
                      })
                    "
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="MVZ"
                    v-model="servicioCita.idEmpleado"
                    :items="[
                      { id: '0', nombre: 'Sin asignar' },
                      ...empleadosMVZ
                    ]"
                    item-value="id"
                    item-text="nombre"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Motivo del servicio"
                    v-model="servicioCita.motivoServicio"
                    rows="2"
                    ref="motivoServicio"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'motivoServicio',
                        nextRef: 'serviciosADomicilio'
                      })
                    "
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="SAD"
                    chips
                    v-model="servicioCita.idsSAD"
                    item-value="id"
                    item-text="nombre"
                    :items="serviciosADomicilio"
                    multiple
                    ref="serviciosADomicilio"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'serviciosADomicilio',
                        nextRef: 'cuidadoMedico'
                      })
                    "
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Observaciones"
                    v-model="servicioCita.observaciones"
                    rows="2"
                    ref="observaciones"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'observaciones',
                        nextRef: 'fechaHoraCita'
                      })
                    "
                  ></v-textarea>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Fecha de cita"
                    v-model="servicioCita.fechaCita"
                    type="date"
                    rows="2"
                    ref="fechaCita"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'fechaCita',
                        nextRef: 'horaCita'
                      })
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Hora de cita"
                    v-model="servicioCita.horaCita"
                    type="time"
                    rows="2"
                    ref="horaCita"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'horaCita',
                        nextRef: 'citaConfirmada'
                      })
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Cita confirmada"
                    v-model="servicioCita.citaConfirmada"
                    item-value="value"
                    item-text="nombre"
                    ref="citaConfirmada"
                    :items="[
                      { nombre: 'No', value: 0 },
                      { nombre: 'Si', value: 1 }
                    ]"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'citaConfirmada',
                        nextRef: 'fuente'
                      })
                    "
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Fuente"
                    v-model="servicioCita.fuente"
                    ref="fuente"
                    :items="['Redes', 'Prospección', 'Recepción']"
                    @keydown.enter="
                      focusNext({
                        actualRef: 'fuente'
                      })
                    "
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-if="servicioCita.id == undefined"
              :disabled="isSending"
            >
              Agregar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-else
              :disabled="isSending"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
import Perfil from "../../components/Perfil";
export default {
  name: "ModalCitasMedicas",
  components: { Perfil },
  data: () => ({
    isLoading: false,
    isLoadingCliente: false,
    isSending: false,
    valid: false,
    debouncing: null,
    tipoRequest: "",
    especificos: [],
    empleados: [],
    serviciosSelect: [
      {
        nombre: "Consulta",
        tipos: ["Consulta", "Dermatológica", "Oftálmica", "Urgencia"]
      },
      {
        nombre: "Recepción procedimiento",
        tipos: ["General"]
      },
      {
        nombre: "Estudios",
        tipos: ["BH", "ECO", "EGO", "Electro", "QS", "RX", "US"]
      },
      {
        nombre: "Revisión",
        tipos: ["Consulta", "Hospitalización", "Retiro de puntos"]
      },
      {
        nombre: "Vacuna/Desparasitación",
        tipos: [
          "Puppy",
          "Cuádruple",
          "Quíntuple",
          "Séxtuple",
          "Rabia",
          "TripleFelina",
          "Leucemia",
          "Bordetella",
          "Desparasitación"
        ]
      },
      { nombre: "Cirugía", tipos: ["General"] },
      { nombre: "Visita", tipos: ["Visita"] },
      { nombre: "Estancia", tipos: ["Estancia general", "Estancia medicada"] }
    ],
    servicios: [],
    empleadosMVZ: [],
    mascotaSeleccionada: [{ idGrupo: 0 }],
    idservicioCita: "0",
    date: new Date().toISOString().substr(0, 10),
    grupos: [],
    nameRules: [v => !!v || "El nombre es requerido"],
    espRules: [v => !!v || "El específico es requerido"],
    sexoRules: [v => !!v || "El sexo es requerido"],
    tamanoRules: [v => !!v || "El tamaño es requerido"],
    esterilRules: [v => !!v || "Este campo es requerido"],
    bajaRules: [v => !!v || "Este campo es requerido"],
    propietarioRules: [v => !!v || "El nombre del propietario es requerido"],
    sexos: [{ nombre: "Macho" }, { nombre: "Hembra" }],
    tamanos: [
      "Cachorro",
      "Chico",
      "Mediano",
      "Intermedio",
      "Grande",
      "Gigante"
    ],
    binario: [{ nombre: "Si" }, { nombre: "No" }],
    objCliente: {},
    telRules: [
      v => !!v || "El num. telefónico es requerido",
      v =>
        /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
        "Ingrese un número de teléfono válido"
    ],
    emailRules: [
      v => !!v || "El email es requerido",
      v => /.+@.+/.test(v) || "El email debe ser válido"
    ],
    addressRules: [v => !!v || "La dirección es requerida"]
  }),
  watch: {
    clientesFiltrados(misClientesFiltrados) {
      let miMascota = this.mascotas.filter(
        mascota => this.servicioCita.idMascota == mascota.id
      )[0];
      if (miMascota) {
        misClientesFiltrados = this.clientes?.filter(
          cliente => cliente.id == miMascota.idPropietario
        );

        if (misClientesFiltrados) {
          this.servicioCita.tamano = miMascota.tamano;
          this.servicioCita.idPropietario = misClientesFiltrados[0]?.id;
        } else {
          this.servicioCita.idPropietario = 0;
        }
      }
    },
    mascotasFiltradas() {
      let miCliente = this.clientes.filter(
        cliente => this.servicioCita.idPropietario == cliente.id
      )[0];
      if (miCliente) {
        this.servicioCita.direccion = miCliente.direccion;
      }
    },
    open(isOpen) {
      if (isOpen) {
        const horaCita = this.$store.state.modals.servicioCita.item.horaCita;
        if (!horaCita) return;
        const [fecha, hora] = horaCita.split(" ");
        if (!fecha || !hora) {
          this.servicioCita.fechaCita = moment().format("yyyy-MM-DD");
        } else {
          this.servicioCita.horaCita = moment(hora, "HH:mm:ss").format("HH:mm");
          this.servicioCita.fechaCita = moment(fecha, "yyyy-MM-DD").format(
            "yyyy-MM-DD"
          );
        }
      }
    }
  },
  computed: {
    ...mapState("clientes", ["clientes"]),
    ...mapState("mascotas", ["mascotas"]),
    open() {
      return this.$store.state.modals.servicioCita.open;
    },
    tiposServicio() {
      let serviciosClinicaFiltrados = this.serviciosSelect.filter(
        servicio => servicio.nombre == this.servicioCita.servicio
      );
      return serviciosClinicaFiltrados[0]?.tipos;
    },
    serviciosClinica() {
      return this.servicios.filter(servicio => servicio.idCategoria == "3");
    },
    serviciosAdicionales() {
      return this.servicios.filter(servicio => servicio.idSubcategoria == "7");
    },
    serviciosADomicilio() {
      return this.servicios.filter(servicio => servicio.idSubcategoria == "16");
    },

    servicioCita() {
      return this.$store.state.modals.servicioCita.item;
    },
    mostrarDireccion() {
      return true;
    },
    mascotasFiltradas() {
      if (this.servicioCita.idPropietario) {
        return this.mascotas.filter(
          mascota => mascota.idPropietario == this.servicioCita.idPropietario
        );
      }
      return this.mascotas;
    },
    clientesFiltrados() {
      if (!this.servicioCita.idMascota) {
        return this.clientes;
      } else {
        let miMascota = this.mascotas.filter(
          mascota => this.servicioCita.idMascota == mascota?.id
        )[0];
        if (miMascota) {
          let misClientesFiltrados = this.clientes.filter(
            cliente => cliente?.id == miMascota.idPropietario
          );
          return misClientesFiltrados;
        }
        return this.clientes;
      }
    },
    grupoEspecie: {
      get() {
        return this.mascotaSeleccionada[0]?.idGrupo;
      },
      set(nuevoValor) {
        if (typeof nuevoValor !== "undefined") {
          this.mascotaSeleccionada[0].idGrupo = nuevoValor;
        }
      }
    },
    stringCalcularEdad() {
      let fechaNacimiento = this.mascotaSeleccionada[0]?.fechaNacimiento;
      if (this.mascotaSeleccionada[0]?.fechaNacimiento) {
        const edad = moment().diff(fechaNacimiento, "months");
        const years = parseInt(edad / 12);
        const months = edad % 12;
        let stringEdad = "";
        years != 1
          ? (stringEdad = years + " años")
          : (stringEdad = years + "año");
        months != 1
          ? (stringEdad = stringEdad + " y " + months + " meses")
          : (stringEdad = stringEdad + " y " + months + " mes");
        return stringEdad;
      } else {
        return "";
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.fetchGrupos();
      this.fetchEmpleados();
      this.fetchClientes();
      this.fetchMascotas();
      this.fetchServicios();
      this.servicioCita.horaCita = moment().format("HH:mm");
      this.servicioCita.fechaCita = moment().format("yyyy-MM-DD");
    },
    handleClienteSearchInput(e) {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchClientes(e);
      }, 500);
    },
    focusNext(parameters) {
      let { actualRef, nextRef } = {
        actualRef: null,
        nextRef: null,
        ...parameters
      };
      if (actualRef) this.$refs[actualRef].blur();
      if (nextRef) this.$refs[nextRef].focus();
    },
    testIfObject(item) {
      return typeof item !== "object";
    },
    resetearCampos() {
      this.servicioCita.idPropietario = 0;
      this.servicioCita.idMascota = 0;
      this.servicioCita.horaCita = moment().format("HH:mm");
      this.servicioCita.fechaCita = moment().format("yyyy-MM-DD");
    },
    async fetchGrupos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "grupos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.grupos = respuesta.data.rows;
      this.isLoading = false;
    },
    async fetchServicios() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "productosClinica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.servicios = respuesta.data.rows;
      }
      this.isLoading = false;
    },
    async fetchClientes() {
      this.isLoading = true;
      await this.$store.dispatch("clientes/getIniciales");
      this.isLoading = false;
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.empleados = respuesta.data.rows;
        this.empleadosMVZ = respuesta.data.rows?.filter(
          empleado =>
            empleado.departamento == "clinica" ||
            empleado.departamento == "Clínica" ||
            empleado.departamento == "Clinica"
        );
      }
      this.isLoading = false;
    },
    async insertServicioCita() {
      this.isSending = true;
      if (this.servicioCita.id != undefined) {
        this.tipoRequest = "updateCita";
      } else {
        this.tipoRequest = "insertCita";
      }
      let salvarHora = this.servicioCita.horaCita;
      this.servicioCita.horaCita = `${this.servicioCita.fechaCita} ${this.servicioCita.horaCita}`;
      let myUrl = process.env.VUE_APP_API_URL + "serviciosClinica.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: this.tipoRequest,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.servicioCita],
        id: this.servicioCita.id
      });
      if (respuesta.data.status == "Ok") {
        this.servicioCita.horaCita = salvarHora;
        this.isSending = false;
        this.$store.state.modals["servicioCita"].callback();
        if (this.servicioCita.id == undefined) {
          this.$toasted.success("Servicio de Estetica agregado", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Servicio de Estetica actualizado", {
            position: "bottom-right",
            duration: 5000
          });
        }
        this.cerrar();
      }
    },
    async submitHandler() {
      let valido = await this.$refs.entryForm.validate();
      if (valido) {
        this.insertServicioCita();
      }
    },
    cerrar() {
      this.$store.commit("openModal", {
        modal: "servicioCita",
        open: false
      });
    },
    codigosInternos(cods) {
      if (cods?.length && cods?.length > 1) {
        try {
          const parsedCods = JSON.parse(cods);

          if (Array.isArray(parsedCods)) {
            return [...parsedCods];
          }
          return [];
        } catch (error) {
          return [];
        }
      }
      return [];
    },
    async setMyPet() {
      let myUrl = process.env.VUE_APP_API_URL + "mascotas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getOne",
        id: this.servicioCita.idMascota,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        this.mascotaSeleccionada = respuesta.data.rows;
      }
      this.isLoading = false;
    },
    async fetchMascotas(idCliente = null) {
      this.isLoading = true;
      if (idCliente)
        await this.$store.dispatch("mascotas/getByOwnerId", idCliente);
      else await this.$store.dispatch("mascotas/getIniciales");
      this.isLoading = false;
    },
    async fetchSearchClientes(e) {
      this.isLoadingCliente = true;
      await this.$store.dispatch("clientes/getBySearch", e.target.value);
      this.isLoadingCliente = false;
    }
  }
};
</script>
