<template>
  <v-row justify="center">
    <v-dialog
      v-model="isOpen"
      @keydown.esc="cerrar"
      @click:outside="cerrar"
      max-width="600px"
    >
      <v-card :loading="isLoading">
        <v-form
          ref="entryForm"
          color="teal"
          v-model="valid"
          @submit.prevent="submitHandler"
        >
          <v-card-title>
            <span class="headline">Reportar Error</span>
          </v-card-title>
          <v-card-text
            ><v-container>
              <v-row>
                <v-col cols="6">
                  <v-select
                    label="Sección:"
                    :items="sectionOptions"
                    v-model="errorReportado.section"
                    item-text="section"
                    :rules="textRules"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Subsección:"
                    :items="subsectionOptions"
                    v-model="errorReportado.subsection"
                    :rules="textRules"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :items="[...empleados]"
                    label="Empleado"
                    v-model="errorReportado.idEmpleado"
                    required
                    :rules="textRules"
                    item-text="nombre"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Explica el error:"
                    v-model="errorReportado.error"
                    :rules="textRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Cómo reproducir el error:"
                    v-model="errorReportado.comoReproducir"
                    :rules="textRules"
                    counter
                    maxlength="250"
                    rows="2"
                    auto-grow
                    required
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Cómo debería ser el funcionamiento adecuado:"
                    v-model="errorReportado.funcionamientoAdecuado"
                    :rules="textRules"
                    counter
                    maxlength="250"
                    rows="2"
                    auto-grow
                    required
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Urgencia:"
                    :items="afectaOptions"
                    v-model="errorReportado.afecta"
                    :rules="textRules"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cerrar"> Cerrar </v-btn>
            <v-btn
              color="info"
              text
              @click="submitHandler"
              :disabled="isSending"
            >
              Enviar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "ModalReporteErrores",
  props: ["onClose", "isOpen"],
  data: () => ({
    isLoading: false,
    isSending: false,
    valid: false,
    tipoRequest: "",
    iderrorReportado: "0",
    isEditing: false,
    grupos: [],
    empleados: [],
    textRules: [v => !!v || "El campo es requerido"],
    sectionOptions: [
      {
        section: "Ventas",
        subsections: [
          "Nueva Venta",
          "Abonos",
          "Ventas",
          "Uso Interno",
          "Cierres de cajas"
        ]
      },
      {
        section: "Productos & Servicios",
        subsections: ["Productos", "Marcas", "Categorías", "Subcategorías"]
      },
      {
        section: "Gastos",
        subsections: ["Gastos", "Fondo fijo"]
      },
      {
        section: "Compras",
        subsections: ["Nueva Compra", "Compras cerradas y por cobrar"]
      },
      {
        section: "Clientes",
        subsections: ["Ninguna"]
      },
      {
        section: "Mascotas",
        subsections: ["Ninguna"]
      },
      {
        section: "Empleados",
        subsections: ["Personal", "Comisiones"]
      },
      {
        section: "Proveedores",
        subsections: ["Ninguna"]
      },
      {
        section: "Crear mascotas",
        subsections: ["Especificos"]
      },
      {
        section: "Estética",
        subsections: ["Registro Estética", "Estética"]
      },
      {
        section: "Clínica",
        subsections: [
          "Registro médico",
          "Registro citas",
          "Expedientes",
          "Químicas Sanguíneas",
          "Biometría Hemática"
        ]
      },
      {
        section: "Servicio a Domicilio",
        subsections: ["Ninguna"]
      },
      {
        section: "Reportes",
        subsections: ["Especificado en la descripción"]
      },
      {
        section: "Perfiles y permisos",
        subsections: ["Ninguna"]
      }
    ],
    afectaOptions: ["Mejora", "No urgente", "Algo urgente", "Máxima urgencia"],
    errorReportado: {
      idEmpleado: 0,
      seccion: "",
      error: "",
      comoReproducir: "",
      funcionamientoAdecuado: "",
      afecta: "",
      section: "",
      subsection: ""
    }
  }),
  computed: {
    rutaActual() {
      return window.location.pathname;
    },
    subsectionOptions() {
      return (
        this.sectionOptions.find(
          sectionOption => sectionOption.section == this.errorReportado.section
        )?.subsections || []
      );
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.fetchEmpleados();
    },
    toggleModal() {},
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta) this.empleados = respuesta.data.rows;
      this.isLoading = false;
    },
    async inserterrorReportado() {
      this.isSending = true;
      this.tipoRequest = "insert";
      let myUrl = process.env.VUE_APP_API_URL + "erroresReportados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: this.tipoRequest,
        token: localStorage.token,
        geo: localStorage.geo,
        errorReportado: this.errorReportado
      });
      if (respuesta.data.status == "Ok") {
        this.isSending = false;
        this.$toasted.success("Error reportado", {
          position: "bottom-right",
          duration: 5000
        });
        this.$refs.entryForm.reset();
        this.cerrar();
      }
      this.isSending = false;
    },
    async submitHandler() {
      this.isSending = true;
      this.errorReportado.seccion =
        "/" +
        this.errorReportado.section +
        "/" +
        this.errorReportado.subsection;
      let valido = await this.$refs.entryForm.validate();
      if (valido) {
        this.inserterrorReportado();
      }
    },
    cerrar() {
      this.isSending = false;
      this.onClose();
    }
  }
};
</script>
