<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <h1>Uso Interno</h1>
              <br />
              <v-row>
                <v-col cols="12" sm="5" md="5">
                  <label>Desde: </label>
                  <input type="date" v-model="desde" />
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <label>Hasta: </label>
                  <input type="date" v-model="hasta" />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-btn
                    elevation="2"
                    small
                    label
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="
            registrosUsoInterno.map((item, index) => ({
              ...item,
              key: item.id + '-' + index
            }))
          "
          :items-per-page="7"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          calculate-widths
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
export default {
  name: "ReportesUsoInterno",
  data: () => ({
    isLoading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    headers: [
      { text: "Id Movimiento", value: "id" },
      { text: "Id Venta", value: "idVenta" },
      { text: "Fecha", value: "fecha", width: "110px" },
      { text: "Hora", value: "hora" },
      { text: "Status", value: "status", width: "140px" },
      { text: "Vendedor", value: "nombresEmpleados", width: "140px" },
      { text: "Cliente", value: "nombreCliente", width: "110px" },
      { text: "Categoria", value: "categoria", width: "110px" },
      { text: "Subcategoria", value: "subcategoria", width: "130px" },
      { text: "Producto/Servicio", value: "productoServicio", width: "160px" },
      {
        text: "Precio de compra sin imp. (unitario)",
        value: "precioCompraSinImpuesto",
        width: "180px"
      },
      {
        text: "Precio de compra con imp. (unitario)",
        value: "precioCompraConImpuesto",
        width: "180px"
      },
      {
        text: "Precio de venta sin imp. (unitario)",
        value: "precioVentaSinImpuesto",
        width: "180px"
      },
      {
        text: "Precio de venta con imp. (unitario)",
        value: "precioVentaConImpuesto",
        width: "180px"
      },
      { text: "Cantidad", value: "cantidad", width: "110px" },
      { text: "Subtotal", value: "subtotal", width: "110px" },
      { text: "Impuesto %", value: "impuestoPorcentual", width: "120px" },
      { text: "Impuesto", value: "impuestoAplicado", width: "110px" },
      { text: "Total", value: "total", width: "110px" },
      { text: "Descuento %", value: "descuentoPorcentual", width: "120px" },
      { text: "Descuento", value: "descuentoAplicado", width: "110px" },
      { text: "Neto", value: "neto", width: "110px" }
    ],
    registrosUsoInterno: [],
    placeholderRegistrosUsoInterno: [
      {
        id: "72812",
        fechaHora: "22/10/2021	12:58:25",
        fecha: "22/10/2021",
        hora: "12:58:25",
        status: "Uso Interno",
        vendedor: "Karina Arethia Alcantara Aguilera",
        cliente: "Uso Interno Clinica",
        categoria: "Insumos",
        subcategoria: "Insumos Materiales",
        productoServicio: "Solucion CS 250ml",
        precioCompraAI: "22.00",
        precioCompraDI: "22.00",
        cantidad: "1",
        subtotal: "0.00",
        impuestoPorcentaje: "0%",
        impuesto: "0.00",
        total: "0.00",
        descuentoPorcentaje: "0%",
        descuento: "0.00",
        neto: "0.00"
      },
      {
        id: "72813",
        fechaHora: "22/10/2021	12:58:25",
        fecha: "22/10/2021",
        hora: "12:58:25",
        status: "Uso Interno",
        vendedor: "Karina Arethia Alcantara Aguilera",
        cliente: "Uso Interno Clinica",
        categoria: "Insumos",
        subcategoria: "Insumos Materiales",
        productoServicio: "Pisacaína 2%",
        precioCompraAI: "77.00",
        precioCompraDI: "77.00",
        cantidad: "1",
        subtotal: "0.00",
        impuestoPorcentaje: "0%",
        impuesto: "0.00",
        total: "0.00",
        descuentoPorcentaje: "0%",
        descuento: "0.00",
        neto: "0.00"
      },
      {
        id: "72767",
        fechaHora: "22/10/2021	07:11:03",
        fecha: "22/10/2021",
        hora: "07:11:03",
        status: "Uso Interno",
        vendedor: "Medico General",
        cliente: "Uso Interno Clinica",
        categoria: "Tienda-Farmacia",
        subcategoria: "Alimentos",
        productoServicio: "Proplan Veterinary Diet canine EN lata 380grs",
        precioCompraAI: "45.47",
        precioCompraDI: "52.75",
        cantidad: "1",
        subtotal: "62.93",
        impuestoPorcentaje: "16%",
        impuesto: "10.07",
        total: "73.00",
        descuentoPorcentaje: "0%",
        descuento: "0.00",
        neto: "73.00"
      }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchRegistrosUsoInterno(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchRegistrosUsoInterno(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  methods: {
    async fetchRegistrosUsoInterno(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllUsoInterno",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta) {
        this.registrosUsoInterno = respuesta.data.rows;
        this.isLoading = false;
      }
      //this.registrosUsoInterno = this.placeholderRegistrosUsoInterno;
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var registrosUsoInterno = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        registrosUsoInterno,
        "registrosUsoInterno"
      );
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.registrosUsoInterno.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            // Verificar si el valor es numérico
            let valor = registro[header.value];
            if (!isNaN(valor) && valor !== "") {
              // Convertir el valor a número
              valor = Number(valor);
            }
            XLSX.utils.sheet_add_aoa(sheet, [[valor]], {
              // Agregar el valor al archivo
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-UsoInterno.xlsx`);
    },
    initialize() {
      this.fetchRegistrosUsoInterno(
        moment()
          .subtract(1, "weeks")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }
};
</script>
