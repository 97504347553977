<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <h1>Compras</h1>
              <br />
              <v-row>
                <v-col cols="12" sm="5" md="5">
                  <label>Desde: </label>
                  <input type="date" v-model="desde" />
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <label>Hasta: </label>
                  <input type="date" v-model="hasta" />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-btn
                    elevation="2"
                    small
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="compras"
          :items-per-page="7"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          calculate-widths
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
export default {
  name: "ReportesCompras",
  data: () => ({
    isLoading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    headers: [
      { text: "Id", value: "id" },
      { text: "Fecha", value: "fecha", width: "110px" },
      { text: "Hora", value: "hora", width: "110px" },
      { text: "Status", value: "status", width: "140px" },
      { text: "Empleado", value: "nombreCajero" },
      { text: "Proveedor", value: "nombreProveedor" },
      { text: "Categoria", value: "nombreCategoria" },
      { text: "Subcategoria", value: "nombreSubcategoria" },
      { text: "No. de documento", value: "numeroDocumento" },
      { text: "Tipo de documento", value: "tipoDocumento" },
      { text: "Producto/Servicio", value: "nombreProducto" },
      { text: "Precio de compra", value: "precioCompra", width: "130px" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Impuesto %", value: "impuestoPorcentaje", width: "110px" },
      { text: "Impuesto", value: "impuesto" },
      { text: "Descuento %", value: "descuentoPorcentaje", width: "110px" },
      { text: "Descuento", value: "descuento" },
      { text: "Total", value: "total" }
    ],
    compras: [],
    placeholderCompras: [
      {
        id: "1567",
        fechaHora: "21/10/2021	18:08:56",
        status: "Cerrada",
        fecha: "21/10/2021",
        hora: "18:08:56",
        empleado: "Moises Venegas Navarro",
        proveedor: "Total Pets",
        categoria: "Tienda-Farmacia",
        subcategoria: "Accesorios",
        noDocumento: "6865",
        tipoDocumento: "Factura",
        productoServicio: "Sipupet locion repelente antibacterial spray 250ml",
        precioCompra: "315.52",
        cantidad: "2",
        subtotal: "315.52",
        impuestoPorcentaje: "16%",
        impuesto: "50.48",
        total: "366.00",
        descuentoPorcentaje: "0%",
        descuento: "0.00",
        neto: "366.00"
      },
      {
        id: "1568",
        fechaHora: "20/10/2021	18:08:56",
        status: "Cerrada",
        fecha: "20/10/2021",
        hora: "18:08:56",
        empleado: "Moises Venegas Navarro",
        proveedor: "Total Pets",
        categoria: "Insumos",
        subcategoria: "Estetica",
        noDocumento: "6865",
        tipoDocumento: "Factura",
        productoServicio: "Sipupet locion repelente antibacterial spray 250ml",
        precioCompra: "315.52",
        cantidad: "2",
        subtotal: "315.52",
        impuestoPorcentaje: "16%",
        impuesto: "50.48",
        total: "366.00",
        descuentoPorcentaje: "0%",
        descuento: "0.00",
        neto: "366.00"
      },
      {
        id: "1569",
        fechaHora: "19/10/2021	18:08:56",
        status: "Cerrada",
        fecha: "19/10/2021",
        hora: "18:08:56",
        empleado: "Moises Venegas Navarro",
        proveedor: "Total Pets",
        categoria: "Tienda-Farmacia",
        subcategoria: "Accesorios",
        noDocumento: "6865",
        tipoDocumento: "Factura",
        productoServicio: "Sipupet locion repelente antibacterial spray 250ml",
        precioCompra: "315.52",
        cantidad: "2",
        subtotal: "315.52",
        impuestoPorcentaje: "16%",
        impuesto: "50.48",
        total: "366.00",
        descuentoPorcentaje: "0%",
        descuento: "0.00",
        neto: "366.00"
      }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchCompras(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchCompras(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  methods: {
    async fetchCompras(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "compras.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllReporte",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta) {
        this.compras = respuesta.data.rows;
        this.isLoading = false;
      }
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var compras = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, compras, "compras");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.compras.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            // Verificar si el valor es numérico
            let valor = registro[header.value];
            if (!isNaN(valor) && valor !== "") {
              // Convertir el valor a número
              valor = Number(valor);
            }
            XLSX.utils.sheet_add_aoa(sheet, [[valor]], {
              // Agregar el valor al archivo
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-compras.xlsx`);
    },
    initialize() {
      this.fetchCompras(
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }
};
</script>
