<style scoped>
input {
  max-width: 65px;
  display: inline-block;
}
</style>

<template>
  <v-data-table
    :headers="headers"
    :items="productosNuevaVenta"
    :search="search"
    :items-per-page="-1"
    hide-default-footer
    dense
    item-key="uniqueId"
  >
    <template v-slot:item.cantidad="{ item }">
      <input
        type="number"
        :value="item.cantidad"
        @input="
          e => {
            item.cantidad = Number(e.target.value);
            calculateTotal({ ...item });
          }
        "
      />
    </template>
    <template v-slot:item.precio="{ item }">
      <input
        type="number"
        :value="item.precio"
        v-if="item.isEditable == 'true'"
        @input="
          e => {
            item.precio = Number(e.target.value);
            calculateTotal({ ...item });
          }
        "
      />
      <span v-else>{{ item.precio }} </span>
    </template>
    <template v-slot:item.impuesto="{ item }"> {{ item.impuesto }} % </template>
    <template v-slot:item.descuentoPorcentaje="{ item }">
      <input
        type="number"
        :value="item.descuentoPorcentaje"
        @input="
          e => {
            item.descuentoPorcentaje = Number(e.target.value);
            updateDescuentoMonto(item);
            calculateTotal(item);
          }
        "
      />
      <span>%</span>
    </template>
    <template v-slot:item.descuentoMonto="{ item }">
      <input
        type="number"
        :value="item.descuentoMonto"
        @input="
          e => {
            item.descuentoMonto = Number(e.target.value);
            updateDescuentoPorcentaje(item);
            calculateTotal(item);
          }
        "
      />
    </template>
    <template v-slot:item.mascota="{ item }">
      <v-select
        v-model="item.idMascota"
        :items="mascotasFiltradasPorCliente"
        item-text="nombre"
        item-value="id"
        @focus="
          () => {
            fetchMascotas(cliente.id);
          }
        "
      >
        <template slot="item" slot-scope="data">
          <Perfil
            :item="{
              nombre: data.item.nombre,
              imagen: '',
              tipo: 'mascotas',
              codigosInternos: codigosInternos(data.item.codigosInternos),
              tamano: 'sm'
            }"
          ></Perfil>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.nombre"></v-list-item-title>
            <v-list-item-subtitle
              v-html="data.item.tipo"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>
    </template>
    <template v-slot:item.vendedor="{ item }">
      <v-select
        v-model="item.idVendedor"
        :items="vendedores"
        item-text="nombre"
        item-value="id"
      >
        <template slot="item" slot-scope="data">
          <v-list-item-content>
            <v-list-item-title v-html="data.item.nombre"></v-list-item-title>
            <v-list-item-subtitle
              v-html="data.item.tipo"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>
    </template>
    <template v-slot:item.acciones="{ item }">
      <v-btn icon color="danger" small @click="removeProduct(item.uniqueId)">
        <v-icon small>mdi-eraser</v-icon>
      </v-btn>
    </template>
    <template v-slot:body.append>
      <tr>
        <td colspan="3"></td>
        <td>Subtotal</td>
        <td>{{ subtotal }}</td>
        <td>Descuento</td>
        <td>{{ descuento }}</td>
        <td>IVA</td>
        <td>{{ iva }}</td>
        <td>Total</td>
        <td>{{ total }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Perfil from "../../components/Perfil.vue";

export default {
  data() {
    return {
      search: "",
      editing: false,
      editedItem: {},
      headers: [
        {
          text: "Producto",
          value: "nombre"
        },
        {
          text: "Cantidad",
          value: "cantidad"
        },
        {
          text: "Precio unitario",
          value: "precio"
        },
        {
          text: "Precio antes de impuesto",
          value: "precioAntesImpuesto"
        },
        {
          text: "Impuesto",
          value: "impuesto"
        },
        {
          text: "Descuento %",
          value: "descuentoPorcentaje"
        },
        {
          text: "Descuento Monto",
          value: "descuentoMonto"
        },
        {
          text: "Total",
          value: "total"
        },
        {
          text: "Mascota",
          value: "mascota"
        },
        {
          text: "Vendedor",
          value: "vendedor"
        },
        {
          text: "Acciones",
          value: "acciones"
        }
      ],
      clientes: []
    };
  },
  components: {
    Perfil
  },
  watch: {
    cliente(val) {
      this.fetchMascotas(val.id);
    }
  },
  computed: {
    ...mapState({
      productosNuevaVenta: state => state.nuevaVenta.productosNuevaVenta,
      cliente: state => state.nuevaVenta.cliente,
      totalesNuevaVenta: state => state.nuevaVenta.totalesNuevaVenta
    }),
    ...mapState({
      vendedores: state => state.empleados.empleados
    }),
    ...mapState("mascotas", ["mascotas"]),
    mascotasFiltradasPorCliente() {
      return (
        this.mascotas?.filter(
          mascota => mascota.idPropietario == this.cliente?.id
        ) || []
      );
    },
    descuento() {
      return this.productosNuevaVenta
        .reduce(
          (sum, producto) => sum + parseFloat(producto.descuentoMonto) || 0,
          0
        )
        .toFixed(2);
    },
    iva() {
      return this.productosNuevaVenta
        .reduce((sum, producto) => sum + parseFloat(producto.ivaMonto) || 0, 0)
        .toFixed(2);
    },
    subtotal() {
      return this.productosNuevaVenta
        .reduce((sum, producto) => sum + parseFloat(producto.subtotal) || 0, 0)
        .toFixed(2);
    },
    total() {
      return this.productosNuevaVenta
        .reduce((sum, producto) => sum + parseFloat(producto.total) || 0, 0)
        .toFixed(2);
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapMutations("nuevaVenta", [
      "updateDescuentoMonto",
      "updateDescuentoPorcentaje",
      "calculateTotal",
      "refreshMutation",
      "setCliente",
      "removeProduct"
    ]),
    async initialize() {
      await this.$store.dispatch("mascotas/getIniciales");
    },
    async fetchMascotas(idCliente = null) {
      this.isLoading = true;
      if (idCliente)
        await this.$store.dispatch("mascotas/getByOwnerId", idCliente);
      else await this.$store.dispatch("mascotas/getIniciales");
      this.isLoading = false;
    },
    
    codigosInternos(cods) {
      if (cods?.length && cods?.length > 1) {
        try {
          const parsedCods = JSON.parse(cods);

          if (Array.isArray(parsedCods)) {
            return [...parsedCods];
          }
          return [];
        } catch (error) {
          return [];
        }
      }
      return [];
    },
  }
};
</script>
