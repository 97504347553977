<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      @keydown.esc="cerrar"
      @click:outside="cerrar"
      max-width="600px"
    >
      <!--<template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template>-->
      <v-card :loading="isLoading">
        <v-form
          ref="entryForm"
          color="teal"
          v-model="valid"
          @submit.prevent="submitHandler"
        >
          <v-card-title>
            <span class="headline">Agregar Empleado</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre"
                    v-model="empleado.nombre"
                    :rules="nameRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Telefono 1"
                    v-model="empleado.tel1"
                    :rules="telRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Telefono 2"
                    v-model="empleado.tel2"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Correo"
                    v-model="empleado.correo"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Código interno"
                    v-model="empleado.codInterno"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="RFC"
                    v-model="empleado.rfc"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Código postal"
                    v-model="empleado.codigoPostal"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Dirección"
                    v-model="empleado.direccion"
                    :rules="addressRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Departamento"
                    v-model="empleado.departamento"
                    :items="opcionesDepartamento"
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Cargo"
                    v-model="empleado.cargo"
                    :items="opcionesCargo"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-if="empleado.id == undefined"
            >
              Agregar
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitHandler" v-else>
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "ModalEmpleados",
  data: () => ({
    isLoading: false,
    isSending: false,
    valid: false,
    tipoRequest: "",
    idEmpleado: "0",
    grupos: [],
    nameRules: [v => !!v || "El nombre es requerido"],
    telRules: [
      v => !!v || "El num. telefónico es requerido",
      v =>
        /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
        "Ingrese un número de teléfono válido"
    ],
    emailRules: [
      v => !!v || "El email es requerido",
      v => /.+@.+/.test(v) || "El email debe ser válido"
    ],
    addressRules: [v => !!v || "La dirección es requerida"],

    opcionesDepartamento: [
      "Tienda",
      "Ninguno",
      "Estetica",
      "Departamento 1",
      "Departamento 2",
      "Clinica",
      "Administracion",
      "Almacén"
    ],
    opcionesCargo: [
      "Asesor de ventas",
      "Gerente comercial",
      "Ninguno",
      "Encargado de estilistas",
      "Chofer repartidor",
      "Auxiliar de estilista",
      "Auxiliar de almacén",
      "Jefe Chofer",
      "Estilista basico",
      "Estilista intermedio",
      "Estilista",
      "Cargo 6",
      "Gerente de medicos",
      "Encargado de chofer",
      "Auxiliar veterinario",
      "Medico veterinario titular",
      "Encargado de medicos",
      "Gerente administratrivo"
    ]
  }),
  computed: {
    open() {
      return this.$store.state.modals.empleados.open;
    },
    empleado() {
      return this.$store.state.modals.empleados.item;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    async insertEmpleado() {
      this.isSending = true;
      if (this.empleado.id != undefined) {
        this.tipoRequest = "update";
      } else {
        this.tipoRequest = "insert";
      }
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: this.tipoRequest,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.empleado]
      });
      if (respuesta) {
        this.isSending = false;
        this.$store.state.modals["empleados"].callback();
        if (this.empleado.id == undefined) {
          this.$toasted.success("Empleado agregado", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Empleado actualizado", {
            position: "bottom-right",
            duration: 5000
          });
        }
        this.$refs.entryForm.reset();
        this.cerrar();
      }
    },
    async submitHandler() {
      let valido = await this.$refs.entryForm.validate();
      if (valido) {
        this.insertEmpleado();
      }
    },
    cerrar() {
      this.$store.commit("openModal", { modal: "empleados", open: false });
    }
  }
};
</script>
