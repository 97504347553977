import Vue from "vue";
import Vuex from "vuex";
import clientes from "./clientes";
import ventas from "./ventas";
import empleados from "./empleados";
import mascotas from "./mascotas";
import productos from "./productos";
import nuevaVenta from "./nuevaVenta";
import nuevaCompra from "./nuevaCompra";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cliente: null,
    proveedor: null,
    itemsNuevaVenta: [],
    itemsNuevaCompra: [],
    searchParams: {},
    modals: {
      comisiones: {
        open: false,
        item: {},
        callback: () => {}
      },
      especificos: {
        open: false,
        item: {},
        callback: () => {}
      },
      clientes: {
        open: false,
        item: {},
        callback: () => {}
      },
      productos: {
        open: false,
        item: {},
        callback: () => {}
      },
      llamadas: {
        open: false,
        item: {},
        callback: () => {}
      },
      subcategorias: {
        open: false,
        item: {},
        callback: () => {}
      },
      empleados: {
        open: false,
        item: {},
        callback: () => {}
      },
      servicioEstetica: {
        open: false,
        item: {
          idPropietario: 0,
          idMascota: 0
        },
        callback: () => {}
      },
      abonos: {
        open: false,
        item: {},
        callback: () => {}
      },
      servicioClinica: {
        open: false,
        item: {
          idPropietario: 0,
          idMascota: 0,
          idResponsableRegistro: 0,
          idResponsableCita: 0
        },
        callback: () => {}
      },
      servicioCita: {
        open: false,
        item: {
          idPropietario: 0,
          idMascota: 0,
          idResponsableRegistro: 0,
          idResponsableCita: 0
        },
        callback: () => {}
      },
      mascotas: {
        open: false,
        item: {},
        callback: () => {}
      },
      proveedores: {
        open: false,
        item: {},
        callback: () => {}
      },
      marcas: {
        open: false,
        item: {},
        callback: () => {}
      }
    },
    routeObj: {}
  },
  mutations: {
    setSearchParams(state, item) {
      state.searchParams = { ...state.searchParams, [item.name]: item.search };
    },
    setRouteObj(state, item) {
      state.routeObj = { ...item };
    },
    openModal(state, item) {
      state.modals[item.modal] = {
        ...state.modals[item.modal],
        open: item.open
      };
    },

    // { modal: "especificos", item: {id: 12, nombre:} }
    setItemModal(state, modalConfig) {
      state.modals[modalConfig.modal] = {
        ...state.modals[modalConfig.modal],
        item: modalConfig.item,
        callback: modalConfig.callback
      };
    },
    cleanVenta(state) {
      state.itemsNuevaVenta = [];
    },
    refreshMutation(state) {
      state.itemsNuevaVenta = [...state.itemsNuevaVenta];
      state.itemsNuevaCompra = [...state.itemsNuevaCompra];
    },
    addItemNuevaVenta(state, item) {
      let myArr = state.itemsNuevaVenta.filter(itm => itm.id == item.id);
      if (myArr.length < 0) {
        //myArr.length > 0
        myArr[0].cantidad += 1;
        if (myArr[0].cantidad > myArr[0].stock)
          myArr[0].cantidad = myArr[0].stock;
        state.itemsNuevaVenta = [...state.itemsNuevaVenta];
      } else {
        item.uniqueId =
          "id" +
          Math.random()
            .toString(16)
            .slice(2);
        item.stock <= 0 ? (item.cantidad = 0) : (item.cantidad = 1);
        item.descuento = item.pDescuento = 0;
        item.calcularSetearCantidad = cantidad => {
          let myTotal =
            cantidad *
            (parseFloat(item.precio) / (1 + parseFloat(item.impuesto) * 0.01));
          if (item.pDescuento > 0) {
            item.descuento = ((item.pDescuento / 100) * myTotal).toFixed(2);
          } else {
            item.descuento = 0;
          }
        };
        item.calcularMontoDescuento = porcentaje => {
          let myTotal =
            item.cantidad *
            (parseFloat(item.precio) / (1 + parseFloat(item.impuesto) * 0.01));
          if (porcentaje > 100) {
            item.pDescuento = 100;
            porcentaje = 100;
          }
          if (porcentaje > 0) {
            item.descuento = ((porcentaje / 100) * myTotal).toFixed(2);
          } else {
            item.descuento = 0;
          }
        };
        item.calcularPorcentajeDescuento = monto => {
          let myTotal =
            item.cantidad *
            (parseFloat(item.precio) / (1 + parseFloat(item.impuesto) * 0.01));
          if (monto > myTotal) {
            item.descuento = myTotal;
            monto = myTotal;
          }
          if (monto > 0) {
            item.pDescuento = ((monto / myTotal) * 100).toFixed(2);
          } else {
            item.pDescuento = 0;
          }
        };
        state.itemsNuevaVenta = [...state.itemsNuevaVenta, item];
      }
    },
    removeItemNuevaVenta(state, item) {
      state.itemsNuevaVenta = [
        ...state.itemsNuevaVenta.filter(itm => itm.uniqueId != item.uniqueId)
      ];
    },
    removeAllItemsNuevaVenta(state) {
      state.itemsNuevaVenta = [];
    },
    addItemNuevaCompra(state, item) {
      let myArr = state.itemsNuevaCompra.filter(itm => itm.id == item.id);
      if (myArr.length > 0) {
        myArr[0].cantidad += 1;
        state.itemsNuevaCompra = [...state.itemsNuevaCompra];
      } else {
        item.stock <= 0 ? (item.cantidad = 1) : (item.cantidad = 1);
        item.descuento = item.pDescuento = item.idMascota = 0;
        item.calcularSetearCantidad = cantidad => {
          let myTotal = cantidad * item.precio;
          if (item.pDescuento > 0) {
            item.descuento = ((item.pDescuento / 100) * myTotal).toFixed(2);
          } else {
            item.descuento = 0;
          }
        };
        item.calcularMontoDescuento = porcentaje => {
          let myTotal = item.cantidad * item.precio;
          if (porcentaje > 100) {
            item.pDescuento = 100;
            porcentaje = 100;
          }
          if (porcentaje > 0) {
            item.descuento = ((porcentaje / 100) * myTotal).toFixed(2);
          } else {
            item.descuento = 0;
          }
        };
        item.calcularPorcentajeDescuento = monto => {
          let myTotal = item.cantidad * item.precio;
          if (monto > myTotal) {
            item.descuento = myTotal;
            monto = myTotal;
          }
          if (monto > 0) {
            item.pDescuento = ((monto / myTotal) * 100).toFixed(2);
          } else {
            item.pDescuento = 0;
          }
        };
        state.itemsNuevaCompra = [...state.itemsNuevaCompra, item];
      }
    },
    removeItemNuevaCompra(state, item) {
      state.itemsNuevaCompra = [
        ...state.itemsNuevaCompra.filter(itm => itm.id != item.id)
      ];
    },
    removeAllItemsNuevaCompra(state) {
      state.itemsNuevaCompra = [];
    }
  },
  actions: {},
  modules: {
    clientes,
    ventas,
    empleados,
    mascotas,
    productos,
    nuevaVenta,
    nuevaCompra
  }
});
