var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Agregar un abono a la venta "+_vm._s(_vm.venta.id)+" "),_c('br'),_vm._v(" se adeuda: "+_vm._s(_vm.venta.montoSaldo)+" ")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.empleados,"label":"Empleado","item-value":"id","item-text":"nombre"},model:{value:(_vm.idEmpleado),callback:function ($$v) {_vm.idEmpleado=$$v},expression:"idEmpleado"}}),_c('v-row',_vm._l((_vm.pagos),function(pago){return _c('v-col',{key:pago.label,attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"number","label":pago.label},on:{"dblclick":function($event){return _vm.cerrarPago(pago)}},model:{value:(pago.monto),callback:function ($$v) {_vm.$set(pago, "monto", $$v)},expression:"pago.monto"}})],1)}),1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"readonly":"","outlined":"","dense":"","label":"Saldo a pagar:"},model:{value:(_vm.venta.montoSaldo),callback:function ($$v) {_vm.$set(_vm.venta, "montoSaldo", $$v)},expression:"venta.montoSaldo"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"readonly":"","outlined":"","dense":"","label":"Cambio por entregar:","error":_vm.cambio < 0 || _vm.cambio > _vm.venta.montoSaldo},model:{value:(_vm.cambio),callback:function ($$v) {_vm.cambio=$$v},expression:"cambio"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"success","text":"","disabled":_vm.isSending},on:{"click":function () {
              _vm.agregarAbono();
            }}},[_vm._v(" Agregar ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" ¿Realmente deseas eliminar la venta "),_c('br'),_vm._v("con el ID: "+_vm._s(_vm.venta.id)+" ?"),_c('br'),_c('br')]),_c('v-card-subtitle',[_vm._v(" Al hacerlo, los productos asociados a la venta se marcarán cómo devueltos a stock. "),_c('br'),_vm._v(" Los ingresos asociados en el registro serán eliminados. "),_c('br'),_c('span',{staticClass:"dangerBlink"},[_vm._v("Los datos de la venta, ingresos y movimientos de productos asociados no podrán ser recuperados. ")]),_c('br'),_c('span',{staticClass:"infoBlink"},[_vm._v(" Sugerencia : Imprime el ticket de la venta que vas a eliminar. ")]),_c('br'),_vm._v("Además, necesitas permisos de administrador para realizar ésta acción. "),_c('br')]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Usuario","type":"text","autocomplete":"off"},model:{value:(_vm.usuario),callback:function ($$v) {_vm.usuario=$$v},expression:"usuario"}}),_c('v-text-field',{attrs:{"label":"Contraseña","type":"password","autocomplete":"off"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"danger","text":"","loading":_vm.isLoading},on:{"click":function () {
              _vm.pedirPermiso();
            }}},[_vm._v(" Sí, eliminar ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Ventas")]),_c('br'),_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","color":"red darken-3","dense":""},on:{"keydown":_vm.handleSearchInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":['Todas', 'Por cobrar', 'Cerradas', 'Canceladas'],"dense":""},model:{value:(_vm.viendo),callback:function ($$v) {_vm.viendo=$$v},expression:"viendo"}}),(false)?_c('v-btn',{attrs:{"elevation":"2","small":"","color":"red darken-3 white--text ","disabled":""}},[_vm._v(" Agregar venta ")]):_vm._e()],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ventasFiltradas,"items-per-page":7,"search":_vm.search,"custom-filter":_vm.guessSearch,"sort-by":"id","sort-desc":true,"footer-props":{
          'items-per-page-text': 'Items por pagina',
          'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
        },"fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"item.acciones",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function () {
                _vm.venta = Object.assign({}, item);
                _vm.dialog = true;
              }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-cash ")])],1),_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.refreshItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-update ")])],1),_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.printItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-printer-outline ")])],1),(_vm.canEditSale)?_c('v-btn',{attrs:{"icon":"","to":("Venta/" + (item.id)),"color":"info"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","color":"danger"},on:{"click":function () {
                _vm.venta = Object.assign({}, item);
                _vm.deleteDialog = true;
              }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"red white--text darken-3 my-6","rounded":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")])],1)]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }