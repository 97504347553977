<template>
  <div>
    <h1>{{counter}}</h1>
    <v-btn @click="restart()">Restar</v-btn>
  </div>
</template>

<script>
export default {
  name: "Contador",
  data: () => ({
    counter: 100
  }),

  methods: {
    restart() {
      this.counter = this.counter - 1;
    }
  }
};
</script>