var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cerrar($event)},"click:outside":_vm.cerrar},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',[_c('v-form',{ref:"entryForm",attrs:{"color":"teal"},on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[(_vm.servicioClinica.id != undefined)?_c('span',[_vm._v("Actualizar")]):_c('span',[_vm._v("Agregar")]),_vm._v(" Servicio médico")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"responsableRegistro",attrs:{"label":"Responsable de registro","rules":_vm.nameRules,"items":_vm.empleados,"item-text":"nombre","item-value":"id","required":"","loading":_vm.isLoading},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'responsableRegistro',
                      nextRef: 'cliente'
                    })}},model:{value:(_vm.servicioClinica.idResponsableRegistro),callback:function ($$v) {_vm.$set(_vm.servicioClinica, "idResponsableRegistro", $$v)},expression:"servicioClinica.idResponsableRegistro"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"cliente",attrs:{"label":"Cliente","rules":_vm.nameRules,"items":_vm.clientesFiltrados,"item-text":"nombre","item-value":"id","required":"","search-input":_vm.searchCliente,"loading":_vm.isLoadingCliente},on:{"dblclick":_vm.resetearCampos,"update:searchInput":function($event){_vm.searchCliente=$event},"update:search-input":function($event){_vm.searchCliente=$event},"keydown":[_vm.handleClienteSearchInput,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({ actualRef: 'cliente', nextRef: 'mascota' })}]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [(_vm.testIfObject(item))?[_c('v-list-item',[_c('v-list-item-content',{domProps:{"textContent":_vm._s(item)}})],1)]:_vm._e(),_c('v-list-item',[_c('perfil',{attrs:{"item":{
                          nombre: item.nombre,
                          imagen: '',
                          tipo: 'clientes',
                          codigosInternos: _vm.codigosInternos(
                            item.codigosInternos
                          ),
                          tamano: 'sm'
                        }}}),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.tipo)}})],1)],1)]}}]),model:{value:(_vm.servicioClinica.idPropietario),callback:function ($$v) {_vm.$set(_vm.servicioClinica, "idPropietario", $$v)},expression:"servicioClinica.idPropietario"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"mascota",attrs:{"label":"Mascota","rules":_vm.nameRules,"items":_vm.mascotasFiltradas,"loading":_vm.isLoading,"item-text":"nombre","item-value":"id","required":""},on:{"dblclick":_vm.resetearCampos,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({ actualRef: 'mascota', nextRef: 'tamano' })},"focus":function () {
                      _vm.fetchMascotas(_vm.servicioClinica.idPropietario);
                    }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [(_vm.testIfObject(item))?[_c('v-list-item',[_c('v-list-item-content',{domProps:{"textContent":_vm._s(item)}})],1)]:_vm._e(),_c('v-list-item',[_c('perfil',{attrs:{"item":{
                          nombre: item.nombre,
                          imagen: '',
                          tipo: 'mascotas',
                          codigosInternos: _vm.codigosInternos(
                            item.codigosInternos
                          ),
                          tamano: 'sm'
                        }}}),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.propietario)}})],1)],1)]}}]),model:{value:(_vm.servicioClinica.idMascota),callback:function ($$v) {_vm.$set(_vm.servicioClinica, "idMascota", $$v)},expression:"servicioClinica.idMascota"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{ref:"tamano",attrs:{"label":"Tamaño","items":_vm.tamanos,"disabled":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({ actualRef: 'tamano', nextRef: 'direccion' })}},model:{value:(_vm.servicioClinica.tamano),callback:function ($$v) {_vm.$set(_vm.servicioClinica, "tamano", $$v)},expression:"servicioClinica.tamano"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.mostrarDireccion)?_c('v-textarea',{ref:"direccion",attrs:{"label":"Direccion","rows":"2"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'direccion',
                      nextRef: 'servicio'
                    })}},model:{value:(_vm.servicioClinica.direccion),callback:function ($$v) {_vm.$set(_vm.servicioClinica, "direccion", $$v)},expression:"servicioClinica.direccion"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{ref:"servicio",attrs:{"label":"Servicio","items":_vm.serviciosSelect,"item-nombre":"nombre","item-text":"nombre"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'servicio',
                      nextRef: 'tipoServicio'
                    })}},model:{value:(_vm.servicioClinica.servicio),callback:function ($$v) {_vm.$set(_vm.servicioClinica, "servicio", $$v)},expression:"servicioClinica.servicio"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{ref:"tipoServicio",attrs:{"label":"Tipo de servicio","items":_vm.tiposServicio ? _vm.tiposServicio : []},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'tipoServicio',
                      nextRef: 'motivoServicio'
                    })}},model:{value:(_vm.servicioClinica.tipoServicio),callback:function ($$v) {_vm.$set(_vm.servicioClinica, "tipoServicio", $$v)},expression:"servicioClinica.tipoServicio"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{ref:"motivoServicio",attrs:{"label":"Motivo del servicio","rows":"2"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'motivoServicio',
                      nextRef: 'serviciosADomicilio'
                    })}},model:{value:(_vm.servicioClinica.motivoServicio),callback:function ($$v) {_vm.$set(_vm.servicioClinica, "motivoServicio", $$v)},expression:"servicioClinica.motivoServicio"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{ref:"serviciosADomicilio",attrs:{"label":"SAD","chips":"","item-value":"id","item-text":"nombre","items":_vm.serviciosADomicilio,"multiple":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'serviciosADomicilio',
                      nextRef: 'cuidadoMedico'
                    })}},model:{value:(_vm.servicioClinica.idsSAD),callback:function ($$v) {_vm.$set(_vm.servicioClinica, "idsSAD", $$v)},expression:"servicioClinica.idsSAD"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{ref:"observaciones",attrs:{"label":"Observaciones","rows":"2"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext({
                      actualRef: 'observaciones',
                      nextRef: 'conCita'
                    })}},model:{value:(_vm.servicioClinica.observaciones),callback:function ($$v) {_vm.$set(_vm.servicioClinica, "observaciones", $$v)},expression:"servicioClinica.observaciones"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{ref:"conCita",attrs:{"label":"Con/Sin cita","chips":"","item-value":"id","item-text":"nombre","items":[
                    { nombre: 'Con Cita', id: 1 },
                    { nombre: 'Sin Cita', id: 0 }
                  ],"single":""},model:{value:(_vm.servicioClinica.conCita),callback:function ($$v) {_vm.$set(_vm.servicioClinica, "conCita", $$v)},expression:"servicioClinica.conCita"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cerrar}},[_vm._v(" Cerrar ")]),(_vm.servicioClinica.id == undefined)?_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.isSending},on:{"click":_vm.submitHandler}},[_vm._v(" Agregar ")]):_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.isSending},on:{"click":_vm.submitHandler}},[_vm._v(" Actualizar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }