<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      @keydown.esc="cerrar"
      @click:outside="cerrar"
      max-width="600px"
    >
      <v-card :loading="isLoading">
        <v-form ref="entryForm" v-model="valid" @submit.prevent="submitHandler">
          <v-card-title>
            <span class="headline">Agregar comision</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :rules="rules"
                    :items="empleados"
                    item-text="nombre"
                    item-value="id"
                    label="Empleado"
                    v-model="comision.idEmpleado"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    :rules="rules"
                    :items="categorias"
                    item-text="nombre"
                    item-value="id"
                    label="Categoría"
                    v-model="comision.idCategoria"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    :rules="rules"
                    :items="subcategoriasFiltradas"
                    item-text="nombre"
                    item-value="id"
                    label="Subcategoria"
                    v-model="comision.idSubcategoria"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :items="productosFiltrados"
                    item-text="nombre"
                    item-value="id"
                    label="Concepto"
                    v-model="comision.idProducto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    :rules="rules"
                    :items="['monto', 'porcentaje']"
                    item-text="nombre"
                    item-value="id"
                    label="Tipo"
                    v-model="comision.tipo"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    :rules="rules"
                    type="number"
                    label="Monto"
                    prefix="$"
                    suffix="MXN"
                    :min="0"
                    v-model="comision.monto"
                    v-if="comision.tipo == 'monto'"
                  />
                  <v-text-field
                    :rules="rules"
                    type="number"
                    label="Porcentaje"
                    suffix="%"
                    :min="0"
                    :max="100"
                    v-model="comision.porcentaje"
                    v-if="comision.tipo == 'porcentaje'"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitHandler"
              v-if="comision.id == undefined"
            >
              Agregar
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitHandler" v-else>
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "Modalcomisiones",
  data: () => ({
    isLoading: false,
    isSending: false,
    valid: false,
    tipoRequest: "",
    idComision: "0",
    empleados: [],
    categorias: [],
    subcategorias: [],
    productos: [],
    rules: [v => !!v || v == 0 || "El valor es requerido"],
    porcentajeRules: [v => v <= 100 || "Una comisión no puede exceder el 100%"],
    groupRules: [v => !!v || "El grupo es requerido"],
    comision: {
      idProducto: 0,
      tipo: "monto",
      monto: 0,
      porcentaje: 0
    }
  }),
  computed: {
    open() {
      return this.$store.state.modals.comisiones.open;
    },
    subcategoriasFiltradas() {
      if (this.comision?.idCategoria) {
        return [
          { id: 0, nombre: "Cualquiera" },
          ...this.subcategorias.filter(
            subcategoria =>
              subcategoria.idCategoria == this.comision.idCategoria
          )
        ];
      }
      return this.subcategorias;
    },
    productosFiltrados() {
      if (this.comision?.idSubcategoria) {
        return [
          { id: 0, nombre: "Cualquiera" },
          ...this.productos.filter(
            producto => producto.idSubcategoria == this.comision.idSubcategoria
          )
        ];
      }
      if (this.comision?.idCategoria) {
        return [
          { id: 0, nombre: "Cualquiera" },
          ...this.productos.filter(
            producto => producto.idCategoria == this.comision.idCategoria
          )
        ];
      }
      return this.productos;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.fetchEmpleados();
      this.fetchCategorias();
      this.fetchSubcategorias();
      this.fetchProductos();
    },
    async fetchEmpleados() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "empleados.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok")
        this.empleados = [
          /* 
          { id: 0, nombre: "cualquiera" }, */
          ...respuesta.data.rows
        ];
      this.isLoading = false;
    },
    async fetchCategorias() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "categorias.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok")
        this.categorias = [...respuesta.data.rows];
      this.isLoading = false;
    },
    async fetchSubcategorias() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "subcategorias.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok")
        this.subcategorias = [
          { id: 0, nombre: "Cualquiera" },
          ...respuesta.data.rows
        ];
      this.isLoading = false;
    },
    async fetchProductos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "productos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok")
        this.productos = [
          { id: 0, nombre: "Cualquiera" },
          ...respuesta.data.rows
        ];
      this.isLoading = false;
    },
    async insertcomision() {
      this.isSending = true;
      if (this.comision.id != undefined) {
        this.tipoRequest = "update";
      } else {
        this.tipoRequest = "insert";
      }
      let myUrl = process.env.VUE_APP_API_URL + "comisiones.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: this.tipoRequest,
        token: localStorage.token,
        geo: localStorage.geo,
        items: [this.comision]
      });
      if (respuesta.data.status == "Ok") {
        this.isSending = false;
        this.$store.state.modals["comisiones"].callback();
        if (this.comision.id == undefined) {
          this.$toasted.success("Comisión agregada", {
            position: "bottom-right",
            duration: 5000
          });
        } else {
          this.$toasted.success("Comisión actualizado", {
            position: "bottom-right",
            duration: 5000
          });
        }
        this.$refs.entryForm.reset();
        this.cerrar();
      }
    },
    async submitHandler() {
      //Antes de enviar hay que comprobar la relación de subcates y cates y conceptos, para que no haya registros fantasmas y que se confundan
      if (this.comision.idProducto == undefined) {
        this.comision.idProducto = 0;
      }
      let valido = await this.$refs.entryForm.validate();

      if (!this.comision.idProducto) this.comision.idProducto = 0;
      if (!this.comision.idCategoria) this.comision.idCategoria = 0;
      if (!this.comision.idSubcategoria) this.comision.idCategoria = 0;

      if (valido) {
        this.insertcomision();
      }
    },
    cerrar() {
      this.$store.commit("openModal", { modal: "comisiones", open: false });
    }
  }
};
</script>
