<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <h1>Reporte de Cierres de cajas</h1>
              <br />
              <v-row>
                <v-col cols="12" sm="5" md="5">
                  <label>Desde: </label>
                  <input type="date" v-model="desde" />
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <label>Hasta: </label>
                  <input type="date" v-model="hasta" />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-btn
                    elevation="2"
                    small
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="cierresCajas"
          :items-per-page="7"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          calculate-widths
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
export default {
  name: "ReportesCierresCajas",
  data: () => ({
    isLoading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    headers: [
      { text: "Id", value: "id" },
      { text: "Fecha", value: "fecha", width: "110px" },
      { text: "Hora", value: "hora" },
      { text: "Turno declarado", value: "turno" },
      {
        text: "Empleado de apertura",
        value: "empleadoApertura",
        width: "140px"
      },
      { text: "Empleado de cierre", value: "empleadoCierre", width: "140px" },
      { text: "Caja", value: "caja", width: "140px" },
      { text: "Efectivo", value: "efectivo", width: "130px" },
      { text: "Credito", value: "credito", width: "160px" },
      { text: "Debito", value: "debito", width: "160px" },
      { text: "Cheques", value: "cheque", width: "160px" },
      { text: "Transferencias", value: "transferencia", width: "160px" },
      { text: "Gastos", value: "gastos", width: "160px" },
      { text: "Compras", value: "compras", width: "160px" }
    ],
    cierresCajas: [],
    placeholderCierresCajas: [
      {
        id: "6034",
        fechaHora: "24/10/2021	14:09:58",
        fecha: "24/10/2021",
        hora: "14:09:58",
        empleadoApertura: "Diana Laura Campos Aguilar",
        empleadoCierre: "Diana Laura Campos Aguilar",
        caja: "1",
        status: "Cierre",
        turno: "Turno 2",
        efectivo: "6938.00",
        credito: "1898.00",
        debito: "6389.00",
        cheques: "0.00",
        transferencias: "0.00",
        gastos: "0.00",
        compras: "0.00",
        fondoCaja: "0.00",
        efectivoVenta: "6938.00",
        efectivoFondo: "0.00",
        efectivoTotal: "6938.00"
      }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchCierresCajas(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchCierresCajas(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  methods: {
    async fetchCierresCajas(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "cajas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllReporte",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta.data.status == "Ok") {
        this.cierresCajas = respuesta.data.rows;
        this.isLoading = false;
      }
      //this.cierresCajas = this.placeholderCierresCajas;
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var cierresCajas = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, cierresCajas, "Cierres cajas");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.cierresCajas.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            // Verificar si el valor es numérico
            let valor = registro[header.value];
            if (!isNaN(valor) && valor !== "") {
              // Convertir el valor a número
              valor = Number(valor);
            }
            XLSX.utils.sheet_add_aoa(sheet, [[valor]], {
              // Agregar el valor al archivo
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-CierreCajas.xlsx`);
    },
    initialize() {
      this.fetchCierresCajas(
        moment()
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment()
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }
};
</script>
