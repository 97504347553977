var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Precio rápido")]),_c('br'),_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","color":"pink darken-4","dense":""},on:{"keyup":_vm.handleSearchInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"min":0,"max":100,"label":"Incrementar precio por %","suffix":"%","type":"number","dense":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.risePriceByPercent}},[_c('v-icon',[_vm._v("mdi-arrow-up-bold-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.revertPriceByPercent}},[_c('v-icon',[_vm._v("mdi-arrow-down-bold")])],1)]},proxy:true}]),model:{value:(_vm.porcentaje),callback:function ($$v) {_vm.porcentaje=$$v},expression:"porcentaje"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.productos,"items-per-page":7,"search":_vm.search,"sort-by":"id","sort-desc":true,"footer-props":{
          'items-per-page-text': 'Items por pagina',
          'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
        },"loading":_vm.isLoading,"dense":""},scopedSlots:_vm._u([{key:"item.precio",fn:function(ref){
        var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.precio},on:{"blur":function (e) {
                _vm.updatePrecio(e, item);
              }}})]}},{key:"item.precioCompra",fn:function(ref){
              var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.precioCompra},on:{"blur":function (e) {
                _vm.updatePrecioCompra(e, item);
              }}})]}},{key:"item.minimo",fn:function(ref){
              var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.minimo},on:{"change":function (e) {
                _vm.handleMinimoChange(e, item);
              }}})]}},{key:"item.maximo",fn:function(ref){
              var item = ref.item;
return [_c('input',{attrs:{"type":"number"},domProps:{"value":item.maximo},on:{"change":function (e) {
                _vm.handleMaximoChange(e, item);
              }}})]}},{key:"item.controlPrecio",fn:function(ref){
              var item = ref.item;
return [_c('select',{attrs:{"type":"number"},domProps:{"value":item.controlPrecio}},[_c('option',{attrs:{"value":"Si"}},[_vm._v("Si")]),_c('option',{attrs:{"value":"No"}},[_vm._v("No")])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"pink darken-4 white--text my-6","rounded":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")])],1)]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }