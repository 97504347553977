<template>
  <v-dialog v-model="dialog" max-width="350">
    <template v-slot:activator="{ on, attrs }">
      <div>
        <v-btn
          color="primary"
          dark
          class="mx-4"
          outlined
          x-small
          v-bind="attrs"
          v-on="on"
          v-if="!isCaja"
        >
          Abrir caja
        </v-btn>
        <v-btn
          color="primary"
          dark
          class="mx-4"
          outlined
          x-small
          v-bind="attrs"
          v-on="on"
          v-if="isCaja"
        >
          Cerrar caja
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title class="text-h5" v-if="!isCaja">
        ¿Deseas abrir <br />
        una caja?
      </v-card-title>
      <v-card-title class="text-h5" v-if="isCaja">
        ¿Deseas cerrar <br />
        la caja {{ idCaja }}?
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <SelectEmpleado
              ref="selectEmpleado"
              :empleado.sync="empleado"
            ></SelectEmpleado>
          </v-col>
          <v-col cols="12" v-if="!isCaja">
            <v-select label="Caja" :items="[1, 2, 3, 4, 5]" v-model="caja" />
          </v-col>
          <v-col cols="12" v-if="!isCaja">
            <v-text-field label="Turno" type="text" v-model="turno" />
          </v-col>
          <v-col cols="12" v-if="isCaja">
            Empleado : {{ infoCierre.nombreEmpleado }}
          </v-col>

          <v-col>
            <v-simple-table>
              <tr v-for="(m, iMonto) in ingresos" :key="iMonto">
                <td>
                  {{
                    proStr(m.tipo == "puntosSaldo" ? "Puntos Saldo" : m.tipo, {
                      firstCapOnly: true
                    })
                  }}
                  {{ m.tipo == "cambio" ? "(Info)" : null }}
                  :
                </td>
                <td class="d-flex justify-end">
                  {{ mxnMoneyFormat(m.monto) }}
                </td>
              </tr>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="isCaja"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          text
          @click="
            () => {
              dialog = !dialog;
            }
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="info" text @click="cerrarCaja" v-if="isCaja">
          Cerrar caja
        </v-btn>
        <v-btn color="info" text @click="abrirCaja" v-if="!isCaja">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { proString, mxnMoneyFormat } from "../../tools/proStrings";
import SelectEmpleado from "../../components/SelectEmpleado.vue";
export default {
  name: "ModalCajas",
  components: { SelectEmpleado },
  data: () => ({
    isSending: false,
    dialog: false,
    caja: 0,
    idCaja: 0,
    turno: "",
    boolCajaTemporal: !!parseInt(localStorage.getItem("idCaja")),
    monto: 0,
    empleado: {},
    infoCierre: { nombreEmpleado: "", fondoInicial: 0 },
    ingresos: [],
    montoGastos: 0,
    montoEfectivo: 0
  }),
  computed: {
    isCaja() {
      return !!parseInt(this.idCaja) || this.boolCajaTemporal;
    }
  },
  mounted() {
    if (localStorage.idCaja) {
      this.idCaja = localStorage.idCaja;
    }
  },
  watch: {
    dialog(value) {
      if (value && this.isCaja) {
        this.getMontosCaja();
      }
    },
    idCaja(id) {
      localStorage.idCaja = id;
    }
  },
  methods: {
    proStr(value, opts) {
      return proString(value, opts);
    },
    mxnMoneyFormat,
    async abrirCaja() {
      this.empleado = { ...this.$refs.selectEmpleado?.getEmpleado() };
      if (!this.empleado?.id || !this.caja) {
        this.$toasted.error("Verifica la información", {
          position: "bottom-right",
          duration: 5000
        });
        return;
      }
      this.isSending = true;
      let myUrl = process.env.VUE_APP_API_URL + "cajas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "insertManejoCaja",
        token: localStorage.token,
        geo: localStorage.geo,
        idEmpleado: this.empleado.id,
        idCaja: this.caja,
        monto: this.monto,
        status: "apertura",
        turno: this.turno
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Caja abierta", {
          position: "bottom-right",
          duration: 5000
        });
        this.idCaja = this.caja;
        this.boolCajaTemporal = true;
        this.isSending = false;
        this.dialog = false;
      }
    },
    async cerrarCaja() {
      this.empleado = { ...this.$refs.selectEmpleado?.getEmpleado() };
      if (!this.empleado?.id) {
        this.$toasted.error("Selecciona el empleado que cierra la caja", {
          position: "bottom-right",
          duration: 5000
        });
        return;
      }
      this.isSending = true;
      let myUrl = process.env.VUE_APP_API_URL + "cajas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "insertManejoCaja",
        token: localStorage.token,
        geo: localStorage.geo,
        idEmpleado: this.empleado.id,
        idCaja: localStorage.getItem("idCaja"),
        monto: this.monto,
        status: "cierre",
        turno: this.turno
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Caja cerrada", {
          position: "bottom-right",
          duration: 5000
        });
        this.idCaja = 0;
        this.boolCajaTemporal = false;
        this.isSending = false;
        this.dialog = false;
        localStorage.idCaja = 0;
      }
    },
    async getMontosCaja() {
      this.isSending = true;
      let myUrl = process.env.VUE_APP_API_URL + "cajas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getInfoCierreCaja",
        token: localStorage.token,
        geo: localStorage.geo,
        idCaja: localStorage.getItem("idCaja")
      });
      if (respuesta.data.status == "Ok") {
        this.infoCierre = respuesta.data.infoCierre[0];
        this.montoGastos = respuesta.data.gastos[0].montoGastos;
        this.ingresos = respuesta.data.ingresos;
        this.montoEfectivo = this.ingresos.reduce((suma, ingreso) => {
          if (ingreso.tipo === "efectivo") {
            return suma + ingreso.monto;
          } else {
            return suma;
          }
        }, 0);
        this.$refs.selectEmpleado?.setEmpleadoById(this.infoCierre.idEmpleado);
        this.empleado = { ...this.$refs.selectEmpleado?.getEmpleado() };
        this.isSending = false;
      }
    }
  }
};
</script>
