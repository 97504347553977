<template>
  <v-autocomplete
    :items="clientes"
    :value="clienteSeleccionado"
    :search-input.sync="search"
    @keydown="handleSearchInput"
    :label="placeholder"
    item-text="nombre"
    return-object
    v-on:change="handleChange"
    :prepend-inner-icon="'mdi-account-circle'"
    :append-outer-icon="'mdi-magnify'"
    :loading="isLoading"
    :menu-props="{ maxWidth: '280px' }"
  >
    <template v-slot:item="{ item }">
      <Perfil
        :item="{
          nombre: item.nombre,
          imagen: '',
          tipo: 'clientes',
          codigosInternos: codigosInternos(item.codigosInternos),
          tamano: 'sm'
        }"
      ></Perfil>
      <v-list-item-content>
        <v-list-item-title v-text="item.nombre"></v-list-item-title>
        <v-list-item-subtitle
          v-text="`Saldo en puntos: ${item.puntosSaldo}`"
        ></v-list-item-subtitle>
        <v-list-item-subtitle
          v-text="`Saldo por cobrar: ${item.saldoPorCobrar}`"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from "vuex";
import Perfil from "../components/Perfil.vue";
export default {
  components: { Perfil },
  name: "ClientSelector",
  props: {
    callback: {
      type: Function,
      required: false,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: "Seleccione un cliente"
    }
  },
  data() {
    return {
      search: "",
      debouncing: null,
      clienteSeleccionado: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState("clientes", ["clientes"])
  },
  methods: {
    handleSearchInput() {
      clearTimeout(this.debouncing);
      this.debouncing = setTimeout(() => {
        this.fetchSearchClientes();
      }, 500);
    },
    async fetchSearchClientes() {
      this.isLoading = true;
      await this.$store.dispatch("clientes/getBySearch", this.search);
      this.isLoading = false;
    },
    setClienteById(idCliente) {
      this.clienteSeleccionado = this.clientes.find(
        cliente => cliente.id == idCliente
      );
    },
    handleChange(val) {
      this.clienteSeleccionado = val;
      this.callback(val);
    },

    codigosInternos(cods) {
      if (cods?.length && cods?.length > 1) {
        try {
          const parsedCods = JSON.parse(cods);

          if (Array.isArray(parsedCods)) {
            return [...parsedCods];
          }
          return [];
        } catch (error) {
          return [];
        }
      }
      return [];
    }
  }
};
</script>
