<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="12">
              <h1>Llamadas</h1>
              <br />
              <v-row>
                <v-col cols="12" sm="5" md="5">
                  <label>Desde: </label>
                  <input type="date" v-model="desde" />
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <label>Hasta: </label>
                  <input type="date" v-model="hasta" />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-btn
                    elevation="2"
                    small
                    color="green darken-2"
                    dark
                    @click="descargarReporte"
                    >Exportar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="llamadas"
          :items-per-page="7"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          calculate-widths
          dense
        >
          <template v-slot:no-data>
            <v-btn
              color="green darken-2 white--text my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
export default {
  name: "ReportesLlamadas",
  data: () => ({
    isLoading: false,
    menu: false,
    menu2: false,
    desde: "",
    hasta: "",
    headers: [
      { text: "Id", value: "id" },
      { text: "Fecha", value: "fecha", width: "110px" },
      { text: "Hora", value: "hora" },
      { text: "Cliente", value: "cliente" },
      { text: "Mascota", value: "mascota" },
      { text: "Tipo de llamada", value: "tipoLlamada", width: "130px" },
      { text: "Motivo", value: "motivo", width: "110px" },
      { text: "MVZ", value: "mvz" },
      { text: "Realizada", value: "realizada" },
      { text: "Status", value: "status", width: "140px" },
      {
        text: "Llamada de seg.",
        value: "llamadaSeguimiento",
        width: "130px"
      }
    ],
    llamadas: [],
    placeholderLlamadas: [
      {
        id: "6884",
        fechaHora: "23/10/2021	13:56:06",
        fecha: "23/10/2021",
        hora: "13:56:06",
        cliente: "Alma magaña",
        mascota: "Frodo(B)",
        tipoLlamada: "Filtro médico",
        motivo: "lesiones en piel",
        mvz: "D. Yurei Figueroa Pech",
        realizada: "No",
        mensaje: "",
        status: "No confirmó",
        llamadaSeguimiento: "No"
      },
      {
        id: "6865",
        fechaHora: "21/10/2021	13:14:05",
        fecha: "21/10/2021",
        hora: "13:14:05",
        cliente: "AIDE CAUICH CAUICH",
        mascota: "TOMMY",
        tipoLlamada: "Recordatorio",
        motivo: "revision",
        mvz: "Sofia de Atocha Quimé Canché",
        realizada: "Si",
        mensaje: "",
        status: "Cita confirmada",
        llamadaSeguimiento: "Si"
      },
      {
        id: "6873",
        fechaHora: "22/10/2021	12:46:54",
        fecha: "22/10/2021",
        hora: "12:46:54",
        cliente: "Guadalupe Del Rosario Chan Bacab",
        mascota: "clark",
        tipoLlamada: "Recordatorio",
        motivo: "revision",
        mvz: "Diana Laura Campos Aguilar",
        realizada: "Si",
        mensaje: "",
        status: "Cita confirmada",
        llamadaSeguimiento: "No"
      }
    ]
  }),
  created() {
    this.initialize();
  },
  watch: {
    desde(val) {
      this.fetchLlamadas(
        moment(val).format("YYYY-MM-DD HH:mm:ss"),
        moment(this.hasta).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    hasta(val) {
      this.fetchLlamadas(
        moment(this.desde).format("YYYY-MM-DD HH:mm:ss"),
        moment(val).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  methods: {
    async fetchLlamadas(desde, hasta) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "llamadas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAllReporte",
        token: localStorage.token,
        geo: localStorage.geo,
        desde: desde,
        hasta: hasta
      });
      if (respuesta) {
        /*this.llamadas = respuesta.data.rows;*/
        this.isLoading = false;
      }
      this.llamadas = this.placeholderLlamadas;
    },
    async descargarReporte() {
      let columnasProcesadas = [];
      var llamadas = XLSX.utils.json_to_sheet(columnasProcesadas);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, llamadas, "llamadas");
      var sheet = wb.Sheets[wb.SheetNames[0]];
      var offset = 1;

      this.llamadas.map((registro, i) => {
        let col = 0;
        this.headers.map(header => {
          if (i == 0) {
            XLSX.utils.sheet_add_aoa(sheet, [[header.text]], {
              origin: XLSX.utils.encode_cell({ r: i, c: col })
            });
          }
          if (Object.prototype.hasOwnProperty.call(registro, header.value)) {
            // Verificar si el valor es numérico
            let valor = registro[header.value];
            if (!isNaN(valor) && valor !== "") {
              // Convertir el valor a número
              valor = Number(valor);
            }
            XLSX.utils.sheet_add_aoa(sheet, [[valor]], {
              // Agregar el valor al archivo
              origin: XLSX.utils.encode_cell({ r: i + offset, c: col })
            });
          }
          col++;
        });
      });

      XLSX.writeFile(wb, `Reporte-llamadas.xlsx`);
    },
    initialize() {
      this.fetchLlamadas(
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }
};
</script>
