<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card :loading="isLoading">
        <v-card-title>
          <v-row align="end">
            <v-col cols="12" sm="6">
              <h1>Abonos</h1>
              <br />
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                color="red darken-3"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
              <v-btn
                elevation="2"
                small
                color="red darken-3 white--text "
                disabled
                v-if="false"
                >Agregar abono</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-dialog v-model="isVerifyModalOpen" max-width="520px">
          <v-card>
            <v-card-title>
              Por favor, ingresa credenciales que tengan permiso para ésta
              acción
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="usuario" label="Usuario"></v-text-field>
              <v-text-field
                v-model="password"
                label="Contraseña"
                type="password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="pedirPermiso()"
                :disabled="isVerifyBtnDisabled"
                >Verificar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
          :headers="headers"
          :items="abonos"
          :items-per-page="7"
          :search="search"
          :custom-filter="guessSearch"
          sort-by="id"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-text': 'Items por pagina',
            'items-per-page-options': [7, 25, 50, 100, 200, 500, -1]
          }"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.tipo`]="{ item }">
            <select v-model="item.tipo" @change="update(item)">
              <option value="efectivo">Efectivo</option>
              <option value="transferencia">Transferencia</option>
              <option value="izCredito">Ize. Crédito</option>
              <option value="izDebito">Ize. Débito</option>
              <option value="depEfectivo">Depósito en efectivo</option>
              <option value="credito">Tarjeta de crédito</option>
              <option value="debito">Tarjeta de débito</option>
              <option value="puntosSaldo">Pago con puntos</option>
            </select>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon @click="printItem(item)" color="info">
              <v-icon small> mdi-printer-outline </v-icon>
            </v-btn>
            <v-btn icon @click="openUpdateVerifyModal(item)" color="info">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon @click="openDeleteVerifyModal(item)" color="danger">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="red white--text darken-3 my-6"
              rounded
              @click="initialize"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <modal-abonos />
  </v-row>
</template>

<script>
import "jspdf-autotable";
import { matchStrings } from "../tools/proStrings";
import { proString } from "../tools/proStrings";
import axios from "axios";
import jsPDF from "jspdf";
import moment from "moment";
import ModalAbonos from "../layouts/AgregarEditar/ModalAbonos";
export default {
  name: "abonos",
  components: { ModalAbonos },
  data: () => ({
    search: "",
    isLoading: false,
    isVerifyModalOpen: false,
    isVerifyBtnDisabled: false,
    itemModal: {},
    usuario: "",
    password: "",
    headers: [
      { text: "Id", value: "id" },
      { text: "Cliente", value: "nombreCliente" },
      { text: "Cajero", value: "nombreEmpleado" },
      { text: "Vendedor", value: "nombreVendedor" },
      { text: "Monto", value: "monto" },
      { text: "Tipo", value: "tipo" },
      { text: "Id de venta", value: "idVenta" },
      { text: "Fecha Venta", value: "fechaVenta" },
      { text: "Fecha Ingreso", value: "fechaHora" },
      { text: "Acciones", value: "acciones", align: "right" }
    ],
    abonos: []
  }),
  created() {
    this.initialize();
  },
  watch: {
    search: function(val) {
      this.$store.commit("setSearchParams", {
        name: "abonos",
        search: val
      });
    },
    isVerifyModalOpen(val) {
      if (!val) return;
      this.usuario = "usuario";
      this.password = "contraseña";
    }
  },
  methods: {
    initialize() {
      this.fetchAbonos();
      this.search = this.$store.state.searchParams["abonos"];
    },

    async fetchAbonos() {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "abonos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getAll",
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") this.abonos = respuesta.data.rows;
      this.isLoading = false;
    },

    editItem(myItem) {
      this.$store.commit("setItemModal", {
        modal: "abonos",
        item: { ...myItem },
        callback: () => {
          this.fetchAbonos();
        }
      });
      this.$store.commit("openModal", {
        modal: "abonos",
        item: { ...myItem },
        open: true
      });
    },

    async deleteItem(item) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "abonos.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "delete",
        token: localStorage.token,
        geo: localStorage.geo,
        items: [item]
      });
      if (respuesta.data.status == "Ok") {
        this.$toasted.success("Abono eliminado", {
          position: "bottom-right",
          duration: 5000
        });
        this.fetchAbonos();
        this.isLoading = false;
      }
    },
    guessSearch(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        matchStrings({ s: search, str: value.toString() })
      );
    },
    async printItem(item) {
      this.isLoading = true;
      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "getOne",
        id: item.idVenta,
        token: localStorage.token,
        geo: localStorage.geo
      });
      if (respuesta.data.status == "Ok") {
        let venta = { ...respuesta.data };
        this.printVenta(venta, item);
      }
      this.isLoading = false;
    },
    printVenta(venta, abono) {
      //let pdfName = "test";
      let lineHeight = 3;
      let totalWidth = 80;
      let actualLine = 10;
      let sangria = 4;
      //let space = 5;
      var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [totalWidth, 160]
      });
      doc.setFont("helvetica");
      doc.setFontSize(7);
      doc.text("Abel Alfonso Ugalde Pérez", sangria, actualLine);
      actualLine += lineHeight;
      doc.text("RFC: UAPA810924HK6", sangria, actualLine);
      actualLine += lineHeight;
      doc.text("Calle 8 x 15 y 15-B #213 Vergel", sangria, actualLine);
      actualLine += lineHeight;
      doc.text("C.P. 97173", sangria, actualLine);
      actualLine += lineHeight;
      doc.line(sangria, actualLine, 80 - sangria, actualLine);
      actualLine += lineHeight + lineHeight / 2;
      doc.text(
        `Nuestro cliente: ${venta.clientes[0].nombre}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.text(`Tel: ${venta.clientes[0].tel1}`, sangria, actualLine);
      actualLine += lineHeight;
      doc.text(
        `Dirección: ${venta.clientes[0].direccion}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.line(sangria, actualLine, 80 - sangria, actualLine);
      actualLine += lineHeight + lineHeight / 2;
      doc.text(`Nota de venta no. ${venta?.id}`, sangria, actualLine);
      actualLine += lineHeight;
      doc.text(
        `Fecha: ${moment(abono?.fechaVenta, "yyyy-MM-DD")
          .locale("es-mx")
          .format("DD/MM/yyyy")}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.text(
        `Hora: ${moment(abono?.fechaVenta, "hh:mm:ss")
          .locale("es-mx")
          .format("h:mm a")}`,
        sangria,
        actualLine
      );
      actualLine += lineHeight;
      doc.line(sangria, actualLine, 80 - sangria, actualLine);
      actualLine += lineHeight + lineHeight / 2;

      let nombresMascotas = "";
      let mascotasTicket = venta.mascotas.filter(mascota => {
        let isIn = false;
        venta.items.map(item => {
          if (item.idMascota == mascota.id) {
            isIn = true;
          }
        });
        return isIn;
      });
      mascotasTicket.map((mascota, ind) => {
        if (ind != 0) {
          nombresMascotas += `, `;
        }
        nombresMascotas += `${mascota.nombre} `;
      });
      doc.text(`Mascota(s): ${nombresMascotas}`, sangria, actualLine);
      actualLine += lineHeight;
      doc.text("Le atendió: " + venta.empleados[0].nombre, sangria, actualLine);
      actualLine += lineHeight;

      doc.line(sangria, actualLine, 80 - sangria, actualLine);
      actualLine += lineHeight + lineHeight * 0.5;
      myActualText = `Usted abonó: ${abono.monto} en forma de: ${abono.tipo}`;
      doc.text(
        myActualText,
        totalWidth * 0.5 - myActualText.length * 0.5,
        actualLine
      );
      actualLine += lineHeight + lineHeight * 0.5;

      let tableHeight = 0;
      doc.autoTable({
        styles: {
          fontSize: "7",
          fillColor: "#fff",
          textColor: "#111",
          cellPadding: 1
        },
        theme: "striped",
        startY: actualLine,
        margin: {
          top: sangria,
          left: sangria,
          right: sangria,
          bottom: sangria
        },
        body: venta.items.map(item => {
          item.total = parseFloat(item.precio - item.descuentoAplicado).toFixed(
            2
          );
          return item;
        }),
        columns: [
          {
            header: "Cant.",
            dataKey: "cantidad"
          },
          {
            header: "Producto",
            dataKey: "nombre"
          },
          {
            header: "Precio",
            dataKey: "precioAplicado"
          },
          {
            header: "Desc.",
            dataKey: "descuentoAplicado"
          },
          {
            header: "Total",
            dataKey: "monto"
          }
        ],
        didDrawCell: data => {
          tableHeight = data.cursor.y + data.row.height;
        }
      });
      actualLine = lineHeight * 2 + tableHeight;
      let total = 0;
      venta.items.map(item => {
        total += parseFloat(item.monto);
      });
      let myActualText = `Total:  ${total.toFixed(2)}`;
      doc.text(
        myActualText,
        totalWidth * 0.5 - myActualText.length * 0.5,
        actualLine
      );
      actualLine += lineHeight;
      doc.line(sangria, actualLine, 80 - sangria, actualLine);
      actualLine += lineHeight + lineHeight * 0.5;
      doc.text(`Formas de pago:`, sangria, actualLine);
      actualLine += lineHeight;
      venta.ingresos.map(ingreso => {
        myActualText = `${proString(ingreso.tipo, { firstCap: true })} : ${
          ingreso.monto
        }`;
        doc.text(
          myActualText,
          totalWidth * 0.5 - myActualText.length * 0.5,
          actualLine
        );
        actualLine += lineHeight;
      });

      doc.line(sangria, actualLine, 80 - sangria, actualLine);
      actualLine += lineHeight + lineHeight * 0.5;
      venta.items.map(item => {
        myActualText = `${item.motivo == "Ninguno" ? null : item.motivo}`;
        doc.text(
          myActualText,
          totalWidth * 0.5 - myActualText.length * 0.5,
          actualLine
        );
        actualLine += lineHeight;
      });

      myActualText = `¡Gracias por su preferencia!`;
      doc.text(
        myActualText,
        totalWidth * 0.5 - myActualText.length * 0.5,
        actualLine
      );
      let img = new Image();
      img.src = require("@/assets/zhLogo.png");
      doc.addImage(img, "png", totalWidth - 30 - sangria, sangria, 30, 10); //doc.save(pdfName + ".pdf");
      doc.autoPrint();
      //This is a key for printing
      doc.output("dataurlnewwindow");
    },
    async pedirPermiso() {
      if (!this.usuario || !this.password) {
        return this.$toasted.error("Introduce un usuario y/o contraseña", {
          position: "bottom-right",
          duration: 1
        });
      }
      this.isVerifyBtnDisabled = true;
      this.$toasted.show("Cargando...", {
        position: "bottom-right",
        duration: 5000
      });

      let myUrl = process.env.VUE_APP_API_URL + "ventas.php";
      let respuesta = await axios.post(myUrl, {
        request: 1,
        tipo: "testUpdatePermisson",
        token: localStorage.token,
        geo: localStorage.geo,
        usuario: this.usuario.trim(),
        password: this.password
      });
      if (respuesta.data.permisson == "Ok") {
        this.verifyModalCallback();
        this.isVerifyModalOpen = false;
      } else {
        this.$toasted.error("Datos incorrectos", {
          position: "bottom-right",
          duration: 2000
        });
      }
      this.isVerifyBtnDisabled = false;
    },

    openUpdateVerifyModal(item) {
      this.itemModal = item;
      this.isVerifyModalOpen = true;
      this.verifyModalCallback = () => {
        this.editItem(this.itemModal);
      };
    },
    openDeleteVerifyModal(item) {
      this.itemModal = item;
      this.isVerifyModalOpen = true;
      this.verifyModalCallback = () => {
        this.deleteItem(this.itemModal);
      };
    }
  }
};
</script>
